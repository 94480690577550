.index2 section.content {
  margin: 101px 0px 0px 0px !important;
}
.index2 .navbar{width: 100%;}
/* ––––––––––––––––––––––––––––––––––––––––––––––––––
Screen style's
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.menu-container {
  width:100%;
  margin: 0 auto;
  background:#263238;
  position:fixed;
  top:60px;
  z-index:10;
}
.menu-mobile {
  display: none;
  padding: 20px;
}
.menu-mobile:after {
  content: "\f313";
  font-family: "Material-Design-Iconic-Font";
  font-size: 2.5rem;
  padding: 0;
  float: right;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-25%);
          transform: translateY(-25%);
}
.menu-dropdown-icon:before {
  content: "\f2ee";
  font-family: "Material-Design-Iconic-Font";
  display: none;
  cursor: pointer;
  float: right;
  padding:13px 20px;
  color: #ccc;
}
.menu > ul {
  margin: 0 auto;
  width: 100%;
  list-style: none;
  padding: 0;
  position: relative;
  /* IF .menu position=relative -> ul = container width, ELSE ul = 100% width */
  box-sizing: border-box;
}
.menu > ul:before,
.menu > ul:after {
  content: "";
  display: table;
}
.menu > ul:after {
  clear: both;
}
.menu > ul > li {
  float: left;
  padding: 0;
  margin: 0;
}
.menu > ul > li a {
  text-decoration: none;
  padding:10px 15px;
  display: block;
  color:#bbb;  
  font-size:14px;
}
.menu > ul > li a i{
  font-size: 16px;
}
.menu > ul > li:hover {
  background: #fff; 
}
.menu > ul > li:hover a{
  background: #fff;
  color:#3d4c5a;
}
.menu > ul > li > ul {
  display: none;
  width: 100%;
  background: #fff;
  padding:10px;
  position: absolute;
  z-index: 99;
  left: 0;
  margin: 2px 0 0 0;
  list-style: none;
  box-sizing: border-box;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  border-radius: 3px;
}
.menu > ul > li > ul.h-small-menu > li {width:33.33%;}
.menu > ul > li > ul:before,
.menu > ul > li > ul:after {
  content: "";
  display: table;
}
.menu > ul > li > ul:after {
  clear: both;
}
.menu > ul > li > ul > li {
  margin: 0;
  padding-bottom: 0;
  list-style: none;
  width: 25%;
  background: none;
  float: left;
  padding:0px;
}
.menu > ul > li > ul > li span {
  color: #777;
  padding:15px;
  width: 95%;
  display: block;
  border-bottom: 1px solid #ccc;
}
.menu > ul > li > ul > li a:hover{color:#fff;}
.menu > ul > li > ul > li > ul {
  display: block;
  padding: 0;
  margin: 10px 0 0;
  list-style: none;
  box-sizing: border-box;
}
.menu > ul > li > ul > li > ul:before,
.menu > ul > li > ul > li > ul:after {
  content: "";
  display: table;
}
.menu > ul > li > ul > li > ul:after {
  clear: both;
}
.menu > ul > li > ul > li > ul > li {
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: .8em;
}
.menu > ul > li > ul > li > ul > li a {
  border: 0;
}
.menu > ul > li > ul.normal-sub {
  width: 300px;
  left: auto;
  padding: 10px;
}
.menu > ul > li > ul.normal-sub > li {
  width: 100%;
}
.menu > ul > li > ul.normal-sub > li a {
  border: 0;
  padding:12px;
}
.menu > ul > li > ul.normal-sub > li a:hover{background:#00BCD4; color:#fff;}


/* ––––––––––––––––––––––––––––––––––––––––––––––––––
Mobile style's
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media only screen and (max-width: 959px) {
  .h-bars:before {
    content: '\E5D2';
    font-family: 'Material Icons';
    color: #000;
    line-height: 44px;
    font-size: 24px;
    position: absolute;
    top: 8px;
    left: 12px;
    margin-right: 10px;
  }

  .index2 section.content {
    margin: 60px 0px 0px 0px !important;
  }

  .menu-container {
    width: 100%;
    padding:0 20px;
  }

  .menu-mobile {
    display: block;
  }

  .menu-dropdown-icon:before {
    display: block;
    position: absolute;
    right: 0;
  }

  .menu > ul {
    display: none;
  }

  .menu > ul > li {
    width: 100%;
    float: none;
    display: block;
    position: relative;
  }

  .menu > ul > li a {
    padding: 13px 20px;
    width: 100%;
    display: block;
  }

  .menu > ul > li > ul {
    position: relative;
  }

  .menu > ul > li > ul.normal-sub {
    width: 100%;
  }

  .menu > ul > li > ul > li {
    float: none;
    width: 100%;
    margin-top: 0px;
  }

  .menu > ul > li > ul > li:first-child {
    margin: 0;
  }

  .menu > ul > li > ul > li > ul {
    position: relative;
  }

  .menu > ul > li > ul > li > ul > li {
    float: none;
  }

  .menu .show-on-mobile {
    display: block;    
    overflow-y: scroll;
    height: calc(100vh - 70px);
    margin-top: 10px;
  }

  .menu > ul > li > ul.h-small-menu > li {
    width: 100%;
  }
}
