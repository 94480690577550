#register, #login {
    width: 600px;
    padding: 0px 15px 15px 15px;
    border-radius: 5px;
    font-family: arial;
    line-height: 16px;
    color: #333333;
    font-size: 14px;
    background: #ffffff;
    margin: 100px auto;
  }
  
  form label, form input {
    display: block;
    margin-bottom: 10px;
    width: 90%
  }
  
  form input {
    padding: 10px;
    border: solid 1px #BDC7D8;
  
  }
  
  .button {
    background-color: #00BFFF;
    border-color: #3ac162;
    font-weight: bold;
    padding: 12px 15px;
    color: #ffffff;
  }
  .button1 {
    background-color:#600080;
    border-color: #3ac162;
    font-weight: bold;
    padding: 12px 15px;
    color: #ffffff;
  }
  
  .button2 {
    background-color:#f9e6ff;
    border-color: #3ac162;
    font-weight: bold;
    padding: 12px 15px;
    color: #ffffff;
    color: black
  }
  .errorMsg {
    color: #cc0000;
    margin-bottom: 12px;
  }
  
  .sucessMsg {
    color: #6B8E23;
    margin-bottom: 10px;
  }