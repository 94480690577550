@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700&display=swap');
@import '../src/assets/scss/_variables.scss';
@import '../src/assets/scss/_mixin.scss';
@import '../src/assets/scss/_icomoon.scss';
@import '../src/assets/scss/_login.scss';
@import '../src/assets/scss/_style.scss';
html,
body,
#root,
.App {
    width: 100%;
    height: 100%;
    font-family: 'Ubuntu', sans-serif;
}

.header .navbar {
    // background-color: transparent;
    background: #323c84
}

.hidden {
    display: none;
}

.customModal {
    width: 100%;
    height: 100%;
    max-width: 100%;
}

.file-drop {
    width: 100%;
    height: 100px;
    padding: 20px;
}