// Colors


// White
$white: #ffffff;
$white-smoke: #eeeeee;


// Grey
$grey-night-rider: #333333;
$dim-grey: #666666;
$grey-nobel: #999999;
$iron-grey: #c9cacb;
$very-light-grey: #cccccc;


// Violet
$vivid-violet: #662D91;
$persian-ndigo-violet: #450b71;
$cherry-pie-violet: #302d54;


// Blue
$summer-sky: #2cbde5;
$pattens-blue: #fafbfd;
$maya-blue: #55aef6;
$blue-chalk: #ebe6f2;

//Red
$red: #ff0000;



// common widths
$triangle-width: 34px;
$triangle-height: 34px;
