.login {

  display: grid;
  height: 100%;

  &__left-panel {
    @extend .app-bg;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;

    &--image {
      background: url('../src/assets/images/login-bg.png') scroll 0 0 / 100%;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      align-content: center;
      display: flex;
    }

  }
  &__right-panel {
    align-items: center;
    display: grid;
    justify-content: center;

    &--form {
      width: 450px;

      .btn-primary {
        background: $vivid-violet;
        width: 100%;
        border-radius: 4px;
        border: 0;
        height: 60px;
        font-size: 26px;
        margin-top: 30px;

        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active {
          background: $vivid-violet;
          outline: 0 none;
          box-shadow: none;
        }
      }

      .form-group {
        position: relative;
        margin-bottom: 50px;
      }

      .form-control:focus {
        box-shadow: none !important;
      }

      ::placeholder {
        color: $grey-nobel;
      }

      input {
        border: 1px solid $grey-nobel;
        border-radius: 4px;
        height: 60px;
        color: $grey-night-rider;
        font-size: 20px;
        cursor: text;

        &:focus {
          border-color: $grey-nobel;
        }
      }

      label {
        background: $white;
        font-size: 16px;
        color: $grey-nobel;
        position: absolute;
        top: -11px;
        left: 10px;
        padding: 0 5px;
      }

      label, input {
        transition: all 0.2s;
        touch-action: manipulation;
      }

      input:placeholder-shown + label {
        cursor: text;
        max-width: 66.66%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transform-origin: left bottom;
        transform: translate(0, 2.125rem) scale(1.5);
        color: $grey-nobel;
      }
      ::-webkit-input-placeholder {
        opacity: 0;
        transition: inherit;
      }
      input:focus::-webkit-input-placeholder {
        opacity: 0;
      }
      input:not(:placeholder-shown) + label,
      input:focus + label {
        transform: translate(0, 0) scale(1);
        cursor: pointer;
      }

      .forgot-password-link {
        text-align: center;
        width: 100%;
        margin: 10px 0;

        .btn-link {
          color: $summer-sky;
        }
      }
    }
  }
}

.error {
  label {
    color: $red;
  }
  input {
    border-color: $red;
  }
  .invalid-feedback {
    display: block;
    position: absolute;
    left: 15px;
    bottom: -25px;
    color: $red;
  }
}