    .app-layout {
  margin-top: -87px;
  padding: 15px;
  background-color: $white;
  border-radius: 12px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  min-height: 1000px;
  height: calc(100vh - 110px);

  .card {
    border: 0;
    background: transparent;
  }

  .page-title {
    font-size: 22px;
    color: $grey-night-rider;
    font-weight: 500;
  }
}

.btn-secondary {
  background: $vivid-violet;
  color: $white;
  border-radius: 20px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  padding-left: 15px;
  padding-right: 15px;


  &:hover, &:focus,
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    background: $vivid-violet;
    color: $white;
  }
}

.document {
  &-title {
    color: $grey-night-rider;
    line-height: 22px;
  }
  &-id {
    color: $grey-nobel;
  }
}

.data-content {
  background: $pattens-blue;
  border-radius: 12px;
  padding-bottom: 40px;
}

.pattern {
  background-image: url('../src/assets/images/container_pattern.png');
  background-repeat: repeat;
}

.date-time {
  span {
    margin-right: 10px;
  }
}

.more-action-items-td {
  > div {
    background: $white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 160px;

    > .custom-icon {
      color: $grey-nobel;
      border: 0;
      background: transparent;
      padding: 0;
    }
    .icon-three-dot {
      &:after {
        font-family: icomoon;
        content: "\e903";
        border: 0;
      }
    }

    .fade {
      position: absolute;
      right: 0;
      display: block;
    }

    &:hover {
      .fade {
        &.action-links {
          flex-direction: row;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          opacity: 1;
          background: $white;

          .btn-outline-light {
            color: $grey-nobel;
            border-radius: 50%;
          }
        }
      }
    }
  }
}


/* Hide the browser's default checkbox */
.ckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 25px;
  left: 22px;
  height: 14px;
  width: 14px;
  border: 1px solid $iron-grey;
  cursor: pointer;
}

/* On mouse-over, add a grey background color */
.ckbox:hover input ~ .checkmark {
}

/* When the checkbox is checked, add a blue background */
.ckbox input:checked ~ .checkmark {
  border: 1px solid $dim-grey;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.ckbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.ckbox .checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 8px;
  border: solid $dim-grey;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.right-panel {
  padding: 20px;
  .section-title {
    font-size: 18px;
    font-weight: 500;
    color: $grey-night-rider;
    line-height: 1;

    .custom-link {
      font-size: 18px;
      font-weight: 500;
      color: $grey-night-rider;
      background: transparent;
      line-height: 1;
      padding: 0;

      span {
        padding-right: 5px;
        margin-top: 2px;
        font-size: 14px;
      }
    }
  }
}
.card-list-items {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    border-left: 2px dashed $vivid-violet;
    top: 0;
    left: 50%;
  }

  > .row:nth-child(odd) {
    > .col:first-child() {
        text-align: right;
    }
  }
  > .row:nth-child(even) {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;

    > .col:first-child() {
      text-align: left;
    }
  }

  > .row {
    margin-bottom: 20px;

    .col-sm-1 {
      width: 50px !important;
      flex: 0 0 50px;
      padding: 20px 10px;
    }
    .date {
      color: $grey-nobel;
      margin-top: 15px;
      display: block;
    }

    .card {
      &.v-card {
        background: $white;
        border: 1px solid $white;
        border-radius: 6px;
        margin-top: 15px;

        .card-body {
          padding: 10px 15px;
          .card-text {
            margin-bottom: 0;
          }
        }

        label {
          color: $grey-nobel;
          padding-right: 10px;
          margin-bottom: 0;
        }

        .card-link {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          color: $summer-sky;

          .icon-edit {
            float: right;
            color: $summer-sky;
            opacity: 0;
          }
        }
        &:hover {
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
          .icon-edit {
            opacity: 1;
          }
        }
      }
    }

    .version-count {
      transform: rotate(45deg);
      position: relative;

      .triangle {
        position: absolute;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#55aef6+0,662d91+100 */
        background: #55aef6; /* Old browsers */
        background: -moz-linear-gradient(top,  #55aef6 0%, #662d91 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  #55aef6 0%,#662d91 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  #55aef6 0%,#662d91 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#55aef6', endColorstr='#662d91',GradientType=0 ); /* IE6-9 */
        border: 1px;
        width: $triangle-width;
        height: $triangle-height;
        display: block;
        transform: rotate(45deg);
        border-radius: 4px;
        border: 4px solid $pattens-blue;
      }
      .triangle:hover
      {
        -webkit-transform: scale(1.9);
    -ms-transform: scale(1.9);
    transform: scale(2.1);
    border-radius: 50%;
    background: #ff7b29;

background-position: left bottom;

      }

      span {
        color: $white;
        font-size: 16px;
        position: relative;
        z-index: 9;
        width: $triangle-width;
        height: calc(#{$triangle-height} - 2px);
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
      }
    }

    &.selected {
      .date {
        color: $grey-night-rider;
      }
      .card {
        &.v-card {
          border: 1px solid $vivid-violet;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        }
      }

      .version-count {
        .triangle {
          border: 4px solid $blue-chalk;
          width: calc(#{$triangle-width} + 6px);
          height: calc(#{$triangle-height} + 6px);
          margin-left: -4px;
        }

        span {
          width: calc(#{$triangle-width} + 6px);
          height: calc(#{$triangle-height} + 4px);
          font-size: 18px;
          margin-left: -4px;
        }
      }
    }

  }
}

.card-list-notes {
  margin-top: 20px;
  > .row {
    padding-top: 15px;
    border-bottom: 1px solid $very-light-grey;

    .date-time {
      color: $grey-nobel;
      text-align: right;
    }

    h5, p {
      font-size: 16px;
      color: $grey-night-rider;
    }

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }
}