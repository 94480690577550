/* html,
body {
    width: 100%;
} */

.main-padding {
    padding-left: 7rem;
}

.navbar {
    width: 100%;
    background-color: #FAFCFF;
}

.digital-sign {
    color: #110151;
}

.section1 {
    background-color: #FAFCFF;
    /* height: 100%; */
    /* padding-left: 100px; */
}

.card .card-custom {
    background-color: #6C63FF;
    padding: 20px;
}

.easytouse {
    padding-right: 7rem;
}

.footer {
    background: url("./assets/Vector.svg");
    background-size: cover;
    min-height: 200px;
    color: white;
}

.section2 {
    background-color: white;
    /* height: 100%; */
}

body {
    background-color: white !important;
    font-family: sans-serif;
}