@import './_variables.scss';
@import './_mixin.scss';

// .navbar {
//   transition: top 0.5s
// }
body {
  margin: 0;
  padding: 0;
}

// .header {
//   padding: 20px
// }

nav {
  width: 100%;
  margin: 0;
  top: 0;
  background: #323c84;;
  color: #ccc;
}

ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

li {
  height: 100%;
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

li:hover {
  background: #848484;
  color: #212121;
}

.fixed-nav {
  position: fixed;
  font-weight:bold;
   font-size:1.2em;
   font-family: Arial, Helvetica, sans-serif;

}

.content {
  width: 80%;
  margin: 0 auto;
  text-align: justify;
  padding-top: 20px;
}

.content > div {
  margin: 10px 0;
}




.header {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#323c84+0,662d91+100 */
  background: #323c84; /* Old browsers */
  background: -moz-linear-gradient(top,  #323c84 0%, #662d91 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #323c84 0%,#662d91 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #323c84 0%,#662d91 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#323c84', endColorstr='#662d91',GradientType=0 ); /* IE6-9 */

  height: 177px;
  width: 100%;
  display: block;

  .navbar {
    padding: 0;
    width:100%;
  }

  .navbar-brand {
    padding: 0;
    margin-right: 80px;
    img {
      width: 189px;
    }
  }

  
  .navbar-light .navbar-nav .nav-link {
    color: $white-smoke;
    font-size: 22px;
    font-weight: 300;
    padding: 28px 14px;
    min-width: 120px;

    &.active {
      font-weight: 500;
      background: $cherry-pie-violet;
      color: $white;

    }
  }

  .form-inline .form-control {
    background: $cherry-pie-violet;
    color: $white;
    border: 0;
    
  }

  .btn-outline-light {
    border: 0;
    background: transparent;

    &:hover {
      color: $white;
    }
  }
  .btn-outline-light:not(:disabled):not(.disabled).active,
  .btn-outline-light:not(:disabled):not(.disabled):active,
  .show>.btn-outline-light.dropdown-toggle{
      background: transparent;
      color: $white;
      border: 0;
  }
}

.search-component {
  position: relative;

  input {
    padding-right: 50px;
  }
  .btn {
    position: absolute;
    right: 10px;
    top: 5px;
    border: 0;
    color: rgba($white-smoke, .6);
  }
}

.custom-icon,
.custom-icon:active,
.custom-icon:focus,
.btn-outline-success.custom-icon:not(:disabled):not(.disabled).active,
.btn-outline-success.custom-icon:not(:disabled):not(.disabled):active, .show>.btn-outline-success.custom-icon.dropdown-toggle,
.btn-outline-success.custom-icon:hover {
  background: transparent;
  color: rgba($white-smoke, .6);
  box-shadow: none;
  border: 0;

  &.icon-notifications {
    position: relative;
    .notification {
      position: absolute;
      width: 4px;
      height: 4px;
      background: $red;
      display: block;
      top: 4px;
      right: 15px;
      border-radius: 50%;
    }
  }
}

.user-avatar {

  .dropdown-toggle {
    display: flex;
    align-items: center;
    &:after {
      font-family: icomoon;
      content: "\e902";
      border: 0;
      font-size: 12px;
      margin-left: 10px;
      margin-top: 4px;
      margin-right: 10px;
    }

    &:active, &:focus {
      outline: 0 !important;
      box-shadow: none !important;
    }
  }
}
