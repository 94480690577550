@import './_variables.scss';

.ReactTable {
  border: 0;

  // <thead>
  .rt-thead {
    &.-header {
      border: 0;
      box-shadow: none;

      .rt-th {
        padding: 25px 15px;
        text-align: left;
        border-right: 0;
        color: rgba($dim-grey, .8);

        &.-sort-asc, &.-sort-desc {
          box-shadow: none;
          color: $grey-night-rider;
          font-weight: 500;
          > div {
            position: relative;
            display: inline-block;
            &:before {
              font-size: 12px;
              font-family: icomoon;
              position: absolute;
              right: -22px;
              top: 3px;
              content: "\e904";
            }
          }
        }
        &.-sort-desc {
          > div {
            &:before {
              transform: rotate(-180deg);
            }
          }
        }
      }
    }
  }

  // <tbody>
  .rt-tbody {
    .rt-td {
      padding: 7px 15px;
      border: 0;
      align-content: center;
      align-items: center;
      display: flex;
      color: $grey-night-rider;
      overflow: visible;
    }
  }

  .rt-tr-group,
  .rt-tr {
    border-bottom: 1px solid $iron-grey;
  }
  .rt-tr {
    &:hover {
      background: $white;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
  }

  .show>.btn-success.dropdown-toggle,
  .btn:focus, .btn:active,
  .btn:not(:disabled):not(.disabled).active:focus,
  .btn:not(:disabled):not(.disabled):active:focus,
  .show>.btn.dropdown-toggle:focus {
    box-shadow: none !important;
    background: transparent !important;
  }
  .show>.btn-success.dropdown-toggle {
    color: $dim-grey;
  }
}