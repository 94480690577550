@import url("https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700&display=swap");
.app-bg, .login__left-panel {
  background: #662d91;
  /* Old browsers */
  background: -moz-linear-gradient(top, #662d91 0%, #2d084a 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #662d91 0%, #2d084a 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #662d91 0%, #2d084a 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#662d91', endColorstr='#2d084a',GradientType=0 );
  /* IE6-9 */ }

@font-face {
  font-family: 'icomoon';
  src: url("../src/assets/fonts/icomoon.eot?wt70xx");
  src: url("../src/assets/fonts/icomoon.eot?wt70xx#iefix") format("embedded-opentype"), url("../src/assets/fonts/icomoon.ttf?wt70xx") format("truetype"), url("../src/assets/fonts/icomoon.woff?wt70xx") format("woff"), url("../src/assets/fonts/icomoon.svg?wt70xx#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 16px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-search:before {
  content: "\e900"; }

.icon-notifications:before {
  content: "\e901"; }

.icon-drop-down:before {
  content: "\e902"; }

.icon-three-dots:before {
  content: "\e903"; }

.icon-sort:before {
  content: "\e904"; }

.icon-back:before {
  content: "\e905"; }

.icon-download:before {
  content: "\e906"; }

.icon-duplicate:before {
  content: "\e907"; }

.icon-edit:before {
  content: "\e908"; }

.login {
  display: grid;
  height: 100%; }
  .login__left-panel {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    align-content: stretch; }
    .login__left-panel--image {
      background: url("../src/assets/images/login-bg.png") scroll 0 0/100%;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      align-content: center;
      display: flex; }
  .login__right-panel {
    align-items: center;
    display: grid;
    justify-content: center; }
    .login__right-panel--form {
      width: 450px; }
      .login__right-panel--form .btn-primary {
        background: #662D91;
        width: 100%;
        border-radius: 4px;
        border: 0;
        height: 60px;
        font-size: 26px;
        margin-top: 30px; }
        .login__right-panel--form .btn-primary:not(:disabled):not(.disabled).active, .login__right-panel--form .btn-primary:not(:disabled):not(.disabled):active {
          background: #662D91;
          outline: 0 none;
          box-shadow: none; }
      .login__right-panel--form .form-group {
        position: relative;
        margin-bottom: 50px; }
      .login__right-panel--form .form-control:focus {
        box-shadow: none !important; }
      .login__right-panel--form ::placeholder {
        color: #999999; }
      .login__right-panel--form input {
        border: 1px solid #999999;
        border-radius: 4px;
        height: 60px;
        color: #333333;
        font-size: 20px;
        cursor: text; }
        .login__right-panel--form input:focus {
          border-color: #999999; }
      .login__right-panel--form label {
        background: #ffffff;
        font-size: 16px;
        color: #999999;
        position: absolute;
        top: -11px;
        left: 10px;
        padding: 0 5px; }
      .login__right-panel--form label, .login__right-panel--form input {
        transition: all 0.2s;
        touch-action: manipulation; }
      .login__right-panel--form input:placeholder-shown + label {
        cursor: text;
        max-width: 66.66%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transform-origin: left bottom;
        transform: translate(0, 2.125rem) scale(1.5);
        color: #999999; }
      .login__right-panel--form ::-webkit-input-placeholder {
        opacity: 0;
        transition: inherit; }
      .login__right-panel--form input:focus::-webkit-input-placeholder {
        opacity: 0; }
      .login__right-panel--form input:not(:placeholder-shown) + label,
      .login__right-panel--form input:focus + label {
        transform: translate(0, 0) scale(1);
        cursor: pointer; }
      .login__right-panel--form .forgot-password-link {
        text-align: center;
        width: 100%;
        margin: 10px 0; }
        .login__right-panel--form .forgot-password-link .btn-link {
          color: #2cbde5; }

.error label {
  color: #ff0000; }

.error input {
  border-color: #ff0000; }

.error .invalid-feedback {
  display: block;
  position: absolute;
  left: 15px;
  bottom: -25px;
  color: #ff0000; }

.app-layout {
  margin-top: -87px;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  min-height: 1000px;
  height: calc(100vh - 110px); }
  .app-layout .card {
    border: 0;
    background: transparent; }
  .app-layout .page-title {
    font-size: 22px;
    color: #333333;
    font-weight: 500; }

.btn-secondary {
  background: #662D91;
  color: #ffffff;
  border-radius: 20px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  padding-left: 15px;
  padding-right: 15px; }
  .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active {
    background: #662D91;
    color: #ffffff; }

.document-title {
  color: #333333;
  line-height: 22px; }

.document-id {
  color: #999999; }

.data-content {
  background: #fafbfd;
  border-radius: 12px;
  padding-bottom: 40px; }

.pattern {
  background-image: url("../src/assets/images/container_pattern.png");
  background-repeat: repeat; }

.date-time span {
  margin-right: 10px; }

.more-action-items-td > div {
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 160px; }
  .more-action-items-td > div > .custom-icon {
    color: #999999;
    border: 0;
    background: transparent;
    padding: 0; }
  .more-action-items-td > div .icon-three-dot:after {
    font-family: icomoon;
    content: "\e903";
    border: 0; }
  .more-action-items-td > div .fade {
    position: absolute;
    right: 0;
    display: block; }
  .more-action-items-td > div:hover .fade.action-links {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    opacity: 1;
    background: #ffffff; }
    .more-action-items-td > div:hover .fade.action-links .btn-outline-light {
      color: #999999;
      border-radius: 50%; }

/* Hide the browser's default checkbox */
.ckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 25px;
  left: 22px;
  height: 14px;
  width: 14px;
  border: 1px solid #c9cacb;
  cursor: pointer; }

/* On mouse-over, add a grey background color */
/* When the checkbox is checked, add a blue background */
.ckbox input:checked ~ .checkmark {
  border: 1px solid #666666; }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none; }

/* Show the checkmark when checked */
.ckbox input:checked ~ .checkmark:after {
  display: block; }

/* Style the checkmark/indicator */
.ckbox .checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 8px;
  border: solid #666666;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.right-panel {
  padding: 20px; }
  .right-panel .section-title {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 1; }
    .right-panel .section-title .custom-link {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      background: transparent;
      line-height: 1;
      padding: 0; }
      .right-panel .section-title .custom-link span {
        padding-right: 5px;
        margin-top: 2px;
        font-size: 14px; }

.card-list-items {
  position: relative; }
  .card-list-items:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    border-left: 2px dashed #662D91;
    top: 0;
    left: 50%; }
  .card-list-items > .row:nth-child(odd) > .col:first-child {
    text-align: right; }
  .card-list-items > .row:nth-child(even) {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch; }
    .card-list-items > .row:nth-child(even) > .col:first-child {
      text-align: left; }
  .card-list-items > .row {
    margin-bottom: 20px; }
    .card-list-items > .row .col-sm-1 {
      width: 50px !important;
      flex: 0 0 50px;
      padding: 20px 10px; }
    .card-list-items > .row .date {
      color: #999999;
      margin-top: 15px;
      display: block; }
    .card-list-items > .row .card.v-card {
      background: #ffffff;
      border: 1px solid #ffffff;
      border-radius: 6px;
      margin-top: 15px; }
      .card-list-items > .row .card.v-card .card-body {
        padding: 10px 15px; }
        .card-list-items > .row .card.v-card .card-body .card-text {
          margin-bottom: 0; }
      .card-list-items > .row .card.v-card label {
        color: #999999;
        padding-right: 10px;
        margin-bottom: 0; }
      .card-list-items > .row .card.v-card .card-link {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        color: #2cbde5; }
        .card-list-items > .row .card.v-card .card-link .icon-edit {
          float: right;
          color: #2cbde5;
          opacity: 0; }
      .card-list-items > .row .card.v-card:hover {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
        .card-list-items > .row .card.v-card:hover .icon-edit {
          opacity: 1; }
    .card-list-items > .row .version-count {
      transform: rotate(45deg);
      position: relative; }
      .card-list-items > .row .version-count .triangle {
        position: absolute;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#55aef6+0,662d91+100 */
        background: #55aef6;
        /* Old browsers */
        background: -moz-linear-gradient(top, #55aef6 0%, #662d91 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #55aef6 0%, #662d91 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #55aef6 0%, #662d91 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#55aef6', endColorstr='#662d91',GradientType=0 );
        /* IE6-9 */
        border: 1px;
        width: 34px;
        height: 34px;
        display: block;
        transform: rotate(45deg);
        border-radius: 4px;
        border: 4px solid #fafbfd; }
      .card-list-items > .row .version-count .triangle:hover {
        -webkit-transform: scale(1.9);
        -ms-transform: scale(1.9);
        transform: scale(2.1);
        border-radius: 50%;
        background: #ff7b29;
        background-position: left bottom; }
      .card-list-items > .row .version-count span {
        color: #ffffff;
        font-size: 16px;
        position: relative;
        z-index: 9;
        width: 34px;
        height: calc(34px - 2px);
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center; }
    .card-list-items > .row.selected .date {
      color: #333333; }
    .card-list-items > .row.selected .card.v-card {
      border: 1px solid #662D91;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
    .card-list-items > .row.selected .version-count .triangle {
      border: 4px solid #ebe6f2;
      width: calc(34px + 6px);
      height: calc(34px + 6px);
      margin-left: -4px; }
    .card-list-items > .row.selected .version-count span {
      width: calc(34px + 6px);
      height: calc(34px + 4px);
      font-size: 18px;
      margin-left: -4px; }

.card-list-notes {
  margin-top: 20px; }
  .card-list-notes > .row {
    padding-top: 15px;
    border-bottom: 1px solid #cccccc; }
    .card-list-notes > .row .date-time {
      color: #999999;
      text-align: right; }
    .card-list-notes > .row h5, .card-list-notes > .row p {
      font-size: 16px;
      color: #333333; }
    .card-list-notes > .row img {
      width: 50px;
      height: 50px;
      border-radius: 50%; }

html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
  font-family: 'Ubuntu', sans-serif; }

.header .navbar {
  background: #323c84; }

.hidden {
  display: none; }

.customModal {
  width: 100%;
  height: 100%;
  max-width: 100%; }

.file-drop {
  width: 100%;
  height: 100px;
  padding: 20px; }
