.theme-purple .menu>ul>li>ul.normal-sub>li a:hover,
.theme-purple .menu>ul>li>ul>li>ul>li a:hover {
	background-color: #8c99e0
}

.theme-purple.sidebar-collapse .navbar-collapse:before {
	background-color: #6572b8
}

.theme-purple .btn-primary {
	background: #662d91
}

.theme-purple .btn-primary:active,
.theme-purple .btn-primary:hover,
.theme-purple .btn-primary:focus {
	background: #662d91
}

.theme-purple .btn-primary.btn-simple {
	border-color: #6572b8;
	color: #6572b8;
	background: transparent
}

.theme-purple .block-header h2 {
	color: #fff
}

.theme-purple .block-header h2 small {
	color: #fff
}

.theme-purple section.content:before {
	background: linear-gradient(to bottom, #662d91, #323c84);
	
}

.theme-purple .navbar,
.theme-purple .page-loader-wrapper {
	background: linear-gradient(to bottom, #323c84, #662d91);
	
}

.theme-purple .bars {
	color: #fff
}

.theme-purple .sidebar .menu .list li.active {
	background-color: transparent
}

.theme-purple .sidebar .menu .list li.active>:first-child i,
.theme-purple .sidebar .menu .list li.active>:first-child span {
	color: #6572b8
}

.theme-purple .sidebar .menu .list a:hover {
	color: #6572b8
}

.theme-purple .checkbox input[type="checkbox"]:checked+label::after {
	border: 1px solid #6572b8
}

.theme-purple .nav-tabs .nav-link.active {
	border: 1px solid #6572b8 !important
}

.theme-purple .card .header h2:before {
	background: #6572b8
}

.theme-purple .card .header h2 strong {
	color: #6572b8
}

.theme-blue .menu>ul>li>ul.normal-sub>li a:hover,
.theme-blue .menu>ul>li>ul>li>ul>li a:hover {
	background-color: #60bafd
}

.theme-blue.sidebar-collapse .navbar-collapse:before {
	background-color: #3eacff
}

.theme-blue .btn-primary {
	background: #3eacff
}

.theme-blue .btn-primary:active,
.theme-blue .btn-primary:hover,
.theme-blue .btn-primary:focus {
	background: #60bafd
}

.theme-blue .btn-primary.btn-simple {
	border-color: #3eacff;
	color: #3eacff;
	background: transparent
}

.theme-blue .block-header h2 {
	color: #fff
}

.theme-blue .block-header h2 small {
	color: #fff
}

.theme-blue section.content:before {
	background-color: #60bafd
}

.theme-blue .navbar,
.theme-blue .page-loader-wrapper {
	background: #60bafd
}

.theme-blue .bars {
	color: #fff
}

.theme-blue .sidebar .menu .list li.active {
	background-color: transparent
}

.theme-blue .sidebar .menu .list li.active>:first-child i,
.theme-blue .sidebar .menu .list li.active>:first-child span {
	color: #3eacff
}

.theme-blue .sidebar .menu .list a:hover {
	color: #3eacff
}

.theme-blue .checkbox input[type="checkbox"]:checked+label::after {
	border: 1px solid #3eacff
}

.theme-blue .nav-tabs .nav-link.active {
	border: 1px solid #3eacff !important
}

.theme-blue .card .header h2:before {
	background: #3eacff
}

.theme-blue .card .header h2 strong {
	color: #3eacff
}

.theme-cyan .menu>ul>li>ul.normal-sub>li a:hover,
.theme-cyan .menu>ul>li>ul>li>ul>li a:hover {
	background-color: #01d8da
}

.theme-cyan.sidebar-collapse .navbar-collapse:before {
	background-color: #00cfd1
}

.theme-cyan .btn-primary {
	background: #00cfd1
}

.theme-cyan .btn-primary:active,
.theme-cyan .btn-primary:hover,
.theme-cyan .btn-primary:focus {
	background: #01d8da
}

.theme-cyan .btn-primary.btn-simple {
	border-color: #00cfd1;
	color: #00cfd1;
	background: transparent
}

.theme-cyan .block-header h2 {
	color: #fff
}

.theme-cyan .block-header h2 small {
	color: #fff
}

.theme-cyan section.content:before {
	background-color: #01d8da
}

.theme-cyan .navbar,
.theme-cyan .page-loader-wrapper {
	background: #01d8da
}

.theme-cyan .bars {
	color: #fff
}

.theme-cyan .sidebar .menu .list li.active {
	background-color: transparent
}

.theme-cyan .sidebar .menu .list li.active>:first-child i,
.theme-cyan .sidebar .menu .list li.active>:first-child span {
	color: #00cfd1
}

.theme-cyan .sidebar .menu .list a:hover {
	color: #00cfd1
}

.theme-cyan .checkbox input[type="checkbox"]:checked+label::after {
	border: 1px solid #00cfd1
}

.theme-cyan .nav-tabs .nav-link.active {
	border: 1px solid #00cfd1 !important
}

.theme-cyan .card .header h2:before {
	background: #00cfd1
}

.theme-cyan .card .header h2 strong {
	color: #00cfd1
}

.theme-green .menu>ul>li>ul.normal-sub>li a:hover,
.theme-green .menu>ul>li>ul>li>ul>li a:hover {
	background-color: #5cdb94
}

.theme-green.sidebar-collapse .navbar-collapse:before {
	background-color: #50d38a
}

.theme-green .btn-primary {
	background: #50d38a
}

.theme-green .btn-primary:active,
.theme-green .btn-primary:hover,
.theme-green .btn-primary:focus {
	background: #5cdb94
}

.theme-green .btn-primary.btn-simple {
	border-color: #50d38a;
	color: #50d38a;
	background: transparent
}

.theme-green .block-header h2 {
	color: #fff
}

.theme-green .block-header h2 small {
	color: #fff
}

.theme-green section.content:before {
	background-color: #5cdb94
}

.theme-green .navbar,
.theme-green .page-loader-wrapper {
	background: #5cdb94
}

.theme-green .bars {
	color: #fff
}

.theme-green .sidebar .menu .list li.active {
	background-color: transparent
}

.theme-green .sidebar .menu .list li.active>:first-child i,
.theme-green .sidebar .menu .list li.active>:first-child span {
	color: #50d38a
}

.theme-green .sidebar .menu .list a:hover {
	color: #50d38a
}

.theme-green .checkbox input[type="checkbox"]:checked+label::after {
	border: 1px solid #50d38a
}

.theme-green .nav-tabs .nav-link.active {
	border: 1px solid #50d38a !important
}

.theme-green .card .header h2:before {
	background: #50d38a
}

.theme-green .card .header h2 strong {
	color: #50d38a
}

.theme-orange .menu>ul>li>ul.normal-sub>li a:hover,
.theme-orange .menu>ul>li>ul>li>ul>li a:hover {
	background-color: #ffc323
}

.theme-orange.sidebar-collapse .navbar-collapse:before {
	background-color: #ff9f00
}

.theme-orange .btn-primary {
	background: #ff9f00
}

.theme-orange .btn-primary:active,
.theme-orange .btn-primary:hover,
.theme-orange .btn-primary:focus {
	background: #ffc323
}

.theme-orange .btn-primary.btn-simple {
	border-color: #ff9f00;
	color: #ff9f00;
	background: transparent
}

.theme-orange .block-header h2 {
	color: #fff
}

.theme-orange .block-header h2 small {
	color: #fff
}

.theme-orange section.content:before {
	background-color: #ffc323
}

.theme-orange .navbar,
.theme-orange .page-loader-wrapper {
	background: #ffc323
}

.theme-orange .bars {
	color: #fff
}

.theme-orange .sidebar .menu .list li.active {
	background-color: transparent
}

.theme-orange .sidebar .menu .list li.active>:first-child i,
.theme-orange .sidebar .menu .list li.active>:first-child span {
	color: #ff9f00
}

.theme-orange .sidebar .menu .list a:hover {
	color: #ff9f00
}

.theme-orange .checkbox input[type="checkbox"]:checked+label::after {
	border: 1px solid #ff9f00
}

.theme-orange .nav-tabs .nav-link.active {
	border: 1px solid #ff9f00 !important
}

.theme-orange .card .header h2:before {
	background: #ff9f00
}

.theme-orange .card .header h2 strong {
	color: #ff9f00
}

.theme-blush .menu>ul>li>ul.normal-sub>li a:hover,
.theme-blush .menu>ul>li>ul>li>ul>li a:hover {
	background-color: #ffbba4
}

.theme-blush.sidebar-collapse .navbar-collapse:before {
	background-color: #ffafbd
}

.theme-blush .btn-primary {
	background: #ffafbd
}

.theme-blush .btn-primary:active,
.theme-blush .btn-primary:hover,
.theme-blush .btn-primary:focus {
	background: #ffbba4
}

.theme-blush .btn-primary.btn-simple {
	border-color: #ffafbd;
	color: #ffafbd;
	background: transparent
}

.theme-blush .block-header h2 {
	color: #fff
}

.theme-blush .block-header h2 small {
	color: #fff
}

.theme-blush section.content:before {
	background-color: #ffbba4
}

.theme-blush .navbar,
.theme-blush .page-loader-wrapper {
	background: #ffbba4
}

.theme-blush .bars {
	color: #fff
}

.theme-blush .sidebar .menu .list li.active {
	background-color: transparent
}

.theme-blush .sidebar .menu .list li.active>:first-child i,
.theme-blush .sidebar .menu .list li.active>:first-child span {
	color: #ffafbd
}

.theme-blush .sidebar .menu .list a:hover {
	color: #ffafbd
}

.theme-blush .checkbox input[type="checkbox"]:checked+label::after {
	border: 1px solid #ffafbd
}

.theme-blush .nav-tabs .nav-link.active {
	border: 1px solid #ffafbd !important
}

.theme-blush .card .header h2:before {
	background: #ffafbd
}

.theme-blush .card .header h2 strong {
	color: #ffafbd
}