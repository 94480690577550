@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700");
@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: local("Material Icons"), local("MaterialIcons-Regular"), url(https://fonts.gstatic.com/s/materialicons/v19/2fcrYFNaTjcS6g4U3t-Y5ZjZjT5FdEJ140U2DJYC3mY.woff2) format("woff2")
}

.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased
}
.backgroundImg{
	background-image: url("../images/bg.png") !important;
}
.MenuActive{
	background: #302d54;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.bootstrap-switch {
	display: inline-block;
	direction: ltr;
	cursor: pointer;
	border-radius: 30px;
	border: 0;
	position: relative;
	text-align: left;
	margin-bottom: 10px;
	line-height: 8px;
	width: 59px !important;
	height: 22px;
	outline: none;
	z-index: 0;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	vertical-align: middle;
	-webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	margin-right: 20px;
	background: rgba(44, 44, 44, 0.2)
}

.bootstrap-switch .bootstrap-switch-container {
	display: inline-flex;
	top: 0;
	height: 22px;
	border-radius: 4px;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	width: 100px !important
}

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-label {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
	display: inline-block !important;
	height: 100%;
	color: #fff;
	padding: 6px 12px;
	font-size: 11px;
	text-indent: -5px;
	line-height: 15px;
	-webkit-transition: 0.25s ease-out;
	transition: 0.25s ease-out
}

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off {
	text-align: center;
	z-index: 1;
	float: left;
	line-height: 11px;
	width: 50% !important
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-brown,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-brown {
	color: #fff;
	background: #662d91
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-blue,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-blue {
	color: #fff;
	background: #2CA8FF
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-green,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-green {
	color: #fff;
	background: #18ce0f
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-orange,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-orange {
	background: #FFB236;
	color: #fff
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-red,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-red {
	color: #fff;
	background: #FF3636
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
	color: #fff
}

.bootstrap-switch .bootstrap-switch-label {
	text-align: center;
	z-index: 100;
	color: #333333;
	background: #ffffff;
	width: 22px !important;
	height: 22px !important;
	margin: 0px -11px;
	border-radius: 20px;
	position: absolute;
	float: left;
	top: 0;
	left: 50%;
	padding: 0;
	box-shadow: 0 1px 11px rgba(0, 0, 0, 0.25)
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label {
	background-color: rgba(23, 23, 23, 0.4)
}

.bootstrap-switch.bootstrap-switch-on:hover .bootstrap-switch-label {
	width: 27px !important;
	margin-left: -16px
}

.bootstrap-switch.bootstrap-switch-off:hover .bootstrap-switch-label {
	width: 27px !important;
	margin-left: -11px
}

.bootstrap-switch .bootstrap-switch-handle-on {
	border-bottom-left-radius: 3px;
	border-top-left-radius: 3px
}

.bootstrap-switch .bootstrap-switch-handle-off {
	text-indent: 6px
}

.bootstrap-switch input[type='radio'],
.bootstrap-switch input[type='checkbox'] {
	position: absolute !important;
	top: 0;
	left: 0;
	opacity: 0;
	filter: alpha(opacity=0);
	z-index: -1
}

.bootstrap-switch input[type='radio'].form-control,
.bootstrap-switch input[type='checkbox'].form-control {
	height: auto
}

.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-label {
	padding: 1px 5px;
	font-size: 12px;
	line-height: 1.5
}

.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-label {
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5
}

.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-label {
	padding: 6px 16px;
	font-size: 18px;
	line-height: 1.33
}

.bootstrap-switch.bootstrap-switch-disabled,
.bootstrap-switch.bootstrap-switch-readonly,
.bootstrap-switch.bootstrap-switch-indeterminate {
	cursor: default !important
}

.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-label {
	opacity: 0.5;
	filter: alpha(opacity=50);
	cursor: default !important
}

.bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
	-webkit-transition: margin-left 0.5s;
	transition: margin-left 0.5s
}

.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-on {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	border-bottom-right-radius: 3px;
	border-top-right-radius: 3px
}

.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-off {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
	border-bottom-left-radius: 3px;
	border-top-left-radius: 3px
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-container {
	margin-left: -2px !important
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-container {
	margin-left: -39px !important
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-label:before {
	background-color: #FFFFFF
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-red~.bootstrap-switch-default {
	background-color: #FF3636
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-orange~.bootstrap-switch-default {
	background-color: #FFB236
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-green~.bootstrap-switch-default {
	background-color: #18ce0f
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-brown~.bootstrap-switch-default {
	background-color: #662d91
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-blue~.bootstrap-switch-default {
	background-color: #2CA8FF
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-red,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-brown,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-blue,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-orange,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-green {
	background-color: #E3E3E3
}

.bootstrap-switch-off .bootstrap-switch-handle-on {
	opacity: 0
}

.bootstrap-switch-on .bootstrap-switch-handle-off {
	opacity: 0
}


/*! nouislider - 9.1.0 - 2016-12-10 16:00:32 */

.noUi-target,
.noUi-target * {
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-user-select: none;
	-ms-touch-action: none;
	touch-action: none;
	-ms-user-select: none;
	-moz-user-select: none;
	user-select: none;
	-moz-box-sizing: border-box;
	box-sizing: border-box
}

.noUi-target {
	position: relative;
	direction: ltr
}

.noUi-base {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1
}

.noUi-connect {
	position: absolute;
	right: 0;
	top: 0;
	left: 0;
	bottom: 0
}

.noUi-origin {
	position: absolute;
	height: 0;
	width: 0
}

.noUi-handle {
	position: relative;
	z-index: 1
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
	-webkit-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
	transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s
}

.noUi-state-drag * {
	cursor: inherit !important
}

.noUi-base,
.noUi-handle {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0)
}

.noUi-horizontal {
	height: 1px
}

.noUi-horizontal .noUi-handle {
	border-radius: 50%;
	background-color: #fff;
	box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.2);
	height: 15px;
	width: 15px;
	cursor: pointer;
	margin-left: -10px;
	margin-top: -7px
}

.noUi-vertical {
	width: 18px
}

.noUi-vertical .noUi-handle {
	width: 28px;
	height: 34px;
	left: -6px;
	top: -17px
}

.noUi-target {
	background-color: rgba(182, 182, 182, 0.3);
	border-radius: 3px
}

.noUi-connect {
	background: #888;
	border-radius: 3px;
	-webkit-transition: background 450ms;
	transition: background 450ms
}

.noUi-draggable {
	cursor: ew-resize
}

.noUi-vertical .noUi-draggable {
	cursor: ns-resize
}

.noUi-handle {
	border-radius: 3px;
	background: #FFF;
	cursor: default;
	box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
	-webkit-transition: 300ms ease 0s;
	-moz-transition: 300ms ease 0s;
	-ms-transition: 300ms ease 0s;
	-o-transform: 300ms ease 0s;
	transition: 300ms ease 0s
}

.noUi-active {
	-webkit-transform: scale3d(1.5, 1.5, 1);
	-moz-transform: scale3d(1.5, 1.5, 1);
	-ms-transform: scale3d(1.5, 1.5, 1);
	-o-transform: scale3d(1.5, 1.5, 1);
	transform: scale3d(1.5, 1.5, 1)
}

[disabled] .noUi-connect {
	background: #B8B8B8
}

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
	cursor: not-allowed
}

.noUi-pips,
.noUi-pips * {
	-moz-box-sizing: border-box;
	box-sizing: border-box
}

.noUi-pips {
	position: absolute;
	color: #999
}

.noUi-value {
	position: absolute;
	text-align: center
}

.noUi-value-sub {
	color: #ccc;
	font-size: 10px
}

.noUi-marker {
	position: absolute;
	background: #CCC
}

.noUi-marker-sub {
	background: #AAA
}

.noUi-marker-large {
	background: #AAA
}

.noUi-pips-horizontal {
	padding: 10px 0;
	height: 80px;
	top: 100%;
	left: 0;
	width: 100%
}

.noUi-value-horizontal {
	-webkit-transform: translate3d(-50%, 50%, 0);
	transform: translate3d(-50%, 50%, 0)
}

.noUi-marker-horizontal.noUi-marker {
	margin-left: -1px;
	width: 2px;
	height: 5px
}

.noUi-marker-horizontal.noUi-marker-sub {
	height: 10px
}

.noUi-marker-horizontal.noUi-marker-large {
	height: 15px
}

.noUi-pips-vertical {
	padding: 0 10px;
	height: 100%;
	top: 0;
	left: 100%
}

.noUi-value-vertical {
	-webkit-transform: translate3d(0, 50%, 0);
	transform: translate3d(0, 50%, 0);
	padding-left: 25px
}

.noUi-marker-vertical.noUi-marker {
	width: 5px;
	height: 2px;
	margin-top: -1px
}

.noUi-marker-vertical.noUi-marker-sub {
	width: 10px
}

.noUi-marker-vertical.noUi-marker-large {
	width: 15px
}

.noUi-tooltip {
	display: block;
	position: absolute;
	border: 1px solid #D9D9D9;
	border-radius: 3px;
	background: #fff;
	color: #000;
	padding: 5px;
	text-align: center
}

.noUi-horizontal .noUi-tooltip {
	-webkit-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
	left: 50%;
	bottom: 120%
}

.noUi-vertical .noUi-tooltip {
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	top: 50%;
	right: 120%
}

.slider.slider-neutral .noUi-connect,
.slider.slider-neutral.noUi-connect {
	background-color: #fff
}

.slider.slider-neutral.noUi-target {
	background-color: rgba(255, 255, 255, 0.3)
}

.slider.slider-neutral .noUi-handle {
	background-color: #fff
}

.slider.slider-primary .noUi-connect,
.slider.slider-primary.noUi-connect {
	background-color: #662d91
}

.slider.slider-primary.noUi-target {
	background-color: rgba(249, 99, 50, 0.3)
}

.slider.slider-primary .noUi-handle {
	background-color: #662d91
}

.slider.slider-info .noUi-connect,
.slider.slider-info.noUi-connect {
	background-color: #2CA8FF
}

.slider.slider-info.noUi-target {
	background-color: rgba(44, 168, 255, 0.3)
}

.slider.slider-info .noUi-handle {
	background-color: #2CA8FF
}

.slider.slider-success .noUi-connect,
.slider.slider-success.noUi-connect {
	background-color: #18ce0f
}

.slider.slider-success.noUi-target {
	background-color: rgba(24, 206, 15, 0.3)
}

.slider.slider-success .noUi-handle {
	background-color: #18ce0f
}

.slider.slider-warning .noUi-connect,
.slider.slider-warning.noUi-connect {
	background-color: #FFB236
}

.slider.slider-warning.noUi-target {
	background-color: rgba(255, 178, 54, 0.3)
}

.slider.slider-warning .noUi-handle {
	background-color: #FFB236
}

.slider.slider-danger .noUi-connect,
.slider.slider-danger.noUi-connect {
	background-color: #FF3636
}

.slider.slider-danger.noUi-target {
	background-color: rgba(255, 54, 54, 0.3)
}

.slider.slider-danger .noUi-handle {
	background-color: #FF3636
}


/*!
 * Datepicker for Bootstrap v1.7.0-dev (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */

.datepicker {
	padding: 8px 6px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	direction: ltr;
	-webkit-transform: translate3d(0, -40px, 0);
	-moz-transform: translate3d(0, -40px, 0);
	-o-transform: translate3d(0, -40px, 0);
	-ms-transform: translate3d(0, -40px, 0);
	transform: translate3d(0, -40px, 0);
	transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, opacity 0.3s ease 0s, height 0s linear 0.35s;
	opacity: 0;
	filter: alpha(opacity=0);
	visibility: hidden;
	display: block;
	width: 254px;
	max-width: 254px
}

.datepicker.dropdown-menu:before {
	display: none
}

.datepicker.datepicker-primary {
	background-color: #662d91
}

.datepicker.datepicker-primary th,
.datepicker.datepicker-primary .day div,
.datepicker.datepicker-primary table tr td span {
	color: #fff
}

.datepicker.datepicker-primary:after {
	border-bottom-color: #662d91
}

.datepicker.datepicker-primary.datepicker-orient-top:after {
	border-top-color: #662d91
}

.datepicker.datepicker-primary .dow {
	color: rgba(255, 255, 255, 0.8)
}

.datepicker.datepicker-primary table tr td.old div,
.datepicker.datepicker-primary table tr td.new div,
.datepicker.datepicker-primary table tr td span.old,
.datepicker.datepicker-primary table tr td span.new {
	color: rgba(255, 255, 255, 0.4)
}

.datepicker.datepicker-primary table tr td span:hover,
.datepicker.datepicker-primary table tr td span.focused {
	background: rgba(255, 255, 255, 0.1)
}

.datepicker.datepicker-primary .datepicker-switch:hover,
.datepicker.datepicker-primary .prev:hover,
.datepicker.datepicker-primary .next:hover,
.datepicker.datepicker-primary tfoot tr th:hover {
	background: rgba(255, 255, 255, 0.2)
}

.datepicker.datepicker-primary table tr td.active div,
.datepicker.datepicker-primary table tr td.active:hover div,
.datepicker.datepicker-primary table tr td.active.disabled div,
.datepicker.datepicker-primary table tr td.active.disabled:hover div {
	background-color: #fff;
	color: #662d91
}

.datepicker.datepicker-primary table tr td.day:hover div,
.datepicker.datepicker-primary table tr td.day.focused div {
	background: rgba(255, 255, 255, 0.2)
}

.datepicker.datepicker-primary table tr td.active:hover div,
.datepicker.datepicker-primary table tr td.active:hover:hover div,
.datepicker.datepicker-primary table tr td.active.disabled:hover div,
.datepicker.datepicker-primary table tr td.active.disabled:hover:hover div,
.datepicker.datepicker-primary table tr td.active:active div,
.datepicker.datepicker-primary table tr td.active:hover:active div,
.datepicker.datepicker-primary table tr td.active.disabled:active div,
.datepicker.datepicker-primary table tr td.active.disabled:hover:active div,
.datepicker.datepicker-primary table tr td.active.active div,
.datepicker.datepicker-primary table tr td.active:hover.active div,
.datepicker.datepicker-primary table tr td.active.disabled.active div,
.datepicker.datepicker-primary table tr td.active.disabled:hover.active div,
.datepicker.datepicker-primary table tr td.active.disabled div,
.datepicker.datepicker-primary table tr td.active:hover.disabled div,
.datepicker.datepicker-primary table tr td.active.disabled.disabled div,
.datepicker.datepicker-primary table tr td.active.disabled:hover.disabled div,
.datepicker.datepicker-primary table tr td.active[disabled] div,
.datepicker.datepicker-primary table tr td.active:hover[disabled] div,
.datepicker.datepicker-primary table tr td.active.disabled[disabled] div,
.datepicker.datepicker-primary table tr td.active.disabled:hover[disabled] div,
.datepicker.datepicker-primary table tr td span.active:hover,
.datepicker.datepicker-primary table tr td span.active:hover:hover,
.datepicker.datepicker-primary table tr td span.active.disabled:hover,
.datepicker.datepicker-primary table tr td span.active.disabled:hover:hover,
.datepicker.datepicker-primary table tr td span.active:active,
.datepicker.datepicker-primary table tr td span.active:hover:active,
.datepicker.datepicker-primary table tr td span.active.disabled:active,
.datepicker.datepicker-primary table tr td span.active.disabled:hover:active,
.datepicker.datepicker-primary table tr td span.active.active,
.datepicker.datepicker-primary table tr td span.active:hover.active,
.datepicker.datepicker-primary table tr td span.active.disabled.active,
.datepicker.datepicker-primary table tr td span.active.disabled:hover.active,
.datepicker.datepicker-primary table tr td span.active.disabled,
.datepicker.datepicker-primary table tr td span.active:hover.disabled,
.datepicker.datepicker-primary table tr td span.active.disabled.disabled,
.datepicker.datepicker-primary table tr td span.active.disabled:hover.disabled,
.datepicker.datepicker-primary table tr td span.active[disabled],
.datepicker.datepicker-primary table tr td span.active:hover[disabled],
.datepicker.datepicker-primary table tr td span.active.disabled[disabled],
.datepicker.datepicker-primary table tr td span.active.disabled:hover[disabled] {
	background-color: #fff
}

.datepicker.datepicker-primary table tr td span.active:hover,
.datepicker.datepicker-primary table tr td span.active:hover:hover,
.datepicker.datepicker-primary table tr td span.active.disabled:hover,
.datepicker.datepicker-primary table tr td span.active.disabled:hover:hover,
.datepicker.datepicker-primary table tr td span.active:active,
.datepicker.datepicker-primary table tr td span.active:hover:active,
.datepicker.datepicker-primary table tr td span.active.disabled:active,
.datepicker.datepicker-primary table tr td span.active.disabled:hover:active,
.datepicker.datepicker-primary table tr td span.active.active,
.datepicker.datepicker-primary table tr td span.active:hover.active,
.datepicker.datepicker-primary table tr td span.active.disabled.active,
.datepicker.datepicker-primary table tr td span.active.disabled:hover.active,
.datepicker.datepicker-primary table tr td span.active.disabled,
.datepicker.datepicker-primary table tr td span.active:hover.disabled,
.datepicker.datepicker-primary table tr td span.active.disabled.disabled,
.datepicker.datepicker-primary table tr td span.active.disabled:hover.disabled,
.datepicker.datepicker-primary table tr td span.active[disabled],
.datepicker.datepicker-primary table tr td span.active:hover[disabled],
.datepicker.datepicker-primary table tr td span.active.disabled[disabled],
.datepicker.datepicker-primary table tr td span.active.disabled:hover[disabled] {
	color: #662d91
}

.datepicker-inline {
	width: 220px
}

.datepicker.datepicker-rtl {
	direction: rtl
}

.datepicker.datepicker-rtl.dropdown-menu {
	left: auto
}

.datepicker.datepicker-rtl table tr td span {
	float: right
}

.datepicker-dropdown {
	top: 0;
	left: 0
}

.datepicker-dropdown:before {
	content: '';
	display: inline-block;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 7px solid transparent;
	border-top: 0;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	position: absolute
}

.datepicker-dropdown:after {
	content: '';
	display: inline-block;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #fff;
	border-top: 0;
	position: absolute
}

.datepicker-dropdown.datepicker-orient-left:before {
	left: 6px
}

.datepicker-dropdown.datepicker-orient-left:after {
	left: 7px
}

.datepicker-dropdown.datepicker-orient-right:before {
	right: 6px
}

.datepicker-dropdown.datepicker-orient-right:after {
	right: 7px
}

.datepicker-dropdown.datepicker-orient-bottom:before {
	top: -7px
}

.datepicker-dropdown.datepicker-orient-bottom:after {
	top: -6px
}

.datepicker-dropdown.datepicker-orient-top:before {
	bottom: -7px;
	border-bottom: 0;
	border-top: 7px solid transparent
}

.datepicker-dropdown.datepicker-orient-top:after {
	bottom: -6px;
	border-bottom: 0;
	border-top: 6px solid #fff
}

.datepicker table {
	margin: 0;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	width: 241px;
	max-width: 241px
}

.datepicker .day div,
.datepicker th {
	-webkit-transition: all 300ms ease 0s;
	-moz-transition: all 300ms ease 0s;
	-o-transition: all 300ms ease 0s;
	-ms-transition: all 300ms ease 0s;
	transition: all 300ms ease 0s;
	text-align: center;
	width: 30px;
	height: 30px;
	line-height: 2.2;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 50%;
	font-weight: 300;
	font-size: 14px;
	border: none;
	position: relative;
	cursor: pointer
}

.datepicker th {
	color: #662d91
}

.table-condensed>tbody>tr>td,
.table-condensed>tbody>tr>th,
.table-condensed>tfoot>tr>td,
.table-condensed>tfoot>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>thead>tr>th {
	padding: 2px;
	text-align: center;
	cursor: pointer
}

.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
	background-color: transparent
}

.datepicker table tr td.day:hover div,
.datepicker table tr td.day.focused div {
	background: #eee;
	cursor: pointer
}

.datepicker table tr td.old,
.datepicker table tr td.new {
	color: #888
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
	background: none;
	color: #888;
	cursor: default
}

.datepicker table tr td.highlighted {
	background: #d9edf7;
	border-radius: 0
}

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
	background-color: #fde19a;
	background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
	background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
	background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
	background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
	background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
	border-color: #fdf59a #fdf59a #fbed50;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	color: #000
}

.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today.disabled:hover[disabled] {
	background-color: #fdf59a
}

.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active {
	background-color: #fbf069 \9
}

.datepicker table tr td.today:hover:hover {
	color: #000
}

.datepicker table tr td.today.active:hover {
	color: #fff
}

.datepicker table tr td.range,
.datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover {
	background: #eee;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0
}

.datepicker table tr td.range.today,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:hover {
	background-color: #f3d17a;
	background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
	background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
	background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
	background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
	background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
	border-color: #f3e97a #f3e97a #edde34;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0
}

.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:hover:hover,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.disabled:hover:hover,
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today:hover.disabled,
.datepicker table tr td.range.today.disabled.disabled,
.datepicker table tr td.range.today.disabled:hover.disabled,
.datepicker table tr td.range.today[disabled],
.datepicker table tr td.range.today:hover[disabled],
.datepicker table tr td.range.today.disabled[disabled],
.datepicker table tr td.range.today.disabled:hover[disabled] {
	background-color: #f3e97a
}

.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active {
	background-color: #efe24b \9
}

.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
	background-color: #9e9e9e;
	background-image: -moz-linear-gradient(to bottom, #b3b3b3, gray);
	background-image: -ms-linear-gradient(to bottom, #b3b3b3, gray);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(gray));
	background-image: -webkit-linear-gradient(to bottom, #b3b3b3, gray);
	background-image: -o-linear-gradient(to bottom, #b3b3b3, gray);
	background-image: linear-gradient(to bottom, #b3b3b3, gray);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
	border-color: #808080 #808080 #595959;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	color: #fff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25)
}

.datepicker table tr td.selected:hover,
.datepicker table tr td.selected:hover:hover,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.disabled:hover:hover,
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected:hover.disabled,
.datepicker table tr td.selected.disabled.disabled,
.datepicker table tr td.selected.disabled:hover.disabled,
.datepicker table tr td.selected[disabled],
.datepicker table tr td.selected:hover[disabled],
.datepicker table tr td.selected.disabled[disabled],
.datepicker table tr td.selected.disabled:hover[disabled] {
	background-color: #808080
}

.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active {
	background-color: #666666 \9
}

.datepicker table tr td.active div,
.datepicker table tr td.active:hover div,
.datepicker table tr td.active.disabled div,
.datepicker table tr td.active.disabled:hover div {
	background-color: #662d91;
	color: #fff;
	box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2)
}

.datepicker table tr td.active:hover div,
.datepicker table tr td.active:hover:hover div,
.datepicker table tr td.active.disabled:hover div,
.datepicker table tr td.active.disabled:hover:hover div,
.datepicker table tr td.active:active div,
.datepicker table tr td.active:hover:active div,
.datepicker table tr td.active.disabled:active div,
.datepicker table tr td.active.disabled:hover:active div,
.datepicker table tr td.active.active div,
.datepicker table tr td.active:hover.active div,
.datepicker table tr td.active.disabled.active div,
.datepicker table tr td.active.disabled:hover.active div,
.datepicker table tr td.active.disabled div,
.datepicker table tr td.active:hover.disabled div,
.datepicker table tr td.active.disabled.disabled div,
.datepicker table tr td.active.disabled:hover.disabled div,
.datepicker table tr td.active[disabled] div,
.datepicker table tr td.active:hover[disabled] div,
.datepicker table tr td.active.disabled[disabled] div,
.datepicker table tr td.active.disabled:hover[disabled] div {
	background-color: #662d91
}

.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active {
	background-color: #003399 \9
}

.datepicker table tr td span {
	display: block;
	width: 41px;
	height: 41px;
	line-height: 41px;
	float: left;
	margin: 1%;
	font-size: 14px;
	cursor: pointer;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%
}

.datepicker table tr td span:hover,
.datepicker table tr td span.focused {
	background: #eee
}

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
	background: none;
	color: #888;
	cursor: default
}

.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
	color: #fff;
	background-color: #662d91
}

.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
	background-color: #662d91;
	box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.2)
}

.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active {
	background-color: #003399 \9
}

.datepicker table tr td span.old,
.datepicker table tr td span.new {
	color: #888
}

.datepicker .datepicker-switch {
	width: auto;
	border-radius: .1875rem
}

.datepicker .datepicker-switch,
.datepicker .prev,
.datepicker .next,
.datepicker tfoot tr th {
	cursor: pointer
}

.datepicker .prev,
.datepicker .next {
	width: 35px;
	height: 35px
}

.datepicker i {
	position: relative;
	top: 2px
}

.datepicker .prev i {
	left: -1px
}

.datepicker .next i {
	right: -1px
}

.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
	background: #eee
}

.datepicker .prev.disabled,
.datepicker .next.disabled {
	visibility: hidden
}

.datepicker .cw {
	font-size: 10px;
	width: 12px;
	padding: 0 2px 0 5px;
	vertical-align: middle
}

.input-append.date .add-on,
.input-prepend.date .add-on {
	cursor: pointer
}

.input-append.date .add-on i,
.input-prepend.date .add-on i {
	margin-top: 3px
}

.input-daterange input {
	text-align: center
}

.input-daterange input:first-child {
	-webkit-border-radius: 3px 0 0 3px;
	-moz-border-radius: 3px 0 0 3px;
	border-radius: 3px 0 0 3px
}

.input-daterange input:last-child {
	-webkit-border-radius: 0 3px 3px 0;
	-moz-border-radius: 0 3px 3px 0;
	border-radius: 0 3px 3px 0
}

.input-daterange .add-on {
	display: inline-block;
	width: auto;
	min-width: 16px;
	height: 18px;
	padding: 4px 5px;
	font-weight: normal;
	line-height: 18px;
	text-align: center;
	text-shadow: 0 1px 0 #fff;
	vertical-align: middle;
	background-color: #eee;
	border: 1px solid #ccc;
	margin-left: -5px;
	margin-right: -5px
}

.btn,
.navbar .navbar-nav>a.btn {
	border-width: 2px;
	font-weight: 400;
	font-size: .8571em;
	line-height: 1.35em;
	margin: 5px 1px;
	border: none;
	border-radius: .1875rem;
	padding: 11px 22px;
	cursor: pointer;
	background-color: #888;
	color: #fff
}

.btn:hover,
.btn:focus,
.btn:active,
.btn.active,
.btn:active:focus,
.btn:active:hover,
.btn.active:focus,
.btn.active:hover,
.show>.btn.dropdown-toggle,
.show>.btn.dropdown-toggle:focus,
.show>.btn.dropdown-toggle:hover,
.navbar .navbar-nav>a.btn:hover,
.navbar .navbar-nav>a.btn:focus,
.navbar .navbar-nav>a.btn:active,
.navbar .navbar-nav>a.btn.active,
.navbar .navbar-nav>a.btn:active:focus,
.navbar .navbar-nav>a.btn:active:hover,
.navbar .navbar-nav>a.btn.active:focus,
.navbar .navbar-nav>a.btn.active:hover,
.show>.navbar .navbar-nav>a.btn.dropdown-toggle,
.show>.navbar .navbar-nav>a.btn.dropdown-toggle:focus,
.show>.navbar .navbar-nav>a.btn.dropdown-toggle:hover {
	background-color: #979797;
	color: #fff;
	box-shadow: none
}

.btn:hover,
.navbar .navbar-nav>a.btn:hover {
	box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17)
}

.btn.disabled,
.btn.disabled:hover,
.btn.disabled:focus,
.btn.disabled.focus,
.btn.disabled:active,
.btn.disabled.active,
.btn:disabled,
.btn:disabled:hover,
.btn:disabled:focus,
.btn:disabled.focus,
.btn:disabled:active,
.btn:disabled.active,
.btn[disabled],
.btn[disabled]:hover,
.btn[disabled]:focus,
.btn[disabled].focus,
.btn[disabled]:active,
.btn[disabled].active,
fieldset[disabled] .btn,
fieldset[disabled] .btn:hover,
fieldset[disabled] .btn:focus,
fieldset[disabled] .btn.focus,
fieldset[disabled] .btn:active,
fieldset[disabled] .btn.active,
.navbar .navbar-nav>a.btn.disabled,
.navbar .navbar-nav>a.btn.disabled:hover,
.navbar .navbar-nav>a.btn.disabled:focus,
.navbar .navbar-nav>a.btn.disabled.focus,
.navbar .navbar-nav>a.btn.disabled:active,
.navbar .navbar-nav>a.btn.disabled.active,
.navbar .navbar-nav>a.btn:disabled,
.navbar .navbar-nav>a.btn:disabled:hover,
.navbar .navbar-nav>a.btn:disabled:focus,
.navbar .navbar-nav>a.btn:disabled.focus,
.navbar .navbar-nav>a.btn:disabled:active,
.navbar .navbar-nav>a.btn:disabled.active,
.navbar .navbar-nav>a.btn[disabled],
.navbar .navbar-nav>a.btn[disabled]:hover,
.navbar .navbar-nav>a.btn[disabled]:focus,
.navbar .navbar-nav>a.btn[disabled].focus,
.navbar .navbar-nav>a.btn[disabled]:active,
.navbar .navbar-nav>a.btn[disabled].active,
fieldset[disabled] .navbar .navbar-nav>a.btn,
fieldset[disabled] .navbar .navbar-nav>a.btn:hover,
fieldset[disabled] .navbar .navbar-nav>a.btn:focus,
fieldset[disabled] .navbar .navbar-nav>a.btn.focus,
fieldset[disabled] .navbar .navbar-nav>a.btn:active,
fieldset[disabled] .navbar .navbar-nav>a.btn.active {
	background-color: #888;
	border-color: #888
}

.btn.btn-simple,
.navbar .navbar-nav>a.btn.btn-simple {
	color: #888;
	border-color: #888
}

.btn.btn-simple:hover,
.btn.btn-simple:focus,
.btn.btn-simple:active,
.navbar .navbar-nav>a.btn.btn-simple:hover,
.navbar .navbar-nav>a.btn.btn-simple:focus,
.navbar .navbar-nav>a.btn.btn-simple:active {
	background-color: rgba(0, 0, 0, 0);
	color: #979797;
	border-color: #979797;
	box-shadow: none
}

.btn.btn-link,
.navbar .navbar-nav>a.btn.btn-link {
	color: #888
}

.btn.btn-link:hover,
.btn.btn-link:focus,
.btn.btn-link:active,
.navbar .navbar-nav>a.btn.btn-link:hover,
.navbar .navbar-nav>a.btn.btn-link:focus,
.navbar .navbar-nav>a.btn.btn-link:active {
	background-color: rgba(0, 0, 0, 0);
	color: #979797;
	text-decoration: none;
	box-shadow: none
}

.btn:hover,
.btn:focus,
.navbar .navbar-nav>a.btn:hover,
.navbar .navbar-nav>a.btn:focus {
	opacity: 1;
	filter: alpha(opacity=100);
	outline: 0 !important
}

.btn:active,
.btn.active,
.open>.btn.dropdown-toggle,
.navbar .navbar-nav>a.btn:active,
.navbar .navbar-nav>a.btn.active,
.open>.navbar .navbar-nav>a.btn.dropdown-toggle {
	-webkit-box-shadow: none;
	box-shadow: none;
	outline: 0 !important
}

.btn.btn-icon,
.navbar .navbar-nav>a.btn.btn-icon {
	height: 2.375rem;
	min-width: 2.375rem;
	width: 2.375rem;
	padding: 0;
	font-size: .9375rem;
	overflow: hidden;
	position: relative;
	line-height: normal
}

.btn.btn-icon.btn-simple,
.navbar .navbar-nav>a.btn.btn-icon.btn-simple {
	padding: 0
}

.btn.btn-icon.btn-sm,
.navbar .navbar-nav>a.btn.btn-icon.btn-sm {
	height: 1.875rem;
	min-width: 1.875rem;
	width: 1.875rem
}

.btn.btn-icon.btn-sm i.fa,
.btn.btn-icon.btn-sm i.now-ui-icons,
.navbar .navbar-nav>a.btn.btn-icon.btn-sm i.fa,
.navbar .navbar-nav>a.btn.btn-icon.btn-sm i.now-ui-icons {
	font-size: .6875rem
}

.btn.btn-icon.btn-lg,
.navbar .navbar-nav>a.btn.btn-icon.btn-lg {
	height: 3.6rem;
	min-width: 3.6rem;
	width: 3.6rem
}

.btn.btn-icon.btn-lg i.now-ui-icons,
.btn.btn-icon.btn-lg i.fa,
.navbar .navbar-nav>a.btn.btn-icon.btn-lg i.now-ui-icons,
.navbar .navbar-nav>a.btn.btn-icon.btn-lg i.fa {
	font-size: 1.325rem
}

.btn.btn-icon:not(.btn-footer) i.now-ui-icons,
.btn.btn-icon:not(.btn-footer) i.fa,
.navbar .navbar-nav>a.btn.btn-icon:not(.btn-footer) i.now-ui-icons,
.navbar .navbar-nav>a.btn.btn-icon:not(.btn-footer) i.fa {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-12px, -12px);
	line-height: 1.5626rem;
	width: 25px
}

.btn:not(.btn-icon) .now-ui-icons,
.navbar .navbar-nav>a.btn:not(.btn-icon) .now-ui-icons {
	position: relative;
	top: 1px
}

.btn-primary {
	background-color: #662d91;
	color: #fff
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.btn-primary.active:focus,
.btn-primary.active:hover,
.show>.btn-primary.dropdown-toggle,
.show>.btn-primary.dropdown-toggle:focus,
.show>.btn-primary.dropdown-toggle:hover {
	background-color: #fa7a50;
	color: #fff;
	box-shadow: none
}

.btn-primary:hover {
	box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17)
}

.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled.focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary:disabled,
.btn-primary:disabled:hover,
.btn-primary:disabled:focus,
.btn-primary:disabled.focus,
.btn-primary:disabled:active,
.btn-primary:disabled.active,
.btn-primary[disabled],
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled].focus,
.btn-primary[disabled]:active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
	background-color: #662d91;
	border-color: #662d91
}

.btn-primary.btn-simple {
	color: #662d91;
	border-color: #662d91
}

.btn-primary.btn-simple:hover,
.btn-primary.btn-simple:focus,
.btn-primary.btn-simple:active {
	background-color: rgba(0, 0, 0, 0);
	color: #fa7a50;
	border-color: #fa7a50;
	box-shadow: none
}

.btn-primary.btn-link {
	color: #662d91
}

.btn-primary.btn-link:hover,
.btn-primary.btn-link:focus,
.btn-primary.btn-link:active {
	background-color: rgba(0, 0, 0, 0);
	color: #fa7a50;
	text-decoration: none;
	box-shadow: none
}

.btn-success {
	background-color: #18ce0f;
	color: #fff
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success:active:focus,
.btn-success:active:hover,
.btn-success.active:focus,
.btn-success.active:hover,
.show>.btn-success.dropdown-toggle,
.show>.btn-success.dropdown-toggle:focus,
.show>.btn-success.dropdown-toggle:hover {
	background-color: #1beb11;
	color: #fff;
	box-shadow: none
}

.btn-success:hover {
	box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17)
}

.btn-success.disabled,
.btn-success.disabled:hover,
.btn-success.disabled:focus,
.btn-success.disabled.focus,
.btn-success.disabled:active,
.btn-success.disabled.active,
.btn-success:disabled,
.btn-success:disabled:hover,
.btn-success:disabled:focus,
.btn-success:disabled.focus,
.btn-success:disabled:active,
.btn-success:disabled.active,
.btn-success[disabled],
.btn-success[disabled]:hover,
.btn-success[disabled]:focus,
.btn-success[disabled].focus,
.btn-success[disabled]:active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success.focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success.active {
	background-color: #18ce0f;
	border-color: #18ce0f
}

.btn-success.btn-simple {
	color: #18ce0f;
	border-color: #18ce0f
}

.btn-success.btn-simple:hover,
.btn-success.btn-simple:focus,
.btn-success.btn-simple:active {
	background-color: rgba(0, 0, 0, 0);
	color: #1beb11;
	border-color: #1beb11;
	box-shadow: none
}

.btn-success.btn-link {
	color: #18ce0f
}

.btn-success.btn-link:hover,
.btn-success.btn-link:focus,
.btn-success.btn-link:active {
	background-color: rgba(0, 0, 0, 0);
	color: #1beb11;
	text-decoration: none;
	box-shadow: none
}

.btn-info {
	background-color: #2CA8FF;
	color: #fff
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info:active:focus,
.btn-info:active:hover,
.btn-info.active:focus,
.btn-info.active:hover,
.show>.btn-info.dropdown-toggle,
.show>.btn-info.dropdown-toggle:focus,
.show>.btn-info.dropdown-toggle:hover {
	background-color: #4bb5ff;
	color: #fff;
	box-shadow: none
}

.btn-info:hover {
	box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17)
}

.btn-info.disabled,
.btn-info.disabled:hover,
.btn-info.disabled:focus,
.btn-info.disabled.focus,
.btn-info.disabled:active,
.btn-info.disabled.active,
.btn-info:disabled,
.btn-info:disabled:hover,
.btn-info:disabled:focus,
.btn-info:disabled.focus,
.btn-info:disabled:active,
.btn-info:disabled.active,
.btn-info[disabled],
.btn-info[disabled]:hover,
.btn-info[disabled]:focus,
.btn-info[disabled].focus,
.btn-info[disabled]:active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info.focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info.active {
	background-color: #2CA8FF;
	border-color: #2CA8FF
}

.btn-info.btn-simple {
	color: #2CA8FF;
	border-color: #2CA8FF
}

.btn-info.btn-simple:hover,
.btn-info.btn-simple:focus,
.btn-info.btn-simple:active {
	background-color: rgba(0, 0, 0, 0);
	color: #4bb5ff;
	border-color: #4bb5ff;
	box-shadow: none
}

.btn-info.btn-link {
	color: #2CA8FF
}

.btn-info.btn-link:hover,
.btn-info.btn-link:focus,
.btn-info.btn-link:active {
	background-color: rgba(0, 0, 0, 0);
	color: #4bb5ff;
	text-decoration: none;
	box-shadow: none
}

.btn-warning {
	background-color: #FFB236;
	color: #fff
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.btn-warning.active:focus,
.btn-warning.active:hover,
.show>.btn-warning.dropdown-toggle,
.show>.btn-warning.dropdown-toggle:focus,
.show>.btn-warning.dropdown-toggle:hover {
	background-color: #ffbe55;
	color: #fff;
	box-shadow: none
}

.btn-warning:hover {
	box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17)
}

.btn-warning.disabled,
.btn-warning.disabled:hover,
.btn-warning.disabled:focus,
.btn-warning.disabled.focus,
.btn-warning.disabled:active,
.btn-warning.disabled.active,
.btn-warning:disabled,
.btn-warning:disabled:hover,
.btn-warning:disabled:focus,
.btn-warning:disabled.focus,
.btn-warning:disabled:active,
.btn-warning:disabled.active,
.btn-warning[disabled],
.btn-warning[disabled]:hover,
.btn-warning[disabled]:focus,
.btn-warning[disabled].focus,
.btn-warning[disabled]:active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning.focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning.active {
	background-color: #FFB236;
	border-color: #FFB236
}

.btn-warning.btn-simple {
	color: #FFB236;
	border-color: #FFB236
}

.btn-warning.btn-simple:hover,
.btn-warning.btn-simple:focus,
.btn-warning.btn-simple:active {
	background-color: rgba(0, 0, 0, 0);
	color: #ffbe55;
	border-color: #ffbe55;
	box-shadow: none
}

.btn-warning.btn-link {
	color: #FFB236
}

.btn-warning.btn-link:hover,
.btn-warning.btn-link:focus,
.btn-warning.btn-link:active {
	background-color: rgba(0, 0, 0, 0);
	color: #ffbe55;
	text-decoration: none;
	box-shadow: none
}

.btn-danger {
	background-color: #FF3636;
	color: #fff
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.btn-danger.active:focus,
.btn-danger.active:hover,
.show>.btn-danger.dropdown-toggle,
.show>.btn-danger.dropdown-toggle:focus,
.show>.btn-danger.dropdown-toggle:hover {
	background-color: #f55;
	color: #fff;
	box-shadow: none
}

.btn-danger:hover {
	box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17)
}

.btn-danger.disabled,
.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled.focus,
.btn-danger.disabled:active,
.btn-danger.disabled.active,
.btn-danger:disabled,
.btn-danger:disabled:hover,
.btn-danger:disabled:focus,
.btn-danger:disabled.focus,
.btn-danger:disabled:active,
.btn-danger:disabled.active,
.btn-danger[disabled],
.btn-danger[disabled]:hover,
.btn-danger[disabled]:focus,
.btn-danger[disabled].focus,
.btn-danger[disabled]:active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger.focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active {
	background-color: #FF3636;
	border-color: #FF3636
}

.btn-danger.btn-simple {
	color: #FF3636;
	border-color: #FF3636
}

.btn-danger.btn-simple:hover,
.btn-danger.btn-simple:focus,
.btn-danger.btn-simple:active {
	background-color: rgba(0, 0, 0, 0);
	color: #f55;
	border-color: #f55;
	box-shadow: none
}

.btn-danger.btn-link {
	color: #FF3636
}

.btn-danger.btn-link:hover,
.btn-danger.btn-link:focus,
.btn-danger.btn-link:active {
	background-color: rgba(0, 0, 0, 0);
	color: #f55;
	text-decoration: none;
	box-shadow: none
}

.btn-neutral {
	background-color: #fff;
	color: #662d91
}

.btn-neutral:hover,
.btn-neutral:focus,
.btn-neutral:active,
.btn-neutral.active,
.btn-neutral:active:focus,
.btn-neutral:active:hover,
.btn-neutral.active:focus,
.btn-neutral.active:hover,
.show>.btn-neutral.dropdown-toggle,
.show>.btn-neutral.dropdown-toggle:focus,
.show>.btn-neutral.dropdown-toggle:hover {
	background-color: #fff;
	color: #fff;
	box-shadow: none
}

.btn-neutral:hover {
	box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17)
}

.btn-neutral.disabled,
.btn-neutral.disabled:hover,
.btn-neutral.disabled:focus,
.btn-neutral.disabled.focus,
.btn-neutral.disabled:active,
.btn-neutral.disabled.active,
.btn-neutral:disabled,
.btn-neutral:disabled:hover,
.btn-neutral:disabled:focus,
.btn-neutral:disabled.focus,
.btn-neutral:disabled:active,
.btn-neutral:disabled.active,
.btn-neutral[disabled],
.btn-neutral[disabled]:hover,
.btn-neutral[disabled]:focus,
.btn-neutral[disabled].focus,
.btn-neutral[disabled]:active,
.btn-neutral[disabled].active,
fieldset[disabled] .btn-neutral,
fieldset[disabled] .btn-neutral:hover,
fieldset[disabled] .btn-neutral:focus,
fieldset[disabled] .btn-neutral.focus,
fieldset[disabled] .btn-neutral:active,
fieldset[disabled] .btn-neutral.active {
	background-color: #fff;
	border-color: #fff
}

.btn-neutral.btn-danger {
	color: #FF3636
}

.btn-neutral.btn-danger:hover,
.btn-neutral.btn-danger:focus,
.btn-neutral.btn-danger:active {
	color: #f55
}

.btn-neutral.btn-info {
	color: #2CA8FF
}

.btn-neutral.btn-info:hover,
.btn-neutral.btn-info:focus,
.btn-neutral.btn-info:active {
	color: #4bb5ff
}

.btn-neutral.btn-warning {
	color: #FFB236
}

.btn-neutral.btn-warning:hover,
.btn-neutral.btn-warning:focus,
.btn-neutral.btn-warning:active {
	color: #ffbe55
}

.btn-neutral.btn-success {
	color: #18ce0f
}

.btn-neutral.btn-success:hover,
.btn-neutral.btn-success:focus,
.btn-neutral.btn-success:active {
	color: #1beb11
}

.btn-neutral.btn-default {
	color: #888
}

.btn-neutral.btn-default:hover,
.btn-neutral.btn-default:focus,
.btn-neutral.btn-default:active {
	color: #979797
}

.btn-neutral.active,
.btn-neutral:active,
.btn-neutral:active:focus,
.btn-neutral:active:hover,
.btn-neutral.active:focus,
.btn-neutral.active:hover,
.show>.btn-neutral.dropdown-toggle,
.show>.btn-neutral.dropdown-toggle:focus,
.show>.btn-neutral.dropdown-toggle:hover {
	background-color: #fff;
	color: #fa7a50;
	box-shadow: none
}

.btn-neutral:hover,
.btn-neutral:focus {
	color: #fa7a50
}

.btn-neutral:hover:not(.nav-link),
.btn-neutral:focus:not(.nav-link) {
	box-shadow: none
}

.btn-neutral.btn-simple {
	color: #fff;
	border-color: #fff
}

.btn-neutral.btn-simple:hover,
.btn-neutral.btn-simple:focus,
.btn-neutral.btn-simple:active {
	background-color: rgba(0, 0, 0, 0);
	color: #fff;
	border-color: #fff;
	box-shadow: none
}

.btn-neutral.btn-link {
	color: #fff
}

.btn-neutral.btn-link:hover,
.btn-neutral.btn-link:focus,
.btn-neutral.btn-link:active {
	background-color: rgba(0, 0, 0, 0);
	color: #fff;
	text-decoration: none;
	box-shadow: none
}

.btn:disabled,
.btn[disabled],
.btn.disabled {
	opacity: .5;
	filter: alpha(opacity=50)
}

.btn-round {
	border-width: 1px;
	border-radius: 30px !important;
	padding: 11px 23px
}

.btn-round.btn-simple {
	padding: 10px 22px
}

.btn-simple {
	border: 1px solid;
	border-color: #888;
	padding: 10px 22px;
	background-color: rgba(0, 0, 0, 0)
}

.btn-simple.disabled,
.btn-simple.disabled:hover,
.btn-simple.disabled:focus,
.btn-simple.disabled.focus,
.btn-simple.disabled:active,
.btn-simple.disabled.active,
.btn-simple:disabled,
.btn-simple:disabled:hover,
.btn-simple:disabled:focus,
.btn-simple:disabled.focus,
.btn-simple:disabled:active,
.btn-simple:disabled.active,
.btn-simple[disabled],
.btn-simple[disabled]:hover,
.btn-simple[disabled]:focus,
.btn-simple[disabled].focus,
.btn-simple[disabled]:active,
.btn-simple[disabled].active,
fieldset[disabled] .btn-simple,
fieldset[disabled] .btn-simple:hover,
fieldset[disabled] .btn-simple:focus,
fieldset[disabled] .btn-simple.focus,
fieldset[disabled] .btn-simple:active,
fieldset[disabled] .btn-simple.active,
.btn-link.disabled,
.btn-link.disabled:hover,
.btn-link.disabled:focus,
.btn-link.disabled.focus,
.btn-link.disabled:active,
.btn-link.disabled.active,
.btn-link:disabled,
.btn-link:disabled:hover,
.btn-link:disabled:focus,
.btn-link:disabled.focus,
.btn-link:disabled:active,
.btn-link:disabled.active,
.btn-link[disabled],
.btn-link[disabled]:hover,
.btn-link[disabled]:focus,
.btn-link[disabled].focus,
.btn-link[disabled]:active,
.btn-link[disabled].active,
fieldset[disabled] .btn-link,
fieldset[disabled] .btn-link:hover,
fieldset[disabled] .btn-link:focus,
fieldset[disabled] .btn-link.focus,
fieldset[disabled] .btn-link:active,
fieldset[disabled] .btn-link.active {
	background-color: rgba(0, 0, 0, 0)
}

.btn-lg {
	font-size: 1em;
	border-radius: .25rem;
	padding: 15px 48px
}

.btn-lg.btn-simple {
	padding: 14px 47px
}

.btn-sm {
	font-size: 14px;
	border-radius: .1875rem;
	padding: 5px 15px
}

.btn-sm.btn-simple {
	padding: 4px 14px
}

.btn-link {
	border: 0;
	padding: .5rem .7rem;
	background-color: rgba(0, 0, 0, 0)
}

.btn-wd {
	min-width: 140px
}

.btn-group.select {
	width: 100%
}

.btn-group.select .btn {
	text-align: left
}

.btn-group.select .caret {
	position: absolute;
	top: 50%;
	margin-top: -1px;
	right: 8px
}

.form-control::-moz-placeholder {
	color: #9A9A9A;
	opacity: 1;
	filter: alpha(opacity=100)
}

.form-control:-moz-placeholder {
	color: #9A9A9A;
	opacity: 1;
	filter: alpha(opacity=100)
}

.form-control::-webkit-input-placeholder {
	color: #9A9A9A;
	opacity: 1;
	filter: alpha(opacity=100)
}

.form-control:-ms-input-placeholder {
	color: #9A9A9A;
	opacity: 1;
	filter: alpha(opacity=100)
}

.form-control {
	background-color: rgba(0, 0, 0, 0);
	border: 1px solid #E3E3E3;
	border-radius: 30px;
	color: #2c2c2c;
	height: auto;
	line-height: normal;
	font-size: .8571em;
	-webkit-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
	-moz-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
	-o-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
	-ms-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
	transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
	-webkit-box-shadow: none;
	box-shadow: none
}

.has-success .form-control {
	border-color: #18ce0f
}

.form-control:focus {
	border: 1px solid #662d91;
	-webkit-box-shadow: none;
	box-shadow: none;
	outline: 0 !important;
	color: #2c2c2c
}

.form-control:focus+.input-group-addon,
.form-control:focus~.input-group-addon {
	border: 1px solid #662d91;
	border-left: none;
	background-color: rgba(0, 0, 0, 0)
}

.has-success .form-control,
.has-error .form-control,
.has-success .form-control:focus,
.has-error .form-control:focus {
	-webkit-box-shadow: none;
	box-shadow: none
}

.has-success .form-control:focus {
	border-color: #1be611
}

.has-danger .form-control.form-control-success,
.has-danger .form-control.form-control-danger,
.has-success .form-control.form-control-success,
.has-success .form-control.form-control-danger {
	background-image: none
}

.has-danger .form-control {
	border-color: #ffcfcf;
	color: #FF3636;
	background-color: rgba(222, 222, 222, 0.1)
}

.has-danger .form-control:focus {
	background-color: #fff
}

.form-control+.form-control-feedback {
	border-radius: .25rem;
	font-size: 14px;
	margin-top: 0;
	position: absolute;
	left: 18px;
	bottom: -20px;
	vertical-align: middle
}

.form-control+.input-group-addon {
	background-color: transparent
}

.form-control+.input-group-addon:not(:first-child) {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0
}

.input-group-addon:first-child {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0
}

.has-success:after,
.has-danger:after {
	font-family: 'Material-Design-Iconic-Font';
	content: "\f26b";
	display: inline-block;
	position: absolute;
	right: 15px;
	bottom: 10px;
	color: #18ce0f;
	font-size: 11px
}

.has-success.input-lg:after,
.has-danger.input-lg:after {
	font-size: 13px;
	top: 13px
}

.has-danger:after {
	content: "\f136";
	color: #FF3636
}

.form-group.form-group-no-border.input-lg .input-group-addon,
.input-group.form-group-no-border.input-lg .input-group-addon {
	padding: 15px 0 15px 19px
}

.form-group.form-group-no-border.input-lg .form-control,
.input-group.form-group-no-border.input-lg .form-control {
	padding: 15px 19px
}

.form-group.form-group-no-border.input-lg .form-control+.input-group-addon,
.input-group.form-group-no-border.input-lg .form-control+.input-group-addon {
	padding: 15px 19px 15px 0
}

.form-group.input-lg .form-control,
.input-group.input-lg .form-control {
	padding: 14px 18px
}

.form-group.input-lg .form-control+.input-group-addon,
.input-group.input-lg .form-control+.input-group-addon {
	padding: 14px 18px 14px 0
}

.form-group.input-lg .input-group-addon,
.input-group.input-lg .input-group-addon {
	padding: 14px 0 15px 18px
}

.form-group.input-lg .input-group-addon+.form-control,
.input-group.input-lg .input-group-addon+.form-control {
	padding: 15px 18px 15px 16px
}

.form-group.form-group-no-border .form-control,
.input-group.form-group-no-border .form-control {
	padding: 11px 19px
}

.form-group.form-group-no-border .form-control+.input-group-addon,
.input-group.form-group-no-border .form-control+.input-group-addon {
	padding: 11px 19px 11px 0
}

.form-group.form-group-no-border .input-group-addon,
.input-group.form-group-no-border .input-group-addon {
	padding: 11px 0 11px 19px
}

.form-group .form-control,
.input-group .form-control {
	padding: 10px 18px 10px 18px
}

.form-group .form-control+.input-group-addon,
.input-group .form-control+.input-group-addon {
	padding: 9px 18px 5px 0
}

.form-group .input-group-addon,
.input-group .input-group-addon {
	padding: 8px 0 6px 18px
}

.form-group .input-group-addon+.form-control,
.form-group .input-group-addon~.form-control,
.input-group .input-group-addon+.form-control,
.input-group .input-group-addon~.form-control {
	padding: 10px 19px 11px 16px
}

.form-group.form-group-no-border .form-control,
.form-group.form-group-no-border .form-control+.input-group-addon,
.input-group.form-group-no-border .form-control,
.input-group.form-group-no-border .form-control+.input-group-addon {
	background-color: rgba(222, 222, 222, 0.3);
	border: medium none
}

.form-group.form-group-no-border .form-control:focus,
.form-group.form-group-no-border .form-control:active,
.form-group.form-group-no-border .form-control:active,
.form-group.form-group-no-border .form-control+.input-group-addon:focus,
.form-group.form-group-no-border .form-control+.input-group-addon:active,
.form-group.form-group-no-border .form-control+.input-group-addon:active,
.input-group.form-group-no-border .form-control:focus,
.input-group.form-group-no-border .form-control:active,
.input-group.form-group-no-border .form-control:active,
.input-group.form-group-no-border .form-control+.input-group-addon:focus,
.input-group.form-group-no-border .form-control+.input-group-addon:active,
.input-group.form-group-no-border .form-control+.input-group-addon:active {
	border: medium none;
	background-color: rgba(222, 222, 222, 0.5)
}

.form-group.form-group-no-border .form-control:focus+.input-group-addon,
.input-group.form-group-no-border .form-control:focus+.input-group-addon {
	background-color: rgba(222, 222, 222, 0.5)
}

.form-group.form-group-no-border .input-group-addon,
.input-group.form-group-no-border .input-group-addon {
	background-color: rgba(222, 222, 222, 0.3);
	border: none
}

.has-error .form-control-feedback,
.has-error .control-label {
	color: #FF3636
}

.has-success .form-control-feedback,
.has-success .control-label {
	color: #18ce0f
}

.input-group-addon {
	background-color: #fff;
	border: 1px solid #E3E3E3;
	border-radius: 30px;
	color: #555;
	padding: -.5rem 0 -.5rem -.3rem;
	-webkit-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
	-moz-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
	-o-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
	-ms-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
	transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out
}

.has-success .input-group-addon,
.has-danger .input-group-addon {
	background-color: #fff
}

.has-danger .form-control:focus+.input-group-addon {
	color: #FF3636
}

.has-success .form-control:focus+.input-group-addon {
	color: #18ce0f
}

.input-group-addon+.form-control,
.input-group-addon~.form-control {
	padding: -.5rem .7rem;
	padding-left: 18px
}

.input-group-addon i {
	width: 17px
}

.input-group-focus .input-group-addon {
	background-color: #fff;
	border-color: #662d91
}

.input-group-focus.form-group-no-border .input-group-addon {
	background-color: rgba(222, 222, 222, 0.5)
}

.input-group,
.form-group {
	margin-bottom: 10px
}

.input-group[disabled] .input-group-addon {
	background-color: #E3E3E3
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child>.dropdown-toggle,
.input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
	border-right: 0 none
}

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child>.dropdown-toggle,
.input-group-btn:first-child>.btn:not(:first-child) {
	border-left: 0 none
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
	background-color: #E3E3E3;
	color: #888;
	cursor: not-allowed
}

.input-group-btn .btn {
	border-width: 1px;
	padding: 11px .7rem
}

.input-group-btn .btn-default:not(.btn-fill) {
	border-color: #ddd
}

.input-group-btn:last-child>.btn {
	margin-left: 0
}

textarea.form-control {
	max-width: 100%;
	padding: 10px 10px 0 0;
	resize: none;
	border: none;
	border-bottom: 1px solid #E3E3E3;
	border-radius: 0;
	line-height: 2
}

textarea.form-control:focus,
textarea.form-control:active {
	border-left: none;
	border-top: none;
	border-right: none
}

.has-success.form-group .form-control,
.has-success.form-group.form-group-no-border .form-control,
.has-danger.form-group .form-control,
.has-danger.form-group.form-group-no-border .form-control {
	padding-right: 40px
}

.form-group {
	position: relative
}

.form-group .help-info {
	font-style: italic;
	font-size: 12px;
	margin-top: 5px
}

.form-group.has-error,
.form-group.has-danger {
	margin-bottom: 20px
}

.checkbox,
.radio {
	margin-bottom: 12px
}

.checkbox label,
.radio label {
	display: inline-block;
	position: relative;
	cursor: pointer;
	padding-left: 35px;
	line-height: 27px
}

.radio label {
	padding-left: 28px
}

.checkbox label::before,
.checkbox label::after {
	content: " ";
	display: inline-block;
	position: absolute;
	width: 26px;
	height: 26px;
	left: 0;
	cursor: pointer;
	border-radius: 3px;
	top: 0;
	background-color: transparent;
	border: 1px solid #E3E3E3;
	-webkit-transition: opacity 0.3s linear;
	-moz-transition: opacity 0.3s linear;
	-o-transition: opacity 0.3s linear;
	-ms-transition: opacity 0.3s linear;
	transition: opacity 0.3s linear
}

.checkbox label::after {
	font-family: 'Material-Design-Iconic-Font';
	content: "\f26b";
	top: 0px;
	text-align: center;
	font-size: 14px;
	opacity: 0;
	color: #555;
	border: 0;
	background-color: inherit
}

.checkbox input[type="checkbox"],
.radio input[type="radio"] {
	opacity: 0;
	position: absolute;
	visibility: hidden
}

.checkbox input[type="checkbox"]:checked+label::after {
	opacity: 1
}

.checkbox input[type="checkbox"]:disabled+label,
.radio input[type="radio"]:disabled+label {
	color: #9A9A9A;
	opacity: .5
}

.checkbox input[type="checkbox"]:disabled+label::before,
.checkbox input[type="checkbox"]:disabled+label::after {
	cursor: not-allowed
}

.checkbox input[type="checkbox"]:disabled+label,
.radio input[type="radio"]:disabled+label {
	cursor: not-allowed
}

.checkbox.checkbox-circle label::before {
	border-radius: 50%
}

.checkbox.checkbox-inline {
	margin-top: 0
}

.checkbox-primary input[type="checkbox"]:checked+label::before {
	background-color: #428bca;
	border-color: #428bca
}

.checkbox-primary input[type="checkbox"]:checked+label::after {
	color: #fff
}

.checkbox-danger input[type="checkbox"]:checked+label::before {
	background-color: #d9534f;
	border-color: #d9534f
}

.checkbox-danger input[type="checkbox"]:checked+label::after {
	color: #fff
}

.checkbox-info input[type="checkbox"]:checked+label::before {
	background-color: #5bc0de;
	border-color: #5bc0de
}

.checkbox-info input[type="checkbox"]:checked+label::after {
	color: #fff
}

.checkbox-warning input[type="checkbox"]:checked+label::before {
	background-color: #f0ad4e;
	border-color: #f0ad4e
}

.checkbox-warning input[type="checkbox"]:checked+label::after {
	color: #fff
}

.checkbox-success input[type="checkbox"]:checked+label::before {
	background-color: #5cb85c;
	border-color: #5cb85c
}

.checkbox-success input[type="checkbox"]:checked+label::after {
	color: #fff
}

.radio label::before,
.radio label::after {
	content: " ";
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 1px solid #E3E3E3;
	display: inline-block;
	position: absolute;
	left: 3px;
	top: 3px;
	padding: 1px;
	-webkit-transition: opacity 0.3s linear;
	-moz-transition: opacity 0.3s linear;
	-o-transition: opacity 0.3s linear;
	-ms-transition: opacity 0.3s linear;
	transition: opacity 0.3s linear
}

.radio input[type="radio"]+label:after,
.radio input[type="radio"] {
	opacity: 0
}

.radio input[type="radio"]:checked+label::after {
	width: 4px;
	height: 4px;
	background-color: #555;
	border-color: #555;
	top: 11px;
	left: 11px;
	opacity: 1
}

.radio input[type="radio"]:checked+label::after {
	opacity: 1
}

.radio input[type="radio"]:disabled+label {
	color: #9A9A9A
}

.radio input[type="radio"]:disabled+label::before,
.radio input[type="radio"]:disabled+label::after {
	color: #9A9A9A
}

.radio.radio-inline {
	margin-top: 0
}

.progress-container {
	position: relative
}

.progress-container+.progress-container,
.progress-container~.progress-container {
	margin-top: 15px
}

.progress-container .progress-badge {
	color: #888;
	font-size: .8571em;
	text-transform: uppercase
}

.progress-container .progress {
	height: 1px;
	border-radius: 0;
	box-shadow: none;
	background: rgba(222, 222, 222, 0.8);
	margin-top: 14px
}

.progress-container .progress .progress-bar {
	box-shadow: none;
	background-color: #888
}

.progress-container .progress .progress-value {
	position: absolute;
	top: 6px;
	right: 0;
	color: #888;
	font-size: .8571em
}

.progress-container.progress-neutral .progress {
	background: rgba(255, 255, 255, 0.3)
}

.progress-container.progress-neutral .progress-bar {
	background: #fff
}

.progress-container.progress-neutral .progress-value,
.progress-container.progress-neutral .progress-badge {
	color: #fff
}

.progress-container.progress-primary .progress {
	background: rgba(249, 99, 50, 0.3)
}

.progress-container.progress-primary .progress-bar {
	background: #662d91
}

.progress-container.progress-primary .progress-value,
.progress-container.progress-primary .progress-badge {
	color: #662d91
}

.progress-container.progress-info .progress {
	background: rgba(44, 168, 255, 0.3)
}

.progress-container.progress-info .progress-bar {
	background: #2CA8FF
}

.progress-container.progress-info .progress-value,
.progress-container.progress-info .progress-badge {
	color: #2CA8FF
}

.progress-container.progress-success .progress {
	background: rgba(24, 206, 15, 0.3)
}

.progress-container.progress-success .progress-bar {
	background: #18ce0f
}

.progress-container.progress-success .progress-value,
.progress-container.progress-success .progress-badge {
	color: #18ce0f
}

.progress-container.progress-warning .progress {
	background: rgba(255, 178, 54, 0.3)
}

.progress-container.progress-warning .progress-bar {
	background: #FFB236
}

.progress-container.progress-warning .progress-value,
.progress-container.progress-warning .progress-badge {
	color: #FFB236
}

.progress-container.progress-danger .progress {
	background: rgba(255, 54, 54, 0.3)
}

.progress-container.progress-danger .progress-bar {
	background: #FF3636
}

.progress-container.progress-danger .progress-value,
.progress-container.progress-danger .progress-badge {
	color: #FF3636
}

.badge {
	border-radius: 8px;
	padding: 4px 8px;
	text-transform: uppercase;
	font-size: .7142em;
	line-height: 12px;
	background-color: rgba(0, 0, 0, 0);
	border: 1px solid;
	margin-bottom: 5px;
	border-radius: .875rem
}

.badge-icon {
	padding: 0.4em 0.55em
}

.badge-icon i {
	font-size: 0.8em
}

.badge-default {
	border-color: #888;
	color: #888
}

.badge-primary {
	border-color: #662d91;
	color: #662d91
}

.badge-info {
	border-color: #2CA8FF;
	color: #2CA8FF
}

.badge-success {
	border-color: #18ce0f;
	color: #18ce0f
}

.badge-warning {
	border-color: #FFB236;
	color: #FFB236
}

.badge-danger {
	border-color: #FF3636;
	color: #FF3636
}

.badge-neutral {
	border-color: #fff;
	color: #fff
}

.pagination .page-item .page-link {
	border: 0;
	border-radius: 30px !important;
	transition: all .3s;
	padding: 0px 11px;
	margin: 0 3px;
	min-width: 30px;
	text-align: center;
	height: 30px;
	line-height: 30px;
	color: #2c2c2c;
	cursor: pointer;
	font-size: 14px;
	text-transform: uppercase;
	background: transparent
}

.pagination .page-item .page-link:hover,
.pagination .page-item .page-link:focus {
	color: #2c2c2c;
	background-color: rgba(222, 222, 222, 0.3);
	border: none
}

.pagination .arrow-margin-left,
.pagination .arrow-margin-right {
	position: absolute
}

.pagination .arrow-margin-right {
	right: 0
}

.pagination .arrow-margin-left {
	left: 0
}

.pagination .page-item.active>.page-link {
	color: #E3E3E3;
	box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2)
}

.pagination .page-item.active>.page-link,
.pagination .page-item.active>.page-link:focus,
.pagination .page-item.active>.page-link:hover {
	background-color: #888;
	border-color: #888;
	color: #fff
}

.pagination .page-item.disabled>.page-link {
	opacity: .5;
	background-color: rgba(255, 255, 255, 0.2);
	color: #fff
}

.pagination.pagination-info .page-item.active>.page-link,
.pagination.pagination-info .page-item.active>.page-link:focus,
.pagination.pagination-info .page-item.active>.page-link:hover {
	background-color: #2CA8FF;
	border-color: #2CA8FF
}

.pagination.pagination-success .page-item.active>.page-link,
.pagination.pagination-success .page-item.active>.page-link:focus,
.pagination.pagination-success .page-item.active>.page-link:hover {
	background-color: #18ce0f;
	border-color: #18ce0f
}

.pagination.pagination-primary .page-item.active>.page-link,
.pagination.pagination-primary .page-item.active>.page-link:focus,
.pagination.pagination-primary .page-item.active>.page-link:hover {
	background-color: #662d91;
	border-color: #662d91
}

.pagination.pagination-warning .page-item.active>.page-link,
.pagination.pagination-warning .page-item.active>.page-link:focus,
.pagination.pagination-warning .page-item.active>.page-link:hover {
	background-color: #FFB236;
	border-color: #FFB236
}

.pagination.pagination-danger .page-item.active>.page-link,
.pagination.pagination-danger .page-item.active>.page-link:focus,
.pagination.pagination-danger .page-item.active>.page-link:hover {
	background-color: #FF3636;
	border-color: #FF3636
}

.pagination.pagination-neutral .page-item>.page-link {
	color: #fff
}

.pagination.pagination-neutral .page-item>.page-link:focus,
.pagination.pagination-neutral .page-item>.page-link:hover {
	background-color: rgba(255, 255, 255, 0.2);
	color: #fff
}

.pagination.pagination-neutral .page-item.active>.page-link,
.pagination.pagination-neutral .page-item.active>.page-link:focus,
.pagination.pagination-neutral .page-item.active>.page-link:hover {
	background-color: #fff;
	border-color: #fff;
	color: #662d91
}

a {
	color: #662d91
}

a:hover,
a:focus {
	color: #662d91
}

h1,
.h1 {
	font-size: 3.5em;
	line-height: 1.15;
	margin-bottom: 30px
}

h1 small,
.h1 small {
	font-weight: 700;
	text-transform: uppercase;
	opacity: .8
}

h2,
.h2 {
	font-size: 2.5em;
	margin-bottom: 30px
}

h3,
.h3 {
	font-size: 2em;
	margin-bottom: 30px;
	line-height: 1.4em
}

h4,
.h4 {
	font-size: 1.714em;
	line-height: 1.45em;
	margin-top: 30px;
	margin-bottom: 15px
}

h4+.category,
h4.title+.category,
.h4+.category,
.h4.title+.category {
	margin-top: -10px
}

h5,
.h5 {
	font-size: 1.57em;
	line-height: 1.4em;
	margin-bottom: 15px
}

h6,
.h6 {
	font-size: 1em;
	font-weight: 700;
	text-transform: uppercase
}

p {
	line-height: 1.61em
}

.description p,
p.description {
	font-size: 1.14em
}

.title {
	font-weight: 700
}

.title.title-up {
	text-transform: uppercase
}

.title.title-up a {
	color: #2c2c2c;
	text-decoration: none
}

.title+.category {
	margin-top: -25px
}

.description,
.card-description,
.footer-big p {
	color: #9A9A9A;
	font-weight: 300
}

.category {
	text-transform: uppercase;
	font-weight: 700;
	color: #9A9A9A
}

.text-primary {
	color: #662d91 !important
}

.text-info,
.search_result_page .title a {
	color: #2CA8FF !important
}

.text-success {
	color: #18ce0f !important
}

.text-warning {
	color: #FFB236 !important
}

.text-danger {
	color: #FF3636 !important
}

.text-black {
	color: #444
}

.blockquote {
	border-left: none;
	border: 1px solid #888;
	padding: 20px;
	font-size: 1em;
	line-height: 1.8
}

.blockquote small {
	color: #888;
	font-size: .8571em;
	text-transform: uppercase
}

.blockquote.blockquote-primary {
	border-color: #662d91;
	color: #662d91
}

.blockquote.blockquote-primary small {
	color: #662d91
}

.blockquote.blockquote-danger {
	border-color: #FF3636;
	color: #FF3636
}

.blockquote.blockquote-danger small {
	color: #FF3636
}

.blockquote.blockquote-white {
	border-color: rgba(255, 255, 255, 0.8);
	color: #fff
}

.blockquote.blockquote-white small {
	color: rgba(255, 255, 255, 0.8)
}

body {
	color: #2c2c2c;
	font-size: 14px;
	font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased
}

.main {
	position: relative;
	background: #fff
}

.nav-pills .nav-link,
.nav-item .nav-link,
.navbar,
.nav-tabs .nav-link {
	-webkit-transition: all 300ms ease 0s;
	-moz-transition: all 300ms ease 0s;
	-o-transition: all 300ms ease 0s;
	-ms-transition: all 300ms ease 0s;
	transition: all 300ms ease 0s
}

.dropdown-toggle:after,
.bootstrap-switch-label:before {
	-webkit-transition: all 150ms ease 0s;
	-moz-transition: all 150ms ease 0s;
	-o-transition: all 150ms ease 0s;
	-ms-transition: all 150ms ease 0s;
	transition: all 150ms ease 0s
}

.dropdown-toggle[aria-expanded="true"]:after {
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg)
}

.button-bar {
	display: block;
	position: relative;
	width: 22px;
	height: 1px;
	border-radius: 1px;
	background: #fff
}

.button-bar+.button-bar {
	margin-top: 7px
}

.button-bar:nth-child(2) {
	width: 17px
}

.open {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	opacity: 1;
	filter: alpha(opacity=100);
	visibility: visible
}

.separator {
	height: 2px;
	width: 44px;
	background-color: #888;
	margin: 20px auto
}

.separator.separator-primary {
	background-color: #662d91
}

.nav-pills .nav-item .nav-link {
	padding: 0 15.5px;
	text-align: center;
	height: 60px;
	width: 60px;
	font-weight: 400;
	color: #9A9A9A;
	margin-right: 19px;
	background-color: rgba(222, 222, 222, 0.3);
	border-radius: 30px
}

.nav-pills .nav-item .nav-link:hover {
	background-color: rgba(222, 222, 222, 0.3)
}

.nav-pills .nav-item .nav-link.active,
.nav-pills .nav-item .nav-link.active:focus,
.nav-pills .nav-item .nav-link.active:hover {
	background-color: #9A9A9A;
	color: #fff;
	box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3)
}

.nav-pills .nav-item .nav-link.disabled,
.nav-pills .nav-item .nav-link:disabled,
.nav-pills .nav-item .nav-link[disabled] {
	opacity: .5
}

.nav-pills .nav-item i {
	display: block;
	font-size: 20px;
	line-height: 60px
}

.nav-pills.nav-pills-neutral .nav-item .nav-link {
	background-color: rgba(255, 255, 255, 0.2);
	color: #fff
}

.nav-pills.nav-pills-neutral .nav-item .nav-link.active,
.nav-pills.nav-pills-neutral .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-neutral .nav-item .nav-link.active:hover {
	background-color: #fff;
	color: #662d91
}

.nav-pills.nav-pills-primary .nav-item .nav-link.active,
.nav-pills.nav-pills-primary .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-primary .nav-item .nav-link.active:hover {
	background-color: #662d91
}

.nav-pills.nav-pills-info .nav-item .nav-link.active,
.nav-pills.nav-pills-info .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-info .nav-item .nav-link.active:hover {
	background-color: #2CA8FF
}

.nav-pills.nav-pills-success .nav-item .nav-link.active,
.nav-pills.nav-pills-success .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-success .nav-item .nav-link.active:hover {
	background-color: #18ce0f
}

.nav-pills.nav-pills-warning .nav-item .nav-link.active,
.nav-pills.nav-pills-warning .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-warning .nav-item .nav-link.active:hover {
	background-color: #FFB236
}

.nav-pills.nav-pills-danger .nav-item .nav-link.active,
.nav-pills.nav-pills-danger .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-danger .nav-item .nav-link.active:hover {
	background-color: #FF3636
}

.tab-space {
	padding: 20px 0 50px 0px
}

.nav-align-center {
	text-align: center
}

.nav-align-center .nav-pills {
	display: inline-flex
}

.btn-twitter {
	color: #55acee
}

.btn-twitter:hover,
.btn-twitter:focus,
.btn-twitter:active {
	color: #3ea1ec
}

.btn-facebook {
	color: #3b5998
}

.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook:active {
	color: #344e86
}

.btn-google {
	color: #dd4b39
}

.btn-google:hover,
.btn-google:focus,
.btn-google:active {
	color: #d73925
}

.btn-linkedin {
	color: #0077B5
}

.btn-linkedin:hover,
.btn-linkedin:focus,
.btn-linkedin:active {
	color: #00669c
}

.nav-tabs {
	border: 0;
	padding: 15px .7rem
}

.nav-tabs>.nav-item>.nav-link {
	color: #888;
	margin: 0;
	margin-right: 5px;
	background-color: rgba(0, 0, 0, 0);
	border: 1px solid rgba(0, 0, 0, 0);
	border-radius: 30px;
	font-size: 14px;
	padding: 11px 23px;
	line-height: 1.5
}

.nav-tabs>.nav-item>.nav-link:hover {
	background-color: rgba(0, 0, 0, 0)
}

.nav-tabs>.nav-item>.nav-link.active {
	border: 1px solid #888;
	border-radius: 30px
}

.nav-tabs>.nav-item>.nav-link i.now-ui-icons {
	font-size: 14px;
	position: relative;
	top: 1px;
	margin-right: 3px
}

.nav-tabs>.nav-item.disabled>.nav-link,
.nav-tabs>.nav-item.disabled>.nav-link:hover {
	color: rgba(255, 255, 255, 0.5)
}

.nav-tabs.nav-tabs-neutral>.nav-item>.nav-link {
	color: #fff
}

.nav-tabs.nav-tabs-neutral>.nav-item>.nav-link.active {
	border-color: rgba(255, 255, 255, 0.5);
	color: #fff
}

.nav-tabs.nav-tabs-primary>.nav-item>.nav-link.active {
	border-color: #662d91;
	color: #662d91
}

.nav-tabs.nav-tabs-info>.nav-item>.nav-link.active {
	border-color: #2CA8FF;
	color: #2CA8FF
}

.nav-tabs.nav-tabs-danger>.nav-item>.nav-link.active {
	border-color: #FF3636;
	color: #FF3636
}

.nav-tabs.nav-tabs-warning>.nav-item>.nav-link.active {
	border-color: #FFB236;
	color: #FFB236
}

.nav-tabs.nav-tabs-success>.nav-item>.nav-link.active {
	border-color: #18ce0f;
	color: #18ce0f
}

.navbar {
	padding-top: .625rem;
	padding-bottom: .625rem;
	min-height: 53px;
	margin-bottom: 20px;
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15)
}

.navbar a {
	vertical-align: middle
}

.navbar a:not(.btn):not(.dropdown-item) {
	color: #fff
}

.navbar p {
	display: inline-block;
	margin: 0;
	line-height: 21px
}

.navbar .navbar-nav.navbar-logo {
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 49px;
	top: -4px
}

.navbar .navbar-nav .nav-link.btn {
	padding: 11px 22px
}

.navbar .navbar-nav .nav-link.btn.btn-lg {
	padding: 15px 48px
}

.navbar .navbar-nav .nav-link.btn.btn-sm {
	padding: 5px 15px
}

.navbar .navbar-nav .nav-link:not(.btn) {
	text-transform: uppercase;
	font-size: .7142em;
	padding: .5rem .7rem;
	line-height: 1.625rem
}

.navbar .navbar-nav .nav-link:not(.btn) i.fa+p,
.navbar .navbar-nav .nav-link:not(.btn) i.now-ui-icons+p {
	margin-left: 5px
}

.navbar .navbar-nav .nav-link:not(.btn) i.fa,
.navbar .navbar-nav .nav-link:not(.btn) i.now-ui-icons {
	font-size: 18px;
	position: relative;
	top: 2px;
	text-align: center;
	width: 21px
}

.navbar .navbar-nav .nav-link:not(.btn) i.now-ui-icons {
	top: 4px;
	font-size: 16px
}

.navbar .navbar-nav .nav-link:not(.btn).profile-photo .profile-photo-small {
	width: 27px;
	height: 27px
}

.navbar .navbar-nav .nav-link:not(.btn).disabled {
	opacity: .5;
	color: #fff
}

.navbar .navbar-nav .nav-item.active .nav-link:not(.btn),
.navbar .navbar-nav .nav-item .nav-link:not(.btn):focus,
.navbar .navbar-nav .nav-item .nav-link:not(.btn):hover,
.navbar .navbar-nav .nav-item .nav-link:not(.btn):active {
	background-color: rgba(255, 255, 255, 0.2);
	border-radius: .1875rem
}

.navbar .logo-container {
	width: 27px;
	height: 27px;
	overflow: hidden;
	margin: 0 auto;
	border-radius: 50%;
	border: 1px solid transparent
}

.navbar .navbar-brand {
	text-transform: uppercase;
	font-size: .8571em;
	padding-top: .5rem;
	padding-bottom: .5rem;
	line-height: 3.625rem
}
.diamond{
	width: 30px;
	height: 30px;
	font-size: 15px;
	line-height: 30px;
	position: absolute;
	color: #fff;
	background-image: linear-gradient(to bottom, #55aef6, #662d91);
	border-radius: 4px;
	text-align: center;
	left: -15px;
	top: 0;
	transform: rotate(45deg);
	transform-origin: center;
}
.textRotate
{
	transform: rotate(-45deg);
}
.navbar .navbar-toggler {
	width: 37px;
	height: 27px;
	outline: 0;
	cursor: pointer
}

.navbar .navbar-toggler.navbar-toggler-left {
	position: relative;
	left: 0;
	padding-left: 0
}

.navbar .navbar-toggler:hover .navbar-toggler-bar.bar2 {
	width: 22px
}

.navbar .button-dropdown .navbar-toggler-bar:nth-child(2) {
	width: 17px
}

.navbar.navbar-transparent {
	background-color: rgba(0, 0, 0, 0) !important;
	box-shadow: none;
	color: #fff;
	padding-top: 20px
}

.navbar.bg-white:not(.navbar-transparent) a:not(.dropdown-item) {
	color: #888
}

.navbar.bg-white:not(.navbar-transparent) a:not(.dropdown-item).disabled {
	opacity: .5;
	color: #888
}

.navbar.bg-white:not(.navbar-transparent) .button-bar {
	background: #888
}

.navbar.bg-white:not(.navbar-transparent) .nav-item.active .nav-link:not(.btn),
.navbar.bg-white:not(.navbar-transparent) .nav-item .nav-link:not(.btn):focus,
.navbar.bg-white:not(.navbar-transparent) .nav-item .nav-link:not(.btn):hover,
.navbar.bg-white:not(.navbar-transparent) .nav-item .nav-link:not(.btn):active {
	background-color: rgba(222, 222, 222, 0.3)
}

.navbar.bg-white:not(.navbar-transparent) .logo-container {
	border: 1px solid #888
}

.bg-default {
	background-color: #888 !important
}

.bg-primary {
	background-color: #662d91 !important
}

.bg-info {
	background-color: #2CA8FF !important
}

.bg-success {
	background-color: #18ce0f !important
}

.bg-danger {
	background-color: #FF3636 !important
}

.bg-warning {
	background-color: #FFB236 !important
}

.bg-white {
	background-color: #fff !important
}

.dropdown-menu {
	border: 0;
	box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
	font-size: 14px
}

.dropdown-menu .dropdown-item {
	font-size: .8571em;
	padding-top: .5rem;
	padding-bottom: .5rem
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
	background-color: rgba(222, 222, 222, 0.3)
}

.dropdown-menu .dropdown-divider {
	background-color: rgba(222, 222, 222, 0.5)
}

.dropdown-menu .dropdown-header:not([href]):not([tabindex]) {
	color: rgba(182, 182, 182, 0.6);
	font-size: .7142em;
	text-transform: uppercase;
	font-weight: 700
}

.dropdown-menu.dropdown-primary {
	background-color: #f95823
}

.dropdown-menu.dropdown-primary:before {
	color: #f95823
}

.dropdown-menu.dropdown-primary .dropdown-header:not([href]):not([tabindex]) {
	color: rgba(255, 255, 255, 0.8)
}

.dropdown-menu.dropdown-primary .dropdown-item {
	color: #fff
}

.dropdown-menu.dropdown-primary .dropdown-item:hover,
.dropdown-menu.dropdown-primary .dropdown-item:focus {
	background-color: rgba(255, 255, 255, 0.2)
}

.dropdown-menu.dropdown-primary .dropdown-divider {
	background-color: rgba(255, 255, 255, 0.2)
}

.dropdown-menu.dropdown-info {
	background-color: #1da2ff
}

.dropdown-menu.dropdown-info:before {
	color: #1da2ff
}

.dropdown-menu.dropdown-info .dropdown-header:not([href]):not([tabindex]) {
	color: rgba(255, 255, 255, 0.8)
}

.dropdown-menu.dropdown-info .dropdown-item {
	color: #fff
}

.dropdown-menu.dropdown-info .dropdown-item:hover,
.dropdown-menu.dropdown-info .dropdown-item:focus {
	background-color: rgba(255, 255, 255, 0.2)
}

.dropdown-menu.dropdown-info .dropdown-divider {
	background-color: rgba(255, 255, 255, 0.2)
}

.dropdown-menu.dropdown-danger {
	background-color: #ff2727
}

.dropdown-menu.dropdown-danger:before {
	color: #ff2727
}

.dropdown-menu.dropdown-danger .dropdown-header:not([href]):not([tabindex]) {
	color: rgba(255, 255, 255, 0.8)
}

.dropdown-menu.dropdown-danger .dropdown-item {
	color: #fff
}

.dropdown-menu.dropdown-danger .dropdown-item:hover,
.dropdown-menu.dropdown-danger .dropdown-item:focus {
	background-color: rgba(255, 255, 255, 0.2)
}

.dropdown-menu.dropdown-danger .dropdown-divider {
	background-color: rgba(255, 255, 255, 0.2)
}

.dropdown-menu.dropdown-success {
	background-color: #16c00e
}

.dropdown-menu.dropdown-success:before {
	color: #16c00e
}

.dropdown-menu.dropdown-success .dropdown-header:not([href]):not([tabindex]) {
	color: rgba(255, 255, 255, 0.8)
}

.dropdown-menu.dropdown-success .dropdown-item {
	color: #fff
}

.dropdown-menu.dropdown-success .dropdown-item:hover,
.dropdown-menu.dropdown-success .dropdown-item:focus {
	background-color: rgba(255, 255, 255, 0.2)
}

.dropdown-menu.dropdown-success .dropdown-divider {
	background-color: rgba(255, 255, 255, 0.2)
}

.dropdown-menu.dropdown-warning {
	background-color: #ffac27
}

.dropdown-menu.dropdown-warning:before {
	color: #ffac27
}

.dropdown-menu.dropdown-warning .dropdown-header:not([href]):not([tabindex]) {
	color: rgba(255, 255, 255, 0.8)
}

.dropdown-menu.dropdown-warning .dropdown-item {
	color: #fff
}

.dropdown-menu.dropdown-warning .dropdown-item:hover,
.dropdown-menu.dropdown-warning .dropdown-item:focus {
	background-color: rgba(255, 255, 255, 0.2)
}

.dropdown-menu.dropdown-warning .dropdown-divider {
	background-color: rgba(255, 255, 255, 0.2)
}

.dropdown .dropdown-menu {
	-webkit-transform: translate3d(0, -25px, 0);
	-moz-transform: translate3d(0, -25px, 0);
	-o-transform: translate3d(0, -25px, 0);
	-ms-transform: translate3d(0, -25px, 0);
	transform: translate3d(0, -25px, 0);
	visibility: hidden;
	display: none;
	opacity: 0;
	filter: alpha(opacity=0)
}

.dropdown.show .dropdown-menu,
.dropdown-menu.open {
	opacity: 1;
	filter: alpha(opacity=100);
	visibility: visible;
	display: block;
	-webkit-transform: translate3d(0, 0px, 0);
	-moz-transform: translate3d(0, 0px, 0);
	-o-transform: translate3d(0, 0px, 0);
	-ms-transform: translate3d(0, 0px, 0);
	transform: translate3d(0, 0px, 0)
}

.button-dropdown {
	padding-right: .7rem;
	cursor: pointer
}

.button-dropdown .dropdown-toggle {
	padding-top: .5rem;
	padding-bottom: .5rem;
	display: block
}

.button-dropdown .dropdown-toggle:after {
	display: none
}

.alert {
	border: 0;
	border-radius: 0;
	color: #fff;
	padding-top: .9rem;
	padding-bottom: .9rem;
	position: relative
}

.alert.alert-success {
	background-color: rgba(24, 206, 15, 0.8)
}

.alert.alert-danger {
	background-color: rgba(255, 54, 54, 0.8)
}

.alert.alert-warning {
	background-color: rgba(255, 178, 54, 0.8)
}

.alert.alert-info {
	background-color: rgba(44, 168, 255, 0.8)
}

.alert.alert-primary {
	background-color: rgba(249, 99, 50, 0.8)
}

.alert .alert-icon {
	display: block;
	float: left;
	margin-right: 15px;
	margin-top: -1px
}

.alert strong {
	text-transform: uppercase;
	font-size: 12px
}

.alert i.fa,
.alert i.now-ui-icons {
	font-size: 20px
}

.alert .close {
	color: #fff;
	opacity: .9;
	text-shadow: none;
	line-height: 0;
	outline: 0;
	right: -5px !important;
	top: 14px !important
}

img {
	max-width: 100%;
	border-radius: 1px
}

.img-raised {
	box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3)
}

.popover {
	font-size: 14px;
	box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
	border: none;
	line-height: 1.7;
	max-width: 240px
}

.popover.bs-popover-top .arrow:before,
.popover.bs-popover-left .arrow:before,
.popover.bs-popover-right .arrow:before,
.popover.bs-popover-bottom .arrow:before {
	border-top-color: transparent;
	border-left-color: transparent;
	border-right-color: transparent;
	border-bottom-color: transparent
}

.popover .popover-header {
	color: rgba(182, 182, 182, 0.6);
	font-size: 14px;
	text-transform: capitalize;
	font-weight: 600;
	margin: 0;
	margin-top: 5px;
	border: none;
	background-color: transparent
}

.popover:before {
	display: none
}

.popover.bs-tether-element-attached-top:after {
	border-bottom-color: #fff;
	top: -9px
}

.popover.popover-primary {
	background-color: #662d91
}

.popover.popover-primary .popover-body {
	color: #fff
}

.popover.popover-primary.bs-popover-right .arrow:after {
	border-right-color: #662d91
}

.popover.popover-primary.bs-popover-top .arrow:after {
	border-top-color: #662d91
}

.popover.popover-primary.bs-popover-bottom .arrow:after {
	border-bottom-color: #662d91
}

.popover.popover-primary.bs-popover-left .arrow:after {
	border-left-color: #662d91
}

.popover.popover-primary .popover-header {
	color: #fff;
	opacity: .6
}

.popover.popover-info {
	background-color: #2CA8FF
}

.popover.popover-info .popover-body {
	color: #fff
}

.popover.popover-info.bs-popover-right .arrow:after {
	border-right-color: #2CA8FF
}

.popover.popover-info.bs-popover-top .arrow:after {
	border-top-color: #2CA8FF
}

.popover.popover-info.bs-popover-bottom .arrow:after {
	border-bottom-color: #2CA8FF
}

.popover.popover-info.bs-popover-left .arrow:after {
	border-left-color: #2CA8FF
}

.popover.popover-info .popover-header {
	color: #fff;
	opacity: .6
}

.popover.popover-warning {
	background-color: #FFB236
}

.popover.popover-warning .popover-body {
	color: #fff
}

.popover.popover-warning.bs-popover-right .arrow:after {
	border-right-color: #FFB236
}

.popover.popover-warning.bs-popover-top .arrow:after {
	border-top-color: #FFB236
}

.popover.popover-warning.bs-popover-bottom .arrow:after {
	border-bottom-color: #FFB236
}

.popover.popover-warning.bs-popover-left .arrow:after {
	border-left-color: #FFB236
}

.popover.popover-warning .popover-header {
	color: #fff;
	opacity: .6
}

.popover.popover-danger {
	background-color: #FF3636
}

.popover.popover-danger .popover-body {
	color: #fff
}

.popover.popover-danger.bs-popover-right .arrow:after {
	border-right-color: #FF3636
}

.popover.popover-danger.bs-popover-top .arrow:after {
	border-top-color: #FF3636
}

.popover.popover-danger.bs-popover-bottom .arrow:after {
	border-bottom-color: #FF3636
}

.popover.popover-danger.bs-popover-left .arrow:after {
	border-left-color: #FF3636
}

.popover.popover-danger .popover-header {
	color: #fff;
	opacity: .6
}

.popover.popover-success {
	background-color: #18ce0f
}

.popover.popover-success .popover-body {
	color: #fff
}

.popover.popover-success.bs-popover-right .arrow:after {
	border-right-color: #18ce0f
}

.popover.popover-success.bs-popover-top .arrow:after {
	border-top-color: #18ce0f
}

.popover.popover-success.bs-popover-bottom .arrow:after {
	border-bottom-color: #18ce0f
}

.popover.popover-success.bs-popover-left .arrow:after {
	border-left-color: #18ce0f
}

.popover.popover-success .popover-header {
	color: #fff;
	opacity: .6
}

.tooltip.bs-tooltip-right .arrow:before {
	border-right-color: #fff
}

.tooltip.bs-tooltip-top .arrow:before {
	border-top-color: #fff
}

.tooltip.bs-tooltip-bottom .arrow:before {
	border-bottom-color: #fff
}

.tooltip.bs-tooltip-left .arrow:before {
	border-left-color: #fff
}

.tooltip-inner {
	padding: .5rem .7rem;
	min-width: 130px;
	background-color: #fff;
	font-size: 14px;
	color: inherit;
	box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2)
}

/* @font-face {
	font-family: 'Nucleo Outline';
	src: url("../fonts/nucleo-outline.eot");
	src: url("../fonts/nucleo-outline.eot") format("embedded-opentype"), url("../fonts/nucleo-outline.woff2") format("woff2"), url("../fonts/nucleo-outline.woff") format("woff"), url("../fonts/nucleo-outline.ttf") format("truetype");
	font-weight: normal;
	font-style: normal
} */

.now-ui-icons {
	display: inline-block;
	font: normal normal normal 14px/1 'Nucleo Outline';
	font-size: inherit;
	speak: none;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

.now-ui-icons.circle {
	padding: 0.33333333em;
	vertical-align: -16%;
	background-color: #eee
}

.now-ui-icons.circle {
	border-radius: 50%
}

.nc-icon-ul {
	padding-left: 0;
	margin-left: 2.14285714em;
	list-style-type: none
}

.nc-icon-ul>li {
	position: relative
}

.nc-icon-ul>li>.now-ui-icons {
	position: absolute;
	left: -1.57142857em;
	top: 0.14285714em;
	text-align: center
}

.nc-icon-ul>li>.now-ui-icons.circle {
	top: -0.19047619em;
	left: -1.9047619em
}

.now-ui-icons.spin {
	-webkit-animation: nc-icon-spin 2s infinite linear;
	-moz-animation: nc-icon-spin 2s infinite linear;
	animation: nc-icon-spin 2s infinite linear
}

@-webkit-keyframes nc-icon-spin {
	0% {
		-webkit-transform: rotate(0deg)
	}
	100% {
		-webkit-transform: rotate(360deg)
	}
}

@-moz-keyframes nc-icon-spin {
	0% {
		-moz-transform: rotate(0deg)
	}
	100% {
		-moz-transform: rotate(360deg)
	}
}

@keyframes nc-icon-spin {
	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg)
	}
	100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg)
	}
}

.now-ui-icons.ui-1_check:before {
	content: "\ea22"
}

.now-ui-icons.ui-1_email-85:before {
	content: "\ea2a"
}

.now-ui-icons.arrows-1_cloud-download-93:before {
	content: "\ea21"
}

.now-ui-icons.arrows-1_cloud-upload-94:before {
	content: "\ea24"
}

.now-ui-icons.arrows-1_minimal-down:before {
	content: "\ea39"
}

.now-ui-icons.arrows-1_minimal-left:before {
	content: "\ea3a"
}

.now-ui-icons.arrows-1_minimal-right:before {
	content: "\ea3b"
}

.now-ui-icons.arrows-1_minimal-up:before {
	content: "\ea3c"
}

.now-ui-icons.arrows-1_refresh-69:before {
	content: "\ea44"
}

.now-ui-icons.arrows-1_share-66:before {
	content: "\ea4c"
}

.now-ui-icons.business_badge:before {
	content: "\ea09"
}

.now-ui-icons.business_bank:before {
	content: "\ea0a"
}

.now-ui-icons.business_briefcase-24:before {
	content: "\ea13"
}

.now-ui-icons.business_bulb-63:before {
	content: "\ea15"
}

.now-ui-icons.business_chart-bar-32:before {
	content: "\ea1e"
}

.now-ui-icons.business_chart-pie-36:before {
	content: "\ea1f"
}

.now-ui-icons.business_globe:before {
	content: "\ea2f"
}

.now-ui-icons.business_money-coins:before {
	content: "\ea40"
}

.now-ui-icons.clothes_tie-bow:before {
	content: "\ea5b"
}

.now-ui-icons.design_vector:before {
	content: "\ea61"
}

.now-ui-icons.design_app:before {
	content: "\ea08"
}

.now-ui-icons.design_bullet-list-67:before {
	content: "\ea14"
}

.now-ui-icons.design_image:before {
	content: "\ea33"
}

.now-ui-icons.design_palette:before {
	content: "\ea41"
}

.now-ui-icons.design_scissors:before {
	content: "\ea4a"
}

.now-ui-icons.design-2_html5:before {
	content: "\ea32"
}

.now-ui-icons.design-2_ruler-pencil:before {
	content: "\ea48"
}

.now-ui-icons.emoticons_satisfied:before {
	content: "\ea49"
}

.now-ui-icons.files_box:before {
	content: "\ea12"
}

.now-ui-icons.files_paper:before {
	content: "\ea43"
}

.now-ui-icons.files_single-copy-04:before {
	content: "\ea52"
}

.now-ui-icons.health_ambulance:before {
	content: "\ea07"
}

.now-ui-icons.loader_gear:before {
	content: "\ea4e"
}

.now-ui-icons.loader_refresh:before {
	content: "\ea44"
}

.now-ui-icons.location_bookmark:before {
	content: "\ea10"
}

.now-ui-icons.location_compass-05:before {
	content: "\ea25"
}

.now-ui-icons.location_map-big:before {
	content: "\ea3d"
}

.now-ui-icons.location_pin:before {
	content: "\ea47"
}

.now-ui-icons.location_world:before {
	content: "\ea63"
}

.now-ui-icons.media-1_album:before {
	content: "\ea02"
}

.now-ui-icons.media-1_button-pause:before {
	content: "\ea16"
}

.now-ui-icons.media-1_button-play:before {
	content: "\ea18"
}

.now-ui-icons.media-1_button-power:before {
	content: "\ea19"
}

.now-ui-icons.media-1_camera-compact:before {
	content: "\ea1c"
}

.now-ui-icons.media-2_note-03:before {
	content: "\ea3f"
}

.now-ui-icons.media-2_sound-wave:before {
	content: "\ea57"
}

.now-ui-icons.objects_diamond:before {
	content: "\ea29"
}

.now-ui-icons.objects_globe:before {
	content: "\ea2f"
}

.now-ui-icons.objects_key-25:before {
	content: "\ea38"
}

.now-ui-icons.objects_planet:before {
	content: "\ea46"
}

.now-ui-icons.objects_spaceship:before {
	content: "\ea55"
}

.now-ui-icons.objects_support-17:before {
	content: "\ea56"
}

.now-ui-icons.objects_umbrella-13:before {
	content: "\ea5f"
}

.now-ui-icons.education_agenda-bookmark:before {
	content: "\ea01"
}

.now-ui-icons.education_atom:before {
	content: "\ea0c"
}

.now-ui-icons.education_glasses:before {
	content: "\ea2d"
}

.now-ui-icons.education_hat:before {
	content: "\ea30"
}

.now-ui-icons.education_paper:before {
	content: "\ea42"
}

.now-ui-icons.shopping_bag-16:before {
	content: "\ea0d"
}

.now-ui-icons.shopping_basket:before {
	content: "\ea0b"
}

.now-ui-icons.shopping_box:before {
	content: "\ea11"
}

.now-ui-icons.shopping_cart-simple:before {
	content: "\ea1d"
}

.now-ui-icons.shopping_credit-card:before {
	content: "\ea28"
}

.now-ui-icons.shopping_delivery-fast:before {
	content: "\ea27"
}

.now-ui-icons.shopping_shop:before {
	content: "\ea50"
}

.now-ui-icons.shopping_tag-content:before {
	content: "\ea59"
}

.now-ui-icons.sport_trophy:before {
	content: "\ea5d"
}

.now-ui-icons.sport_user-run:before {
	content: "\ea60"
}

.now-ui-icons.tech_controller-modern:before {
	content: "\ea26"
}

.now-ui-icons.tech_headphones:before {
	content: "\ea31"
}

.now-ui-icons.tech_laptop:before {
	content: "\ea36"
}

.now-ui-icons.tech_mobile:before {
	content: "\ea3e"
}

.now-ui-icons.tech_tablet:before {
	content: "\ea58"
}

.now-ui-icons.tech_tv:before {
	content: "\ea5e"
}

.now-ui-icons.tech_watch-time:before {
	content: "\ea62"
}

.now-ui-icons.text_align-center:before {
	content: "\ea05"
}

.now-ui-icons.text_align-left:before {
	content: "\ea06"
}

.now-ui-icons.text_bold:before {
	content: "\ea0e"
}

.now-ui-icons.text_caps-small:before {
	content: "\ea1b"
}

.now-ui-icons.gestures_tap-01:before {
	content: "\ea5a"
}

.now-ui-icons.transportation_air-baloon:before {
	content: "\ea03"
}

.now-ui-icons.transportation_bus-front-12:before {
	content: "\ea17"
}

.now-ui-icons.travel_info:before {
	content: "\ea04"
}

.now-ui-icons.travel_istanbul:before {
	content: "\ea34"
}

.now-ui-icons.ui-1_bell-53:before {
	content: "\ea0f"
}

.now-ui-icons.ui-1_calendar-60:before {
	content: "\ea1a"
}

.now-ui-icons.ui-1_lock-circle-open:before {
	content: "\ea35"
}

.now-ui-icons.ui-1_send:before {
	content: "\ea4d"
}

.now-ui-icons.ui-1_settings-gear-63:before {
	content: "\ea4e"
}

.now-ui-icons.ui-1_simple-add:before {
	content: "\ea4f"
}

.now-ui-icons.ui-1_simple-delete:before {
	content: "\ea54"
}

.now-ui-icons.ui-1_simple-remove:before {
	content: "\ea53"
}

.now-ui-icons.ui-1_zoom-bold:before {
	content: "\ea64"
}

.now-ui-icons.ui-2_chat-round:before {
	content: "\ea20"
}

.now-ui-icons.ui-2_favourite-28:before {
	content: "\ea2b"
}

.now-ui-icons.ui-2_like:before {
	content: "\ea37"
}

.now-ui-icons.ui-2_settings-90:before {
	content: "\ea4b"
}

.now-ui-icons.ui-2_time-alarm:before {
	content: "\ea5c"
}

.now-ui-icons.users_circle-08:before {
	content: "\ea23"
}

.now-ui-icons.users_single-02:before {
	content: "\ea51"
}

.modal-content {
	border-radius: .1875rem;
	border: none;
	box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.5)
}

.modal-content .modal-header {
	border-bottom: none;
	padding-top: 24px;
	padding-right: 24px;
	padding-bottom: 0;
	padding-left: 24px
}

.modal-content .modal-header button {
	position: absolute;
	right: 27px;
	top: 30px;
	outline: 0
}

.modal-content .modal-header .title {
	margin-top: 5px;
	margin-bottom: 0
}

.modal-content .modal-body {
	padding-top: 24px;
	padding-right: 24px;
	padding-bottom: 16px;
	padding-left: 24px;
	line-height: 1.9
}

.modal-content .modal-footer {
	border-top: none;
	padding-right: 24px;
	padding-bottom: 16px;
	padding-left: 24px;
	-webkit-justify-content: space-between;
	justify-content: space-between
}

.modal-content .modal-footer button {
	margin: 0;
	padding-left: 16px;
	padding-right: 16px;
	width: auto
}

.modal-content .modal-footer button.pull-left {
	padding-left: 5px;
	padding-right: 5px;
	position: relative;
	left: -5px
}

.modal-content .modal-body+.modal-footer {
	padding-top: 0
}

.modal-backdrop {
	background: rgba(0, 0, 0, 0.3)
}

.modal.modal-mini p {
	text-align: center
}

.modal.modal-mini .modal-dialog {
	max-width: 255px;
	margin: 0 auto
}

.modal.modal-mini .modal-profile {
	width: 70px;
	height: 70px;
	background-color: #fff;
	border-radius: 50%;
	text-align: center;
	line-height: 5.9;
	box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.3)
}

.modal.modal-mini .modal-profile i {
	color: #662d91;
	font-size: 21px
}

.modal.modal-mini .modal-profile[class*="modal-profile-"] i {
	color: #fff
}

.modal.modal-mini .modal-profile.modal-profile-primary {
	background-color: #662d91
}

.modal.modal-mini .modal-profile.modal-profile-danger {
	background-color: #FF3636
}

.modal.modal-mini .modal-profile.modal-profile-warning {
	background-color: #FFB236
}

.modal.modal-mini .modal-profile.modal-profile-success {
	background-color: #18ce0f
}

.modal.modal-mini .modal-profile.modal-profile-info {
	background-color: #2CA8FF
}

.modal.modal-mini .modal-footer button {
	text-transform: uppercase
}

.modal.modal-mini .modal-footer button:first-child {
	opacity: .5
}

.modal.modal-default .modal-content {
	background-color: #fff;
	color: #2c2c2c
}

.modal.modal-default .modal-header .close {
	color: #2c2c2c
}

.modal.modal-primary .modal-content {
	background-color: #662d91;
	color: #fff
}

.modal.modal-primary .modal-header .close {
	color: #fff
}

.modal.modal-danger .modal-content {
	background-color: #FF3636;
	color: #fff
}

.modal.modal-danger .modal-header .close {
	color: #fff
}

.modal.modal-warning .modal-content {
	background-color: #FFB236;
	color: #fff
}

.modal.modal-warning .modal-header .close {
	color: #fff
}

.modal.modal-success .modal-content {
	background-color: #18ce0f;
	color: #fff
}

.modal.modal-success .modal-header .close {
	color: #fff
}

.modal.modal-info .modal-content {
	background-color: #2CA8FF;
	color: #fff
}

.modal.modal-info .modal-header .close {
	color: #fff
}

.modal.show.modal-mini .modal-dialog {
	-webkit-transform: translate(0, 30%);
	-o-transform: translate(0, 30%);
	transform: translate(0, 30%)
}

.modal .modal-header .close {
	color: #FF3636;
	text-shadow: none
}

.modal .modal-header .close:hover,
.modal .modal-header .close:focus {
	opacity: 1
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
	display: block
}

.carousel .carousel-inner {
	box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3)
}

.carousel .now-ui-icons {
	font-size: 2em
}

.footer {
	padding: 24px 0
}

.footer.footer-default {
	background-color: #f2f2f2
}

.footer nav {
	display: inline-block;
	float: left
}

.footer ul {
	margin-bottom: 0;
	padding: 0;
	list-style: none
}

.footer ul li {
	display: inline-block
}

.footer ul li a {
	color: inherit;
	padding: .5rem;
	font-size: .8571em;
	text-transform: uppercase;
	text-decoration: none
}

.footer ul li a:hover {
	text-decoration: none
}

.footer .copyright {
	font-size: .8571em
}

.footer:after {
	display: table;
	clear: both;
	content: " "
}

.index-page .page-header {
	height: 125vh
}

.index-page .page-header .container>.content-center {
	top: 37%
}

.index-page .page-header .category-absolute {
	position: absolute;
	top: 100vh;
	margin-top: -60px;
	padding: 0 15px;
	width: 100%;
	color: rgba(255, 255, 255, 0.5)
}

.landing-page .header {
	height: 100vh;
	position: relative
}

.landing-page .header .container {
	padding-top: 26vh;
	color: #FFFFFF;
	z-index: 2;
	position: relative
}

.landing-page .header .share {
	margin-top: 150px
}

.landing-page .header h1 {
	font-weight: 600
}

.landing-page .header .title {
	color: #fff
}

.landing-page .section-team .team .team-player img {
	max-width: 100px
}

.landing-page .section-team .team-player {
	margin-bottom: 15px
}

.landing-page .section-contact-us .title {
	margin-bottom: 15px
}

.landing-page .section-contact-us .description {
	margin-bottom: 30px
}

.landing-page .section-contact-us .input-group,
.landing-page .section-contact-us .send-button,
.landing-page .section-contact-us .textarea-container {
	padding: 0 40px
}

.landing-page .section-contact-us .textarea-container {
	margin: 40px 0
}

.landing-page .section-contact-us a.btn {
	margin-top: 35px
}

.profile-page .page-header {
	min-height: 550px
}

.profile-page .profile-container {
	color: #FFFFFF
}

.profile-page .photo-container {
	width: 123px;
	height: 123px;
	border-radius: 50%;
	overflow: hidden;
	margin: 0 auto;
	box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3)
}

.profile-page .title {
	text-align: center;
	margin-top: 30px
}

.profile-page .description,
.profile-page .category {
	text-align: center
}

.profile-page h5.description {
	max-width: 700px;
	margin: 20px auto 75px
}

.profile-page .nav-align-center {
	margin-top: 30px
}

.profile-page .content {
	max-width: 450px;
	margin: 0 auto
}

.profile-page .content .social-description {
	display: inline-block;
	max-width: 150px;
	width: 145px;
	text-align: center;
	margin: 15px 0 0px
}

.profile-page .content .social-description h2 {
	margin-bottom: 15px
}

.profile-page .button-container {
	text-align: center;
	margin-top: -106px
}

.profile-page .collections img {
	margin-bottom: 30px
}

.profile-page .gallery {
	margin-top: 45px;
	padding-bottom: 50px
}

.section-full-page:after,
.section-full-page:before {
	display: block;
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 2
}

.section-full-page:before {
	background-color: rgba(0, 0, 0, 0.5)
}

.section-full-page[filter-color="purple"]:after,
.section-full-page[filter-color="primary"]:after {
	background: rgba(227, 227, 227, 0.26);
	background: -webkit-linear-gradient(90deg, rgba(227, 227, 227, 0.26), rgba(249, 99, 50, 0.95));
	background: -o-linear-gradient(90deg, rgba(227, 227, 227, 0.26), rgba(249, 99, 50, 0.95));
	background: -moz-linear-gradient(90deg, rgba(227, 227, 227, 0.26), rgba(249, 99, 50, 0.95));
	background: linear-gradient(0deg, rgba(227, 227, 227, 0.26), rgba(249, 99, 50, 0.95))
}

.section-full-page[data-image]:after {
	opacity: .5
}

.section-full-page>.content,
.section-full-page>.footer {
	position: relative;
	z-index: 4
}

.section-full-page>.content {
	min-height: calc(100vh - 80px)
}

.section-full-page .full-page-background {
	position: absolute;
	z-index: 1;
	height: 100%;
	width: 100%;
	display: block;
	top: 0;
	left: 0;
	background-size: cover;
	background-position: center center
}

.section-full-page .footer nav>ul a:not(.btn),
.section-full-page .footer,
.section-full-page .footer .copyright a {
	color: #fff
}

.authentication .card-plain {
	border-radius: .25rem;
	padding-bottom: .7rem;
	max-width: 320px
}

.authentication .card-plain .btn-wd {
	min-width: 180px
}

.authentication .card-plain .logo-container {
	width: 65px;
	margin: 0 auto
}

.authentication .card-plain .logo-container img {
	width: 100%
}

.authentication .card-plain.card-plain {
	margin: 0 auto
}

.authentication .card-plain.card-plain .form-control::-moz-placeholder {
	color: #ebebeb;
	opacity: 1;
	filter: alpha(opacity=100)
}

.authentication .card-plain.card-plain .form-control:-moz-placeholder {
	color: #ebebeb;
	opacity: 1;
	filter: alpha(opacity=100)
}

.authentication .card-plain.card-plain .form-control::-webkit-input-placeholder {
	color: #ebebeb;
	opacity: 1;
	filter: alpha(opacity=100)
}

.authentication .card-plain.card-plain .form-control:-ms-input-placeholder {
	color: #ebebeb;
	opacity: 1;
	filter: alpha(opacity=100)
}

.authentication .card-plain.card-plain .form-control {
	border-color: rgba(255, 255, 255, 0.5);
	color: #fff
}

.authentication .card-plain.card-plain .form-control:focus {
	border-color: #fff;
	background-color: rgba(0, 0, 0, 0);
	color: #fff
}

.authentication .card-plain.card-plain .has-success:after,
.authentication .card-plain.card-plain .has-danger:after {
	color: #fff
}

.authentication .card-plain.card-plain .has-danger .form-control {
	background-color: rgba(0, 0, 0, 0)
}

.authentication .card-plain.card-plain .input-group-addon {
	background-color: rgba(0, 0, 0, 0);
	border-color: rgba(255, 255, 255, 0.5);
	color: #fff
}

.authentication .card-plain.card-plain .input-group-focus .input-group-addon {
	background-color: rgba(0, 0, 0, 0);
	border-color: #fff;
	color: #fff
}

.authentication .card-plain.card-plain .form-group.form-group-no-border .form-control,
.authentication .card-plain.card-plain .input-group.form-group-no-border .form-control {
	background-color: rgba(255, 255, 255, 0.2);
	color: #fff
}

.authentication .card-plain.card-plain .form-group.form-group-no-border .form-control:focus,
.authentication .card-plain.card-plain .form-group.form-group-no-border .form-control:active,
.authentication .card-plain.card-plain .form-group.form-group-no-border .form-control:active,
.authentication .card-plain.card-plain .input-group.form-group-no-border .form-control:focus,
.authentication .card-plain.card-plain .input-group.form-group-no-border .form-control:active,
.authentication .card-plain.card-plain .input-group.form-group-no-border .form-control:active {
	background-color: rgba(255, 255, 255, 0.2);
	color: #fff
}

.authentication .card-plain.card-plain .form-group.form-group-no-border .form-control+.input-group-addon,
.authentication .card-plain.card-plain .input-group.form-group-no-border .form-control+.input-group-addon {
	background-color: rgba(255, 255, 255, 0.2)
}

.authentication .card-plain.card-plain .form-group.form-group-no-border .form-control+.input-group-addon:focus,
.authentication .card-plain.card-plain .form-group.form-group-no-border .form-control+.input-group-addon:active,
.authentication .card-plain.card-plain .form-group.form-group-no-border .form-control+.input-group-addon:active,
.authentication .card-plain.card-plain .input-group.form-group-no-border .form-control+.input-group-addon:focus,
.authentication .card-plain.card-plain .input-group.form-group-no-border .form-control+.input-group-addon:active,
.authentication .card-plain.card-plain .input-group.form-group-no-border .form-control+.input-group-addon:active {
	background-color: rgba(255, 255, 255, 0.2);
	color: #fff
}

.authentication .card-plain.card-plain .form-group.form-group-no-border .form-control:focus+.input-group-addon,
.authentication .card-plain.card-plain .input-group.form-group-no-border .form-control:focus+.input-group-addon {
	background-color: rgba(255, 255, 255, 0.2);
	color: #fff
}

.authentication .card-plain.card-plain .form-group.form-group-no-border .input-group-addon,
.authentication .card-plain.card-plain .input-group.form-group-no-border .input-group-addon {
	background-color: rgba(255, 255, 255, 0.2);
	border: none;
	color: #fff
}

.authentication .card-plain.card-plain .form-group.form-group-no-border.input-group-focus .input-group-addon,
.authentication .card-plain.card-plain .input-group.form-group-no-border.input-group-focus .input-group-addon {
	background-color: rgba(255, 255, 255, 0.2);
	color: #fff
}

.authentication .card-plain.card-plain .input-group-addon,
.authentication .card-plain.card-plain .form-group.form-group-no-border .input-group-addon,
.authentication .card-plain.card-plain .input-group.form-group-no-border .input-group-addon {
	color: rgba(255, 255, 255, 0.5)
}

.authentication .link {
	font-size: 12px;
	color: #fff;
	text-decoration: none
}

.section {
	padding: 70px 0;
	position: relative;
	background: #fff
}

.section .row+.category {
	margin-top: 15px
}

.section-navbars {
	padding-bottom: 0
}

.section-full-screen {
	height: 100vh
}

.section-signup {
	padding-top: 20vh
}

.page-header {
	height: 100vh;
	max-height: 1050px;
	padding: 0;
	color: #fff;
	position: relative;
	background-position: center center;
	background-size: cover
}

.page-header .page-header-image {
	position: absolute;
	background-size: cover;
	background-position: center center;
	width: 100%;
	height: 100%;
	z-index: -1
}

.page-header footer {
	position: absolute;
	bottom: 0;
	width: 100%
}

.page-header .container {
	height: 100%;
	z-index: 1;
	text-align: center;
	position: relative
}

.page-header .container>.content-center {
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	text-align: center;
	padding: 0 15px;
	color: #FFFFFF;
	width: 100%;
	max-width: 880px
}

.page-header .category,
.page-header .description {
	color: rgba(255, 255, 255, 0.5)
}

.page-header.page-header-small {
	height: 60vh;
	max-height: 440px
}

.page-header:after,
.page-header:before {
	position: absolute;
	z-index: 0;
	width: 100%;
	height: 100%;
	display: block;
	left: 0;
	top: 0;
	content: ""
}

.page-header:before {
	background-color: rgba(0, 0, 0, 0.4)
}

.page-header[filter-color="orange"] {
	background: rgba(44, 44, 44, 0.2);
	background: -webkit-linear-gradient(90deg, rgba(44, 44, 44, 0.2), rgba(224, 23, 3, 0.6));
	background: -o-linear-gradient(90deg, rgba(44, 44, 44, 0.2), rgba(224, 23, 3, 0.6));
	background: -moz-linear-gradient(90deg, rgba(44, 44, 44, 0.2), rgba(224, 23, 3, 0.6));
	background: linear-gradient(0deg, rgba(44, 44, 44, 0.2), rgba(224, 23, 3, 0.6))
}

.page-header .container {
	z-index: 2
}

.clear-filter:after,
.clear-filter:before {
	display: none
}

.section-story-overview {
	padding: 50px 0
}

.section-story-overview .image-container {
	height: 335px;
	position: relative;
	background-position: center center;
	background-size: cover;
	box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
	border-radius: .25rem
}

.section-story-overview .image-container+.category {
	padding-top: 15px
}

.section-story-overview .image-container.image-right {
	z-index: 2
}

.section-story-overview .image-container.image-right+h3.title {
	margin-top: 120px
}

.section-story-overview .image-container.image-left {
	z-index: 1
}

.section-story-overview .image-container:nth-child(2) {
	margin-top: 420px;
	margin-left: -105px
}

.section-story-overview p.blockquote {
	width: 220px;
	min-height: 180px;
	text-align: left;
	position: absolute;
	top: 376px;
	right: 155px;
	z-index: 0
}

.section-nucleo-icons .nucleo-container img {
	width: auto;
	left: 0;
	top: 0;
	height: 100%;
	position: absolute
}

.section-nucleo-icons .nucleo-container {
	height: 335px;
	position: relative
}

.section-nucleo-icons h5 {
	margin-bottom: 35px
}

.section-nucleo-icons .icons-container {
	position: relative;
	max-width: 450px;
	height: 300px;
	max-height: 300px;
	margin: 0 auto
}

.section-nucleo-icons .icons-container i {
	font-size: 34px;
	position: absolute;
	left: 0;
	top: 0
}

.section-nucleo-icons .icons-container i:nth-child(1) {
	top: 5%;
	left: 7%
}

.section-nucleo-icons .icons-container i:nth-child(2) {
	top: 28%;
	left: 24%
}

.section-nucleo-icons .icons-container i:nth-child(3) {
	top: 40%
}

.section-nucleo-icons .icons-container i:nth-child(4) {
	top: 18%;
	left: 62%
}

.section-nucleo-icons .icons-container i:nth-child(5) {
	top: 74%;
	left: 3%
}

.section-nucleo-icons .icons-container i:nth-child(6) {
	top: 36%;
	left: 44%;
	font-size: 65px;
	color: #662d91;
	padding: 1px
}

.section-nucleo-icons .icons-container i:nth-child(7) {
	top: 59%;
	left: 26%
}

.section-nucleo-icons .icons-container i:nth-child(8) {
	top: 60%;
	left: 69%
}

.section-nucleo-icons .icons-container i:nth-child(9) {
	top: 72%;
	left: 47%
}

.section-nucleo-icons .icons-container i:nth-child(10) {
	top: 88%;
	left: 27%
}

.section-nucleo-icons .icons-container i:nth-child(11) {
	top: 31%;
	left: 80%
}

.section-nucleo-icons .icons-container i:nth-child(12) {
	top: 88%;
	left: 68%
}

.section-nucleo-icons .icons-container i:nth-child(13) {
	top: 5%;
	left: 81%
}

.section-nucleo-icons .icons-container i:nth-child(14) {
	top: 58%;
	left: 90%
}

.section-nucleo-icons .icons-container i:nth-child(15) {
	top: 6%;
	left: 40%
}

.section-images {
	max-height: 670px;
	height: 670px
}

.section-images .hero-images-container,
.section-images .hero-images-container-1,
.section-images .hero-images-container-2 {
	margin-top: -38vh
}

.section-images .hero-images-container {
	max-width: 670px
}

.section-images .hero-images-container-1 {
	max-width: 390px;
	position: absolute;
	top: 55%;
	right: 18%
}

.section-images .hero-images-container-2 {
	max-width: 225px;
	position: absolute;
	top: 68%;
	right: 12%
}

[data-background-color="orange"] {
	background-color: #e95e38
}

[data-background-color="black"] {
	background-color: #2c2c2c
}

[data-background-color] {
	color: #fff
}

[data-background-color] .title,
[data-background-color] .social-description h2,
[data-background-color] p,
[data-background-color] p.blockquote,
[data-background-color] p.blockquote small,
[data-background-color] h1,
[data-background-color] h2,
[data-background-color] h3,
[data-background-color] h4,
[data-background-color] h5,
[data-background-color] h6,
[data-background-color] a:not(.btn):not(.dropdown-item),
[data-background-color] .icons-container i {
	color: #fff
}

[data-background-color] .separator {
	background-color: #fff
}

[data-background-color] .navbar.bg-white p {
	color: #888
}

[data-background-color] .description,
[data-background-color] .social-description p {
	color: rgba(255, 255, 255, 0.8)
}

[data-background-color] p.blockquote {
	border-color: rgba(255, 255, 255, 0.2)
}

[data-background-color] .checkbox label::before,
[data-background-color] .checkbox label::after,
[data-background-color] .radio label::before,
[data-background-color] .radio label::after {
	border-color: rgba(255, 255, 255, 0.2)
}

[data-background-color] .checkbox label::after,
[data-background-color] .checkbox label,
[data-background-color] .radio label {
	color: #fff
}

[data-background-color] .checkbox input[type="checkbox"]:disabled+label,
[data-background-color] .radio input[type="radio"]:disabled+label {
	color: #fff
}

[data-background-color] .radio input[type="radio"]:not(:disabled):hover+label::after,
[data-background-color] .radio input[type="radio"]:checked+label::after {
	background-color: #fff;
	border-color: #fff
}

[data-background-color] .form-control::-moz-placeholder {
	color: #ebebeb;
	opacity: 1;
	filter: alpha(opacity=100)
}

[data-background-color] .form-control:-moz-placeholder {
	color: #ebebeb;
	opacity: 1;
	filter: alpha(opacity=100)
}

[data-background-color] .form-control::-webkit-input-placeholder {
	color: #ebebeb;
	opacity: 1;
	filter: alpha(opacity=100)
}

[data-background-color] .form-control:-ms-input-placeholder {
	color: #ebebeb;
	opacity: 1;
	filter: alpha(opacity=100)
}

[data-background-color] .form-control {
	border-color: rgba(255, 255, 255, 0.5);
	color: #fff
}

[data-background-color] .form-control:focus {
	border-color: #fff;
	background-color: rgba(0, 0, 0, 0);
	color: #fff
}

[data-background-color] .has-success:after,
[data-background-color] .has-danger:after {
	color: #fff
}

[data-background-color] .has-danger .form-control {
	background-color: rgba(0, 0, 0, 0)
}

[data-background-color] .input-group-addon {
	background-color: rgba(0, 0, 0, 0);
	border-color: rgba(255, 255, 255, 0.5);
	color: #fff
}

[data-background-color] .input-group-focus .input-group-addon {
	background-color: rgba(0, 0, 0, 0);
	border-color: #fff;
	color: #fff
}

[data-background-color] .form-group.form-group-no-border .form-control,
[data-background-color] .input-group.form-group-no-border .form-control {
	background-color: rgba(255, 255, 255, 0.1);
	color: #fff
}

[data-background-color] .form-group.form-group-no-border .form-control:focus,
[data-background-color] .form-group.form-group-no-border .form-control:active,
[data-background-color] .form-group.form-group-no-border .form-control:active,
[data-background-color] .input-group.form-group-no-border .form-control:focus,
[data-background-color] .input-group.form-group-no-border .form-control:active,
[data-background-color] .input-group.form-group-no-border .form-control:active {
	background-color: rgba(255, 255, 255, 0.2);
	color: #fff
}

[data-background-color] .form-group.form-group-no-border .form-control+.input-group-addon,
[data-background-color] .input-group.form-group-no-border .form-control+.input-group-addon {
	background-color: rgba(255, 255, 255, 0.1)
}

[data-background-color] .form-group.form-group-no-border .form-control+.input-group-addon:focus,
[data-background-color] .form-group.form-group-no-border .form-control+.input-group-addon:active,
[data-background-color] .form-group.form-group-no-border .form-control+.input-group-addon:active,
[data-background-color] .input-group.form-group-no-border .form-control+.input-group-addon:focus,
[data-background-color] .input-group.form-group-no-border .form-control+.input-group-addon:active,
[data-background-color] .input-group.form-group-no-border .form-control+.input-group-addon:active {
	background-color: rgba(255, 255, 255, 0.2);
	color: #fff
}

[data-background-color] .form-group.form-group-no-border .form-control:focus+.input-group-addon,
[data-background-color] .input-group.form-group-no-border .form-control:focus+.input-group-addon {
	background-color: rgba(255, 255, 255, 0.2);
	color: #fff
}

[data-background-color] .form-group.form-group-no-border .input-group-addon,
[data-background-color] .input-group.form-group-no-border .input-group-addon {
	background-color: rgba(255, 255, 255, 0.1);
	border: none;
	color: #fff
}

[data-background-color] .form-group.form-group-no-border.input-group-focus .input-group-addon,
[data-background-color] .input-group.form-group-no-border.input-group-focus .input-group-addon {
	background-color: rgba(255, 255, 255, 0.2);
	color: #fff
}

[data-background-color] .input-group-addon,
[data-background-color] .form-group.form-group-no-border .input-group-addon,
[data-background-color] .input-group.form-group-no-border .input-group-addon {
	color: rgba(255, 255, 255, 0.8)
}

[data-background-color] .btn.btn-simple {
	background-color: rgba(0, 0, 0, 0);
	border-color: rgba(255, 255, 255, 0.5);
	color: #fff
}

[data-background-color] .btn.btn-simple:hover,
[data-background-color] .btn.btn-simple:hover,
[data-background-color] .btn.btn-simple:focus,
[data-background-color] .btn.btn-simple:active {
	background-color: rgba(0, 0, 0, 0);
	border-color: #fff
}

[data-background-color] .nav-tabs>.nav-item>.nav-link i.now-ui-icons {
	color: #fff
}

[data-background-color].section-nucleo-icons .icons-container i:nth-child(6) {
	color: #fff
}

@media screen and (max-width: 991px) {
	.sidebar-collapse .navbar-collapse {
		position: fixed;
		display: block;
		top: 0;
		height: 100% !important;
		width: 300px;
		right: 0;
		z-index: 1032;
		visibility: visible;
		background-color: #999;
		overflow-y: visible;
		border-top: none;
		text-align: left;
		max-height: none !important;
		-webkit-transform: translate3d(300px, 0, 0);
		-moz-transform: translate3d(300px, 0, 0);
		-o-transform: translate3d(300px, 0, 0);
		-ms-transform: translate3d(300px, 0, 0);
		transform: translate3d(300px, 0, 0);
		-webkit-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		-moz-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		-o-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		-ms-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1)
	}
	.sidebar-collapse .navbar-collapse:before {
		display: block;
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1
	}
	.sidebar-collapse .navbar-collapse .navbar-nav:not(.navbar-logo) .nav-link {
		margin: 0 1rem;
		margin-top: 0.3125rem
	}
	.sidebar-collapse .navbar-collapse .navbar-nav:not(.navbar-logo) .nav-link:not(.btn) {
		color: #fff
	}
	.sidebar-collapse .navbar-collapse .dropdown-menu .dropdown-item {
		color: #fff
	}
	.sidebar-collapse .navbar .navbar-nav .nav-link {
		padding-top: 0.75rem;
		padding-bottom: .75rem
	}
	.sidebar-collapse .navbar .navbar-nav.navbar-logo {
		top: 0;
		height: 53px
	}
	.sidebar-collapse .navbar .dropdown.show .dropdown-menu,
	.sidebar-collapse .navbar .dropdown .dropdown-menu {
		background-color: transparent;
		border: 0;
		transition: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		width: auto;
		margin: 0 1rem;
		margin-bottom: 15px;
		padding-top: 0;
		height: 150px;
		overflow-y: scroll
	}
	.sidebar-collapse .navbar .dropdown.show .dropdown-menu:before,
	.sidebar-collapse .navbar .dropdown .dropdown-menu:before {
		display: none
	}
	.sidebar-collapse .navbar .dropdown .dropdown-item {
		padding-left: 2.5rem
	}
	.sidebar-collapse .navbar .dropdown .dropdown-menu {
		display: none
	}
	.sidebar-collapse .navbar .dropdown.show .dropdown-menu {
		display: block
	}
	.sidebar-collapse .navbar .dropdown-menu .dropdown-item:focus,
	.sidebar-collapse .navbar .dropdown-menu .dropdown-item:hover {
		color: #fff
	}
	.sidebar-collapse .navbar .navbar-translate {
		width: 100%;
		position: relative;
		display: flex;
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
		-ms-flex-align: center;
		align-items: center;
		-webkit-transform: translate3d(0px, 0, 0);
		-moz-transform: translate3d(0px, 0, 0);
		-o-transform: translate3d(0px, 0, 0);
		-ms-transform: translate3d(0px, 0, 0);
		transform: translate3d(0px, 0, 0);
		-webkit-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		-moz-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		-o-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		-ms-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1)
	}
	.sidebar-collapse .navbar .navbar-toggler-bar {
		display: block;
		position: relative;
		width: 22px;
		height: 1px;
		border-radius: 1px;
		background: #fff
	}
	.sidebar-collapse .navbar .navbar-toggler-bar+.navbar-toggler-bar {
		margin-top: 7px
	}
	.sidebar-collapse .navbar .navbar-toggler-bar.bar2 {
		width: 17px;
		transition: width .2s linear
	}
	.sidebar-collapse .navbar.bg-white:not(.navbar-transparent) .navbar-toggler-bar {
		background: #888
	}
	.sidebar-collapse .navbar .toggled .navbar-toggler-bar {
		width: 24px
	}
	.sidebar-collapse .navbar .toggled .navbar-toggler-bar+.navbar-toggler-bar {
		margin-top: 5px
	}
	.sidebar-collapse .bar1,
	.sidebar-collapse .bar2,
	.sidebar-collapse .bar3 {
		outline: 1px solid transparent
	}
	.sidebar-collapse .bar1 {
		top: 0px;
		-webkit-animation: topbar-back 500ms linear 0s;
		-moz-animation: topbar-back 500ms linear 0s;
		animation: topbar-back 500ms 0s;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		animation-fill-mode: forwards
	}
	.sidebar-collapse .bar2 {
		opacity: 1
	}
	.sidebar-collapse .bar3 {
		bottom: 0px;
		-webkit-animation: bottombar-back 500ms linear 0s;
		-moz-animation: bottombar-back 500ms linear 0s;
		animation: bottombar-back 500ms 0s;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		animation-fill-mode: forwards
	}
	.sidebar-collapse .toggled .bar1 {
		top: 6px;
		-webkit-animation: topbar-x 500ms linear 0s;
		-moz-animation: topbar-x 500ms linear 0s;
		animation: topbar-x 500ms 0s;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		animation-fill-mode: forwards
	}
	.sidebar-collapse .toggled .bar2 {
		opacity: 0
	}
	.sidebar-collapse .toggled .bar3 {
		bottom: 6px;
		-webkit-animation: bottombar-x 500ms linear 0s;
		-moz-animation: bottombar-x 500ms linear 0s;
		animation: bottombar-x 500ms 0s;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		animation-fill-mode: forwards
	}
	@keyframes topbar-x {
		0% {
			top: 0px;
			transform: rotate(0deg)
		}
		45% {
			top: 6px;
			transform: rotate(145deg)
		}
		75% {
			transform: rotate(130deg)
		}
		100% {
			transform: rotate(135deg)
		}
	}
	@-webkit-keyframes topbar-x {
		0% {
			top: 0px;
			-webkit-transform: rotate(0deg)
		}
		45% {
			top: 6px;
			-webkit-transform: rotate(145deg)
		}
		75% {
			-webkit-transform: rotate(130deg)
		}
		100% {
			-webkit-transform: rotate(135deg)
		}
	}
	@-moz-keyframes topbar-x {
		0% {
			top: 0px;
			-moz-transform: rotate(0deg)
		}
		45% {
			top: 6px;
			-moz-transform: rotate(145deg)
		}
		75% {
			-moz-transform: rotate(130deg)
		}
		100% {
			-moz-transform: rotate(135deg)
		}
	}
	@keyframes topbar-back {
		0% {
			top: 6px;
			transform: rotate(135deg)
		}
		45% {
			transform: rotate(-10deg)
		}
		75% {
			transform: rotate(5deg)
		}
		100% {
			top: 0px;
			transform: rotate(0)
		}
	}
	@-webkit-keyframes topbar-back {
		0% {
			top: 6px;
			-webkit-transform: rotate(135deg)
		}
		45% {
			-webkit-transform: rotate(-10deg)
		}
		75% {
			-webkit-transform: rotate(5deg)
		}
		100% {
			top: 0px;
			-webkit-transform: rotate(0)
		}
	}
	@-moz-keyframes topbar-back {
		0% {
			top: 6px;
			-moz-transform: rotate(135deg)
		}
		45% {
			-moz-transform: rotate(-10deg)
		}
		75% {
			-moz-transform: rotate(5deg)
		}
		100% {
			top: 0px;
			-moz-transform: rotate(0)
		}
	}
	@keyframes bottombar-x {
		0% {
			bottom: 0px;
			transform: rotate(0deg)
		}
		45% {
			bottom: 6px;
			transform: rotate(-145deg)
		}
		75% {
			transform: rotate(-130deg)
		}
		100% {
			transform: rotate(-135deg)
		}
	}
	@-webkit-keyframes bottombar-x {
		0% {
			bottom: 0px;
			-webkit-transform: rotate(0deg)
		}
		45% {
			bottom: 6px;
			-webkit-transform: rotate(-145deg)
		}
		75% {
			-webkit-transform: rotate(-130deg)
		}
		100% {
			-webkit-transform: rotate(-135deg)
		}
	}
	@-moz-keyframes bottombar-x {
		0% {
			bottom: 0px;
			-moz-transform: rotate(0deg)
		}
		45% {
			bottom: 6px;
			-moz-transform: rotate(-145deg)
		}
		75% {
			-moz-transform: rotate(-130deg)
		}
		100% {
			-moz-transform: rotate(-135deg)
		}
	}
	@keyframes bottombar-back {
		0% {
			bottom: 6px;
			transform: rotate(-135deg)
		}
		45% {
			transform: rotate(10deg)
		}
		75% {
			transform: rotate(-5deg)
		}
		100% {
			bottom: 0px;
			transform: rotate(0)
		}
	}
	@-webkit-keyframes bottombar-back {
		0% {
			bottom: 6px;
			-webkit-transform: rotate(-135deg)
		}
		45% {
			-webkit-transform: rotate(10deg)
		}
		75% {
			-webkit-transform: rotate(-5deg)
		}
		100% {
			bottom: 0px;
			-webkit-transform: rotate(0)
		}
	}
	@-moz-keyframes bottombar-back {
		0% {
			bottom: 6px;
			-moz-transform: rotate(-135deg)
		}
		45% {
			-moz-transform: rotate(10deg)
		}
		75% {
			-moz-transform: rotate(-5deg)
		}
		100% {
			bottom: 0px;
			-moz-transform: rotate(0)
		}
	}
	@-webkit-keyframes fadeIn {
		0% {
			opacity: 0
		}
		100% {
			opacity: 1
		}
	}
	@-moz-keyframes fadeIn {
		0% {
			opacity: 0
		}
		100% {
			opacity: 1
		}
	}
	@keyframes fadeIn {
		0% {
			opacity: 0
		}
		100% {
			opacity: 1
		}
	}
	.sidebar-collapse [class*="navbar-expand-"] .navbar-collapse {
		width: 300px
	}
	.sidebar-collapse .wrapper {
		-webkit-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		-moz-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		-o-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		-ms-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1)
	}
	.sidebar-collapse #bodyClick {
		height: 100%;
		width: 100%;
		position: fixed;
		opacity: 1;
		top: 0;
		left: auto;
		right: 300px;
		content: "";
		z-index: 9999;
		overflow-x: hidden;
		background-color: transparent;
		-webkit-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		-moz-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		-o-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		-ms-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1)
	}
	.sidebar-collapse.menu-on-left .navbar-collapse {
		right: auto;
		left: 0;
		-webkit-transform: translate3d(-300px, 0, 0);
		-moz-transform: translate3d(-300px, 0, 0);
		-o-transform: translate3d(-300px, 0, 0);
		-ms-transform: translate3d(-300px, 0, 0);
		transform: translate3d(-300px, 0, 0)
	}
	.nav-open .sidebar-collapse .navbar-collapse {
		-webkit-transform: translate3d(0px, 0, 0);
		-moz-transform: translate3d(0px, 0, 0);
		-o-transform: translate3d(0px, 0, 0);
		-ms-transform: translate3d(0px, 0, 0);
		transform: translate3d(0px, 0, 0)
	}
	.nav-open .sidebar-collapse .wrapper {
		-webkit-transform: translate3d(-150px, 0, 0);
		-moz-transform: translate3d(-150px, 0, 0);
		-o-transform: translate3d(-150px, 0, 0);
		-ms-transform: translate3d(-150px, 0, 0);
		transform: translate3d(-150px, 0, 0)
	}
	.nav-open .sidebar-collapse .navbar-translate {
		-webkit-transform: translate3d(-300px, 0, 0);
		-moz-transform: translate3d(-300px, 0, 0);
		-o-transform: translate3d(-300px, 0, 0);
		-ms-transform: translate3d(-300px, 0, 0);
		transform: translate3d(-300px, 0, 0)
	}
	.nav-open .sidebar-collapse.menu-on-left .navbar-collapse {
		-webkit-transform: translate3d(0px, 0, 0);
		-moz-transform: translate3d(0px, 0, 0);
		-o-transform: translate3d(0px, 0, 0);
		-ms-transform: translate3d(0px, 0, 0);
		transform: translate3d(0px, 0, 0)
	}
	.nav-open .sidebar-collapse.menu-on-left .navbar-translate {
		-webkit-transform: translate3d(300px, 0, 0);
		-moz-transform: translate3d(300px, 0, 0);
		-o-transform: translate3d(300px, 0, 0);
		-ms-transform: translate3d(300px, 0, 0);
		transform: translate3d(300px, 0, 0)
	}
	.nav-open .sidebar-collapse.menu-on-left .wrapper {
		-webkit-transform: translate3d(150px, 0, 0);
		-moz-transform: translate3d(150px, 0, 0);
		-o-transform: translate3d(150px, 0, 0);
		-ms-transform: translate3d(150px, 0, 0);
		transform: translate3d(150px, 0, 0)
	}
	.nav-open .sidebar-collapse.menu-on-left #bodyClick {
		right: auto;
		left: 300px
	}
	.bootstrap-collapse .navbar .navbar-collapse {
		background: none !important
	}
	.bootstrap-collapse .navbar .navbar-nav .nav-link {
		padding-top: 0.75rem;
		padding-bottom: .75rem
	}
	.bootstrap-collapse .navbar .navbar-nav.navbar-logo {
		top: 0;
		height: 53px
	}
	.bootstrap-collapse .navbar .dropdown.show .dropdown-menu,
	.bootstrap-collapse .navbar .dropdown .dropdown-menu {
		background-color: transparent;
		border: 0;
		transition: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		width: auto;
		margin: 0 1rem;
		margin-bottom: 15px;
		padding-top: 0;
		height: 150px;
		overflow-y: scroll
	}
	.bootstrap-collapse .navbar .dropdown.show .dropdown-menu:before,
	.bootstrap-collapse .navbar .dropdown .dropdown-menu:before {
		display: none
	}
	.bootstrap-collapse .navbar .dropdown .dropdown-item {
		padding-left: 2.5rem
	}
	.bootstrap-collapse .navbar .dropdown .dropdown-menu {
		display: none
	}
	.bootstrap-collapse .navbar .dropdown.show .dropdown-menu {
		display: block
	}
	.bootstrap-collapse .navbar .dropdown-menu .dropdown-item:focus,
	.bootstrap-collapse .navbar .dropdown-menu .dropdown-item:hover {
		color: #fff
	}
	.bootstrap-collapse .navbar .navbar-translate {
		width: 100%;
		position: relative;
		display: flex;
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
		-ms-flex-align: center;
		align-items: center;
		-webkit-transform: translate3d(0px, 0, 0);
		-moz-transform: translate3d(0px, 0, 0);
		-o-transform: translate3d(0px, 0, 0);
		-ms-transform: translate3d(0px, 0, 0);
		transform: translate3d(0px, 0, 0);
		-webkit-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		-moz-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		-o-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		-ms-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1)
	}
	.bootstrap-collapse .navbar .navbar-toggler-bar {
		display: block;
		position: relative;
		width: 22px;
		height: 1px;
		border-radius: 1px;
		background: #fff
	}
	.bootstrap-collapse .navbar .navbar-toggler-bar+.navbar-toggler-bar {
		margin-top: 7px
	}
	.bootstrap-collapse .navbar .navbar-toggler-bar.bar2 {
		width: 17px;
		transition: width .2s linear
	}
	.bootstrap-collapse .navbar.bg-white:not(.navbar-transparent) .navbar-toggler-bar {
		background: #888
	}
	.bootstrap-collapse .navbar .toggled .navbar-toggler-bar {
		width: 24px
	}
	.bootstrap-collapse .navbar .toggled .navbar-toggler-bar+.navbar-toggler-bar {
		margin-top: 5px
	}
	.bootstrap-collapse .bar1,
	.bootstrap-collapse .bar2,
	.bootstrap-collapse .bar3 {
		outline: 1px solid transparent
	}
	.bootstrap-collapse .bar1 {
		top: 0px;
		-webkit-animation: topbar-back 500ms linear 0s;
		-moz-animation: topbar-back 500ms linear 0s;
		animation: topbar-back 500ms 0s;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		animation-fill-mode: forwards
	}
	.bootstrap-collapse .bar2 {
		opacity: 1
	}
	.bootstrap-collapse .bar3 {
		bottom: 0px;
		-webkit-animation: bottombar-back 500ms linear 0s;
		-moz-animation: bottombar-back 500ms linear 0s;
		animation: bottombar-back 500ms 0s;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		animation-fill-mode: forwards
	}
	.bootstrap-collapse .toggled .bar1 {
		top: 6px;
		-webkit-animation: topbar-x 500ms linear 0s;
		-moz-animation: topbar-x 500ms linear 0s;
		animation: topbar-x 500ms 0s;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		animation-fill-mode: forwards
	}
	.bootstrap-collapse .toggled .bar2 {
		opacity: 0
	}
	.bootstrap-collapse .toggled .bar3 {
		bottom: 6px;
		-webkit-animation: bottombar-x 500ms linear 0s;
		-moz-animation: bottombar-x 500ms linear 0s;
		animation: bottombar-x 500ms 0s;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		animation-fill-mode: forwards
	}
	@keyframes topbar-x {
		0% {
			top: 0px;
			transform: rotate(0deg)
		}
		45% {
			top: 6px;
			transform: rotate(145deg)
		}
		75% {
			transform: rotate(130deg)
		}
		100% {
			transform: rotate(135deg)
		}
	}
	@-webkit-keyframes topbar-x {
		0% {
			top: 0px;
			-webkit-transform: rotate(0deg)
		}
		45% {
			top: 6px;
			-webkit-transform: rotate(145deg)
		}
		75% {
			-webkit-transform: rotate(130deg)
		}
		100% {
			-webkit-transform: rotate(135deg)
		}
	}
	@-moz-keyframes topbar-x {
		0% {
			top: 0px;
			-moz-transform: rotate(0deg)
		}
		45% {
			top: 6px;
			-moz-transform: rotate(145deg)
		}
		75% {
			-moz-transform: rotate(130deg)
		}
		100% {
			-moz-transform: rotate(135deg)
		}
	}
	@keyframes topbar-back {
		0% {
			top: 6px;
			transform: rotate(135deg)
		}
		45% {
			transform: rotate(-10deg)
		}
		75% {
			transform: rotate(5deg)
		}
		100% {
			top: 0px;
			transform: rotate(0)
		}
	}
	@-webkit-keyframes topbar-back {
		0% {
			top: 6px;
			-webkit-transform: rotate(135deg)
		}
		45% {
			-webkit-transform: rotate(-10deg)
		}
		75% {
			-webkit-transform: rotate(5deg)
		}
		100% {
			top: 0px;
			-webkit-transform: rotate(0)
		}
	}
	@-moz-keyframes topbar-back {
		0% {
			top: 6px;
			-moz-transform: rotate(135deg)
		}
		45% {
			-moz-transform: rotate(-10deg)
		}
		75% {
			-moz-transform: rotate(5deg)
		}
		100% {
			top: 0px;
			-moz-transform: rotate(0)
		}
	}
	@keyframes bottombar-x {
		0% {
			bottom: 0px;
			transform: rotate(0deg)
		}
		45% {
			bottom: 6px;
			transform: rotate(-145deg)
		}
		75% {
			transform: rotate(-130deg)
		}
		100% {
			transform: rotate(-135deg)
		}
	}
	@-webkit-keyframes bottombar-x {
		0% {
			bottom: 0px;
			-webkit-transform: rotate(0deg)
		}
		45% {
			bottom: 6px;
			-webkit-transform: rotate(-145deg)
		}
		75% {
			-webkit-transform: rotate(-130deg)
		}
		100% {
			-webkit-transform: rotate(-135deg)
		}
	}
	@-moz-keyframes bottombar-x {
		0% {
			bottom: 0px;
			-moz-transform: rotate(0deg)
		}
		45% {
			bottom: 6px;
			-moz-transform: rotate(-145deg)
		}
		75% {
			-moz-transform: rotate(-130deg)
		}
		100% {
			-moz-transform: rotate(-135deg)
		}
	}
	@keyframes bottombar-back {
		0% {
			bottom: 6px;
			transform: rotate(-135deg)
		}
		45% {
			transform: rotate(10deg)
		}
		75% {
			transform: rotate(-5deg)
		}
		100% {
			bottom: 0px;
			transform: rotate(0)
		}
	}
	@-webkit-keyframes bottombar-back {
		0% {
			bottom: 6px;
			-webkit-transform: rotate(-135deg)
		}
		45% {
			-webkit-transform: rotate(10deg)
		}
		75% {
			-webkit-transform: rotate(-5deg)
		}
		100% {
			bottom: 0px;
			-webkit-transform: rotate(0)
		}
	}
	@-moz-keyframes bottombar-back {
		0% {
			bottom: 6px;
			-moz-transform: rotate(-135deg)
		}
		45% {
			-moz-transform: rotate(10deg)
		}
		75% {
			-moz-transform: rotate(-5deg)
		}
		100% {
			bottom: 0px;
			-moz-transform: rotate(0)
		}
	}
	@-webkit-keyframes fadeIn {
		0% {
			opacity: 0
		}
		100% {
			opacity: 1
		}
	}
	@-moz-keyframes fadeIn {
		0% {
			opacity: 0
		}
		100% {
			opacity: 1
		}
	}
	@keyframes fadeIn {
		0% {
			opacity: 0
		}
		100% {
			opacity: 1
		}
	}
	.profile-photo .profile-photo-small {
		margin-left: -2px
	}
	.button-dropdown {
		display: none
	}
	.section-nucleo-icons .container .row>[class*="col-"]:first-child {
		text-align: center
	}
	.footer .copyright {
		text-align: right
	}
	.section-nucleo-icons .icons-container {
		margin-top: 65px
	}
	.navbar-nav .nav-link i.fa,
	.navbar-nav .nav-link i.now-ui-icons {
		opacity: .5
	}
	.section-images {
		height: 500px;
		max-height: 500px
	}
	.section-images .hero-images-container {
		max-width: 500px
	}
	.section-images .hero-images-container-1 {
		right: 10%;
		top: 68%;
		max-width: 269px
	}
	.section-images .hero-images-container-2 {
		right: 5%;
		max-width: 135px;
		top: 93%
	}
	.navbar .navbar-nav.navbar-logo {
		display: none
	}
}

@media screen and (min-width: 992px) {
	.burger-menu .navbar-collapse {
		position: fixed;
		display: block;
		top: 0;
		height: 100% !important;
		width: 300px;
		right: 0;
		z-index: 1032;
		visibility: visible;
		background-color: #999;
		overflow-y: visible;
		border-top: none;
		text-align: left;
		max-height: none !important;
		-webkit-transform: translate3d(300px, 0, 0);
		-moz-transform: translate3d(300px, 0, 0);
		-o-transform: translate3d(300px, 0, 0);
		-ms-transform: translate3d(300px, 0, 0);
		transform: translate3d(300px, 0, 0);
		-webkit-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		-moz-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		-o-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		-ms-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1)
	}
	.burger-menu .navbar-collapse:before {
		display: block;
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1
	}
	.burger-menu .navbar-collapse .navbar-nav:not(.navbar-logo) .nav-link {
		margin: 0 1rem;
		margin-top: 0.3125rem
	}
	.burger-menu .navbar-collapse .navbar-nav:not(.navbar-logo) .nav-link:not(.btn) {
		color: #fff
	}
	.burger-menu .navbar-collapse .dropdown-menu .dropdown-item {
		color: #fff
	}
	.burger-menu .navbar .navbar-nav .nav-link {
		padding-top: 0.75rem;
		padding-bottom: .75rem
	}
	.burger-menu .navbar .navbar-nav.navbar-logo {
		top: 0;
		height: 53px
	}
	.burger-menu .navbar .dropdown.show .dropdown-menu,
	.burger-menu .navbar .dropdown .dropdown-menu {
		background-color: transparent;
		border: 0;
		transition: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		width: auto;
		margin: 0 1rem;
		margin-bottom: 15px;
		padding-top: 0;
		height: 150px;
		overflow-y: scroll
	}
	.burger-menu .navbar .dropdown.show .dropdown-menu:before,
	.burger-menu .navbar .dropdown .dropdown-menu:before {
		display: none
	}
	.burger-menu .navbar .dropdown .dropdown-item {
		padding-left: 2.5rem
	}
	.burger-menu .navbar .dropdown .dropdown-menu {
		display: none
	}
	.burger-menu .navbar .dropdown.show .dropdown-menu {
		display: block
	}
	.burger-menu .navbar .dropdown-menu .dropdown-item:focus,
	.burger-menu .navbar .dropdown-menu .dropdown-item:hover {
		color: #fff
	}
	.burger-menu .navbar .navbar-translate {
		width: 100%;
		position: relative;
		display: flex;
		-ms-flex-pack: justify !important;
		justify-content: space-between !important;
		-ms-flex-align: center;
		align-items: center;
		-webkit-transform: translate3d(0px, 0, 0);
		-moz-transform: translate3d(0px, 0, 0);
		-o-transform: translate3d(0px, 0, 0);
		-ms-transform: translate3d(0px, 0, 0);
		transform: translate3d(0px, 0, 0);
		-webkit-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		-moz-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		-o-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		-ms-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1)
	}
	.burger-menu .navbar .navbar-toggler-bar {
		display: block;
		position: relative;
		width: 22px;
		height: 1px;
		border-radius: 1px;
		background: #fff
	}
	.burger-menu .navbar .navbar-toggler-bar+.navbar-toggler-bar {
		margin-top: 7px
	}
	.burger-menu .navbar .navbar-toggler-bar.bar2 {
		width: 17px;
		transition: width .2s linear
	}
	.burger-menu .navbar.bg-white:not(.navbar-transparent) .navbar-toggler-bar {
		background: #888
	}
	.burger-menu .navbar .toggled .navbar-toggler-bar {
		width: 24px
	}
	.burger-menu .navbar .toggled .navbar-toggler-bar+.navbar-toggler-bar {
		margin-top: 5px
	}
	.burger-menu .bar1,
	.burger-menu .bar2,
	.burger-menu .bar3 {
		outline: 1px solid transparent
	}
	.burger-menu .bar1 {
		top: 0px;
		-webkit-animation: topbar-back 500ms linear 0s;
		-moz-animation: topbar-back 500ms linear 0s;
		animation: topbar-back 500ms 0s;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		animation-fill-mode: forwards
	}
	.burger-menu .bar2 {
		opacity: 1
	}
	.burger-menu .bar3 {
		bottom: 0px;
		-webkit-animation: bottombar-back 500ms linear 0s;
		-moz-animation: bottombar-back 500ms linear 0s;
		animation: bottombar-back 500ms 0s;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		animation-fill-mode: forwards
	}
	.burger-menu .toggled .bar1 {
		top: 6px;
		-webkit-animation: topbar-x 500ms linear 0s;
		-moz-animation: topbar-x 500ms linear 0s;
		animation: topbar-x 500ms 0s;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		animation-fill-mode: forwards
	}
	.burger-menu .toggled .bar2 {
		opacity: 0
	}
	.burger-menu .toggled .bar3 {
		bottom: 6px;
		-webkit-animation: bottombar-x 500ms linear 0s;
		-moz-animation: bottombar-x 500ms linear 0s;
		animation: bottombar-x 500ms 0s;
		-webkit-animation-fill-mode: forwards;
		-moz-animation-fill-mode: forwards;
		animation-fill-mode: forwards
	}
	@keyframes topbar-x {
		0% {
			top: 0px;
			transform: rotate(0deg)
		}
		45% {
			top: 6px;
			transform: rotate(145deg)
		}
		75% {
			transform: rotate(130deg)
		}
		100% {
			transform: rotate(135deg)
		}
	}
	@-webkit-keyframes topbar-x {
		0% {
			top: 0px;
			-webkit-transform: rotate(0deg)
		}
		45% {
			top: 6px;
			-webkit-transform: rotate(145deg)
		}
		75% {
			-webkit-transform: rotate(130deg)
		}
		100% {
			-webkit-transform: rotate(135deg)
		}
	}
	@-moz-keyframes topbar-x {
		0% {
			top: 0px;
			-moz-transform: rotate(0deg)
		}
		45% {
			top: 6px;
			-moz-transform: rotate(145deg)
		}
		75% {
			-moz-transform: rotate(130deg)
		}
		100% {
			-moz-transform: rotate(135deg)
		}
	}
	@keyframes topbar-back {
		0% {
			top: 6px;
			transform: rotate(135deg)
		}
		45% {
			transform: rotate(-10deg)
		}
		75% {
			transform: rotate(5deg)
		}
		100% {
			top: 0px;
			transform: rotate(0)
		}
	}
	@-webkit-keyframes topbar-back {
		0% {
			top: 6px;
			-webkit-transform: rotate(135deg)
		}
		45% {
			-webkit-transform: rotate(-10deg)
		}
		75% {
			-webkit-transform: rotate(5deg)
		}
		100% {
			top: 0px;
			-webkit-transform: rotate(0)
		}
	}
	@-moz-keyframes topbar-back {
		0% {
			top: 6px;
			-moz-transform: rotate(135deg)
		}
		45% {
			-moz-transform: rotate(-10deg)
		}
		75% {
			-moz-transform: rotate(5deg)
		}
		100% {
			top: 0px;
			-moz-transform: rotate(0)
		}
	}
	@keyframes bottombar-x {
		0% {
			bottom: 0px;
			transform: rotate(0deg)
		}
		45% {
			bottom: 6px;
			transform: rotate(-145deg)
		}
		75% {
			transform: rotate(-130deg)
		}
		100% {
			transform: rotate(-135deg)
		}
	}
	@-webkit-keyframes bottombar-x {
		0% {
			bottom: 0px;
			-webkit-transform: rotate(0deg)
		}
		45% {
			bottom: 6px;
			-webkit-transform: rotate(-145deg)
		}
		75% {
			-webkit-transform: rotate(-130deg)
		}
		100% {
			-webkit-transform: rotate(-135deg)
		}
	}
	@-moz-keyframes bottombar-x {
		0% {
			bottom: 0px;
			-moz-transform: rotate(0deg)
		}
		45% {
			bottom: 6px;
			-moz-transform: rotate(-145deg)
		}
		75% {
			-moz-transform: rotate(-130deg)
		}
		100% {
			-moz-transform: rotate(-135deg)
		}
	}
	@keyframes bottombar-back {
		0% {
			bottom: 6px;
			transform: rotate(-135deg)
		}
		45% {
			transform: rotate(10deg)
		}
		75% {
			transform: rotate(-5deg)
		}
		100% {
			bottom: 0px;
			transform: rotate(0)
		}
	}
	@-webkit-keyframes bottombar-back {
		0% {
			bottom: 6px;
			-webkit-transform: rotate(-135deg)
		}
		45% {
			-webkit-transform: rotate(10deg)
		}
		75% {
			-webkit-transform: rotate(-5deg)
		}
		100% {
			bottom: 0px;
			-webkit-transform: rotate(0)
		}
	}
	@-moz-keyframes bottombar-back {
		0% {
			bottom: 6px;
			-moz-transform: rotate(-135deg)
		}
		45% {
			-moz-transform: rotate(10deg)
		}
		75% {
			-moz-transform: rotate(-5deg)
		}
		100% {
			bottom: 0px;
			-moz-transform: rotate(0)
		}
	}
	@-webkit-keyframes fadeIn {
		0% {
			opacity: 0
		}
		100% {
			opacity: 1
		}
	}
	@-moz-keyframes fadeIn {
		0% {
			opacity: 0
		}
		100% {
			opacity: 1
		}
	}
	@keyframes fadeIn {
		0% {
			opacity: 0
		}
		100% {
			opacity: 1
		}
	}
	.burger-menu [class*="navbar-expand-"] .navbar-collapse {
		width: 300px
	}
	.burger-menu .wrapper {
		-webkit-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		-moz-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		-o-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		-ms-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1)
	}
	.burger-menu #bodyClick {
		height: 100%;
		width: 100%;
		position: fixed;
		opacity: 1;
		top: 0;
		left: auto;
		right: 300px;
		content: "";
		z-index: 9999;
		overflow-x: hidden;
		background-color: transparent;
		-webkit-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		-moz-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		-o-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		-ms-transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1);
		transition: all .5s cubic-bezier(0.685, 0.0473, 0.346, 1)
	}
	.nav-open .burger-menu .navbar-collapse {
		-webkit-transform: translate3d(0px, 0, 0);
		-moz-transform: translate3d(0px, 0, 0);
		-o-transform: translate3d(0px, 0, 0);
		-ms-transform: translate3d(0px, 0, 0);
		transform: translate3d(0px, 0, 0)
	}
	.burger-menu .navbar-collapse {
		display: block !important
	}
	.burger-menu .navbar-collapse .navbar-nav {
		margin-top: 53px;
		height: 100%;
		z-index: 2;
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column
	}
	.burger-menu .navbar-collapse .navbar-nav .nav-item {
		margin: 0
	}
	.burger-menu.menu-on-left .navbar-collapse {
		right: auto;
		left: 0;
		-webkit-transform: translate3d(-300px, 0, 0);
		-moz-transform: translate3d(-300px, 0, 0);
		-o-transform: translate3d(-300px, 0, 0);
		-ms-transform: translate3d(-300px, 0, 0);
		transform: translate3d(-300px, 0, 0)
	}
	.burger-menu [class*="navbar-expand-"] .navbar-nav .dropdown-menu {
		position: static;
		float: none
	}
	.burger-menu [class*="navbar-expand-"] .navbar-toggler {
		display: inline-block
	}
	.burger-menu .section-navbars .navbar-collapse {
		display: none !important
	}
	.nav-open .burger-menu.menu-on-left .navbar .navbar-translate {
		-webkit-transform: translate3d(300px, 0, 0);
		-moz-transform: translate3d(300px, 0, 0);
		-o-transform: translate3d(300px, 0, 0);
		-ms-transform: translate3d(300px, 0, 0);
		transform: translate3d(300px, 0, 0)
	}
	.nav-open .burger-menu .navbar .navbar-translate {
		-webkit-transform: translate3d(-300px, 0, 0);
		-moz-transform: translate3d(-300px, 0, 0);
		-o-transform: translate3d(-300px, 0, 0);
		-ms-transform: translate3d(-300px, 0, 0);
		transform: translate3d(-300px, 0, 0)
	}
	.nav-open .burger-menu.menu-on-left .navbar-collapse {
		-webkit-transform: translate3d(0px, 0, 0);
		-moz-transform: translate3d(0px, 0, 0);
		-o-transform: translate3d(0px, 0, 0);
		-ms-transform: translate3d(0px, 0, 0);
		transform: translate3d(0px, 0, 0)
	}
	.nav-open .burger-menu.menu-on-left #bodyClick {
		right: auto;
		left: 300px
	}
	.burger-menu.menu-on-left .navbar-brand {
		float: right;
		margin-right: 0;
		margin-left: 1rem
	}
	.navbar-nav .nav-link.profile-photo {
		padding: 0;
		margin: 7px .7rem
	}
	.navbar-nav .nav-link.btn:not(.btn-sm) {
		margin: 0
	}
	.navbar-nav .nav-item:not(:last-child) {
		margin-right: 5px
	}
	.section-nucleo-icons .icons-container {
		margin: 0 0 0 auto
	}
	.dropdown-menu .dropdown-item {
		color: inherit
	}
	.footer .copyright {
		float: right
	}
}

@media screen and (min-width: 768px) {
	.image-container.image-right {
		top: 80px;
		margin-left: -100px;
		margin-bottom: 130px
	}
	.image-container.image-left {
		margin-right: -100px
	}
}

@media screen and (max-width: 768px) {
	.image-container.image-left {
		margin-bottom: 220px
	}
	.image-container.image-left p.blockquote {
		margin: 0 auto;
		position: relative;
		right: 0
	}
	.nav-tabs {
		display: inline-block;
		width: 100%;
		padding-left: 100px;
		padding-right: 100px;
		text-align: center
	}
	.nav-tabs .nav-item>.nav-link {
		margin-bottom: 5px
	}
	.landing-page .section-story-overview .image-container:nth-child(2) {
		margin-left: 0;
		margin-bottom: 30px
	}
}

@media screen and (max-width: 576px) {
	.navbar[class*='navbar-expand-'] .container {
		margin-left: 0;
		margin-right: 0
	}
	.footer .copyright {
		text-align: center
	}
	.section-nucleo-icons .icons-container i {
		font-size: 30px
	}
	.section-nucleo-icons .icons-container i:nth-child(6) {
		font-size: 48px
	}
	.page-header .container h6.category-absolute {
		width: 90%
	}
}

@media screen and (min-width: 991px) and (max-width: 1200px) {
	.section-images .hero-images-container-1 {
		right: 9%;
		max-width: 370px
	}
	.section-images .hero-images-container-2 {
		right: 2%;
		max-width: 216px
	}
}

@media screen and (max-width: 768px) {
	.section-images {
		height: 300px;
		max-height: 300px
	}
	.section-images .hero-images-container {
		max-width: 380px
	}
	.section-images .hero-images-container-1 {
		right: 7%;
		top: 87%;
		max-width: 210px
	}
	.section-images .hero-images-container-2 {
		right: 1%;
		max-width: 133px;
		top: 99%
	}
}

@media screen and (max-width: 517px) {
	.alert .alert-icon {
		margin-top: 10px
	}
}

@media screen and (min-width: 1200px) {
	.section-images .hero-images-container-1 {
		top: 51%;
		right: 21%
	}
	.section-images .hero-images-container-2 {
		top: 66%;
		right: 14%
	}
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
	.section-images .hero-images-container,
	.section-images .hero-images-container-1,
	.section-images .hero-images-container-2 {
		margin-top: -15vh;
		margin-left: 80px
	}
	.section-images .hero-images-container {
		max-width: 300px
	}
	.section-images .hero-images-container-1 {
		right: 28%;
		top: 40%
	}
	.section-images .hero-images-container-2 {
		right: 21%;
		top: 55%
	}
	.index-page .category-absolute {
		top: 90vh
	}
}

@media screen and (max-width: 580px) {
	.alert button.close {
		position: absolute;
		right: 11px;
		top: 50%;
		transform: translateY(-50%)
	}
}

@font-face {
	font-family: Material-Design-Iconic-Font;
	src: url(../fonts/Material-Design-Iconic-Font.woff2?v=2.2.0) format("woff2"), url(../fonts/Material-Design-Iconic-Font.woff?v=2.2.0) format("woff"), url(../fonts/Material-Design-Iconic-Font.ttf?v=2.2.0) format("truetype")
}

.zmdi {
	display: inline-block;
	font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
	font-size: 18px;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

.zmdi-hc-lg {
	font-size: 1.33333333em;
	line-height: .75em;
	vertical-align: -15%
}

.zmdi-hc-2x {
	font-size: 2em
}

.zmdi-hc-3x {
	font-size: 3em
}

.zmdi-hc-4x {
	font-size: 4em
}

.zmdi-hc-5x {
	font-size: 5em
}

.zmdi-hc-fw {
	width: 1.28571429em;
	text-align: center
}

.zmdi-hc-ul {
	padding-left: 0;
	margin-left: 2.14285714em;
	list-style-type: none
}

.zmdi-hc-ul>li {
	position: relative
}

.zmdi-hc-li {
	position: absolute;
	left: -2.14285714em;
	width: 2.14285714em;
	top: .14285714em;
	text-align: center
}

.zmdi-hc-li.zmdi-hc-lg {
	left: -1.85714286em
}

.zmdi-hc-border {
	padding: .1em .25em;
	border: solid .1em #9e9e9e;
	border-radius: 2px
}

.zmdi-hc-border-circle {
	padding: .1em .25em;
	border: solid .1em #9e9e9e;
	border-radius: 50%
}

.zmdi.pull-left {
	float: left;
	margin-right: .15em
}

.zmdi.pull-right {
	float: right;
	margin-left: .15em
}

.zmdi-hc-spin {
	-webkit-animation: zmdi-spin 1.5s infinite linear;
	animation: zmdi-spin 1.5s infinite linear
}

.zmdi-hc-spin-reverse {
	-webkit-animation: zmdi-spin-reverse 1.5s infinite linear;
	animation: zmdi-spin-reverse 1.5s infinite linear
}

@-webkit-keyframes zmdi-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg)
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg)
	}
}

@keyframes zmdi-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg)
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg)
	}
}

@-webkit-keyframes zmdi-spin-reverse {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg)
	}
	100% {
		-webkit-transform: rotate(-359deg);
		transform: rotate(-359deg)
	}
}

@keyframes zmdi-spin-reverse {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg)
	}
	100% {
		-webkit-transform: rotate(-359deg);
		transform: rotate(-359deg)
	}
}

.zmdi-hc-rotate-90 {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg)
}

.zmdi-hc-rotate-180 {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg)
}

.zmdi-hc-rotate-270 {
	-webkit-transform: rotate(270deg);
	-ms-transform: rotate(270deg);
	transform: rotate(270deg)
}

.zmdi-hc-flip-horizontal {
	-webkit-transform: scale(-1, 1);
	-ms-transform: scale(-1, 1);
	transform: scale(-1, 1)
}

.zmdi-hc-flip-vertical {
	-webkit-transform: scale(1, -1);
	-ms-transform: scale(1, -1);
	transform: scale(1, -1)
}

.zmdi-hc-stack {
	position: relative;
	display: inline-block;
	width: 2em;
	height: 2em;
	line-height: 2em;
	vertical-align: middle
}

.zmdi-hc-stack-1x,
.zmdi-hc-stack-2x {
	position: absolute;
	left: 0;
	width: 100%;
	text-align: center
}

.zmdi-hc-stack-1x {
	line-height: inherit
}

.zmdi-hc-stack-2x {
	font-size: 2em
}

.zmdi-hc-inverse {
	color: #fff
}

.zmdi-3d-rotation:before {
	content: '\f101'
}

.zmdi-airplane-off:before {
	content: '\f102'
}

.zmdi-airplane:before {
	content: '\f103'
}

.zmdi-album:before {
	content: '\f104'
}

.zmdi-archive:before {
	content: '\f105'
}

.zmdi-assignment-account:before {
	content: '\f106'
}

.zmdi-assignment-alert:before {
	content: '\f107'
}

.zmdi-assignment-check:before {
	content: '\f108'
}

.zmdi-assignment-o:before {
	content: '\f109'
}

.zmdi-assignment-return:before {
	content: '\f10a'
}

.zmdi-assignment-returned:before {
	content: '\f10b'
}

.zmdi-assignment:before {
	content: '\f10c'
}

.zmdi-attachment-alt:before {
	content: '\f10d'
}

.zmdi-attachment:before {
	content: '\f10e'
}

.zmdi-audio:before {
	content: '\f10f'
}

.zmdi-badge-check:before {
	content: '\f110'
}

.zmdi-balance-wallet:before {
	content: '\f111'
}

.zmdi-balance:before {
	content: '\f112'
}

.zmdi-battery-alert:before {
	content: '\f113'
}

.zmdi-battery-flash:before {
	content: '\f114'
}

.zmdi-battery-unknown:before {
	content: '\f115'
}

.zmdi-battery:before {
	content: '\f116'
}

.zmdi-bike:before {
	content: '\f117'
}

.zmdi-block-alt:before {
	content: '\f118'
}

.zmdi-block:before {
	content: '\f119'
}

.zmdi-boat:before {
	content: '\f11a'
}

.zmdi-book-image:before {
	content: '\f11b'
}

.zmdi-book:before {
	content: '\f11c'
}

.zmdi-bookmark-outline:before {
	content: '\f11d'
}

.zmdi-bookmark:before {
	content: '\f11e'
}

.zmdi-brush:before {
	content: '\f11f'
}

.zmdi-bug:before {
	content: '\f120'
}

.zmdi-bus:before {
	content: '\f121'
}

.zmdi-cake:before {
	content: '\f122'
}

.zmdi-car-taxi:before {
	content: '\f123'
}

.zmdi-car-wash:before {
	content: '\f124'
}

.zmdi-car:before {
	content: '\f125'
}

.zmdi-card-giftcard:before {
	content: '\f126'
}

.zmdi-card-membership:before {
	content: '\f127'
}

.zmdi-card-travel:before {
	content: '\f128'
}

.zmdi-card:before {
	content: '\f129'
}

.zmdi-case-check:before {
	content: '\f12a'
}

.zmdi-case-download:before {
	content: '\f12b'
}

.zmdi-case-play:before {
	content: '\f12c'
}

.zmdi-case:before {
	content: '\f12d'
}

.zmdi-cast-connected:before {
	content: '\f12e'
}

.zmdi-cast:before {
	content: '\f12f'
}

.zmdi-chart-donut:before {
	content: '\f130'
}

.zmdi-chart:before {
	content: '\f131'
}

.zmdi-city-alt:before {
	content: '\f132'
}

.zmdi-city:before {
	content: '\f133'
}

.zmdi-close-circle-o:before {
	content: '\f134'
}

.zmdi-close-circle:before {
	content: '\f135'
}

.zmdi-close:before {
	content: '\f136'
}

.zmdi-cocktail:before {
	content: '\f137'
}

.zmdi-code-setting:before {
	content: '\f138'
}

.zmdi-code-smartphone:before {
	content: '\f139'
}

.zmdi-code:before {
	content: '\f13a'
}

.zmdi-coffee:before {
	content: '\f13b'
}

.zmdi-collection-bookmark:before {
	content: '\f13c'
}

.zmdi-collection-case-play:before {
	content: '\f13d'
}

.zmdi-collection-folder-image:before {
	content: '\f13e'
}

.zmdi-collection-image-o:before {
	content: '\f13f'
}

.zmdi-collection-image:before {
	content: '\f140'
}

.zmdi-collection-item-1:before {
	content: '\f141'
}

.zmdi-collection-item-2:before {
	content: '\f142'
}

.zmdi-collection-item-3:before {
	content: '\f143'
}

.zmdi-collection-item-4:before {
	content: '\f144'
}

.zmdi-collection-item-5:before {
	content: '\f145'
}

.zmdi-collection-item-6:before {
	content: '\f146'
}

.zmdi-collection-item-7:before {
	content: '\f147'
}

.zmdi-collection-item-8:before {
	content: '\f148'
}

.zmdi-collection-item-9-plus:before {
	content: '\f149'
}

.zmdi-collection-item-9:before {
	content: '\f14a'
}

.zmdi-collection-item:before {
	content: '\f14b'
}

.zmdi-collection-music:before {
	content: '\f14c'
}

.zmdi-collection-pdf:before {
	content: '\f14d'
}

.zmdi-collection-plus:before {
	content: '\f14e'
}

.zmdi-collection-speaker:before {
	content: '\f14f'
}

.zmdi-collection-text:before {
	content: '\f150'
}

.zmdi-collection-video:before {
	content: '\f151'
}

.zmdi-compass:before {
	content: '\f152'
}

.zmdi-cutlery:before {
	content: '\f153'
}

.zmdi-delete:before {
	content: '\f154'
}

.zmdi-dialpad:before {
	content: '\f155'
}

.zmdi-dns:before {
	content: '\f156'
}

.zmdi-drink:before {
	content: '\f157'
}

.zmdi-edit:before {
	content: '\f158'
}

.zmdi-email-open:before {
	content: '\f159'
}

.zmdi-email:before {
	content: '\f15a'
}

.zmdi-eye-off:before {
	content: '\f15b'
}

.zmdi-eye:before {
	content: '\f15c'
}

.zmdi-eyedropper:before {
	content: '\f15d'
}

.zmdi-favorite-outline:before {
	content: '\f15e'
}

.zmdi-favorite:before {
	content: '\f15f'
}

.zmdi-filter-list:before {
	content: '\f160'
}

.zmdi-fire:before {
	content: '\f161'
}

.zmdi-flag:before {
	content: '\f162'
}

.zmdi-flare:before {
	content: '\f163'
}

.zmdi-flash-auto:before {
	content: '\f164'
}

.zmdi-flash-off:before {
	content: '\f165'
}

.zmdi-flash:before {
	content: '\f166'
}

.zmdi-flip:before {
	content: '\f167'
}

.zmdi-flower-alt:before {
	content: '\f168'
}

.zmdi-flower:before {
	content: '\f169'
}

.zmdi-font:before {
	content: '\f16a'
}

.zmdi-fullscreen-alt:before {
	content: '\f16b'
}

.zmdi-fullscreen-exit:before {
	content: '\f16c'
}

.zmdi-fullscreen:before {
	content: '\f16d'
}

.zmdi-functions:before {
	content: '\f16e'
}

.zmdi-gas-station:before {
	content: '\f16f'
}

.zmdi-gesture:before {
	content: '\f170'
}

.zmdi-globe-alt:before {
	content: '\f171'
}

.zmdi-globe-lock:before {
	content: '\f172'
}

.zmdi-globe:before {
	content: '\f173'
}

.zmdi-graduation-cap:before {
	content: '\f174'
}

.zmdi-home:before {
	content: '\f175'
}

.zmdi-hospital-alt:before {
	content: '\f176'
}

.zmdi-hospital:before {
	content: '\f177'
}

.zmdi-hotel:before {
	content: '\f178'
}

.zmdi-hourglass-alt:before {
	content: '\f179'
}

.zmdi-hourglass-outline:before {
	content: '\f17a'
}

.zmdi-hourglass:before {
	content: '\f17b'
}

.zmdi-http:before {
	content: '\f17c'
}

.zmdi-image-alt:before {
	content: '\f17d'
}

.zmdi-image-o:before {
	content: '\f17e'
}

.zmdi-image:before {
	content: '\f17f'
}

.zmdi-inbox:before {
	content: '\f180'
}

.zmdi-invert-colors-off:before {
	content: '\f181'
}

.zmdi-invert-colors:before {
	content: '\f182'
}

.zmdi-key:before {
	content: '\f183'
}

.zmdi-label-alt-outline:before {
	content: '\f184'
}

.zmdi-label-alt:before {
	content: '\f185'
}

.zmdi-label-heart:before {
	content: '\f186'
}

.zmdi-label:before {
	content: '\f187'
}

.zmdi-labels:before {
	content: '\f188'
}

.zmdi-lamp:before {
	content: '\f189'
}

.zmdi-landscape:before {
	content: '\f18a'
}

.zmdi-layers-off:before {
	content: '\f18b'
}

.zmdi-layers:before {
	content: '\f18c'
}

.zmdi-library:before {
	content: '\f18d'
}

.zmdi-link:before {
	content: '\f18e'
}

.zmdi-lock-open:before {
	content: '\f18f'
}

.zmdi-lock-outline:before {
	content: '\f190'
}

.zmdi-lock:before {
	content: '\f191'
}

.zmdi-mail-reply-all:before {
	content: '\f192'
}

.zmdi-mail-reply:before {
	content: '\f193'
}

.zmdi-mail-send:before {
	content: '\f194'
}

.zmdi-mall:before {
	content: '\f195'
}

.zmdi-map:before {
	content: '\f196'
}

.zmdi-menu:before {
	content: '\f197'
}

.zmdi-money-box:before {
	content: '\f198'
}

.zmdi-money-off:before {
	content: '\f199'
}

.zmdi-money:before {
	content: '\f19a'
}

.zmdi-more-vert:before {
	content: '\f19b'
}

.zmdi-more:before {
	content: '\f19c'
}

.zmdi-movie-alt:before {
	content: '\f19d'
}

.zmdi-movie:before {
	content: '\f19e'
}

.zmdi-nature-people:before {
	content: '\f19f'
}

.zmdi-nature:before {
	content: '\f1a0'
}

.zmdi-navigation:before {
	content: '\f1a1'
}

.zmdi-open-in-browser:before {
	content: '\f1a2'
}

.zmdi-open-in-new:before {
	content: '\f1a3'
}

.zmdi-palette:before {
	content: '\f1a4'
}

.zmdi-parking:before {
	content: '\f1a5'
}

.zmdi-pin-account:before {
	content: '\f1a6'
}

.zmdi-pin-assistant:before {
	content: '\f1a7'
}

.zmdi-pin-drop:before {
	content: '\f1a8'
}

.zmdi-pin-help:before {
	content: '\f1a9'
}

.zmdi-pin-off:before {
	content: '\f1aa'
}

.zmdi-pin:before {
	content: '\f1ab'
}

.zmdi-pizza:before {
	content: '\f1ac'
}

.zmdi-plaster:before {
	content: '\f1ad'
}

.zmdi-power-setting:before {
	content: '\f1ae'
}

.zmdi-power:before {
	content: '\f1af'
}

.zmdi-print:before {
	content: '\f1b0'
}

.zmdi-puzzle-piece:before {
	content: '\f1b1'
}

.zmdi-quote:before {
	content: '\f1b2'
}

.zmdi-railway:before {
	content: '\f1b3'
}

.zmdi-receipt:before {
	content: '\f1b4'
}

.zmdi-refresh-alt:before {
	content: '\f1b5'
}

.zmdi-refresh-sync-alert:before {
	content: '\f1b6'
}

.zmdi-refresh-sync-off:before {
	content: '\f1b7'
}

.zmdi-refresh-sync:before {
	content: '\f1b8'
}

.zmdi-refresh:before {
	content: '\f1b9'
}

.zmdi-roller:before {
	content: '\f1ba'
}

.zmdi-ruler:before {
	content: '\f1bb'
}

.zmdi-scissors:before {
	content: '\f1bc'
}

.zmdi-screen-rotation-lock:before {
	content: '\f1bd'
}

.zmdi-screen-rotation:before {
	content: '\f1be'
}

.zmdi-search-for:before {
	content: '\f1bf'
}

.zmdi-search-in-file:before {
	content: '\f1c0'
}

.zmdi-search-in-page:before {
	content: '\f1c1'
}

.zmdi-search-replace:before {
	content: '\f1c2'
}

.zmdi-search:before {
	content: '\f1c3'
}

.zmdi-seat:before {
	content: '\f1c4'
}

.zmdi-settings-square:before {
	content: '\f1c5'
}

.zmdi-settings:before {
	content: '\f1c6'
}

.zmdi-shield-check:before {
	content: '\f1c7'
}

.zmdi-shield-security:before {
	content: '\f1c8'
}

.zmdi-shopping-basket:before {
	content: '\f1c9'
}

.zmdi-shopping-cart-plus:before {
	content: '\f1ca'
}

.zmdi-shopping-cart:before {
	content: '\f1cb'
}

.zmdi-sign-in:before {
	content: '\f1cc'
}

.zmdi-sort-amount-asc:before {
	content: '\f1cd'
}

.zmdi-sort-amount-desc:before {
	content: '\f1ce'
}

.zmdi-sort-asc:before {
	content: '\f1cf'
}

.zmdi-sort-desc:before {
	content: '\f1d0'
}

.zmdi-spellcheck:before {
	content: '\f1d1'
}

.zmdi-storage:before {
	content: '\f1d2'
}

.zmdi-store-24:before {
	content: '\f1d3'
}

.zmdi-store:before {
	content: '\f1d4'
}

.zmdi-subway:before {
	content: '\f1d5'
}

.zmdi-sun:before {
	content: '\f1d6'
}

.zmdi-tab-unselected:before {
	content: '\f1d7'
}

.zmdi-tab:before {
	content: '\f1d8'
}

.zmdi-tag-close:before {
	content: '\f1d9'
}

.zmdi-tag-more:before {
	content: '\f1da'
}

.zmdi-tag:before {
	content: '\f1db'
}

.zmdi-thumb-down:before {
	content: '\f1dc'
}

.zmdi-thumb-up-down:before {
	content: '\f1dd'
}

.zmdi-thumb-up:before {
	content: '\f1de'
}

.zmdi-ticket-star:before {
	content: '\f1df'
}

.zmdi-toll:before {
	content: '\f1e0'
}

.zmdi-toys:before {
	content: '\f1e1'
}

.zmdi-traffic:before {
	content: '\f1e2'
}

.zmdi-translate:before {
	content: '\f1e3'
}

.zmdi-triangle-down:before {
	content: '\f1e4'
}

.zmdi-triangle-up:before {
	content: '\f1e5'
}

.zmdi-truck:before {
	content: '\f1e6'
}

.zmdi-turning-sign:before {
	content: '\f1e7'
}

.zmdi-wallpaper:before {
	content: '\f1e8'
}

.zmdi-washing-machine:before {
	content: '\f1e9'
}

.zmdi-window-maximize:before {
	content: '\f1ea'
}

.zmdi-window-minimize:before {
	content: '\f1eb'
}

.zmdi-window-restore:before {
	content: '\f1ec'
}

.zmdi-wrench:before {
	content: '\f1ed'
}

.zmdi-zoom-in:before {
	content: '\f1ee'
}

.zmdi-zoom-out:before {
	content: '\f1ef'
}

.zmdi-alert-circle-o:before {
	content: '\f1f0'
}

.zmdi-alert-circle:before {
	content: '\f1f1'
}

.zmdi-alert-octagon:before {
	content: '\f1f2'
}

.zmdi-alert-polygon:before {
	content: '\f1f3'
}

.zmdi-alert-triangle:before {
	content: '\f1f4'
}

.zmdi-help-outline:before {
	content: '\f1f5'
}

.zmdi-help:before {
	content: '\f1f6'
}

.zmdi-info-outline:before {
	content: '\f1f7'
}

.zmdi-info:before {
	content: '\f1f8'
}

.zmdi-notifications-active:before {
	content: '\f1f9'
}

.zmdi-notifications-add:before {
	content: '\f1fa'
}

.zmdi-notifications-none:before {
	content: '\f1fb'
}

.zmdi-notifications-off:before {
	content: '\f1fc'
}

.zmdi-notifications-paused:before {
	content: '\f1fd'
}

.zmdi-notifications:before {
	content: '\f1fe'
}

.zmdi-account-add:before {
	content: '\f1ff'
}

.zmdi-account-box-mail:before {
	content: '\f200'
}

.zmdi-account-box-o:before {
	content: '\f201'
}

.zmdi-account-box-phone:before {
	content: '\f202'
}

.zmdi-account-box:before {
	content: '\f203'
}

.zmdi-account-calendar:before {
	content: '\f204'
}

.zmdi-account-circle:before {
	content: '\f205'
}

.zmdi-account-o:before {
	content: '\f206'
}

.zmdi-account:before {
	content: '\f207'
}

.zmdi-accounts-add:before {
	content: '\f208'
}

.zmdi-accounts-alt:before {
	content: '\f209'
}

.zmdi-accounts-list-alt:before {
	content: '\f20a'
}

.zmdi-accounts-list:before {
	content: '\f20b'
}

.zmdi-accounts-outline:before {
	content: '\f20c'
}

.zmdi-accounts:before {
	content: '\f20d'
}

.zmdi-face:before {
	content: '\f20e'
}

.zmdi-female:before {
	content: '\f20f'
}

.zmdi-male-alt:before {
	content: '\f210'
}

.zmdi-male-female:before {
	content: '\f211'
}

.zmdi-male:before {
	content: '\f212'
}

.zmdi-mood-bad:before {
	content: '\f213'
}

.zmdi-mood:before {
	content: '\f214'
}

.zmdi-run:before {
	content: '\f215'
}

.zmdi-walk:before {
	content: '\f216'
}

.zmdi-cloud-box:before {
	content: '\f217'
}

.zmdi-cloud-circle:before {
	content: '\f218'
}

.zmdi-cloud-done:before {
	content: '\f219'
}

.zmdi-cloud-download:before {
	content: '\f21a'
}

.zmdi-cloud-off:before {
	content: '\f21b'
}

.zmdi-cloud-outline-alt:before {
	content: '\f21c'
}

.zmdi-cloud-outline:before {
	content: '\f21d'
}

.zmdi-cloud-upload:before {
	content: '\f21e'
}

.zmdi-cloud:before {
	content: '\f21f'
}

.zmdi-download:before {
	content: '\f220'
}

.zmdi-file-plus:before {
	content: '\f221'
}

.zmdi-file-text:before {
	content: '\f222'
}

.zmdi-file:before {
	content: '\f223'
}

.zmdi-folder-outline:before {
	content: '\f224'
}

.zmdi-folder-person:before {
	content: '\f225'
}

.zmdi-folder-star-alt:before {
	content: '\f226'
}

.zmdi-folder-star:before {
	content: '\f227'
}

.zmdi-folder:before {
	content: '\f228'
}

.zmdi-gif:before {
	content: '\f229'
}

.zmdi-upload:before {
	content: '\f22a'
}

.zmdi-border-all:before {
	content: '\f22b'
}

.zmdi-border-bottom:before {
	content: '\f22c'
}

.zmdi-border-clear:before {
	content: '\f22d'
}

.zmdi-border-color:before {
	content: '\f22e'
}

.zmdi-border-horizontal:before {
	content: '\f22f'
}

.zmdi-border-inner:before {
	content: '\f230'
}

.zmdi-border-left:before {
	content: '\f231'
}

.zmdi-border-outer:before {
	content: '\f232'
}

.zmdi-border-right:before {
	content: '\f233'
}

.zmdi-border-style:before {
	content: '\f234'
}

.zmdi-border-top:before {
	content: '\f235'
}

.zmdi-border-vertical:before {
	content: '\f236'
}

.zmdi-copy:before {
	content: '\f237'
}

.zmdi-crop:before {
	content: '\f238'
}

.zmdi-format-align-center:before {
	content: '\f239'
}

.zmdi-format-align-justify:before {
	content: '\f23a'
}

.zmdi-format-align-left:before {
	content: '\f23b'
}

.zmdi-format-align-right:before {
	content: '\f23c'
}

.zmdi-format-bold:before {
	content: '\f23d'
}

.zmdi-format-clear-all:before {
	content: '\f23e'
}

.zmdi-format-clear:before {
	content: '\f23f'
}

.zmdi-format-color-fill:before {
	content: '\f240'
}

.zmdi-format-color-reset:before {
	content: '\f241'
}

.zmdi-format-color-text:before {
	content: '\f242'
}

.zmdi-format-indent-decrease:before {
	content: '\f243'
}

.zmdi-format-indent-increase:before {
	content: '\f244'
}

.zmdi-format-italic:before {
	content: '\f245'
}

.zmdi-format-line-spacing:before {
	content: '\f246'
}

.zmdi-format-list-bulleted:before {
	content: '\f247'
}

.zmdi-format-list-numbered:before {
	content: '\f248'
}

.zmdi-format-ltr:before {
	content: '\f249'
}

.zmdi-format-rtl:before {
	content: '\f24a'
}

.zmdi-format-size:before {
	content: '\f24b'
}

.zmdi-format-strikethrough-s:before {
	content: '\f24c'
}

.zmdi-format-strikethrough:before {
	content: '\f24d'
}

.zmdi-format-subject:before {
	content: '\f24e'
}

.zmdi-format-underlined:before {
	content: '\f24f'
}

.zmdi-format-valign-bottom:before {
	content: '\f250'
}

.zmdi-format-valign-center:before {
	content: '\f251'
}

.zmdi-format-valign-top:before {
	content: '\f252'
}

.zmdi-redo:before {
	content: '\f253'
}

.zmdi-select-all:before {
	content: '\f254'
}

.zmdi-space-bar:before {
	content: '\f255'
}

.zmdi-text-format:before {
	content: '\f256'
}

.zmdi-transform:before {
	content: '\f257'
}

.zmdi-undo:before {
	content: '\f258'
}

.zmdi-wrap-text:before {
	content: '\f259'
}

.zmdi-comment-alert:before {
	content: '\f25a'
}

.zmdi-comment-alt-text:before {
	content: '\f25b'
}

.zmdi-comment-alt:before {
	content: '\f25c'
}

.zmdi-comment-edit:before {
	content: '\f25d'
}

.zmdi-comment-image:before {
	content: '\f25e'
}

.zmdi-comment-list:before {
	content: '\f25f'
}

.zmdi-comment-more:before {
	content: '\f260'
}

.zmdi-comment-outline:before {
	content: '\f261'
}

.zmdi-comment-text-alt:before {
	content: '\f262'
}

.zmdi-comment-text:before {
	content: '\f263'
}

.zmdi-comment-video:before {
	content: '\f264'
}

.zmdi-comment:before {
	content: '\f265'
}

.zmdi-comments:before {
	content: '\f266'
}

.zmdi-check-all:before {
	content: '\f267'
}

.zmdi-check-circle-u:before {
	content: '\f268'
}

.zmdi-check-circle:before {
	content: '\f269'
}

.zmdi-check-square:before {
	content: '\f26a'
}

.zmdi-check:before {
	content: '\f26b'
}

.zmdi-circle-o:before {
	content: '\f26c'
}

.zmdi-circle:before {
	content: '\f26d'
}

.zmdi-dot-circle-alt:before {
	content: '\f26e'
}

.zmdi-dot-circle:before {
	content: '\f26f'
}

.zmdi-minus-circle-outline:before {
	content: '\f270'
}

.zmdi-minus-circle:before {
	content: '\f271'
}

.zmdi-minus-square:before {
	content: '\f272'
}

.zmdi-minus:before {
	content: '\f273'
}

.zmdi-plus-circle-o-duplicate:before {
	content: '\f274'
}

.zmdi-plus-circle-o:before {
	content: '\f275'
}

.zmdi-plus-circle:before {
	content: '\f276'
}

.zmdi-plus-square:before {
	content: '\f277'
}

.zmdi-plus:before {
	content: '\f278'
}

.zmdi-square-o:before {
	content: '\f279'
}

.zmdi-star-circle:before {
	content: '\f27a'
}

.zmdi-star-half:before {
	content: '\f27b'
}

.zmdi-star-outline:before {
	content: '\f27c'
}

.zmdi-star:before {
	content: '\f27d'
}

.zmdi-bluetooth-connected:before {
	content: '\f27e'
}

.zmdi-bluetooth-off:before {
	content: '\f27f'
}

.zmdi-bluetooth-search:before {
	content: '\f280'
}

.zmdi-bluetooth-setting:before {
	content: '\f281'
}

.zmdi-bluetooth:before {
	content: '\f282'
}

.zmdi-camera-add:before {
	content: '\f283'
}

.zmdi-camera-alt:before {
	content: '\f284'
}

.zmdi-camera-bw:before {
	content: '\f285'
}

.zmdi-camera-front:before {
	content: '\f286'
}

.zmdi-camera-mic:before {
	content: '\f287'
}

.zmdi-camera-party-mode:before {
	content: '\f288'
}

.zmdi-camera-rear:before {
	content: '\f289'
}

.zmdi-camera-roll:before {
	content: '\f28a'
}

.zmdi-camera-switch:before {
	content: '\f28b'
}

.zmdi-camera:before {
	content: '\f28c'
}

.zmdi-card-alert:before {
	content: '\f28d'
}

.zmdi-card-off:before {
	content: '\f28e'
}

.zmdi-card-sd:before {
	content: '\f28f'
}

.zmdi-card-sim:before {
	content: '\f290'
}

.zmdi-desktop-mac:before {
	content: '\f291'
}

.zmdi-desktop-windows:before {
	content: '\f292'
}

.zmdi-device-hub:before {
	content: '\f293'
}

.zmdi-devices-off:before {
	content: '\f294'
}

.zmdi-devices:before {
	content: '\f295'
}

.zmdi-dock:before {
	content: '\f296'
}

.zmdi-floppy:before {
	content: '\f297'
}

.zmdi-gamepad:before {
	content: '\f298'
}

.zmdi-gps-dot:before {
	content: '\f299'
}

.zmdi-gps-off:before {
	content: '\f29a'
}

.zmdi-gps:before {
	content: '\f29b'
}

.zmdi-headset-mic:before {
	content: '\f29c'
}

.zmdi-headset:before {
	content: '\f29d'
}

.zmdi-input-antenna:before {
	content: '\f29e'
}

.zmdi-input-composite:before {
	content: '\f29f'
}

.zmdi-input-hdmi:before {
	content: '\f2a0'
}

.zmdi-input-power:before {
	content: '\f2a1'
}

.zmdi-input-svideo:before {
	content: '\f2a2'
}

.zmdi-keyboard-hide:before {
	content: '\f2a3'
}

.zmdi-keyboard:before {
	content: '\f2a4'
}

.zmdi-laptop-chromebook:before {
	content: '\f2a5'
}

.zmdi-laptop-mac:before {
	content: '\f2a6'
}

.zmdi-laptop:before {
	content: '\f2a7'
}

.zmdi-mic-off:before {
	content: '\f2a8'
}

.zmdi-mic-outline:before {
	content: '\f2a9'
}

.zmdi-mic-setting:before {
	content: '\f2aa'
}

.zmdi-mic:before {
	content: '\f2ab'
}

.zmdi-mouse:before {
	content: '\f2ac'
}

.zmdi-network-alert:before {
	content: '\f2ad'
}

.zmdi-network-locked:before {
	content: '\f2ae'
}

.zmdi-network-off:before {
	content: '\f2af'
}

.zmdi-network-outline:before {
	content: '\f2b0'
}

.zmdi-network-setting:before {
	content: '\f2b1'
}

.zmdi-network:before {
	content: '\f2b2'
}

.zmdi-phone-bluetooth:before {
	content: '\f2b3'
}

.zmdi-phone-end:before {
	content: '\f2b4'
}

.zmdi-phone-forwarded:before {
	content: '\f2b5'
}

.zmdi-phone-in-talk:before {
	content: '\f2b6'
}

.zmdi-phone-locked:before {
	content: '\f2b7'
}

.zmdi-phone-missed:before {
	content: '\f2b8'
}

.zmdi-phone-msg:before {
	content: '\f2b9'
}

.zmdi-phone-paused:before {
	content: '\f2ba'
}

.zmdi-phone-ring:before {
	content: '\f2bb'
}

.zmdi-phone-setting:before {
	content: '\f2bc'
}

.zmdi-phone-sip:before {
	content: '\f2bd'
}

.zmdi-phone:before {
	content: '\f2be'
}

.zmdi-portable-wifi-changes:before {
	content: '\f2bf'
}

.zmdi-portable-wifi-off:before {
	content: '\f2c0'
}

.zmdi-portable-wifi:before {
	content: '\f2c1'
}

.zmdi-radio:before {
	content: '\f2c2'
}

.zmdi-reader:before {
	content: '\f2c3'
}

.zmdi-remote-control-alt:before {
	content: '\f2c4'
}

.zmdi-remote-control:before {
	content: '\f2c5'
}

.zmdi-router:before {
	content: '\f2c6'
}

.zmdi-scanner:before {
	content: '\f2c7'
}

.zmdi-smartphone-android:before {
	content: '\f2c8'
}

.zmdi-smartphone-download:before {
	content: '\f2c9'
}

.zmdi-smartphone-erase:before {
	content: '\f2ca'
}

.zmdi-smartphone-info:before {
	content: '\f2cb'
}

.zmdi-smartphone-iphone:before {
	content: '\f2cc'
}

.zmdi-smartphone-landscape-lock:before {
	content: '\f2cd'
}

.zmdi-smartphone-landscape:before {
	content: '\f2ce'
}

.zmdi-smartphone-lock:before {
	content: '\f2cf'
}

.zmdi-smartphone-portrait-lock:before {
	content: '\f2d0'
}

.zmdi-smartphone-ring:before {
	content: '\f2d1'
}

.zmdi-smartphone-setting:before {
	content: '\f2d2'
}

.zmdi-smartphone-setup:before {
	content: '\f2d3'
}

.zmdi-smartphone:before {
	content: '\f2d4'
}

.zmdi-speaker:before {
	content: '\f2d5'
}

.zmdi-tablet-android:before {
	content: '\f2d6'
}

.zmdi-tablet-mac:before {
	content: '\f2d7'
}

.zmdi-tablet:before {
	content: '\f2d8'
}

.zmdi-tv-alt-play:before {
	content: '\f2d9'
}

.zmdi-tv-list:before {
	content: '\f2da'
}

.zmdi-tv-play:before {
	content: '\f2db'
}

.zmdi-tv:before {
	content: '\f2dc'
}

.zmdi-usb:before {
	content: '\f2dd'
}

.zmdi-videocam-off:before {
	content: '\f2de'
}

.zmdi-videocam-switch:before {
	content: '\f2df'
}

.zmdi-videocam:before {
	content: '\f2e0'
}

.zmdi-watch:before {
	content: '\f2e1'
}

.zmdi-wifi-alt-2:before {
	content: '\f2e2'
}

.zmdi-wifi-alt:before {
	content: '\f2e3'
}

.zmdi-wifi-info:before {
	content: '\f2e4'
}

.zmdi-wifi-lock:before {
	content: '\f2e5'
}

.zmdi-wifi-off:before {
	content: '\f2e6'
}

.zmdi-wifi-outline:before {
	content: '\f2e7'
}

.zmdi-wifi:before {
	content: '\f2e8'
}

.zmdi-arrow-left-bottom:before {
	content: '\f2e9'
}

.zmdi-arrow-left:before {
	content: '\f2ea'
}

.zmdi-arrow-merge:before {
	content: '\f2eb'
}

.zmdi-arrow-missed:before {
	content: '\f2ec'
}

.zmdi-arrow-right-top:before {
	content: '\f2ed'
}

.zmdi-arrow-right:before {
	content: '\f2ee'
}

.zmdi-arrow-split:before {
	content: '\f2ef'
}

.zmdi-arrows:before {
	content: '\f2f0'
}

.zmdi-caret-down-circle:before {
	content: '\f2f1'
}

.zmdi-caret-down:before {
	content: '\f2f2'
}

.zmdi-caret-left-circle:before {
	content: '\f2f3'
}

.zmdi-caret-left:before {
	content: '\f2f4'
}

.zmdi-caret-right-circle:before {
	content: '\f2f5'
}

.zmdi-caret-right:before {
	content: '\f2f6'
}

.zmdi-caret-up-circle:before {
	content: '\f2f7'
}

.zmdi-caret-up:before {
	content: '\f2f8'
}

.zmdi-chevron-down:before {
	content: '\f2f9'
}

.zmdi-chevron-left:before {
	content: '\f2fa'
}

.zmdi-chevron-right:before {
	content: '\f2fb'
}

.zmdi-chevron-up:before {
	content: '\f2fc'
}

.zmdi-forward:before {
	content: '\f2fd'
}

.zmdi-long-arrow-down:before {
	content: '\f2fe'
}

.zmdi-long-arrow-left:before {
	content: '\f2ff'
}

.zmdi-long-arrow-return:before {
	content: '\f300'
}

.zmdi-long-arrow-right:before {
	content: '\f301'
}

.zmdi-long-arrow-tab:before {
	content: '\f302'
}

.zmdi-long-arrow-up:before {
	content: '\f303'
}

.zmdi-rotate-ccw:before {
	content: '\f304'
}

.zmdi-rotate-cw:before {
	content: '\f305'
}

.zmdi-rotate-left:before {
	content: '\f306'
}

.zmdi-rotate-right:before {
	content: '\f307'
}

.zmdi-square-down:before {
	content: '\f308'
}

.zmdi-square-right:before {
	content: '\f309'
}

.zmdi-swap-alt:before {
	content: '\f30a'
}

.zmdi-swap-vertical-circle:before {
	content: '\f30b'
}

.zmdi-swap-vertical:before {
	content: '\f30c'
}

.zmdi-swap:before {
	content: '\f30d'
}

.zmdi-trending-down:before {
	content: '\f30e'
}

.zmdi-trending-flat:before {
	content: '\f30f'
}

.zmdi-trending-up:before {
	content: '\f310'
}

.zmdi-unfold-less:before {
	content: '\f311'
}

.zmdi-unfold-more:before {
	content: '\f312'
}

.zmdi-apps:before {
	content: '\f313'
}

.zmdi-grid-off:before {
	content: '\f314'
}

.zmdi-grid:before {
	content: '\f315'
}

.zmdi-view-agenda:before {
	content: '\f316'
}

.zmdi-view-array:before {
	content: '\f317'
}

.zmdi-view-carousel:before {
	content: '\f318'
}

.zmdi-view-column:before {
	content: '\f319'
}

.zmdi-view-comfy:before {
	content: '\f31a'
}

.zmdi-view-compact:before {
	content: '\f31b'
}

.zmdi-view-dashboard:before {
	content: '\f31c'
}

.zmdi-view-day:before {
	content: '\f31d'
}

.zmdi-view-headline:before {
	content: '\f31e'
}

.zmdi-view-list-alt:before {
	content: '\f31f'
}

.zmdi-view-list:before {
	content: '\f320'
}

.zmdi-view-module:before {
	content: '\f321'
}

.zmdi-view-quilt:before {
	content: '\f322'
}

.zmdi-view-stream:before {
	content: '\f323'
}

.zmdi-view-subtitles:before {
	content: '\f324'
}

.zmdi-view-toc:before {
	content: '\f325'
}

.zmdi-view-web:before {
	content: '\f326'
}

.zmdi-view-week:before {
	content: '\f327'
}

.zmdi-widgets:before {
	content: '\f328'
}

.zmdi-alarm-check:before {
	content: '\f329'
}

.zmdi-alarm-off:before {
	content: '\f32a'
}

.zmdi-alarm-plus:before {
	content: '\f32b'
}

.zmdi-alarm-snooze:before {
	content: '\f32c'
}

.zmdi-alarm:before {
	content: '\f32d'
}

.zmdi-calendar-alt:before {
	content: '\f32e'
}

.zmdi-calendar-check:before {
	content: '\f32f'
}

.zmdi-calendar-close:before {
	content: '\f330'
}

.zmdi-calendar-note:before {
	content: '\f331'
}

.zmdi-calendar:before {
	content: '\f332'
}

.zmdi-time-countdown:before {
	content: '\f333'
}

.zmdi-time-interval:before {
	content: '\f334'
}

.zmdi-time-restore-setting:before {
	content: '\f335'
}

.zmdi-time-restore:before {
	content: '\f336'
}

.zmdi-time:before {
	content: '\f337'
}

.zmdi-timer-off:before {
	content: '\f338'
}

.zmdi-timer:before {
	content: '\f339'
}

.zmdi-android-alt:before {
	content: '\f33a'
}

.zmdi-android:before {
	content: '\f33b'
}

.zmdi-apple:before {
	content: '\f33c'
}

.zmdi-behance:before {
	content: '\f33d'
}

.zmdi-codepen:before {
	content: '\f33e'
}

.zmdi-dribbble:before {
	content: '\f33f'
}

.zmdi-dropbox:before {
	content: '\f340'
}

.zmdi-evernote:before {
	content: '\f341'
}

.zmdi-facebook-box:before {
	content: '\f342'
}

.zmdi-facebook:before {
	content: '\f343'
}

.zmdi-github-box:before {
	content: '\f344'
}

.zmdi-github:before {
	content: '\f345'
}

.zmdi-google-drive:before {
	content: '\f346'
}

.zmdi-google-earth:before {
	content: '\f347'
}

.zmdi-google-glass:before {
	content: '\f348'
}

.zmdi-google-maps:before {
	content: '\f349'
}

.zmdi-google-pages:before {
	content: '\f34a'
}

.zmdi-google-play:before {
	content: '\f34b'
}

.zmdi-google-plus-box:before {
	content: '\f34c'
}

.zmdi-google-plus:before {
	content: '\f34d'
}

.zmdi-google:before {
	content: '\f34e'
}

.zmdi-instagram:before {
	content: '\f34f'
}

.zmdi-language-css3:before {
	content: '\f350'
}

.zmdi-language-html5:before {
	content: '\f351'
}

.zmdi-language-javascript:before {
	content: '\f352'
}

.zmdi-language-python-alt:before {
	content: '\f353'
}

.zmdi-language-python:before {
	content: '\f354'
}

.zmdi-lastfm:before {
	content: '\f355'
}

.zmdi-linkedin-box:before {
	content: '\f356'
}

.zmdi-paypal:before {
	content: '\f357'
}

.zmdi-pinterest-box:before {
	content: '\f358'
}

.zmdi-pocket:before {
	content: '\f359'
}

.zmdi-polymer:before {
	content: '\f35a'
}

.zmdi-share:before {
	content: '\f35b'
}

.zmdi-stackoverflow:before {
	content: '\f35c'
}

.zmdi-steam-square:before {
	content: '\f35d'
}

.zmdi-steam:before {
	content: '\f35e'
}

.zmdi-twitter-box:before {
	content: '\f35f'
}

.zmdi-twitter:before {
	content: '\f360'
}

.zmdi-vk:before {
	content: '\f361'
}

.zmdi-wikipedia:before {
	content: '\f362'
}

.zmdi-windows:before {
	content: '\f363'
}

.zmdi-aspect-ratio-alt:before {
	content: '\f364'
}

.zmdi-aspect-ratio:before {
	content: '\f365'
}

.zmdi-blur-circular:before {
	content: '\f366'
}

.zmdi-blur-linear:before {
	content: '\f367'
}

.zmdi-blur-off:before {
	content: '\f368'
}

.zmdi-blur:before {
	content: '\f369'
}

.zmdi-brightness-2:before {
	content: '\f36a'
}

.zmdi-brightness-3:before {
	content: '\f36b'
}

.zmdi-brightness-4:before {
	content: '\f36c'
}

.zmdi-brightness-5:before {
	content: '\f36d'
}

.zmdi-brightness-6:before {
	content: '\f36e'
}

.zmdi-brightness-7:before {
	content: '\f36f'
}

.zmdi-brightness-auto:before {
	content: '\f370'
}

.zmdi-brightness-setting:before {
	content: '\f371'
}

.zmdi-broken-image:before {
	content: '\f372'
}

.zmdi-center-focus-strong:before {
	content: '\f373'
}

.zmdi-center-focus-weak:before {
	content: '\f374'
}

.zmdi-compare:before {
	content: '\f375'
}

.zmdi-crop-16-9:before {
	content: '\f376'
}

.zmdi-crop-3-2:before {
	content: '\f377'
}

.zmdi-crop-5-4:before {
	content: '\f378'
}

.zmdi-crop-7-5:before {
	content: '\f379'
}

.zmdi-crop-din:before {
	content: '\f37a'
}

.zmdi-crop-free:before {
	content: '\f37b'
}

.zmdi-crop-landscape:before {
	content: '\f37c'
}

.zmdi-crop-portrait:before {
	content: '\f37d'
}

.zmdi-crop-square:before {
	content: '\f37e'
}

.zmdi-exposure-alt:before {
	content: '\f37f'
}

.zmdi-exposure:before {
	content: '\f380'
}

.zmdi-filter-b-and-w:before {
	content: '\f381'
}

.zmdi-filter-center-focus:before {
	content: '\f382'
}

.zmdi-filter-frames:before {
	content: '\f383'
}

.zmdi-filter-tilt-shift:before {
	content: '\f384'
}

.zmdi-gradient:before {
	content: '\f385'
}

.zmdi-grain:before {
	content: '\f386'
}

.zmdi-graphic-eq:before {
	content: '\f387'
}

.zmdi-hdr-off:before {
	content: '\f388'
}

.zmdi-hdr-strong:before {
	content: '\f389'
}

.zmdi-hdr-weak:before {
	content: '\f38a'
}

.zmdi-hdr:before {
	content: '\f38b'
}

.zmdi-iridescent:before {
	content: '\f38c'
}

.zmdi-leak-off:before {
	content: '\f38d'
}

.zmdi-leak:before {
	content: '\f38e'
}

.zmdi-looks:before {
	content: '\f38f'
}

.zmdi-loupe:before {
	content: '\f390'
}

.zmdi-panorama-horizontal:before {
	content: '\f391'
}

.zmdi-panorama-vertical:before {
	content: '\f392'
}

.zmdi-panorama-wide-angle:before {
	content: '\f393'
}

.zmdi-photo-size-select-large:before {
	content: '\f394'
}

.zmdi-photo-size-select-small:before {
	content: '\f395'
}

.zmdi-picture-in-picture:before {
	content: '\f396'
}

.zmdi-slideshow:before {
	content: '\f397'
}

.zmdi-texture:before {
	content: '\f398'
}

.zmdi-tonality:before {
	content: '\f399'
}

.zmdi-vignette:before {
	content: '\f39a'
}

.zmdi-wb-auto:before {
	content: '\f39b'
}

.zmdi-eject-alt:before {
	content: '\f39c'
}

.zmdi-eject:before {
	content: '\f39d'
}

.zmdi-equalizer:before {
	content: '\f39e'
}

.zmdi-fast-forward:before {
	content: '\f39f'
}

.zmdi-fast-rewind:before {
	content: '\f3a0'
}

.zmdi-forward-10:before {
	content: '\f3a1'
}

.zmdi-forward-30:before {
	content: '\f3a2'
}

.zmdi-forward-5:before {
	content: '\f3a3'
}

.zmdi-hearing:before {
	content: '\f3a4'
}

.zmdi-pause-circle-outline:before {
	content: '\f3a5'
}

.zmdi-pause-circle:before {
	content: '\f3a6'
}

.zmdi-pause:before {
	content: '\f3a7'
}

.zmdi-play-circle-outline:before {
	content: '\f3a8'
}

.zmdi-play-circle:before {
	content: '\f3a9'
}

.zmdi-play:before {
	content: '\f3aa'
}

.zmdi-playlist-audio:before {
	content: '\f3ab'
}

.zmdi-playlist-plus:before {
	content: '\f3ac'
}

.zmdi-repeat-one:before {
	content: '\f3ad'
}

.zmdi-repeat:before {
	content: '\f3ae'
}

.zmdi-replay-10:before {
	content: '\f3af'
}

.zmdi-replay-30:before {
	content: '\f3b0'
}

.zmdi-replay-5:before {
	content: '\f3b1'
}

.zmdi-replay:before {
	content: '\f3b2'
}

.zmdi-shuffle:before {
	content: '\f3b3'
}

.zmdi-skip-next:before {
	content: '\f3b4'
}

.zmdi-skip-previous:before {
	content: '\f3b5'
}

.zmdi-stop:before {
	content: '\f3b6'
}

.zmdi-surround-sound:before {
	content: '\f3b7'
}

.zmdi-tune:before {
	content: '\f3b8'
}

.zmdi-volume-down:before {
	content: '\f3b9'
}

.zmdi-volume-mute:before {
	content: '\f3ba'
}

.zmdi-volume-off:before {
	content: '\f3bb'
}

.zmdi-volume-up:before {
	content: '\f3bc'
}

.zmdi-n-1-square:before {
	content: '\f3bd'
}

.zmdi-n-2-square:before {
	content: '\f3be'
}

.zmdi-n-3-square:before {
	content: '\f3bf'
}

.zmdi-n-4-square:before {
	content: '\f3c0'
}

.zmdi-n-5-square:before {
	content: '\f3c1'
}

.zmdi-n-6-square:before {
	content: '\f3c2'
}

.zmdi-neg-1:before {
	content: '\f3c3'
}

.zmdi-neg-2:before {
	content: '\f3c4'
}

.zmdi-plus-1:before {
	content: '\f3c5'
}

.zmdi-plus-2:before {
	content: '\f3c6'
}

.zmdi-sec-10:before {
	content: '\f3c7'
}

.zmdi-sec-3:before {
	content: '\f3c8'
}

.zmdi-zero:before {
	content: '\f3c9'
}

.zmdi-airline-seat-flat-angled:before {
	content: '\f3ca'
}

.zmdi-airline-seat-flat:before {
	content: '\f3cb'
}

.zmdi-airline-seat-individual-suite:before {
	content: '\f3cc'
}

.zmdi-airline-seat-legroom-extra:before {
	content: '\f3cd'
}

.zmdi-airline-seat-legroom-normal:before {
	content: '\f3ce'
}

.zmdi-airline-seat-legroom-reduced:before {
	content: '\f3cf'
}

.zmdi-airline-seat-recline-extra:before {
	content: '\f3d0'
}

.zmdi-airline-seat-recline-normal:before {
	content: '\f3d1'
}

.zmdi-airplay:before {
	content: '\f3d2'
}

.zmdi-closed-caption:before {
	content: '\f3d3'
}

.zmdi-confirmation-number:before {
	content: '\f3d4'
}

.zmdi-developer-board:before {
	content: '\f3d5'
}

.zmdi-disc-full:before {
	content: '\f3d6'
}

.zmdi-explicit:before {
	content: '\f3d7'
}

.zmdi-flight-land:before {
	content: '\f3d8'
}

.zmdi-flight-takeoff:before {
	content: '\f3d9'
}

.zmdi-flip-to-back:before {
	content: '\f3da'
}

.zmdi-flip-to-front:before {
	content: '\f3db'
}

.zmdi-group-work:before {
	content: '\f3dc'
}

.zmdi-hd:before {
	content: '\f3dd'
}

.zmdi-hq:before {
	content: '\f3de'
}

.zmdi-markunread-mailbox:before {
	content: '\f3df'
}

.zmdi-memory:before {
	content: '\f3e0'
}

.zmdi-nfc:before {
	content: '\f3e1'
}

.zmdi-play-for-work:before {
	content: '\f3e2'
}

.zmdi-power-input:before {
	content: '\f3e3'
}

.zmdi-present-to-all:before {
	content: '\f3e4'
}

.zmdi-satellite:before {
	content: '\f3e5'
}

.zmdi-tap-and-play:before {
	content: '\f3e6'
}

.zmdi-vibration:before {
	content: '\f3e7'
}

.zmdi-voicemail:before {
	content: '\f3e8'
}

.zmdi-group:before {
	content: '\f3e9'
}

.zmdi-rss:before {
	content: '\f3ea'
}

.zmdi-shape:before {
	content: '\f3eb'
}

.zmdi-spinner:before {
	content: '\f3ec'
}

.zmdi-ungroup:before {
	content: '\f3ed'
}

.zmdi-500px:before {
	content: '\f3ee'
}

.zmdi-8tracks:before {
	content: '\f3ef'
}

.zmdi-amazon:before {
	content: '\f3f0'
}

.zmdi-blogger:before {
	content: '\f3f1'
}

.zmdi-delicious:before {
	content: '\f3f2'
}

.zmdi-disqus:before {
	content: '\f3f3'
}

.zmdi-flattr:before {
	content: '\f3f4'
}

.zmdi-flickr:before {
	content: '\f3f5'
}

.zmdi-github-alt:before {
	content: '\f3f6'
}

.zmdi-google-old:before {
	content: '\f3f7'
}

.zmdi-linkedin:before {
	content: '\f3f8'
}

.zmdi-odnoklassniki:before {
	content: '\f3f9'
}

.zmdi-outlook:before {
	content: '\f3fa'
}

.zmdi-paypal-alt:before {
	content: '\f3fb'
}

.zmdi-pinterest:before {
	content: '\f3fc'
}

.zmdi-playstation:before {
	content: '\f3fd'
}

.zmdi-reddit:before {
	content: '\f3fe'
}

.zmdi-skype:before {
	content: '\f3ff'
}

.zmdi-slideshare:before {
	content: '\f400'
}

.zmdi-soundcloud:before {
	content: '\f401'
}

.zmdi-tumblr:before {
	content: '\f402'
}

.zmdi-twitch:before {
	content: '\f403'
}

.zmdi-vimeo:before {
	content: '\f404'
}

.zmdi-whatsapp:before {
	content: '\f405'
}

.zmdi-xbox:before {
	content: '\f406'
}

.zmdi-yahoo:before {
	content: '\f407'
}

.zmdi-youtube-play:before {
	content: '\f408'
}

.zmdi-youtube:before {
	content: '\f409'
}

.zmdi-3d-rotation:before {
	content: '\f101'
}

.zmdi-airplane-off:before {
	content: '\f102'
}

.zmdi-airplane:before {
	content: '\f103'
}

.zmdi-album:before {
	content: '\f104'
}

.zmdi-archive:before {
	content: '\f105'
}

.zmdi-assignment-account:before {
	content: '\f106'
}

.zmdi-assignment-alert:before {
	content: '\f107'
}

.zmdi-assignment-check:before {
	content: '\f108'
}

.zmdi-assignment-o:before {
	content: '\f109'
}

.zmdi-assignment-return:before {
	content: '\f10a'
}

.zmdi-assignment-returned:before {
	content: '\f10b'
}

.zmdi-assignment:before {
	content: '\f10c'
}

.zmdi-attachment-alt:before {
	content: '\f10d'
}

.zmdi-attachment:before {
	content: '\f10e'
}

.zmdi-audio:before {
	content: '\f10f'
}

.zmdi-badge-check:before {
	content: '\f110'
}

.zmdi-balance-wallet:before {
	content: '\f111'
}

.zmdi-balance:before {
	content: '\f112'
}

.zmdi-battery-alert:before {
	content: '\f113'
}

.zmdi-battery-flash:before {
	content: '\f114'
}

.zmdi-battery-unknown:before {
	content: '\f115'
}

.zmdi-battery:before {
	content: '\f116'
}

.zmdi-bike:before {
	content: '\f117'
}

.zmdi-block-alt:before {
	content: '\f118'
}

.zmdi-block:before {
	content: '\f119'
}

.zmdi-boat:before {
	content: '\f11a'
}

.zmdi-book-image:before {
	content: '\f11b'
}

.zmdi-book:before {
	content: '\f11c'
}

.zmdi-bookmark-outline:before {
	content: '\f11d'
}

.zmdi-bookmark:before {
	content: '\f11e'
}

.zmdi-brush:before {
	content: '\f11f'
}

.zmdi-bug:before {
	content: '\f120'
}

.zmdi-bus:before {
	content: '\f121'
}

.zmdi-cake:before {
	content: '\f122'
}

.zmdi-car-taxi:before {
	content: '\f123'
}

.zmdi-car-wash:before {
	content: '\f124'
}

.zmdi-car:before {
	content: '\f125'
}

.zmdi-card-giftcard:before {
	content: '\f126'
}

.zmdi-card-membership:before {
	content: '\f127'
}

.zmdi-card-travel:before {
	content: '\f128'
}

.zmdi-card:before {
	content: '\f129'
}

.zmdi-case-check:before {
	content: '\f12a'
}

.zmdi-case-download:before {
	content: '\f12b'
}

.zmdi-case-play:before {
	content: '\f12c'
}

.zmdi-case:before {
	content: '\f12d'
}

.zmdi-cast-connected:before {
	content: '\f12e'
}

.zmdi-cast:before {
	content: '\f12f'
}

.zmdi-chart-donut:before {
	content: '\f130'
}

.zmdi-chart:before {
	content: '\f131'
}

.zmdi-city-alt:before {
	content: '\f132'
}

.zmdi-city:before {
	content: '\f133'
}

.zmdi-close-circle-o:before {
	content: '\f134'
}

.zmdi-close-circle:before {
	content: '\f135'
}

.zmdi-close:before {
	content: '\f136'
}

.zmdi-cocktail:before {
	content: '\f137'
}

.zmdi-code-setting:before {
	content: '\f138'
}

.zmdi-code-smartphone:before {
	content: '\f139'
}

.zmdi-code:before {
	content: '\f13a'
}

.zmdi-coffee:before {
	content: '\f13b'
}

.zmdi-collection-bookmark:before {
	content: '\f13c'
}

.zmdi-collection-case-play:before {
	content: '\f13d'
}

.zmdi-collection-folder-image:before {
	content: '\f13e'
}

.zmdi-collection-image-o:before {
	content: '\f13f'
}

.zmdi-collection-image:before {
	content: '\f140'
}

.zmdi-collection-item-1:before {
	content: '\f141'
}

.zmdi-collection-item-2:before {
	content: '\f142'
}

.zmdi-collection-item-3:before {
	content: '\f143'
}

.zmdi-collection-item-4:before {
	content: '\f144'
}

.zmdi-collection-item-5:before {
	content: '\f145'
}

.zmdi-collection-item-6:before {
	content: '\f146'
}

.zmdi-collection-item-7:before {
	content: '\f147'
}

.zmdi-collection-item-8:before {
	content: '\f148'
}

.zmdi-collection-item-9-plus:before {
	content: '\f149'
}

.zmdi-collection-item-9:before {
	content: '\f14a'
}

.zmdi-collection-item:before {
	content: '\f14b'
}

.zmdi-collection-music:before {
	content: '\f14c'
}

.zmdi-collection-pdf:before {
	content: '\f14d'
}

.zmdi-collection-plus:before {
	content: '\f14e'
}

.zmdi-collection-speaker:before {
	content: '\f14f'
}

.zmdi-collection-text:before {
	content: '\f150'
}

.zmdi-collection-video:before {
	content: '\f151'
}

.zmdi-compass:before {
	content: '\f152'
}

.zmdi-cutlery:before {
	content: '\f153'
}

.zmdi-delete:before {
	content: '\f154'
}

.zmdi-dialpad:before {
	content: '\f155'
}

.zmdi-dns:before {
	content: '\f156'
}

.zmdi-drink:before {
	content: '\f157'
}

.zmdi-edit:before {
	content: '\f158'
}

.zmdi-email-open:before {
	content: '\f159'
}

.zmdi-email:before {
	content: '\f15a'
}

.zmdi-eye-off:before {
	content: '\f15b'
}

.zmdi-eye:before {
	content: '\f15c'
}

.zmdi-eyedropper:before {
	content: '\f15d'
}

.zmdi-favorite-outline:before {
	content: '\f15e'
}

.zmdi-favorite:before {
	content: '\f15f'
}

.zmdi-filter-list:before {
	content: '\f160'
}

.zmdi-fire:before {
	content: '\f161'
}

.zmdi-flag:before {
	content: '\f162'
}

.zmdi-flare:before {
	content: '\f163'
}

.zmdi-flash-auto:before {
	content: '\f164'
}

.zmdi-flash-off:before {
	content: '\f165'
}

.zmdi-flash:before {
	content: '\f166'
}

.zmdi-flip:before {
	content: '\f167'
}

.zmdi-flower-alt:before {
	content: '\f168'
}

.zmdi-flower:before {
	content: '\f169'
}

.zmdi-font:before {
	content: '\f16a'
}

.zmdi-fullscreen-alt:before {
	content: '\f16b'
}

.zmdi-fullscreen-exit:before {
	content: '\f16c'
}

.zmdi-fullscreen:before {
	content: '\f16d'
}

.zmdi-functions:before {
	content: '\f16e'
}

.zmdi-gas-station:before {
	content: '\f16f'
}

.zmdi-gesture:before {
	content: '\f170'
}

.zmdi-globe-alt:before {
	content: '\f171'
}

.zmdi-globe-lock:before {
	content: '\f172'
}

.zmdi-globe:before {
	content: '\f173'
}

.zmdi-graduation-cap:before {
	content: '\f174'
}

.zmdi-home:before {
	content: '\f175'
}

.zmdi-hospital-alt:before {
	content: '\f176'
}

.zmdi-hospital:before {
	content: '\f177'
}

.zmdi-hotel:before {
	content: '\f178'
}

.zmdi-hourglass-alt:before {
	content: '\f179'
}

.zmdi-hourglass-outline:before {
	content: '\f17a'
}

.zmdi-hourglass:before {
	content: '\f17b'
}

.zmdi-http:before {
	content: '\f17c'
}

.zmdi-image-alt:before {
	content: '\f17d'
}

.zmdi-image-o:before {
	content: '\f17e'
}

.zmdi-image:before {
	content: '\f17f'
}

.zmdi-inbox:before {
	content: '\f180'
}

.zmdi-invert-colors-off:before {
	content: '\f181'
}

.zmdi-invert-colors:before {
	content: '\f182'
}

.zmdi-key:before {
	content: '\f183'
}

.zmdi-label-alt-outline:before {
	content: '\f184'
}

.zmdi-label-alt:before {
	content: '\f185'
}

.zmdi-label-heart:before {
	content: '\f186'
}

.zmdi-label:before {
	content: '\f187'
}

.zmdi-labels:before {
	content: '\f188'
}

.zmdi-lamp:before {
	content: '\f189'
}

.zmdi-landscape:before {
	content: '\f18a'
}

.zmdi-layers-off:before {
	content: '\f18b'
}

.zmdi-layers:before {
	content: '\f18c'
}

.zmdi-library:before {
	content: '\f18d'
}

.zmdi-link:before {
	content: '\f18e'
}

.zmdi-lock-open:before {
	content: '\f18f'
}

.zmdi-lock-outline:before {
	content: '\f190'
}

.zmdi-lock:before {
	content: '\f191'
}

.zmdi-mail-reply-all:before {
	content: '\f192'
}

.zmdi-mail-reply:before {
	content: '\f193'
}

.zmdi-mail-send:before {
	content: '\f194'
}

.zmdi-mall:before {
	content: '\f195'
}

.zmdi-map:before {
	content: '\f196'
}

.zmdi-menu:before {
	content: '\f197'
}

.zmdi-money-box:before {
	content: '\f198'
}

.zmdi-money-off:before {
	content: '\f199'
}

.zmdi-money:before {
	content: '\f19a'
}

.zmdi-more-vert:before {
	content: '\f19b'
}

.zmdi-more:before {
	content: '\f19c'
}

.zmdi-movie-alt:before {
	content: '\f19d'
}

.zmdi-movie:before {
	content: '\f19e'
}

.zmdi-nature-people:before {
	content: '\f19f'
}

.zmdi-nature:before {
	content: '\f1a0'
}

.zmdi-navigation:before {
	content: '\f1a1'
}

.zmdi-open-in-browser:before {
	content: '\f1a2'
}

.zmdi-open-in-new:before {
	content: '\f1a3'
}

.zmdi-palette:before {
	content: '\f1a4'
}

.zmdi-parking:before {
	content: '\f1a5'
}

.zmdi-pin-account:before {
	content: '\f1a6'
}

.zmdi-pin-assistant:before {
	content: '\f1a7'
}

.zmdi-pin-drop:before {
	content: '\f1a8'
}

.zmdi-pin-help:before {
	content: '\f1a9'
}

.zmdi-pin-off:before {
	content: '\f1aa'
}

.zmdi-pin:before {
	content: '\f1ab'
}

.zmdi-pizza:before {
	content: '\f1ac'
}

.zmdi-plaster:before {
	content: '\f1ad'
}

.zmdi-power-setting:before {
	content: '\f1ae'
}

.zmdi-power:before {
	content: '\f1af'
}

.zmdi-print:before {
	content: '\f1b0'
}

.zmdi-puzzle-piece:before {
	content: '\f1b1'
}

.zmdi-quote:before {
	content: '\f1b2'
}

.zmdi-railway:before {
	content: '\f1b3'
}

.zmdi-receipt:before {
	content: '\f1b4'
}

.zmdi-refresh-alt:before {
	content: '\f1b5'
}

.zmdi-refresh-sync-alert:before {
	content: '\f1b6'
}

.zmdi-refresh-sync-off:before {
	content: '\f1b7'
}

.zmdi-refresh-sync:before {
	content: '\f1b8'
}

.zmdi-refresh:before {
	content: '\f1b9'
}

.zmdi-roller:before {
	content: '\f1ba'
}

.zmdi-ruler:before {
	content: '\f1bb'
}

.zmdi-scissors:before {
	content: '\f1bc'
}

.zmdi-screen-rotation-lock:before {
	content: '\f1bd'
}

.zmdi-screen-rotation:before {
	content: '\f1be'
}

.zmdi-search-for:before {
	content: '\f1bf'
}

.zmdi-search-in-file:before {
	content: '\f1c0'
}

.zmdi-search-in-page:before {
	content: '\f1c1'
}

.zmdi-search-replace:before {
	content: '\f1c2'
}

.zmdi-search:before {
	content: '\f1c3'
}

.zmdi-seat:before {
	content: '\f1c4'
}

.zmdi-settings-square:before {
	content: '\f1c5'
}

.zmdi-settings:before {
	content: '\f1c6'
}

.zmdi-shield-check:before {
	content: '\f1c7'
}

.zmdi-shield-security:before {
	content: '\f1c8'
}

.zmdi-shopping-basket:before {
	content: '\f1c9'
}

.zmdi-shopping-cart-plus:before {
	content: '\f1ca'
}

.zmdi-shopping-cart:before {
	content: '\f1cb'
}

.zmdi-sign-in:before {
	content: '\f1cc'
}

.zmdi-sort-amount-asc:before {
	content: '\f1cd'
}

.zmdi-sort-amount-desc:before {
	content: '\f1ce'
}

.zmdi-sort-asc:before {
	content: '\f1cf'
}

.zmdi-sort-desc:before {
	content: '\f1d0'
}

.zmdi-spellcheck:before {
	content: '\f1d1'
}

.zmdi-storage:before {
	content: '\f1d2'
}

.zmdi-store-24:before {
	content: '\f1d3'
}

.zmdi-store:before {
	content: '\f1d4'
}

.zmdi-subway:before {
	content: '\f1d5'
}

.zmdi-sun:before {
	content: '\f1d6'
}

.zmdi-tab-unselected:before {
	content: '\f1d7'
}

.zmdi-tab:before {
	content: '\f1d8'
}

.zmdi-tag-close:before {
	content: '\f1d9'
}

.zmdi-tag-more:before {
	content: '\f1da'
}

.zmdi-tag:before {
	content: '\f1db'
}

.zmdi-thumb-down:before {
	content: '\f1dc'
}

.zmdi-thumb-up-down:before {
	content: '\f1dd'
}

.zmdi-thumb-up:before {
	content: '\f1de'
}

.zmdi-ticket-star:before {
	content: '\f1df'
}

.zmdi-toll:before {
	content: '\f1e0'
}

.zmdi-toys:before {
	content: '\f1e1'
}

.zmdi-traffic:before {
	content: '\f1e2'
}

.zmdi-translate:before {
	content: '\f1e3'
}

.zmdi-triangle-down:before {
	content: '\f1e4'
}

.zmdi-triangle-up:before {
	content: '\f1e5'
}

.zmdi-truck:before {
	content: '\f1e6'
}

.zmdi-turning-sign:before {
	content: '\f1e7'
}

.zmdi-wallpaper:before {
	content: '\f1e8'
}

.zmdi-washing-machine:before {
	content: '\f1e9'
}

.zmdi-window-maximize:before {
	content: '\f1ea'
}

.zmdi-window-minimize:before {
	content: '\f1eb'
}

.zmdi-window-restore:before {
	content: '\f1ec'
}

.zmdi-wrench:before {
	content: '\f1ed'
}

.zmdi-zoom-in:before {
	content: '\f1ee'
}

.zmdi-zoom-out:before {
	content: '\f1ef'
}

.zmdi-alert-circle-o:before {
	content: '\f1f0'
}

.zmdi-alert-circle:before {
	content: '\f1f1'
}

.zmdi-alert-octagon:before {
	content: '\f1f2'
}

.zmdi-alert-polygon:before {
	content: '\f1f3'
}

.zmdi-alert-triangle:before {
	content: '\f1f4'
}

.zmdi-help-outline:before {
	content: '\f1f5'
}

.zmdi-help:before {
	content: '\f1f6'
}

.zmdi-info-outline:before {
	content: '\f1f7'
}

.zmdi-info:before {
	content: '\f1f8'
}

.zmdi-notifications-active:before {
	content: '\f1f9'
}

.zmdi-notifications-add:before {
	content: '\f1fa'
}

.zmdi-notifications-none:before {
	content: '\f1fb'
}

.zmdi-notifications-off:before {
	content: '\f1fc'
}

.zmdi-notifications-paused:before {
	content: '\f1fd'
}

.zmdi-notifications:before {
	content: '\f1fe'
}

.zmdi-account-add:before {
	content: '\f1ff'
}

.zmdi-account-box-mail:before {
	content: '\f200'
}

.zmdi-account-box-o:before {
	content: '\f201'
}

.zmdi-account-box-phone:before {
	content: '\f202'
}

.zmdi-account-box:before {
	content: '\f203'
}

.zmdi-account-calendar:before {
	content: '\f204'
}

.zmdi-account-circle:before {
	content: '\f205'
}

.zmdi-account-o:before {
	content: '\f206'
}

.zmdi-account:before {
	content: '\f207'
}

.zmdi-accounts-add:before {
	content: '\f208'
}

.zmdi-accounts-alt:before {
	content: '\f209'
}

.zmdi-accounts-list-alt:before {
	content: '\f20a'
}

.zmdi-accounts-list:before {
	content: '\f20b'
}

.zmdi-accounts-outline:before {
	content: '\f20c'
}

.zmdi-accounts:before {
	content: '\f20d'
}

.zmdi-face:before {
	content: '\f20e'
}

.zmdi-female:before {
	content: '\f20f'
}

.zmdi-male-alt:before {
	content: '\f210'
}

.zmdi-male-female:before {
	content: '\f211'
}

.zmdi-male:before {
	content: '\f212'
}

.zmdi-mood-bad:before {
	content: '\f213'
}

.zmdi-mood:before {
	content: '\f214'
}

.zmdi-run:before {
	content: '\f215'
}

.zmdi-walk:before {
	content: '\f216'
}

.zmdi-cloud-box:before {
	content: '\f217'
}

.zmdi-cloud-circle:before {
	content: '\f218'
}

.zmdi-cloud-done:before {
	content: '\f219'
}

.zmdi-cloud-download:before {
	content: '\f21a'
}

.zmdi-cloud-off:before {
	content: '\f21b'
}

.zmdi-cloud-outline-alt:before {
	content: '\f21c'
}

.zmdi-cloud-outline:before {
	content: '\f21d'
}

.zmdi-cloud-upload:before {
	content: '\f21e'
}

.zmdi-cloud:before {
	content: '\f21f'
}

.zmdi-download:before {
	content: '\f220'
}

.zmdi-file-plus:before {
	content: '\f221'
}

.zmdi-file-text:before {
	content: '\f222'
}

.zmdi-file:before {
	content: '\f223'
}

.zmdi-folder-outline:before {
	content: '\f224'
}

.zmdi-folder-person:before {
	content: '\f225'
}

.zmdi-folder-star-alt:before {
	content: '\f226'
}

.zmdi-folder-star:before {
	content: '\f227'
}

.zmdi-folder:before {
	content: '\f228'
}

.zmdi-gif:before {
	content: '\f229'
}

.zmdi-upload:before {
	content: '\f22a'
}

.zmdi-border-all:before {
	content: '\f22b'
}

.zmdi-border-bottom:before {
	content: '\f22c'
}

.zmdi-border-clear:before {
	content: '\f22d'
}

.zmdi-border-color:before {
	content: '\f22e'
}

.zmdi-border-horizontal:before {
	content: '\f22f'
}

.zmdi-border-inner:before {
	content: '\f230'
}

.zmdi-border-left:before {
	content: '\f231'
}

.zmdi-border-outer:before {
	content: '\f232'
}

.zmdi-border-right:before {
	content: '\f233'
}

.zmdi-border-style:before {
	content: '\f234'
}

.zmdi-border-top:before {
	content: '\f235'
}

.zmdi-border-vertical:before {
	content: '\f236'
}

.zmdi-copy:before {
	content: '\f237'
}

.zmdi-crop:before {
	content: '\f238'
}

.zmdi-format-align-center:before {
	content: '\f239'
}

.zmdi-format-align-justify:before {
	content: '\f23a'
}

.zmdi-format-align-left:before {
	content: '\f23b'
}

.zmdi-format-align-right:before {
	content: '\f23c'
}

.zmdi-format-bold:before {
	content: '\f23d'
}

.zmdi-format-clear-all:before {
	content: '\f23e'
}

.zmdi-format-clear:before {
	content: '\f23f'
}

.zmdi-format-color-fill:before {
	content: '\f240'
}

.zmdi-format-color-reset:before {
	content: '\f241'
}

.zmdi-format-color-text:before {
	content: '\f242'
}

.zmdi-format-indent-decrease:before {
	content: '\f243'
}

.zmdi-format-indent-increase:before {
	content: '\f244'
}

.zmdi-format-italic:before {
	content: '\f245'
}

.zmdi-format-line-spacing:before {
	content: '\f246'
}

.zmdi-format-list-bulleted:before {
	content: '\f247'
}

.zmdi-format-list-numbered:before {
	content: '\f248'
}

.zmdi-format-ltr:before {
	content: '\f249'
}

.zmdi-format-rtl:before {
	content: '\f24a'
}

.zmdi-format-size:before {
	content: '\f24b'
}

.zmdi-format-strikethrough-s:before {
	content: '\f24c'
}

.zmdi-format-strikethrough:before {
	content: '\f24d'
}

.zmdi-format-subject:before {
	content: '\f24e'
}

.zmdi-format-underlined:before {
	content: '\f24f'
}

.zmdi-format-valign-bottom:before {
	content: '\f250'
}

.zmdi-format-valign-center:before {
	content: '\f251'
}

.zmdi-format-valign-top:before {
	content: '\f252'
}

.zmdi-redo:before {
	content: '\f253'
}

.zmdi-select-all:before {
	content: '\f254'
}

.zmdi-space-bar:before {
	content: '\f255'
}

.zmdi-text-format:before {
	content: '\f256'
}

.zmdi-transform:before {
	content: '\f257'
}

.zmdi-undo:before {
	content: '\f258'
}

.zmdi-wrap-text:before {
	content: '\f259'
}

.zmdi-comment-alert:before {
	content: '\f25a'
}

.zmdi-comment-alt-text:before {
	content: '\f25b'
}

.zmdi-comment-alt:before {
	content: '\f25c'
}

.zmdi-comment-edit:before {
	content: '\f25d'
}

.zmdi-comment-image:before {
	content: '\f25e'
}

.zmdi-comment-list:before {
	content: '\f25f'
}

.zmdi-comment-more:before {
	content: '\f260'
}

.zmdi-comment-outline:before {
	content: '\f261'
}

.zmdi-comment-text-alt:before {
	content: '\f262'
}

.zmdi-comment-text:before {
	content: '\f263'
}

.zmdi-comment-video:before {
	content: '\f264'
}

.zmdi-comment:before {
	content: '\f265'
}

.zmdi-comments:before {
	content: '\f266'
}

.zmdi-check-all:before {
	content: '\f267'
}

.zmdi-check-circle-u:before {
	content: '\f268'
}

.zmdi-check-circle:before {
	content: '\f269'
}

.zmdi-check-square:before {
	content: '\f26a'
}

.zmdi-check:before {
	content: '\f26b'
}

.zmdi-circle-o:before {
	content: '\f26c'
}

.zmdi-circle:before {
	content: '\f26d'
}

.zmdi-dot-circle-alt:before {
	content: '\f26e'
}

.zmdi-dot-circle:before {
	content: '\f26f'
}

.zmdi-minus-circle-outline:before {
	content: '\f270'
}

.zmdi-minus-circle:before {
	content: '\f271'
}

.zmdi-minus-square:before {
	content: '\f272'
}

.zmdi-minus:before {
	content: '\f273'
}

.zmdi-plus-circle-o-duplicate:before {
	content: '\f274'
}

.zmdi-plus-circle-o:before {
	content: '\f275'
}

.zmdi-plus-circle:before {
	content: '\f276'
}

.zmdi-plus-square:before {
	content: '\f277'
}

.zmdi-plus:before {
	content: '\f278'
}

.zmdi-square-o:before {
	content: '\f279'
}

.zmdi-star-circle:before {
	content: '\f27a'
}

.zmdi-star-half:before {
	content: '\f27b'
}

.zmdi-star-outline:before {
	content: '\f27c'
}

.zmdi-star:before {
	content: '\f27d'
}

.zmdi-bluetooth-connected:before {
	content: '\f27e'
}

.zmdi-bluetooth-off:before {
	content: '\f27f'
}

.zmdi-bluetooth-search:before {
	content: '\f280'
}

.zmdi-bluetooth-setting:before {
	content: '\f281'
}

.zmdi-bluetooth:before {
	content: '\f282'
}

.zmdi-camera-add:before {
	content: '\f283'
}

.zmdi-camera-alt:before {
	content: '\f284'
}

.zmdi-camera-bw:before {
	content: '\f285'
}

.zmdi-camera-front:before {
	content: '\f286'
}

.zmdi-camera-mic:before {
	content: '\f287'
}

.zmdi-camera-party-mode:before {
	content: '\f288'
}

.zmdi-camera-rear:before {
	content: '\f289'
}

.zmdi-camera-roll:before {
	content: '\f28a'
}

.zmdi-camera-switch:before {
	content: '\f28b'
}

.zmdi-camera:before {
	content: '\f28c'
}

.zmdi-card-alert:before {
	content: '\f28d'
}

.zmdi-card-off:before {
	content: '\f28e'
}

.zmdi-card-sd:before {
	content: '\f28f'
}

.zmdi-card-sim:before {
	content: '\f290'
}

.zmdi-desktop-mac:before {
	content: '\f291'
}

.zmdi-desktop-windows:before {
	content: '\f292'
}

.zmdi-device-hub:before {
	content: '\f293'
}

.zmdi-devices-off:before {
	content: '\f294'
}

.zmdi-devices:before {
	content: '\f295'
}

.zmdi-dock:before {
	content: '\f296'
}

.zmdi-floppy:before {
	content: '\f297'
}

.zmdi-gamepad:before {
	content: '\f298'
}

.zmdi-gps-dot:before {
	content: '\f299'
}

.zmdi-gps-off:before {
	content: '\f29a'
}

.zmdi-gps:before {
	content: '\f29b'
}

.zmdi-headset-mic:before {
	content: '\f29c'
}

.zmdi-headset:before {
	content: '\f29d'
}

.zmdi-input-antenna:before {
	content: '\f29e'
}

.zmdi-input-composite:before {
	content: '\f29f'
}

.zmdi-input-hdmi:before {
	content: '\f2a0'
}

.zmdi-input-power:before {
	content: '\f2a1'
}

.zmdi-input-svideo:before {
	content: '\f2a2'
}

.zmdi-keyboard-hide:before {
	content: '\f2a3'
}

.zmdi-keyboard:before {
	content: '\f2a4'
}

.zmdi-laptop-chromebook:before {
	content: '\f2a5'
}

.zmdi-laptop-mac:before {
	content: '\f2a6'
}

.zmdi-laptop:before {
	content: '\f2a7'
}

.zmdi-mic-off:before {
	content: '\f2a8'
}

.zmdi-mic-outline:before {
	content: '\f2a9'
}

.zmdi-mic-setting:before {
	content: '\f2aa'
}

.zmdi-mic:before {
	content: '\f2ab'
}

.zmdi-mouse:before {
	content: '\f2ac'
}

.zmdi-network-alert:before {
	content: '\f2ad'
}

.zmdi-network-locked:before {
	content: '\f2ae'
}

.zmdi-network-off:before {
	content: '\f2af'
}

.zmdi-network-outline:before {
	content: '\f2b0'
}

.zmdi-network-setting:before {
	content: '\f2b1'
}

.zmdi-network:before {
	content: '\f2b2'
}

.zmdi-phone-bluetooth:before {
	content: '\f2b3'
}

.zmdi-phone-end:before {
	content: '\f2b4'
}

.zmdi-phone-forwarded:before {
	content: '\f2b5'
}

.zmdi-phone-in-talk:before {
	content: '\f2b6'
}

.zmdi-phone-locked:before {
	content: '\f2b7'
}

.zmdi-phone-missed:before {
	content: '\f2b8'
}

.zmdi-phone-msg:before {
	content: '\f2b9'
}

.zmdi-phone-paused:before {
	content: '\f2ba'
}

.zmdi-phone-ring:before {
	content: '\f2bb'
}

.zmdi-phone-setting:before {
	content: '\f2bc'
}

.zmdi-phone-sip:before {
	content: '\f2bd'
}

.zmdi-phone:before {
	content: '\f2be'
}

.zmdi-portable-wifi-changes:before {
	content: '\f2bf'
}

.zmdi-portable-wifi-off:before {
	content: '\f2c0'
}

.zmdi-portable-wifi:before {
	content: '\f2c1'
}

.zmdi-radio:before {
	content: '\f2c2'
}

.zmdi-reader:before {
	content: '\f2c3'
}

.zmdi-remote-control-alt:before {
	content: '\f2c4'
}

.zmdi-remote-control:before {
	content: '\f2c5'
}

.zmdi-router:before {
	content: '\f2c6'
}

.zmdi-scanner:before {
	content: '\f2c7'
}

.zmdi-smartphone-android:before {
	content: '\f2c8'
}

.zmdi-smartphone-download:before {
	content: '\f2c9'
}

.zmdi-smartphone-erase:before {
	content: '\f2ca'
}

.zmdi-smartphone-info:before {
	content: '\f2cb'
}

.zmdi-smartphone-iphone:before {
	content: '\f2cc'
}

.zmdi-smartphone-landscape-lock:before {
	content: '\f2cd'
}

.zmdi-smartphone-landscape:before {
	content: '\f2ce'
}

.zmdi-smartphone-lock:before {
	content: '\f2cf'
}

.zmdi-smartphone-portrait-lock:before {
	content: '\f2d0'
}

.zmdi-smartphone-ring:before {
	content: '\f2d1'
}

.zmdi-smartphone-setting:before {
	content: '\f2d2'
}

.zmdi-smartphone-setup:before {
	content: '\f2d3'
}

.zmdi-smartphone:before {
	content: '\f2d4'
}

.zmdi-speaker:before {
	content: '\f2d5'
}

.zmdi-tablet-android:before {
	content: '\f2d6'
}

.zmdi-tablet-mac:before {
	content: '\f2d7'
}

.zmdi-tablet:before {
	content: '\f2d8'
}

.zmdi-tv-alt-play:before {
	content: '\f2d9'
}

.zmdi-tv-list:before {
	content: '\f2da'
}

.zmdi-tv-play:before {
	content: '\f2db'
}

.zmdi-tv:before {
	content: '\f2dc'
}

.zmdi-usb:before {
	content: '\f2dd'
}

.zmdi-videocam-off:before {
	content: '\f2de'
}

.zmdi-videocam-switch:before {
	content: '\f2df'
}

.zmdi-videocam:before {
	content: '\f2e0'
}

.zmdi-watch:before {
	content: '\f2e1'
}

.zmdi-wifi-alt-2:before {
	content: '\f2e2'
}

.zmdi-wifi-alt:before {
	content: '\f2e3'
}

.zmdi-wifi-info:before {
	content: '\f2e4'
}

.zmdi-wifi-lock:before {
	content: '\f2e5'
}

.zmdi-wifi-off:before {
	content: '\f2e6'
}

.zmdi-wifi-outline:before {
	content: '\f2e7'
}

.zmdi-wifi:before {
	content: '\f2e8'
}

.zmdi-arrow-left-bottom:before {
	content: '\f2e9'
}

.zmdi-arrow-left:before {
	content: '\f2ea'
}

.zmdi-arrow-merge:before {
	content: '\f2eb'
}

.zmdi-arrow-missed:before {
	content: '\f2ec'
}

.zmdi-arrow-right-top:before {
	content: '\f2ed'
}

.zmdi-arrow-right:before {
	content: '\f2ee'
}

.zmdi-arrow-split:before {
	content: '\f2ef'
}

.zmdi-arrows:before {
	content: '\f2f0'
}

.zmdi-caret-down-circle:before {
	content: '\f2f1'
}

.zmdi-caret-down:before {
	content: '\f2f2'
}

.zmdi-caret-left-circle:before {
	content: '\f2f3'
}

.zmdi-caret-left:before {
	content: '\f2f4'
}

.zmdi-caret-right-circle:before {
	content: '\f2f5'
}

.zmdi-caret-right:before {
	content: '\f2f6'
}

.zmdi-caret-up-circle:before {
	content: '\f2f7'
}

.zmdi-caret-up:before {
	content: '\f2f8'
}

.zmdi-chevron-down:before {
	content: '\f2f9'
}

.zmdi-chevron-left:before {
	content: '\f2fa'
}

.zmdi-chevron-right:before {
	content: '\f2fb'
}

.zmdi-chevron-up:before {
	content: '\f2fc'
}

.zmdi-forward:before {
	content: '\f2fd'
}

.zmdi-long-arrow-down:before {
	content: '\f2fe'
}

.zmdi-long-arrow-left:before {
	content: '\f2ff'
}

.zmdi-long-arrow-return:before {
	content: '\f300'
}

.zmdi-long-arrow-right:before {
	content: '\f301'
}

.zmdi-long-arrow-tab:before {
	content: '\f302'
}

.zmdi-long-arrow-up:before {
	content: '\f303'
}

.zmdi-rotate-ccw:before {
	content: '\f304'
}

.zmdi-rotate-cw:before {
	content: '\f305'
}

.zmdi-rotate-left:before {
	content: '\f306'
}

.zmdi-rotate-right:before {
	content: '\f307'
}

.zmdi-square-down:before {
	content: '\f308'
}

.zmdi-square-right:before {
	content: '\f309'
}

.zmdi-swap-alt:before {
	content: '\f30a'
}

.zmdi-swap-vertical-circle:before {
	content: '\f30b'
}

.zmdi-swap-vertical:before {
	content: '\f30c'
}

.zmdi-swap:before {
	content: '\f30d'
}

.zmdi-trending-down:before {
	content: '\f30e'
}

.zmdi-trending-flat:before {
	content: '\f30f'
}

.zmdi-trending-up:before {
	content: '\f310'
}

.zmdi-unfold-less:before {
	content: '\f311'
}

.zmdi-unfold-more:before {
	content: '\f312'
}

.zmdi-apps:before {
	content: '\f313'
}

.zmdi-grid-off:before {
	content: '\f314'
}

.zmdi-grid:before {
	content: '\f315'
}

.zmdi-view-agenda:before {
	content: '\f316'
}

.zmdi-view-array:before {
	content: '\f317'
}

.zmdi-view-carousel:before {
	content: '\f318'
}

.zmdi-view-column:before {
	content: '\f319'
}

.zmdi-view-comfy:before {
	content: '\f31a'
}

.zmdi-view-compact:before {
	content: '\f31b'
}

.zmdi-view-dashboard:before {
	content: '\f31c'
}

.zmdi-view-day:before {
	content: '\f31d'
}

.zmdi-view-headline:before {
	content: '\f31e'
}

.zmdi-view-list-alt:before {
	content: '\f31f'
}

.zmdi-view-list:before {
	content: '\f320'
}

.zmdi-view-module:before {
	content: '\f321'
}

.zmdi-view-quilt:before {
	content: '\f322'
}

.zmdi-view-stream:before {
	content: '\f323'
}

.zmdi-view-subtitles:before {
	content: '\f324'
}

.zmdi-view-toc:before {
	content: '\f325'
}

.zmdi-view-web:before {
	content: '\f326'
}

.zmdi-view-week:before {
	content: '\f327'
}

.zmdi-widgets:before {
	content: '\f328'
}

.zmdi-alarm-check:before {
	content: '\f329'
}

.zmdi-alarm-off:before {
	content: '\f32a'
}

.zmdi-alarm-plus:before {
	content: '\f32b'
}

.zmdi-alarm-snooze:before {
	content: '\f32c'
}

.zmdi-alarm:before {
	content: '\f32d'
}

.zmdi-calendar-alt:before {
	content: '\f32e'
}

.zmdi-calendar-check:before {
	content: '\f32f'
}

.zmdi-calendar-close:before {
	content: '\f330'
}

.zmdi-calendar-note:before {
	content: '\f331'
}

.zmdi-calendar:before {
	content: '\f332'
}

.zmdi-time-countdown:before {
	content: '\f333'
}

.zmdi-time-interval:before {
	content: '\f334'
}

.zmdi-time-restore-setting:before {
	content: '\f335'
}

.zmdi-time-restore:before {
	content: '\f336'
}

.zmdi-time:before {
	content: '\f337'
}

.zmdi-timer-off:before {
	content: '\f338'
}

.zmdi-timer:before {
	content: '\f339'
}

.zmdi-android-alt:before {
	content: '\f33a'
}

.zmdi-android:before {
	content: '\f33b'
}

.zmdi-apple:before {
	content: '\f33c'
}

.zmdi-behance:before {
	content: '\f33d'
}

.zmdi-codepen:before {
	content: '\f33e'
}

.zmdi-dribbble:before {
	content: '\f33f'
}

.zmdi-dropbox:before {
	content: '\f340'
}

.zmdi-evernote:before {
	content: '\f341'
}

.zmdi-facebook-box:before {
	content: '\f342'
}

.zmdi-facebook:before {
	content: '\f343'
}

.zmdi-github-box:before {
	content: '\f344'
}

.zmdi-github:before {
	content: '\f345'
}

.zmdi-google-drive:before {
	content: '\f346'
}

.zmdi-google-earth:before {
	content: '\f347'
}

.zmdi-google-glass:before {
	content: '\f348'
}

.zmdi-google-maps:before {
	content: '\f349'
}

.zmdi-google-pages:before {
	content: '\f34a'
}

.zmdi-google-play:before {
	content: '\f34b'
}

.zmdi-google-plus-box:before {
	content: '\f34c'
}

.zmdi-google-plus:before {
	content: '\f34d'
}

.zmdi-google:before {
	content: '\f34e'
}

.zmdi-instagram:before {
	content: '\f34f'
}

.zmdi-language-css3:before {
	content: '\f350'
}

.zmdi-language-html5:before {
	content: '\f351'
}

.zmdi-language-javascript:before {
	content: '\f352'
}

.zmdi-language-python-alt:before {
	content: '\f353'
}

.zmdi-language-python:before {
	content: '\f354'
}

.zmdi-lastfm:before {
	content: '\f355'
}

.zmdi-linkedin-box:before {
	content: '\f356'
}

.zmdi-paypal:before {
	content: '\f357'
}

.zmdi-pinterest-box:before {
	content: '\f358'
}

.zmdi-pocket:before {
	content: '\f359'
}

.zmdi-polymer:before {
	content: '\f35a'
}

.zmdi-share:before {
	content: '\f35b'
}

.zmdi-stackoverflow:before {
	content: '\f35c'
}

.zmdi-steam-square:before {
	content: '\f35d'
}

.zmdi-steam:before {
	content: '\f35e'
}

.zmdi-twitter-box:before {
	content: '\f35f'
}

.zmdi-twitter:before {
	content: '\f360'
}

.zmdi-vk:before {
	content: '\f361'
}

.zmdi-wikipedia:before {
	content: '\f362'
}

.zmdi-windows:before {
	content: '\f363'
}

.zmdi-aspect-ratio-alt:before {
	content: '\f364'
}

.zmdi-aspect-ratio:before {
	content: '\f365'
}

.zmdi-blur-circular:before {
	content: '\f366'
}

.zmdi-blur-linear:before {
	content: '\f367'
}

.zmdi-blur-off:before {
	content: '\f368'
}

.zmdi-blur:before {
	content: '\f369'
}

.zmdi-brightness-2:before {
	content: '\f36a'
}

.zmdi-brightness-3:before {
	content: '\f36b'
}

.zmdi-brightness-4:before {
	content: '\f36c'
}

.zmdi-brightness-5:before {
	content: '\f36d'
}

.zmdi-brightness-6:before {
	content: '\f36e'
}

.zmdi-brightness-7:before {
	content: '\f36f'
}

.zmdi-brightness-auto:before {
	content: '\f370'
}

.zmdi-brightness-setting:before {
	content: '\f371'
}

.zmdi-broken-image:before {
	content: '\f372'
}

.zmdi-center-focus-strong:before {
	content: '\f373'
}

.zmdi-center-focus-weak:before {
	content: '\f374'
}

.zmdi-compare:before {
	content: '\f375'
}

.zmdi-crop-16-9:before {
	content: '\f376'
}

.zmdi-crop-3-2:before {
	content: '\f377'
}

.zmdi-crop-5-4:before {
	content: '\f378'
}

.zmdi-crop-7-5:before {
	content: '\f379'
}

.zmdi-crop-din:before {
	content: '\f37a'
}

.zmdi-crop-free:before {
	content: '\f37b'
}

.zmdi-crop-landscape:before {
	content: '\f37c'
}

.zmdi-crop-portrait:before {
	content: '\f37d'
}

.zmdi-crop-square:before {
	content: '\f37e'
}

.zmdi-exposure-alt:before {
	content: '\f37f'
}

.zmdi-exposure:before {
	content: '\f380'
}

.zmdi-filter-b-and-w:before {
	content: '\f381'
}

.zmdi-filter-center-focus:before {
	content: '\f382'
}

.zmdi-filter-frames:before {
	content: '\f383'
}

.zmdi-filter-tilt-shift:before {
	content: '\f384'
}

.zmdi-gradient:before {
	content: '\f385'
}

.zmdi-grain:before {
	content: '\f386'
}

.zmdi-graphic-eq:before {
	content: '\f387'
}

.zmdi-hdr-off:before {
	content: '\f388'
}

.zmdi-hdr-strong:before {
	content: '\f389'
}

.zmdi-hdr-weak:before {
	content: '\f38a'
}

.zmdi-hdr:before {
	content: '\f38b'
}

.zmdi-iridescent:before {
	content: '\f38c'
}

.zmdi-leak-off:before {
	content: '\f38d'
}

.zmdi-leak:before {
	content: '\f38e'
}

.zmdi-looks:before {
	content: '\f38f'
}

.zmdi-loupe:before {
	content: '\f390'
}

.zmdi-panorama-horizontal:before {
	content: '\f391'
}

.zmdi-panorama-vertical:before {
	content: '\f392'
}

.zmdi-panorama-wide-angle:before {
	content: '\f393'
}

.zmdi-photo-size-select-large:before {
	content: '\f394'
}

.zmdi-photo-size-select-small:before {
	content: '\f395'
}

.zmdi-picture-in-picture:before {
	content: '\f396'
}

.zmdi-slideshow:before {
	content: '\f397'
}

.zmdi-texture:before {
	content: '\f398'
}

.zmdi-tonality:before {
	content: '\f399'
}

.zmdi-vignette:before {
	content: '\f39a'
}

.zmdi-wb-auto:before {
	content: '\f39b'
}

.zmdi-eject-alt:before {
	content: '\f39c'
}

.zmdi-eject:before {
	content: '\f39d'
}

.zmdi-equalizer:before {
	content: '\f39e'
}

.zmdi-fast-forward:before {
	content: '\f39f'
}

.zmdi-fast-rewind:before {
	content: '\f3a0'
}

.zmdi-forward-10:before {
	content: '\f3a1'
}

.zmdi-forward-30:before {
	content: '\f3a2'
}

.zmdi-forward-5:before {
	content: '\f3a3'
}

.zmdi-hearing:before {
	content: '\f3a4'
}

.zmdi-pause-circle-outline:before {
	content: '\f3a5'
}

.zmdi-pause-circle:before {
	content: '\f3a6'
}

.zmdi-pause:before {
	content: '\f3a7'
}

.zmdi-play-circle-outline:before {
	content: '\f3a8'
}

.zmdi-play-circle:before {
	content: '\f3a9'
}

.zmdi-play:before {
	content: '\f3aa'
}

.zmdi-playlist-audio:before {
	content: '\f3ab'
}

.zmdi-playlist-plus:before {
	content: '\f3ac'
}

.zmdi-repeat-one:before {
	content: '\f3ad'
}

.zmdi-repeat:before {
	content: '\f3ae'
}

.zmdi-replay-10:before {
	content: '\f3af'
}

.zmdi-replay-30:before {
	content: '\f3b0'
}

.zmdi-replay-5:before {
	content: '\f3b1'
}

.zmdi-replay:before {
	content: '\f3b2'
}

.zmdi-shuffle:before {
	content: '\f3b3'
}

.zmdi-skip-next:before {
	content: '\f3b4'
}

.zmdi-skip-previous:before {
	content: '\f3b5'
}

.zmdi-stop:before {
	content: '\f3b6'
}

.zmdi-surround-sound:before {
	content: '\f3b7'
}

.zmdi-tune:before {
	content: '\f3b8'
}

.zmdi-volume-down:before {
	content: '\f3b9'
}

.zmdi-volume-mute:before {
	content: '\f3ba'
}

.zmdi-volume-off:before {
	content: '\f3bb'
}

.zmdi-volume-up:before {
	content: '\f3bc'
}

.zmdi-n-1-square:before {
	content: '\f3bd'
}

.zmdi-n-2-square:before {
	content: '\f3be'
}

.zmdi-n-3-square:before {
	content: '\f3bf'
}

.zmdi-n-4-square:before {
	content: '\f3c0'
}

.zmdi-n-5-square:before {
	content: '\f3c1'
}

.zmdi-n-6-square:before {
	content: '\f3c2'
}

.zmdi-neg-1:before {
	content: '\f3c3'
}

.zmdi-neg-2:before {
	content: '\f3c4'
}

.zmdi-plus-1:before {
	content: '\f3c5'
}

.zmdi-plus-2:before {
	content: '\f3c6'
}

.zmdi-sec-10:before {
	content: '\f3c7'
}

.zmdi-sec-3:before {
	content: '\f3c8'
}

.zmdi-zero:before {
	content: '\f3c9'
}

.zmdi-airline-seat-flat-angled:before {
	content: '\f3ca'
}

.zmdi-airline-seat-flat:before {
	content: '\f3cb'
}

.zmdi-airline-seat-individual-suite:before {
	content: '\f3cc'
}

.zmdi-airline-seat-legroom-extra:before {
	content: '\f3cd'
}

.zmdi-airline-seat-legroom-normal:before {
	content: '\f3ce'
}

.zmdi-airline-seat-legroom-reduced:before {
	content: '\f3cf'
}

.zmdi-airline-seat-recline-extra:before {
	content: '\f3d0'
}

.zmdi-airline-seat-recline-normal:before {
	content: '\f3d1'
}

.zmdi-airplay:before {
	content: '\f3d2'
}

.zmdi-closed-caption:before {
	content: '\f3d3'
}

.zmdi-confirmation-number:before {
	content: '\f3d4'
}

.zmdi-developer-board:before {
	content: '\f3d5'
}

.zmdi-disc-full:before {
	content: '\f3d6'
}

.zmdi-explicit:before {
	content: '\f3d7'
}

.zmdi-flight-land:before {
	content: '\f3d8'
}

.zmdi-flight-takeoff:before {
	content: '\f3d9'
}

.zmdi-flip-to-back:before {
	content: '\f3da'
}

.zmdi-flip-to-front:before {
	content: '\f3db'
}

.zmdi-group-work:before {
	content: '\f3dc'
}

.zmdi-hd:before {
	content: '\f3dd'
}

.zmdi-hq:before {
	content: '\f3de'
}

.zmdi-markunread-mailbox:before {
	content: '\f3df'
}

.zmdi-memory:before {
	content: '\f3e0'
}

.zmdi-nfc:before {
	content: '\f3e1'
}

.zmdi-play-for-work:before {
	content: '\f3e2'
}

.zmdi-power-input:before {
	content: '\f3e3'
}

.zmdi-present-to-all:before {
	content: '\f3e4'
}

.zmdi-satellite:before {
	content: '\f3e5'
}

.zmdi-tap-and-play:before {
	content: '\f3e6'
}

.zmdi-vibration:before {
	content: '\f3e7'
}

.zmdi-voicemail:before {
	content: '\f3e8'
}

.zmdi-group:before {
	content: '\f3e9'
}

.zmdi-rss:before {
	content: '\f3ea'
}

.zmdi-shape:before {
	content: '\f3eb'
}

.zmdi-spinner:before {
	content: '\f3ec'
}

.zmdi-ungroup:before {
	content: '\f3ed'
}

.zmdi-500px:before {
	content: '\f3ee'
}

.zmdi-8tracks:before {
	content: '\f3ef'
}

.zmdi-amazon:before {
	content: '\f3f0'
}

.zmdi-blogger:before {
	content: '\f3f1'
}

.zmdi-delicious:before {
	content: '\f3f2'
}

.zmdi-disqus:before {
	content: '\f3f3'
}

.zmdi-flattr:before {
	content: '\f3f4'
}

.zmdi-flickr:before {
	content: '\f3f5'
}

.zmdi-github-alt:before {
	content: '\f3f6'
}

.zmdi-google-old:before {
	content: '\f3f7'
}

.zmdi-linkedin:before {
	content: '\f3f8'
}

.zmdi-odnoklassniki:before {
	content: '\f3f9'
}

.zmdi-outlook:before {
	content: '\f3fa'
}

.zmdi-paypal-alt:before {
	content: '\f3fb'
}

.zmdi-pinterest:before {
	content: '\f3fc'
}

.zmdi-playstation:before {
	content: '\f3fd'
}

.zmdi-reddit:before {
	content: '\f3fe'
}

.zmdi-skype:before {
	content: '\f3ff'
}

.zmdi-slideshare:before {
	content: '\f400'
}

.zmdi-soundcloud:before {
	content: '\f401'
}

.zmdi-tumblr:before {
	content: '\f402'
}

.zmdi-twitch:before {
	content: '\f403'
}

.zmdi-vimeo:before {
	content: '\f404'
}

.zmdi-whatsapp:before {
	content: '\f405'
}

.zmdi-xbox:before {
	content: '\f406'
}

.zmdi-yahoo:before {
	content: '\f407'
}

.zmdi-youtube-play:before {
	content: '\f408'
}

.zmdi-youtube:before {
	content: '\f409'
}

.zmdi-import-export:before {
	content: '\f30c'
}

.zmdi-swap-vertical-:before {
	content: '\f30c'
}

.zmdi-airplanemode-inactive:before {
	content: '\f102'
}

.zmdi-airplanemode-active:before {
	content: '\f103'
}

.zmdi-rate-review:before {
	content: '\f103'
}

.zmdi-comment-sign:before {
	content: '\f25a'
}

.zmdi-network-warning:before {
	content: '\f2ad'
}

.zmdi-shopping-cart-add:before {
	content: '\f1ca'
}

.zmdi-file-add:before {
	content: '\f221'
}

.zmdi-network-wifi-scan:before {
	content: '\f2e4'
}

.zmdi-collection-add:before {
	content: '\f14e'
}

.zmdi-format-playlist-add:before {
	content: '\f3ac'
}

.zmdi-format-queue-music:before {
	content: '\f3ab'
}

.zmdi-plus-box:before {
	content: '\f277'
}

.zmdi-tag-backspace:before {
	content: '\f1d9'
}

.zmdi-alarm-add:before {
	content: '\f32b'
}

.zmdi-battery-charging:before {
	content: '\f114'
}

.zmdi-daydream-setting:before {
	content: '\f217'
}

.zmdi-more-horiz:before {
	content: '\f19c'
}

.zmdi-book-photo:before {
	content: '\f11b'
}

.zmdi-incandescent:before {
	content: '\f189'
}

.zmdi-wb-iridescent:before {
	content: '\f38c'
}

.zmdi-calendar-remove:before {
	content: '\f330'
}

.zmdi-refresh-sync-disabled:before {
	content: '\f1b7'
}

.zmdi-refresh-sync-problem:before {
	content: '\f1b6'
}

.zmdi-crop-original:before {
	content: '\f17e'
}

.zmdi-power-off:before {
	content: '\f1af'
}

.zmdi-power-off-setting:before {
	content: '\f1ae'
}

.zmdi-leak-remove:before {
	content: '\f38d'
}

.zmdi-star-border:before {
	content: '\f27c'
}

.zmdi-brightness-low:before {
	content: '\f36d'
}

.zmdi-brightness-medium:before {
	content: '\f36e'
}

.zmdi-brightness-high:before {
	content: '\f36f'
}

.zmdi-smartphone-portrait:before {
	content: '\f2d4'
}

.zmdi-live-tv:before {
	content: '\f2d9'
}

.zmdi-format-textdirection-l-to-r:before {
	content: '\f249'
}

.zmdi-format-textdirection-r-to-l:before {
	content: '\f24a'
}

.zmdi-arrow-back:before {
	content: '\f2ea'
}

.zmdi-arrow-forward:before {
	content: '\f2ee'
}

.zmdi-arrow-in:before {
	content: '\f2e9'
}

.zmdi-arrow-out:before {
	content: '\f2ed'
}

.zmdi-rotate-90-degrees-ccw:before {
	content: '\f304'
}

.zmdi-adb:before {
	content: '\f33a'
}

.zmdi-network-wifi:before {
	content: '\f2e8'
}

.zmdi-network-wifi-alt:before {
	content: '\f2e3'
}

.zmdi-network-wifi-lock:before {
	content: '\f2e5'
}

.zmdi-network-wifi-off:before {
	content: '\f2e6'
}

.zmdi-network-wifi-outline:before {
	content: '\f2e7'
}

.zmdi-network-wifi-info:before {
	content: '\f2e4'
}

.zmdi-layers-clear:before {
	content: '\f18b'
}

.zmdi-colorize:before {
	content: '\f15d'
}

.zmdi-format-paint:before {
	content: '\f1ba'
}

.zmdi-format-quote:before {
	content: '\f1b2'
}

.zmdi-camera-monochrome-photos:before {
	content: '\f285'
}

.zmdi-sort-by-alpha:before {
	content: '\f1cf'
}

.zmdi-folder-shared:before {
	content: '\f225'
}

.zmdi-folder-special:before {
	content: '\f226'
}

.zmdi-comment-dots:before {
	content: '\f260'
}

.zmdi-reorder:before {
	content: '\f31e'
}

.zmdi-dehaze:before {
	content: '\f197'
}

.zmdi-sort:before {
	content: '\f1ce'
}

.zmdi-pages:before {
	content: '\f34a'
}

.zmdi-stack-overflow:before {
	content: '\f35c'
}

.zmdi-calendar-account:before {
	content: '\f204'
}

.zmdi-paste:before {
	content: '\f109'
}

.zmdi-cut:before {
	content: '\f1bc'
}

.zmdi-save:before {
	content: '\f297'
}

.zmdi-smartphone-code:before {
	content: '\f139'
}

.zmdi-directions-bike:before {
	content: '\f117'
}

.zmdi-directions-boat:before {
	content: '\f11a'
}

.zmdi-directions-bus:before {
	content: '\f121'
}

.zmdi-directions-car:before {
	content: '\f125'
}

.zmdi-directions-railway:before {
	content: '\f1b3'
}

.zmdi-directions-run:before {
	content: '\f215'
}

.zmdi-directions-subway:before {
	content: '\f1d5'
}

.zmdi-directions-walk:before {
	content: '\f216'
}

.zmdi-local-hotel:before {
	content: '\f178'
}

.zmdi-local-activity:before {
	content: '\f1df'
}

.zmdi-local-play:before {
	content: '\f1df'
}

.zmdi-local-airport:before {
	content: '\f103'
}

.zmdi-local-atm:before {
	content: '\f198'
}

.zmdi-local-bar:before {
	content: '\f137'
}

.zmdi-local-cafe:before {
	content: '\f13b'
}

.zmdi-local-car-wash:before {
	content: '\f124'
}

.zmdi-local-convenience-store:before {
	content: '\f1d3'
}

.zmdi-local-dining:before {
	content: '\f153'
}

.zmdi-local-drink:before {
	content: '\f157'
}

.zmdi-local-florist:before {
	content: '\f168'
}

.zmdi-local-gas-station:before {
	content: '\f16f'
}

.zmdi-local-grocery-store:before {
	content: '\f1cb'
}

.zmdi-local-hospital:before {
	content: '\f177'
}

.zmdi-local-laundry-service:before {
	content: '\f1e9'
}

.zmdi-local-library:before {
	content: '\f18d'
}

.zmdi-local-mall:before {
	content: '\f195'
}

.zmdi-local-movies:before {
	content: '\f19d'
}

.zmdi-local-offer:before {
	content: '\f187'
}

.zmdi-local-parking:before {
	content: '\f1a5'
}

.zmdi-local-parking:before {
	content: '\f1a5'
}

.zmdi-local-pharmacy:before {
	content: '\f176'
}

.zmdi-local-phone:before {
	content: '\f2be'
}

.zmdi-local-pizza:before {
	content: '\f1ac'
}

.zmdi-local-post-office:before {
	content: '\f15a'
}

.zmdi-local-printshop:before {
	content: '\f1b0'
}

.zmdi-local-see:before {
	content: '\f28c'
}

.zmdi-local-shipping:before {
	content: '\f1e6'
}

.zmdi-local-store:before {
	content: '\f1d4'
}

.zmdi-local-taxi:before {
	content: '\f123'
}

.zmdi-local-wc:before {
	content: '\f211'
}

.zmdi-my-location:before {
	content: '\f299'
}

.zmdi-directions:before {
	content: '\f1e7'
}

.waves-effect {
	position: relative;
	cursor: pointer;
	display: inline-block;
	overflow: hidden;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: transparent
}

.waves-effect .waves-ripple {
	position: absolute;
	border-radius: 50%;
	width: 100px;
	height: 100px;
	margin-top: -50px;
	margin-left: -50px;
	opacity: 0;
	background: rgba(0, 0, 0, 0.2);
	background: -webkit-radial-gradient(rgba(0, 0, 0, 0.2) 0, rgba(0, 0, 0, 0.3) 40%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.5) 60%, rgba(255, 255, 255, 0) 70%);
	background: -o-radial-gradient(rgba(0, 0, 0, 0.2) 0, rgba(0, 0, 0, 0.3) 40%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.5) 60%, rgba(255, 255, 255, 0) 70%);
	background: -moz-radial-gradient(rgba(0, 0, 0, 0.2) 0, rgba(0, 0, 0, 0.3) 40%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.5) 60%, rgba(255, 255, 255, 0) 70%);
	background: radial-gradient(rgba(0, 0, 0, 0.2) 0, rgba(0, 0, 0, 0.3) 40%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.5) 60%, rgba(255, 255, 255, 0) 70%);
	-webkit-transition: all 0.5s ease-out;
	-moz-transition: all 0.5s ease-out;
	-o-transition: all 0.5s ease-out;
	transition: all 0.5s ease-out;
	-webkit-transition-property: -webkit-transform, opacity;
	-moz-transition-property: -moz-transform, opacity;
	-o-transition-property: -o-transform, opacity;
	transition-property: transform, opacity;
	-webkit-transform: scale(0) translate(0, 0);
	-moz-transform: scale(0) translate(0, 0);
	-ms-transform: scale(0) translate(0, 0);
	-o-transform: scale(0) translate(0, 0);
	transform: scale(0) translate(0, 0);
	pointer-events: none
}

.waves-effect.waves-light .waves-ripple {
	background: rgba(255, 255, 255, 0.4);
	background: -webkit-radial-gradient(rgba(255, 255, 255, 0.2) 0, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0) 70%);
	background: -o-radial-gradient(rgba(255, 255, 255, 0.2) 0, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0) 70%);
	background: -moz-radial-gradient(rgba(255, 255, 255, 0.2) 0, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0) 70%);
	background: radial-gradient(rgba(255, 255, 255, 0.2) 0, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0) 70%)
}

.waves-effect.waves-classic .waves-ripple {
	background: rgba(0, 0, 0, 0.2)
}

.waves-effect.waves-classic.waves-light .waves-ripple {
	background: rgba(255, 255, 255, 0.4)
}

.waves-notransition {
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-o-transition: none !important;
	transition: none !important
}

.waves-button,
.waves-circle {
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
	-o-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-mask-image: -webkit-radial-gradient(circle, #fff 100%, #000 100%)
}

.waves-button,
.waves-button:hover,
.waves-button:visited,
.waves-button-input {
	white-space: nowrap;
	vertical-align: middle;
	cursor: pointer;
	border: none;
	outline: none;
	color: inherit;
	background-color: rgba(0, 0, 0, 0);
	font-size: 1em;
	line-height: 1em;
	text-align: center;
	text-decoration: none;
	z-index: 1
}

.waves-button {
	padding: 0.85em 1.1em;
	border-radius: 0.2em
}

.waves-button-input {
	margin: 0;
	padding: 0.85em 1.1em
}

.waves-input-wrapper {
	border-radius: 0.2em;
	vertical-align: bottom
}

.waves-input-wrapper.waves-button {
	padding: 0
}

.waves-input-wrapper .waves-button-input {
	position: relative;
	top: 0;
	left: 0;
	z-index: 1
}

.waves-circle {
	text-align: center;
	width: 2.5em;
	height: 2.5em;
	line-height: 2.5em;
	border-radius: 50%
}

.waves-float {
	-webkit-mask-image: none;
	-webkit-box-shadow: 0px 1px 1.5px 1px rgba(0, 0, 0, 0.12);
	box-shadow: 0px 1px 1.5px 1px rgba(0, 0, 0, 0.12);
	-webkit-transition: all 300ms;
	-moz-transition: all 300ms;
	-o-transition: all 300ms;
	transition: all 300ms
}

.waves-float:active {
	-webkit-box-shadow: 0px 8px 20px 1px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 8px 20px 1px rgba(0, 0, 0, 0.3)
}

.waves-block {
	display: block
}

.slideDown {
	animation-name: slideDown;
	-webkit-animation-name: slideDown;
	animation-duration: 1s;
	-webkit-animation-duration: 1s;
	animation-timing-function: ease;
	-webkit-animation-timing-function: ease;
	visibility: visible !important
}

@keyframes slideDown {
	0% {
		transform: translateY(-100%)
	}
	50% {
		transform: translateY(8%)
	}
	65% {
		transform: translateY(-4%)
	}
	80% {
		transform: translateY(4%)
	}
	95% {
		transform: translateY(-2%)
	}
	100% {
		transform: translateY(0%)
	}
}

@-webkit-keyframes slideDown {
	0% {
		-webkit-transform: translateY(-100%)
	}
	50% {
		-webkit-transform: translateY(8%)
	}
	65% {
		-webkit-transform: translateY(-4%)
	}
	80% {
		-webkit-transform: translateY(4%)
	}
	95% {
		-webkit-transform: translateY(-2%)
	}
	100% {
		-webkit-transform: translateY(0%)
	}
}

.slideUp {
	animation-name: slideUp;
	-webkit-animation-name: slideUp;
	animation-duration: 1s;
	-webkit-animation-duration: 1s;
	animation-timing-function: ease;
	-webkit-animation-timing-function: ease;
	visibility: visible !important
}

@keyframes slideUp {
	0% {
		transform: translateY(100%)
	}
	50% {
		transform: translateY(-8%)
	}
	65% {
		transform: translateY(4%)
	}
	80% {
		transform: translateY(-4%)
	}
	95% {
		transform: translateY(2%)
	}
	100% {
		transform: translateY(0%)
	}
}

@-webkit-keyframes slideUp {
	0% {
		-webkit-transform: translateY(100%)
	}
	50% {
		-webkit-transform: translateY(-8%)
	}
	65% {
		-webkit-transform: translateY(4%)
	}
	80% {
		-webkit-transform: translateY(-4%)
	}
	95% {
		-webkit-transform: translateY(2%)
	}
	100% {
		-webkit-transform: translateY(0%)
	}
}

.slideLeft {
	animation-name: slideLeft;
	-webkit-animation-name: slideLeft;
	animation-duration: 1s;
	-webkit-animation-duration: 1s;
	animation-timing-function: ease-in-out;
	-webkit-animation-timing-function: ease-in-out;
	visibility: visible !important
}

@keyframes slideLeft {
	0% {
		transform: translateX(150%)
	}
	50% {
		transform: translateX(-8%)
	}
	65% {
		transform: translateX(4%)
	}
	80% {
		transform: translateX(-4%)
	}
	95% {
		transform: translateX(2%)
	}
	100% {
		transform: translateX(0%)
	}
}

@-webkit-keyframes slideLeft {
	0% {
		-webkit-transform: translateX(150%)
	}
	50% {
		-webkit-transform: translateX(-8%)
	}
	65% {
		-webkit-transform: translateX(4%)
	}
	80% {
		-webkit-transform: translateX(-4%)
	}
	95% {
		-webkit-transform: translateX(2%)
	}
	100% {
		-webkit-transform: translateX(0%)
	}
}

.slideRight {
	animation-name: slideRight;
	-webkit-animation-name: slideRight;
	animation-duration: 1s;
	-webkit-animation-duration: 1s;
	animation-timing-function: ease-in-out;
	-webkit-animation-timing-function: ease-in-out;
	visibility: visible !important
}

@keyframes slideRight {
	0% {
		transform: translateX(-150%)
	}
	50% {
		transform: translateX(8%)
	}
	65% {
		transform: translateX(-4%)
	}
	80% {
		transform: translateX(4%)
	}
	95% {
		transform: translateX(-2%)
	}
	100% {
		transform: translateX(0%)
	}
}

@-webkit-keyframes slideRight {
	0% {
		-webkit-transform: translateX(-150%)
	}
	50% {
		-webkit-transform: translateX(8%)
	}
	65% {
		-webkit-transform: translateX(-4%)
	}
	80% {
		-webkit-transform: translateX(4%)
	}
	95% {
		-webkit-transform: translateX(-2%)
	}
	100% {
		-webkit-transform: translateX(0%)
	}
}

.slideExpandUp {
	animation-name: slideExpandUp;
	-webkit-animation-name: slideExpandUp;
	animation-duration: 1.6s;
	-webkit-animation-duration: 1.6s;
	animation-timing-function: ease-out;
	-webkit-animation-timing-function: ease -out;
	visibility: visible !important
}

@keyframes slideExpandUp {
	0% {
		transform: translateY(100%) scaleX(0.5)
	}
	30% {
		transform: translateY(-8%) scaleX(0.5)
	}
	40% {
		transform: translateY(2%) scaleX(0.5)
	}
	50% {
		transform: translateY(0%) scaleX(1.1)
	}
	60% {
		transform: translateY(0%) scaleX(0.9)
	}
	70% {
		transform: translateY(0%) scaleX(1.05)
	}
	80% {
		transform: translateY(0%) scaleX(0.95)
	}
	90% {
		transform: translateY(0%) scaleX(1.02)
	}
	100% {
		transform: translateY(0%) scaleX(1)
	}
}

@-webkit-keyframes slideExpandUp {
	0% {
		-webkit-transform: translateY(100%) scaleX(0.5)
	}
	30% {
		-webkit-transform: translateY(-8%) scaleX(0.5)
	}
	40% {
		-webkit-transform: translateY(2%) scaleX(0.5)
	}
	50% {
		-webkit-transform: translateY(0%) scaleX(1.1)
	}
	60% {
		-webkit-transform: translateY(0%) scaleX(0.9)
	}
	70% {
		-webkit-transform: translateY(0%) scaleX(1.05)
	}
	80% {
		-webkit-transform: translateY(0%) scaleX(0.95)
	}
	90% {
		-webkit-transform: translateY(0%) scaleX(1.02)
	}
	100% {
		-webkit-transform: translateY(0%) scaleX(1)
	}
}

.expandUp {
	animation-name: expandUp;
	-webkit-animation-name: expandUp;
	animation-duration: 0.7s;
	-webkit-animation-duration: 0.7s;
	animation-timing-function: ease;
	-webkit-animation-timing-function: ease;
	visibility: visible !important
}

@keyframes expandUp {
	0% {
		transform: translateY(100%) scale(0.6) scaleY(0.5)
	}
	60% {
		transform: translateY(-7%) scaleY(1.12)
	}
	75% {
		transform: translateY(3%)
	}
	100% {
		transform: translateY(0%) scale(1) scaleY(1)
	}
}

@-webkit-keyframes expandUp {
	0% {
		-webkit-transform: translateY(100%) scale(0.6) scaleY(0.5)
	}
	60% {
		-webkit-transform: translateY(-7%) scaleY(1.12)
	}
	75% {
		-webkit-transform: translateY(3%)
	}
	100% {
		-webkit-transform: translateY(0%) scale(1) scaleY(1)
	}
}

.fadeIn {
	animation-name: fadeIn;
	-webkit-animation-name: fadeIn;
	animation-duration: 1.5s;
	-webkit-animation-duration: 1.5s;
	animation-timing-function: ease-in-out;
	-webkit-animation-timing-function: ease-in-out;
	visibility: visible !important
}

@keyframes fadeIn {
	0% {
		transform: scale(0);
		opacity: 0.0
	}
	60% {
		transform: scale(1.1)
	}
	80% {
		transform: scale(0.9);
		opacity: 1
	}
	100% {
		transform: scale(1);
		opacity: 1
	}
}

@-webkit-keyframes fadeIn {
	0% {
		-webkit-transform: scale(0);
		opacity: 0.0
	}
	60% {
		-webkit-transform: scale(1.1)
	}
	80% {
		-webkit-transform: scale(0.9);
		opacity: 1
	}
	100% {
		-webkit-transform: scale(1);
		opacity: 1
	}
}

.expandOpen {
	animation-name: expandOpen;
	-webkit-animation-name: expandOpen;
	animation-duration: 1.2s;
	-webkit-animation-duration: 1.2s;
	animation-timing-function: ease-out;
	-webkit-animation-timing-function: ease-out;
	visibility: visible !important
}

@keyframes expandOpen {
	0% {
		transform: scale(1.8)
	}
	50% {
		transform: scale(0.95)
	}
	80% {
		transform: scale(1.05)
	}
	90% {
		transform: scale(0.98)
	}
	100% {
		transform: scale(1)
	}
}

@-webkit-keyframes expandOpen {
	0% {
		-webkit-transform: scale(1.8)
	}
	50% {
		-webkit-transform: scale(0.95)
	}
	80% {
		-webkit-transform: scale(1.05)
	}
	90% {
		-webkit-transform: scale(0.98)
	}
	100% {
		-webkit-transform: scale(1)
	}
}

.bigEntrance {
	animation-name: bigEntrance;
	-webkit-animation-name: bigEntrance;
	animation-duration: 1.6s;
	-webkit-animation-duration: 1.6s;
	animation-timing-function: ease-out;
	-webkit-animation-timing-function: ease-out;
	visibility: visible !important
}

@keyframes bigEntrance {
	0% {
		transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
		opacity: 0.2
	}
	30% {
		transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
		opacity: 1
	}
	45% {
		transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
		opacity: 1
	}
	60% {
		transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
		opacity: 1
	}
	75% {
		transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
		opacity: 1
	}
	90% {
		transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
		opacity: 1
	}
	100% {
		transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
		opacity: 1
	}
}

@-webkit-keyframes bigEntrance {
	0% {
		-webkit-transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
		opacity: 0.2
	}
	30% {
		-webkit-transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
		opacity: 1
	}
	45% {
		-webkit-transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
		opacity: 1
	}
	60% {
		-webkit-transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
		opacity: 1
	}
	75% {
		-webkit-transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
		opacity: 1
	}
	90% {
		-webkit-transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
		opacity: 1
	}
	100% {
		-webkit-transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
		opacity: 1
	}
}

.hatch {
	animation-name: hatch;
	-webkit-animation-name: hatch;
	animation-duration: 2s;
	-webkit-animation-duration: 2s;
	animation-timing-function: ease-in-out;
	-webkit-animation-timing-function: ease-in-out;
	transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	-webkit-transform-origin: 50% 100%;
	visibility: visible !important
}

@keyframes hatch {
	0% {
		transform: rotate(0deg) scaleY(0.6)
	}
	20% {
		transform: rotate(-2deg) scaleY(1.05)
	}
	35% {
		transform: rotate(2deg) scaleY(1)
	}
	50% {
		transform: rotate(-2deg)
	}
	65% {
		transform: rotate(1deg)
	}
	80% {
		transform: rotate(-1deg)
	}
	100% {
		transform: rotate(0deg)
	}
}

@-webkit-keyframes hatch {
	0% {
		-webkit-transform: rotate(0deg) scaleY(0.6)
	}
	20% {
		-webkit-transform: rotate(-2deg) scaleY(1.05)
	}
	35% {
		-webkit-transform: rotate(2deg) scaleY(1)
	}
	50% {
		-webkit-transform: rotate(-2deg)
	}
	65% {
		-webkit-transform: rotate(1deg)
	}
	80% {
		-webkit-transform: rotate(-1deg)
	}
	100% {
		-webkit-transform: rotate(0deg)
	}
}

.bounce {
	animation-name: bounce;
	-webkit-animation-name: bounce;
	animation-duration: 1.6s;
	-webkit-animation-duration: 1.6s;
	animation-timing-function: ease;
	-webkit-animation-timing-function: ease;
	transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	-webkit-transform-origin: 50% 100%
}

@keyframes bounce {
	0% {
		transform: translateY(0%) scaleY(0.6)
	}
	60% {
		transform: translateY(-100%) scaleY(1.1)
	}
	70% {
		transform: translateY(0%) scaleY(0.95) scaleX(1.05)
	}
	80% {
		transform: translateY(0%) scaleY(1.05) scaleX(1)
	}
	90% {
		transform: translateY(0%) scaleY(0.95) scaleX(1)
	}
	100% {
		transform: translateY(0%) scaleY(1) scaleX(1)
	}
}

@-webkit-keyframes bounce {
	0% {
		-webkit-transform: translateY(0%) scaleY(0.6)
	}
	60% {
		-webkit-transform: translateY(-100%) scaleY(1.1)
	}
	70% {
		-webkit-transform: translateY(0%) scaleY(0.95) scaleX(1.05)
	}
	80% {
		-webkit-transform: translateY(0%) scaleY(1.05) scaleX(1)
	}
	90% {
		-webkit-transform: translateY(0%) scaleY(0.95) scaleX(1)
	}
	100% {
		-webkit-transform: translateY(0%) scaleY(1) scaleX(1)
	}
}

.pulse {
	animation-name: pulse;
	-webkit-animation-name: pulse;
	animation-duration: 1.5s;
	-webkit-animation-duration: 1.5s;
	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite
}

@keyframes pulse {
	0% {
		transform: scale(0.9);
		opacity: 0.7
	}
	50% {
		transform: scale(1);
		opacity: 1
	}
	100% {
		transform: scale(0.9);
		opacity: 0.7
	}
}

@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale(0.95);
		opacity: 0.7
	}
	50% {
		-webkit-transform: scale(1);
		opacity: 1
	}
	100% {
		-webkit-transform: scale(0.95);
		opacity: 0.7
	}
}

.floating {
	animation-name: floating;
	-webkit-animation-name: floating;
	animation-duration: 1.5s;
	-webkit-animation-duration: 1.5s;
	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite
}

@keyframes floating {
	0% {
		transform: translateY(0%)
	}
	50% {
		transform: translateY(8%)
	}
	100% {
		transform: translateY(0%)
	}
}

@-webkit-keyframes floating {
	0% {
		-webkit-transform: translateY(0%)
	}
	50% {
		-webkit-transform: translateY(8%)
	}
	100% {
		-webkit-transform: translateY(0%)
	}
}

.tossing {
	animation-name: tossing;
	-webkit-animation-name: tossing;
	animation-duration: 2.5s;
	-webkit-animation-duration: 2.5s;
	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite
}

@keyframes tossing {
	0% {
		transform: rotate(-4deg)
	}
	50% {
		transform: rotate(4deg)
	}
	100% {
		transform: rotate(-4deg)
	}
}

@-webkit-keyframes tossing {
	0% {
		-webkit-transform: rotate(-4deg)
	}
	50% {
		-webkit-transform: rotate(4deg)
	}
	100% {
		-webkit-transform: rotate(-4deg)
	}
}

.pullUp {
	animation-name: pullUp;
	-webkit-animation-name: pullUp;
	animation-duration: 1.1s;
	-webkit-animation-duration: 1.1s;
	animation-timing-function: ease-out;
	-webkit-animation-timing-function: ease-out;
	transform-origin: 50% 100%;
	-ms-transform-origin: 50% 100%;
	-webkit-transform-origin: 50% 100%
}

@keyframes pullUp {
	0% {
		transform: scaleY(0.1)
	}
	40% {
		transform: scaleY(1.02)
	}
	60% {
		transform: scaleY(0.98)
	}
	80% {
		transform: scaleY(1.01)
	}
	100% {
		transform: scaleY(0.98)
	}
	80% {
		transform: scaleY(1.01)
	}
	100% {
		transform: scaleY(1)
	}
}

@-webkit-keyframes pullUp {
	0% {
		-webkit-transform: scaleY(0.1)
	}
	40% {
		-webkit-transform: scaleY(1.02)
	}
	60% {
		-webkit-transform: scaleY(0.98)
	}
	80% {
		-webkit-transform: scaleY(1.01)
	}
	100% {
		-webkit-transform: scaleY(0.98)
	}
	80% {
		-webkit-transform: scaleY(1.01)
	}
	100% {
		-webkit-transform: scaleY(1)
	}
}

.pullDown {
	animation-name: pullDown;
	-webkit-animation-name: pullDown;
	animation-duration: 1.1s;
	-webkit-animation-duration: 1.1s;
	animation-timing-function: ease-out;
	-webkit-animation-timing-function: ease-out;
	transform-origin: 50% 0%;
	-ms-transform-origin: 50% 0%;
	-webkit-transform-origin: 50% 0%
}

@keyframes pullDown {
	0% {
		transform: scaleY(0.1)
	}
	40% {
		transform: scaleY(1.02)
	}
	60% {
		transform: scaleY(0.98)
	}
	80% {
		transform: scaleY(1.01)
	}
	100% {
		transform: scaleY(0.98)
	}
	80% {
		transform: scaleY(1.01)
	}
	100% {
		transform: scaleY(1)
	}
}

@-webkit-keyframes pullDown {
	0% {
		-webkit-transform: scaleY(0.1)
	}
	40% {
		-webkit-transform: scaleY(1.02)
	}
	60% {
		-webkit-transform: scaleY(0.98)
	}
	80% {
		-webkit-transform: scaleY(1.01)
	}
	100% {
		-webkit-transform: scaleY(0.98)
	}
	80% {
		-webkit-transform: scaleY(1.01)
	}
	100% {
		-webkit-transform: scaleY(1)
	}
}

.stretchLeft {
	animation-name: stretchLeft;
	-webkit-animation-name: stretchLeft;
	animation-duration: 1.5s;
	-webkit-animation-duration: 1.5s;
	animation-timing-function: ease-out;
	-webkit-animation-timing-function: ease-out;
	transform-origin: 100% 0%;
	-ms-transform-origin: 100% 0%;
	-webkit-transform-origin: 100% 0%
}

@keyframes stretchLeft {
	0% {
		transform: scaleX(0.3)
	}
	40% {
		transform: scaleX(1.02)
	}
	60% {
		transform: scaleX(0.98)
	}
	80% {
		transform: scaleX(1.01)
	}
	100% {
		transform: scaleX(0.98)
	}
	80% {
		transform: scaleX(1.01)
	}
	100% {
		transform: scaleX(1)
	}
}

@-webkit-keyframes stretchLeft {
	0% {
		-webkit-transform: scaleX(0.3)
	}
	40% {
		-webkit-transform: scaleX(1.02)
	}
	60% {
		-webkit-transform: scaleX(0.98)
	}
	80% {
		-webkit-transform: scaleX(1.01)
	}
	100% {
		-webkit-transform: scaleX(0.98)
	}
	80% {
		-webkit-transform: scaleX(1.01)
	}
	100% {
		-webkit-transform: scaleX(1)
	}
}

.stretchRight {
	animation-name: stretchRight;
	-webkit-animation-name: stretchRight;
	animation-duration: 1.5s;
	-webkit-animation-duration: 1.5s;
	animation-timing-function: ease-out;
	-webkit-animation-timing-function: ease-out;
	transform-origin: 0% 0%;
	-ms-transform-origin: 0% 0%;
	-webkit-transform-origin: 0% 0%
}

@keyframes stretchRight {
	0% {
		transform: scaleX(0.3)
	}
	40% {
		transform: scaleX(1.02)
	}
	60% {
		transform: scaleX(0.98)
	}
	80% {
		transform: scaleX(1.01)
	}
	100% {
		transform: scaleX(0.98)
	}
	80% {
		transform: scaleX(1.01)
	}
	100% {
		transform: scaleX(1)
	}
}

@-webkit-keyframes stretchRight {
	0% {
		-webkit-transform: scaleX(0.3)
	}
	40% {
		-webkit-transform: scaleX(1.02)
	}
	60% {
		-webkit-transform: scaleX(0.98)
	}
	80% {
		-webkit-transform: scaleX(1.01)
	}
	100% {
		-webkit-transform: scaleX(0.98)
	}
	80% {
		-webkit-transform: scaleX(1.01)
	}
	100% {
		-webkit-transform: scaleX(1)
	}
}

.page-loader-wrapper {
	z-index: 99999999;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	text-align: center
}

.page-loader-wrapper p {
	margin-top: 10px;
	color: #fff
}

.page-loader-wrapper .loader {
	position: relative;
	top: calc(40% - 30px)
}

.navbar {
	font-family: "Montserrat", sans-serif;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	border-radius: 0;
	border: none;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 11;
	width: calc(100% - 250px);
	padding: 0
}

.navbar .navbar-brand {
	white-space: nowrap;
	-ms-text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	overflow: hidden
}

.navbar .navbar-toggle {
	text-decoration: none;
	color: #fff;
	width: 20px;
	height: 20px;
	margin-top: -4px;
	margin-right: 17px
}

.navbar .navbar-toggle:before {
	content: '\E8D5';
	font-family: 'Material Icons';
	font-size: 26px
}

.navbar .navbar-collapse.in {
	overflow: visible
}

.ls-toggle-menu .sidebar {
	margin-left: -300px
}

.ls-toggle-menu .navbar {
	width: 100%
}

.ls-closed .sidebar {
	margin-left: -300px
}

.ls-closed .bars {
	line-height: 43px;
	color: #424242 !important
}

.ls-closed .bars:after,
.ls-closed .bars:before {
	font-family: 'Material Icons';
	font-size: 24px;
	position: absolute;
	top: 9px;
	left: 12px;
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	-webkit-transform: scale(0);
	transform: scale(0);
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s
}

.ls-closed .bars:before {
	content: '\E5D2';
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	-webkit-transform: scale(1);
	transform: scale(1)
}

.ls-closed .bars:after {
	content: '\E5C4';
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	-webkit-transform: scale(0);
	transform: scale(0)
}

.overlay-open .bars:before {
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	-webkit-transform: scale(0);
	transform: scale(0)
}

.overlay-open .bars:after {
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	-webkit-transform: scale(1);
	transform: scale(1)
}

.navbar-header .navbar-brand {
	font-size: 18px
}

.navbar-header .bars {
	float: left;
	text-decoration: none
}

.navbar-nav.navbar-left {
	vertical-align: top;
	width: 100%
}

.navbar-nav.navbar-left .dropdown-menu {
	left: 0;
	right: auto;
	position: absolute
}

.navbar-nav.navbar-left .dropdown-menu ul.menu li {
	list-style: none;
	border-bottom: none
}

.navbar-nav.navbar-left .dropdown-menu ul.menu .media img {
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px
}

.navbar-nav.navbar-left .dropdown-menu ul.menu .media .media-body {
	width: 250px
}

.navbar-nav.navbar-left .dropdown-menu ul.menu .media .media-body .name {
	font-weight: 600
}

.navbar-nav.navbar-left .dropdown-menu ul.menu .media .media-body .name .time {
	font-weight: 400;
	float: right;
	font-size: 12px
}

.navbar-nav.navbar-left .dropdown-menu ul.menu .media .media-body .message {
	font-size: 13px;
	line-height: 15px
}

.navbar-nav.navbar-left .input-group {
	padding-top: 9px
}

.navbar-nav.navbar-left .input-group .form-control {
	background-color: #fff;
	color: #424242;
	border: 1px solid #fff;
	border-right: none
}

.navbar-nav.navbar-left .input-group .form-control~.input-group-addon {
	background-color: #fff;
	color: #424242;
	border: 1px solid #fff;
	border-left: none
}

.navbar-nav.navbar-left .input-group .input-group-addon {
	background-color: transparent;
	color: #fff
}

.navbar-nav>li>a {
	padding: 0px 12px;
	line-height: 60px
}

.col-red .navbar .navbar-brand,
.col-red .navbar .navbar-brand:hover,
.col-red .navbar .navbar-brand:active,
.col-red .navbar .navbar-brand:focus {
	color: #fff
}

.col-red .navbar .nav>li>a:hover,
.col-red .navbar .nav>li>a:focus,
.col-red .navbar .nav .open>a,
.col-red .navbar .nav .open>a:hover,
.col-red .navbar .nav .open>a:focus {
	background-color: rgba(0, 0, 0, 0.05)
}

.col-red .navbar .nav>li>a {
	color: #fff
}

.col-red .navbar .bars {
	color: #fff;
	float: left;
	padding: 10px 20px;
	font-size: 22px;
	margin-right: 10px;
	margin-left: -10px;
	margin-top: 4px
}

.col-red .navbar .bars:hover {
	background-color: rgba(0, 0, 0, 0.08)
}

.col-pink .navbar .navbar-brand,
.col-pink .navbar .navbar-brand:hover,
.col-pink .navbar .navbar-brand:active,
.col-pink .navbar .navbar-brand:focus {
	color: #fff
}

.col-pink .navbar .nav>li>a:hover,
.col-pink .navbar .nav>li>a:focus,
.col-pink .navbar .nav .open>a,
.col-pink .navbar .nav .open>a:hover,
.col-pink .navbar .nav .open>a:focus {
	background-color: rgba(0, 0, 0, 0.05)
}

.col-pink .navbar .nav>li>a {
	color: #fff
}

.col-pink .navbar .bars {
	color: #fff;
	float: left;
	padding: 10px 20px;
	font-size: 22px;
	margin-right: 10px;
	margin-left: -10px;
	margin-top: 4px
}

.col-pink .navbar .bars:hover {
	background-color: rgba(0, 0, 0, 0.08)
}

.col-purple .navbar .navbar-brand,
.col-purple .navbar .navbar-brand:hover,
.col-purple .navbar .navbar-brand:active,
.col-purple .navbar .navbar-brand:focus {
	color: #fff
}

.col-purple .navbar .nav>li>a:hover,
.col-purple .navbar .nav>li>a:focus,
.col-purple .navbar .nav .open>a,
.col-purple .navbar .nav .open>a:hover,
.col-purple .navbar .nav .open>a:focus {
	background-color: rgba(0, 0, 0, 0.05)
}

.col-purple .navbar .nav>li>a {
	color: #fff
}

.col-purple .navbar .bars {
	color: #fff;
	float: left;
	padding: 10px 20px;
	font-size: 22px;
	margin-right: 10px;
	margin-left: -10px;
	margin-top: 4px
}

.col-purple .navbar .bars:hover {
	background-color: rgba(0, 0, 0, 0.08)
}

.col-deep-purple .navbar .navbar-brand,
.col-deep-purple .navbar .navbar-brand:hover,
.col-deep-purple .navbar .navbar-brand:active,
.col-deep-purple .navbar .navbar-brand:focus {
	color: #fff
}

.col-deep-purple .navbar .nav>li>a:hover,
.col-deep-purple .navbar .nav>li>a:focus,
.col-deep-purple .navbar .nav .open>a,
.col-deep-purple .navbar .nav .open>a:hover,
.col-deep-purple .navbar .nav .open>a:focus {
	background-color: rgba(0, 0, 0, 0.05)
}

.col-deep-purple .navbar .nav>li>a {
	color: #fff
}

.col-deep-purple .navbar .bars {
	color: #fff;
	float: left;
	padding: 10px 20px;
	font-size: 22px;
	margin-right: 10px;
	margin-left: -10px;
	margin-top: 4px
}

.col-deep-purple .navbar .bars:hover {
	background-color: rgba(0, 0, 0, 0.08)
}

.col-blue .navbar .navbar-brand,
.col-blue .navbar .navbar-brand:hover,
.col-blue .navbar .navbar-brand:active,
.col-blue .navbar .navbar-brand:focus {
	color: #fff
}

.col-blue .navbar .nav>li>a:hover,
.col-blue .navbar .nav>li>a:focus,
.col-blue .navbar .nav .open>a,
.col-blue .navbar .nav .open>a:hover,
.col-blue .navbar .nav .open>a:focus {
	background-color: rgba(0, 0, 0, 0.05)
}

.col-blue .navbar .nav>li>a {
	color: #fff
}

.col-blue .navbar .bars {
	color: #fff;
	float: left;
	padding: 10px 20px;
	font-size: 22px;
	margin-right: 10px;
	margin-left: -10px;
	margin-top: 4px
}

.col-blue .navbar .bars:hover {
	background-color: rgba(0, 0, 0, 0.08)
}

.col-cyan .navbar .navbar-brand,
.col-cyan .navbar .navbar-brand:hover,
.col-cyan .navbar .navbar-brand:active,
.col-cyan .navbar .navbar-brand:focus {
	color: #fff
}

.col-cyan .navbar .nav>li>a:hover,
.col-cyan .navbar .nav>li>a:focus,
.col-cyan .navbar .nav .open>a,
.col-cyan .navbar .nav .open>a:hover,
.col-cyan .navbar .nav .open>a:focus {
	background-color: rgba(0, 0, 0, 0.05)
}

.col-cyan .navbar .nav>li>a {
	color: #fff
}

.col-cyan .navbar .bars {
	color: #fff;
	float: left;
	padding: 10px 20px;
	font-size: 22px;
	margin-right: 10px;
	margin-left: -10px;
	margin-top: 4px
}

.col-cyan .navbar .bars:hover {
	background-color: rgba(0, 0, 0, 0.08)
}

.col-green .navbar .navbar-brand,
.col-green .navbar .navbar-brand:hover,
.col-green .navbar .navbar-brand:active,
.col-green .navbar .navbar-brand:focus {
	color: #fff
}

.col-green .navbar .nav>li>a:hover,
.col-green .navbar .nav>li>a:focus,
.col-green .navbar .nav .open>a,
.col-green .navbar .nav .open>a:hover,
.col-green .navbar .nav .open>a:focus {
	background-color: rgba(0, 0, 0, 0.05)
}

.col-green .navbar .nav>li>a {
	color: #fff
}

.col-green .navbar .bars {
	color: #fff;
	float: left;
	padding: 10px 20px;
	font-size: 22px;
	margin-right: 10px;
	margin-left: -10px;
	margin-top: 4px
}

.col-green .navbar .bars:hover {
	background-color: rgba(0, 0, 0, 0.08)
}

.col-light-green .navbar .navbar-brand,
.col-light-green .navbar .navbar-brand:hover,
.col-light-green .navbar .navbar-brand:active,
.col-light-green .navbar .navbar-brand:focus {
	color: #fff
}

.col-light-green .navbar .nav>li>a:hover,
.col-light-green .navbar .nav>li>a:focus,
.col-light-green .navbar .nav .open>a,
.col-light-green .navbar .nav .open>a:hover,
.col-light-green .navbar .nav .open>a:focus {
	background-color: rgba(0, 0, 0, 0.05)
}

.col-light-green .navbar .nav>li>a {
	color: #fff
}

.col-light-green .navbar .bars {
	color: #fff;
	float: left;
	padding: 10px 20px;
	font-size: 22px;
	margin-right: 10px;
	margin-left: -10px;
	margin-top: 4px
}

.col-light-green .navbar .bars:hover {
	background-color: rgba(0, 0, 0, 0.08)
}

.col-yellow .navbar .navbar-brand,
.col-yellow .navbar .navbar-brand:hover,
.col-yellow .navbar .navbar-brand:active,
.col-yellow .navbar .navbar-brand:focus {
	color: #fff
}

.col-yellow .navbar .nav>li>a:hover,
.col-yellow .navbar .nav>li>a:focus,
.col-yellow .navbar .nav .open>a,
.col-yellow .navbar .nav .open>a:hover,
.col-yellow .navbar .nav .open>a:focus {
	background-color: rgba(0, 0, 0, 0.05)
}

.col-yellow .navbar .nav>li>a {
	color: #fff
}

.col-yellow .navbar .bars {
	color: #fff;
	float: left;
	padding: 10px 20px;
	font-size: 22px;
	margin-right: 10px;
	margin-left: -10px;
	margin-top: 4px
}

.col-yellow .navbar .bars:hover {
	background-color: rgba(0, 0, 0, 0.08)
}

.col-orange .navbar .navbar-brand,
.col-orange .navbar .navbar-brand:hover,
.col-orange .navbar .navbar-brand:active,
.col-orange .navbar .navbar-brand:focus {
	color: #fff
}

.col-orange .navbar .nav>li>a:hover,
.col-orange .navbar .nav>li>a:focus,
.col-orange .navbar .nav .open>a,
.col-orange .navbar .nav .open>a:hover,
.col-orange .navbar .nav .open>a:focus {
	background-color: rgba(0, 0, 0, 0.05)
}

.col-orange .navbar .nav>li>a {
	color: #fff
}

.col-orange .navbar .bars {
	color: #fff;
	float: left;
	padding: 10px 20px;
	font-size: 22px;
	margin-right: 10px;
	margin-left: -10px;
	margin-top: 4px
}

.col-orange .navbar .bars:hover {
	background-color: rgba(0, 0, 0, 0.08)
}

.col-deep-orange .navbar .navbar-brand,
.col-deep-orange .navbar .navbar-brand:hover,
.col-deep-orange .navbar .navbar-brand:active,
.col-deep-orange .navbar .navbar-brand:focus {
	color: #fff
}

.col-deep-orange .navbar .nav>li>a:hover,
.col-deep-orange .navbar .nav>li>a:focus,
.col-deep-orange .navbar .nav .open>a,
.col-deep-orange .navbar .nav .open>a:hover,
.col-deep-orange .navbar .nav .open>a:focus {
	background-color: rgba(0, 0, 0, 0.05)
}

.col-deep-orange .navbar .nav>li>a {
	color: #fff
}

.col-deep-orange .navbar .bars {
	color: #fff;
	float: left;
	padding: 10px 20px;
	font-size: 22px;
	margin-right: 10px;
	margin-left: -10px;
	margin-top: 4px
}

.col-deep-orange .navbar .bars:hover {
	background-color: rgba(0, 0, 0, 0.08)
}

.col-grey .navbar .navbar-brand,
.col-grey .navbar .navbar-brand:hover,
.col-grey .navbar .navbar-brand:active,
.col-grey .navbar .navbar-brand:focus {
	color: #fff
}

.col-grey .navbar .nav>li>a:hover,
.col-grey .navbar .nav>li>a:focus,
.col-grey .navbar .nav .open>a,
.col-grey .navbar .nav .open>a:hover,
.col-grey .navbar .nav .open>a:focus {
	background-color: rgba(0, 0, 0, 0.05)
}

.col-grey .navbar .nav>li>a {
	color: #fff
}

.col-grey .navbar .bars {
	color: #fff;
	float: left;
	padding: 10px 20px;
	font-size: 22px;
	margin-right: 10px;
	margin-left: -10px;
	margin-top: 4px
}

.col-grey .navbar .bars:hover {
	background-color: rgba(0, 0, 0, 0.08)
}

.col-blue-grey .navbar .navbar-brand,
.col-blue-grey .navbar .navbar-brand:hover,
.col-blue-grey .navbar .navbar-brand:active,
.col-blue-grey .navbar .navbar-brand:focus {
	color: #fff
}

.col-blue-grey .navbar .nav>li>a:hover,
.col-blue-grey .navbar .nav>li>a:focus,
.col-blue-grey .navbar .nav .open>a,
.col-blue-grey .navbar .nav .open>a:hover,
.col-blue-grey .navbar .nav .open>a:focus {
	background-color: rgba(0, 0, 0, 0.05)
}

.col-blue-grey .navbar .nav>li>a {
	color: #fff
}

.col-blue-grey .navbar .bars {
	color: #fff;
	float: left;
	padding: 10px 20px;
	font-size: 22px;
	margin-right: 10px;
	margin-left: -10px;
	margin-top: 4px
}

.col-blue-grey .navbar .bars:hover {
	background-color: rgba(0, 0, 0, 0.08)
}

.col-indigo .navbar .navbar-brand,
.col-indigo .navbar .navbar-brand:hover,
.col-indigo .navbar .navbar-brand:active,
.col-indigo .navbar .navbar-brand:focus {
	color: #fff
}

.col-indigo .navbar .nav>li>a:hover,
.col-indigo .navbar .nav>li>a:focus,
.col-indigo .navbar .nav .open>a,
.col-indigo .navbar .nav .open>a:hover,
.col-indigo .navbar .nav .open>a:focus {
	background-color: rgba(0, 0, 0, 0.05)
}

.col-indigo .navbar .nav>li>a {
	color: #fff
}

.col-indigo .navbar .bars {
	color: #fff;
	float: left;
	padding: 10px 20px;
	font-size: 22px;
	margin-right: 10px;
	margin-left: -10px;
	margin-top: 4px
}

.col-indigo .navbar .bars:hover {
	background-color: rgba(0, 0, 0, 0.08)
}

.col-light-blue .navbar .navbar-brand,
.col-light-blue .navbar .navbar-brand:hover,
.col-light-blue .navbar .navbar-brand:active,
.col-light-blue .navbar .navbar-brand:focus {
	color: #fff
}

.col-light-blue .navbar .nav>li>a:hover,
.col-light-blue .navbar .nav>li>a:focus,
.col-light-blue .navbar .nav .open>a,
.col-light-blue .navbar .nav .open>a:hover,
.col-light-blue .navbar .nav .open>a:focus {
	background-color: rgba(0, 0, 0, 0.05)
}

.col-light-blue .navbar .nav>li>a {
	color: #fff
}

.col-light-blue .navbar .bars {
	color: #fff;
	float: left;
	padding: 10px 20px;
	font-size: 22px;
	margin-right: 10px;
	margin-left: -10px;
	margin-top: 4px
}

.col-light-blue .navbar .bars:hover {
	background-color: rgba(0, 0, 0, 0.08)
}

.col-teal .navbar .navbar-brand,
.col-teal .navbar .navbar-brand:hover,
.col-teal .navbar .navbar-brand:active,
.col-teal .navbar .navbar-brand:focus {
	color: #fff
}

.col-teal .navbar .nav>li>a:hover,
.col-teal .navbar .nav>li>a:focus,
.col-teal .navbar .nav .open>a,
.col-teal .navbar .nav .open>a:hover,
.col-teal .navbar .nav .open>a:focus {
	background-color: rgba(0, 0, 0, 0.05)
}

.col-teal .navbar .nav>li>a {
	color: #fff
}

.col-teal .navbar .bars {
	color: #fff;
	float: left;
	padding: 10px 20px;
	font-size: 22px;
	margin-right: 10px;
	margin-left: -10px;
	margin-top: 4px
}

.col-teal .navbar .bars:hover {
	background-color: rgba(0, 0, 0, 0.08)
}

.col-lime .navbar .navbar-brand,
.col-lime .navbar .navbar-brand:hover,
.col-lime .navbar .navbar-brand:active,
.col-lime .navbar .navbar-brand:focus {
	color: #fff
}

.col-lime .navbar .nav>li>a:hover,
.col-lime .navbar .nav>li>a:focus,
.col-lime .navbar .nav .open>a,
.col-lime .navbar .nav .open>a:hover,
.col-lime .navbar .nav .open>a:focus {
	background-color: rgba(0, 0, 0, 0.05)
}

.col-lime .navbar .nav>li>a {
	color: #fff
}

.col-lime .navbar .bars {
	color: #fff;
	float: left;
	padding: 10px 20px;
	font-size: 22px;
	margin-right: 10px;
	margin-left: -10px;
	margin-top: 4px
}

.col-lime .navbar .bars:hover {
	background-color: rgba(0, 0, 0, 0.08)
}

.col-amber .navbar .navbar-brand,
.col-amber .navbar .navbar-brand:hover,
.col-amber .navbar .navbar-brand:active,
.col-amber .navbar .navbar-brand:focus {
	color: #fff
}

.col-amber .navbar .nav>li>a:hover,
.col-amber .navbar .nav>li>a:focus,
.col-amber .navbar .nav .open>a,
.col-amber .navbar .nav .open>a:hover,
.col-amber .navbar .nav .open>a:focus {
	background-color: rgba(0, 0, 0, 0.05)
}

.col-amber .navbar .nav>li>a {
	color: #fff
}

.col-amber .navbar .bars {
	color: #fff;
	float: left;
	padding: 10px 20px;
	font-size: 22px;
	margin-right: 10px;
	margin-left: -10px;
	margin-top: 4px
}

.col-amber .navbar .bars:hover {
	background-color: rgba(0, 0, 0, 0.08)
}

.col-brown .navbar .navbar-brand,
.col-brown .navbar .navbar-brand:hover,
.col-brown .navbar .navbar-brand:active,
.col-brown .navbar .navbar-brand:focus {
	color: #fff
}

.col-brown .navbar .nav>li>a:hover,
.col-brown .navbar .nav>li>a:focus,
.col-brown .navbar .nav .open>a,
.col-brown .navbar .nav .open>a:hover,
.col-brown .navbar .nav .open>a:focus {
	background-color: rgba(0, 0, 0, 0.05)
}

.col-brown .navbar .nav>li>a {
	color: #fff
}

.col-brown .navbar .bars {
	color: #fff;
	float: left;
	padding: 10px 20px;
	font-size: 22px;
	margin-right: 10px;
	margin-left: -10px;
	margin-top: 4px
}

.col-brown .navbar .bars:hover {
	background-color: rgba(0, 0, 0, 0.08)
}

.col-blush .navbar .navbar-brand,
.col-blush .navbar .navbar-brand:hover,
.col-blush .navbar .navbar-brand:active,
.col-blush .navbar .navbar-brand:focus {
	color: #fff
}

.col-blush .navbar .nav>li>a:hover,
.col-blush .navbar .nav>li>a:focus,
.col-blush .navbar .nav .open>a,
.col-blush .navbar .nav .open>a:hover,
.col-blush .navbar .nav .open>a:focus {
	background-color: rgba(0, 0, 0, 0.05)
}

.col-blush .navbar .nav>li>a {
	color: #fff
}

.col-blush .navbar .bars {
	color: #fff;
	float: left;
	padding: 10px 20px;
	font-size: 22px;
	margin-right: 10px;
	margin-left: -10px;
	margin-top: 4px
}

.col-blush .navbar .bars:hover {
	background-color: rgba(0, 0, 0, 0.08)
}

.col-black .navbar .navbar-brand,
.col-black .navbar .navbar-brand:hover,
.col-black .navbar .navbar-brand:active,
.col-black .navbar .navbar-brand:focus {
	color: #fff
}

.col-black .navbar .nav>li>a:hover,
.col-black .navbar .nav>li>a:focus,
.col-black .navbar .nav .open>a,
.col-black .navbar .nav .open>a:hover,
.col-black .navbar .nav .open>a:focus {
	background-color: rgba(0, 0, 0, 0.05)
}

.col-black .navbar .nav>li>a {
	color: #fff
}

.col-black .navbar .bars {
	color: #fff;
	float: left;
	padding: 10px 20px;
	font-size: 22px;
	margin-right: 10px;
	margin-left: -10px;
	margin-top: 4px
}

.col-black .navbar .bars:hover {
	background-color: rgba(0, 0, 0, 0.08)
}

.col-white .navbar .navbar-brand,
.col-white .navbar .navbar-brand:hover,
.col-white .navbar .navbar-brand:active,
.col-white .navbar .navbar-brand:focus {
	color: #fff
}

.col-white .navbar .nav>li>a:hover,
.col-white .navbar .nav>li>a:focus,
.col-white .navbar .nav .open>a,
.col-white .navbar .nav .open>a:hover,
.col-white .navbar .nav .open>a:focus {
	background-color: rgba(0, 0, 0, 0.05)
}

.col-white .navbar .nav>li>a {
	color: #fff
}

.col-white .navbar .bars {
	color: #fff;
	float: left;
	padding: 10px 20px;
	font-size: 22px;
	margin-right: 10px;
	margin-left: -10px;
	margin-top: 4px
}

.col-white .navbar .bars:hover {
	background-color: rgba(0, 0, 0, 0.08)
}

@media (max-width: 1169px) {
	.navbar-header {
		width: auto
	}
	.ls-toggle-btn {
		display: none !important
	}
}

@media (max-width: 767px) {
	.navbar .navbar-toggle {
		position: relative;
		margin-right: 12px;
		margin-top: 10px
	}
	.navbar .navbar-toggle:before {
		position: absolute;
		top: 0;
		left: 0
	}
}

@media (max-width: 500px) {
	.navbar-header .navbar-brand span {
		display: none
	}
	.block-header h2 {
		margin-bottom: 5px
	}
	.block-header h2 small {
		display: inline-block !important
	}
	.navbar-nav.navbar-left .dropdown-menu {
		left: -120px
	}
}

.overlay {
	position: fixed;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	display: none;
	z-index: 12
}

.overlay-open .sidebar {
	z-index: 13
}

.sidebar {
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
	font-family: "Montserrat", sans-serif;
	background: #fff;
	width: 250px;
	height: 100vh;
	position: fixed;
	top: 0px;
	left: 0;
	box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
	z-index: 11
}

.sidebar .nav-tabs {
	padding: 7px 10px
}

.sidebar .user-info {
	position: relative;
	border-bottom: 1px solid #eee
}

.sidebar .user-info .image img {
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	border-radius: 50%;
	width: 80px;
	vertical-align: bottom !important;
	border: 3px solid #fff;
	box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2)
}

.sidebar .user-info a {
	display: inline-block !important
}

.sidebar .menu {
	position: relative
}

.sidebar .menu .list {
	list-style: none;
	margin: 0 15px
}

.sidebar .menu .list li.active>:first-child span {
	font-weight: 700
}

.sidebar .menu .list .header {
	font-weight: 700;
	color: #455a64;
	padding: 8px 12px;
	position: relative
}

.sidebar .menu .list .header:before {
	content: "--";
	position: absolute;
	left: 0;
	top: 7px
}

.sidebar .menu .list i.material-icons {
	margin-top: 4px
}

.sidebar .menu .list .menu-toggle:after,
.sidebar .menu .list .menu-toggle:before {
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	-webkit-transform: scale(0);
	transform: scale(0);
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	font-family: 'Material-Design-Iconic-Font';
	position: absolute;
	top: calc(50% - 11px);
	right: 17px
}

.sidebar .menu .list .menu-toggle:before {
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	-webkit-transform: scale(1);
	transform: scale(1);
	content: '\f2fb'
}

.sidebar .menu .list .menu-toggle:after {
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	-webkit-transform: scale(0);
	transform: scale(0);
	content: '\f2f9'
}

.sidebar .menu .list .menu-toggle.toggled:before {
	content: '\f2f9';
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	-webkit-transform: scale(0);
	transform: scale(0);
	font-family: 'Material-Design-Iconic-Font'
}

.sidebar .menu .list .menu-toggle.toggled:after {
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	-webkit-transform: scale(1);
	transform: scale(1)
}

.sidebar .menu .list a {
	color: #546e7a;
	position: relative;
	padding: 14px 10px
}

.sidebar .menu .list a:hover,
.sidebar .menu .list a:active,
.sidebar .menu .list a:focus {
	text-decoration: none !important
}

.sidebar .menu .list a i {
	width: 16px
}

.sidebar .menu .list a small {
	position: absolute;
	top: calc(50% - 7.5px);
	right: 15px
}

.sidebar .menu .list a span {
	margin: 0 0 0 12px
}

.sidebar .menu .list .ml-menu {
	list-style: none;
	display: none
}

.sidebar .menu .list .ml-menu span {
	font-weight: normal;
	margin: 3px 0 1px 6px
}

.sidebar .menu .list .ml-menu li a {
	padding-left: 40px;
	padding-top: 7px;
	padding-bottom: 7px
}

.sidebar .menu .list .ml-menu li a:before {
	content: '\f30f';
	position: absolute;
	left: 14px;
	font-family: 'Material-Design-Iconic-Font';
	color: #999;
	top: 8px
}

.sidebar .menu .list .ml-menu li.active a.toggled:not(.menu-toggle) {
	font-weight: 600
}

.sidebar .menu .progress-container {
	margin: 12px
}

.right-sidebar {
	-webkit-box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
	-ms-box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
	box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
	-moz-transition: .5s;
	-o-transition: .5s;
	-webkit-transition: .5s;
	transition: .5s;
	width: 280px;
	height: calc(100vh - 0px);
	position: fixed;
	right: -300px;
	top: 0px;
	background: #fff;
	z-index: 13 !important;
	overflow: hidden
}

.right-sidebar.open {
	right: 0
}

.right-sidebar .card {
	padding: 10px 20px
}

.right-sidebar .card h6 {
	border-bottom: 1px solid #eee
}

.right-sidebar .nav-tabs {
	padding: 11px 20px;
	border-bottom: 1px solid #eee
}

.right-sidebar .nav-tabs .nav-link {
	padding: 8px 20px
}

.right-sidebar .choose-skin li {
	position: relative;
	cursor: pointer
}

.right-sidebar .choose-skin li.active:after {
	color: #fff;
	font-family: 'Material-Design-Iconic-Font';
	position: absolute;
	top: 2px;
	right: 14px;
	content: '\f26b'
}

.right-sidebar .choose-skin li div {
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	width: 30px;
	height: 25px
}

.right-sidebar .choose-skin li span {
	position: relative;
	bottom: 7px;
	left: 5px
}

.right-sidebar .choose-skin .red {
	background-color: #ec3b57
}

.right-sidebar .choose-skin .pink {
	background-color: #E91E63
}

.right-sidebar .choose-skin .purple {
	background-color: #6572b8
}

.right-sidebar .choose-skin .deep-purple {
	background-color: #673AB7
}

.right-sidebar .choose-skin .blue {
	background-color: #60bafd
}

.right-sidebar .choose-skin .cyan {
	background-color: #00cfd1
}

.right-sidebar .choose-skin .green {
	background-color: #50d38a
}

.right-sidebar .choose-skin .light-green {
	background-color: #8BC34A
}

.right-sidebar .choose-skin .yellow {
	background-color: #ffe821
}

.right-sidebar .choose-skin .orange {
	background-color: #ffc323
}

.right-sidebar .choose-skin .deep-orange {
	background-color: #f83600
}

.right-sidebar .choose-skin .grey {
	background-color: #9E9E9E
}

.right-sidebar .choose-skin .blue-grey {
	background-color: #607D8B
}

.right-sidebar .choose-skin .indigo {
	background-color: #3F51B5
}

.right-sidebar .choose-skin .light-blue {
	background-color: #03A9F4
}

.right-sidebar .choose-skin .teal {
	background-color: #009688
}

.right-sidebar .choose-skin .lime {
	background-color: #CDDC39
}

.right-sidebar .choose-skin .amber {
	background-color: #FFC107
}

.right-sidebar .choose-skin .brown {
	background-color: #795548
}

.right-sidebar .choose-skin .blush {
	background-color: #ff758e
}

.right-sidebar .choose-skin .black {
	background-color: #000
}

.right-sidebar .choose-skin .white {
	background-color: #fff
}

.right_chat li {
	position: relative
}

.right_chat li.offline .status {
	background: #FF9800
}

.right_chat li.online .status {
	background: #8BC34A
}

.right_chat li.me .status {
	background: #0498bd
}

.right_chat .media .media-object {
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	-ms-border-radius: 50px;
	border-radius: 50px;
	width: 40px
}

.right_chat .media .name {
	font-weight: 600;
	color: #424242;
	margin: 0px
}

.right_chat .media .message {
	color: #78909c;
	max-width: 180px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden
}

.right_chat .media .status {
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	-ms-border-radius: 50px;
	border-radius: 50px;
	position: absolute;
	left: 30px;
	top: 30px;
	width: 11px;
	height: 11px;
	min-width: inherit;
	border: 2px solid #fff
}

.menu_dark .list {
	color: #78909c
}

.menu_dark .sidebar {
	background: #212121
}

.menu_dark .sidebar .user-info {
	border-bottom: 1px solid #37474f
}

.menu_dark .sidebar .user-info .detail h4 {
	color: #fff
}

.menu_dark .sidebar .user-info .detail small {
	color: #bdbdbd
}

.menu_dark .sidebar .menu .list a {
	color: #78909c
}

/* .menu_img .sidebar:after {
	position: absolute;
	left: 0;
	top: 0;
	content: '';
	background-image: url(../../../assets/images/left-bg.jpg);
	background-size: cover;
	background-position: center center;
	z-index: -1;
	width: 100%;
	opacity: 0.3;
	height: 100%
} */

@media screen and (max-width: 1169px) {
	.navbar {
		width: 100%
	}
}

@media screen and (max-width: 1024px) {
	.right-sidebar .nav-tabs {
		padding: 7px 15px
	}
	.right-sidebar .nav-tabs li {
		display: inline-block
	}
	.right-sidebar .nav-tabs li a {
		margin: 0px !important
	}
}

@media screen and (max-width: 992px) {
	.sidebar .nav-tabs {
		padding-left: 15px;
		padding-right: 15px
	}
	.sidebar .nav-tabs .nav-item {
		display: inline-block
	}
}

@media screen and (max-width: 767px) {
	.right-sidebar .slim_scroll {
		padding-bottom: 40px
	}
	.sidebar .menu .list li:last-child {
		padding-bottom: 40px
	}
}

.m-l--125 {
	margin-left: -125px
}

.m-t--125 {
	margin-top: -125px
}

.m-r--125 {
	margin-right: -125px
}

.m-b--125 {
	margin-bottom: -125px
}

.m-l--120 {
	margin-left: -120px
}

.m-t--120 {
	margin-top: -120px
}

.m-r--120 {
	margin-right: -120px
}

.m-b--120 {
	margin-bottom: -120px
}

.m-l--115 {
	margin-left: -115px
}

.m-t--115 {
	margin-top: -115px
}

.m-r--115 {
	margin-right: -115px
}

.m-b--115 {
	margin-bottom: -115px
}

.m-l--110 {
	margin-left: -110px
}

.m-t--110 {
	margin-top: -110px
}

.m-r--110 {
	margin-right: -110px
}

.m-b--110 {
	margin-bottom: -110px
}

.m-l--105 {
	margin-left: -105px
}

.m-t--105 {
	margin-top: -105px
}

.m-r--105 {
	margin-right: -105px
}

.m-b--105 {
	margin-bottom: -105px
}

.m-l--100 {
	margin-left: -100px
}

.m-t--100 {
	margin-top: -100px
}

.m-r--100 {
	margin-right: -100px
}

.m-b--100 {
	margin-bottom: -100px
}

.m-l--95 {
	margin-left: -95px
}

.m-t--95 {
	margin-top: -95px
}

.m-r--95 {
	margin-right: -95px
}

.m-b--95 {
	margin-bottom: -95px
}

.m-l--90 {
	margin-left: -90px
}

.m-t--90 {
	margin-top: -90px
}

.m-r--90 {
	margin-right: -90px
}

.m-b--90 {
	margin-bottom: -90px
}

.m-l--85 {
	margin-left: -85px
}

.m-t--85 {
	margin-top: -85px
}

.m-r--85 {
	margin-right: -85px
}

.m-b--85 {
	margin-bottom: -85px
}

.m-l--80 {
	margin-left: -80px
}

.m-t--80 {
	margin-top: -80px
}

.m-r--80 {
	margin-right: -80px
}

.m-b--80 {
	margin-bottom: -80px
}

.m-l--75 {
	margin-left: -75px
}

.m-t--75 {
	margin-top: -75px
}

.m-r--75 {
	margin-right: -75px
}

.m-b--75 {
	margin-bottom: -75px
}

.m-l--70 {
	margin-left: -70px
}

.m-t--70 {
	margin-top: -70px
}

.m-r--70 {
	margin-right: -70px
}

.m-b--70 {
	margin-bottom: -70px
}

.m-l--65 {
	margin-left: -65px
}

.m-t--65 {
	margin-top: -65px
}

.m-r--65 {
	margin-right: -65px
}

.m-b--65 {
	margin-bottom: -65px
}

.m-l--60 {
	margin-left: -60px
}

.m-t--60 {
	margin-top: -60px
}

.m-r--60 {
	margin-right: -60px
}

.m-b--60 {
	margin-bottom: -60px
}

.m-l--55 {
	margin-left: -55px
}

.m-t--55 {
	margin-top: -55px
}

.m-r--55 {
	margin-right: -55px
}

.m-b--55 {
	margin-bottom: -55px
}

.m-l--50 {
	margin-left: -50px
}

.m-t--50 {
	margin-top: -50px
}

.m-r--50 {
	margin-right: -50px
}

.m-b--50 {
	margin-bottom: -50px
}

.m-l--45 {
	margin-left: -45px
}

.m-t--45 {
	margin-top: -45px
}

.m-r--45 {
	margin-right: -45px
}

.m-b--45 {
	margin-bottom: -45px
}

.m-l--40 {
	margin-left: -40px
}

.m-t--40 {
	margin-top: -40px
}

.m-r--40 {
	margin-right: -40px
}

.m-b--40 {
	margin-bottom: -40px
}

.m-l--35 {
	margin-left: -35px
}

.m-t--35 {
	margin-top: -35px
}

.m-r--35 {
	margin-right: -35px
}

.m-b--35 {
	margin-bottom: -35px
}

.m-l--30 {
	margin-left: -30px
}

.m-t--30 {
	margin-top: -30px
}

.m-r--30 {
	margin-right: -30px
}

.m-b--30 {
	margin-bottom: -30px
}

.m-l--25 {
	margin-left: -25px
}

.m-t--25 {
	margin-top: -25px
}

.m-r--25 {
	margin-right: -25px
}

.m-b--25 {
	margin-bottom: -25px
}

.m-l--20 {
	margin-left: -20px
}

.m-t--20 {
	margin-top: -20px
}

.m-r--20 {
	margin-right: -20px
}

.m-b--20 {
	margin-bottom: -20px
}

.m-l--15 {
	margin-left: -15px
}

.m-t--15 {
	margin-top: -15px
}

.m-r--15 {
	margin-right: -15px
}

.m-b--15 {
	margin-bottom: -15px
}

.m-l--10 {
	margin-left: -10px
}

.m-t--10 {
	margin-top: -10px
}

.m-r--10 {
	margin-right: -10px
}

.m-b--10 {
	margin-bottom: -10px
}

.m-l--5 {
	margin-left: -5px
}

.m-t--5 {
	margin-top: -5px
}

.m-r--5 {
	margin-right: -5px
}

.m-b--5 {
	margin-bottom: -5px
}

.m-l-0,
.ls-toggle-menu section.content,
.ls-closed section.content,
.overlay-open .sidebar {
	margin-left: 0px
}

.m-t-0,
.sidebar .user-info .detail h4,
.card .card-inside-title:first-child,
.page-calendar .event-name h4,
.activity li a .info h4,
.weather .city,
.weather .city h3,
.weather2 .days-list .day h5,
.product-report .counter,
.contact .c_list .checkbox,
.profile-page #timeline .timeline .timeline-item .item-content,
.sprice h4,
.sprice2 h4 {
	margin-top: 0px
}

.m-r-0 {
	margin-right: 0px
}

.m-b-0,
.checkbox label,
.radio label,
.authentication .card-plain .header h5,
.navbar-nav.navbar-left .dropdown-menu ul.menu .media,
.navbar-nav.navbar-left .input-group,
.sidebar .user-info .detail h4,
.right-sidebar .card,
.right-sidebar .card ul,
.block-header .breadcrumb,
.card .header,
.page-calendar .event-name h6,
.page-calendar .event-name h4,
.page-calendar .event-name p,
.page-calendar .event-name address,
.activity li a .info h4,
.activity_2 h5,
.chat-wrapper .card .input-group,
.project_list .table,
.weather .city h3,
.inbox-widget .inbox-inner .inbox-item-info .inbox-date,
.inbox-widget .inbox-inner .inbox-item-info .author,
.inbox-widget .inbox-inner .inbox-item-info .inbox-message,
.members_profiles .table,
.profile_state li h4,
.social_media_table .table,
.new_friend_list,
.new_friend_list li .users_name,
.contact .action_bar .delete_all,
.contact .c_list .checkbox,
.contact .c_list .c_name,
.contact .c_list address,
.profile-page .profile-sub-header,
.profile-page #timeline .timeline .timeline-item {
	margin-bottom: 0px
}

.m-l-5,
.wizard .actions a {
	margin-left: 5px
}

.m-t-5,
.card .card-inside-title small,
.card .header h2 small,
.page-calendar .event-name address,
.team-info,
.new_friend_list li .users_name {
	margin-top: 5px
}

.m-r-5,
.page-calendar .event-name address i {
	margin-right: 5px
}

.m-b-5,
.weather .days li img {
	margin-bottom: 5px
}

.m-l-10,
.navbar-nav.navbar-left .input-group,
.chat-widget .chat-scroll-list li.left .chat-info,
.contact .c_list .c_name {
	margin-left: 10px
}

.m-t-10,
.member-card .social-links,
.profile_state li h4,
.w_calender span+span {
	margin-top: 10px
}

.m-r-10,
.ls-closed .bars:after,
.ls-closed .bars:before,
.right_chat .media .media-object,
.chat-widget .chat-scroll-list {
	margin-right: 10px
}

.m-b-10,
.sidebar .user-info .detail,
.top-report .progress,
.w_calender ul:nth-of-type(6),
.profile-page .profile-header .job_post {
	margin-bottom: 10px
}

.m-l-15,
.navbar-nav.navbar-left .dropdown-menu ul.menu .media .media-body,
.user_activity .b-accent {
	margin-left: 15px
}

.m-t-15,
.sidebar .menu .list .header {
	margin-top: 15px
}

.m-r-15,
.product-report .icon {
	margin-right: 15px
}

.m-b-15,
.right-sidebar .card h6,
.card .card-inside-title,
.activity li a,
.widget_2 h2,
.new_friend_list li {
	margin-bottom: 15px
}

.m-l-20,
.media.mright .media-right {
	margin-left: 20px
}

.m-t-20,
.w_calender ul:nth-of-type(2),
.w_calender ul:nth-of-type(3),
.w_calender ul:nth-of-type(4),
.w_calender ul:nth-of-type(5),
.w_calender ul:nth-of-type(6) {
	margin-top: 20px
}

.m-r-20,
.media.mleft .media-left,
.bs-example .media .media-left {
	margin-right: 20px
}

.m-b-20,
.authentication .card-plain .logo-container,
.media,
.user_activity .b-accent .sl-item .sl-content,
.chat-widget .chat-scroll-list li,
.weather .city,
.w_calender ul:nth-of-type(5) {
	margin-bottom: 20px
}

.m-l-25,
.user_activity .b-accent .sl-item .sl-content {
	margin-left: 25px
}

.m-t-25,
.card .card-inside-title {
	margin-top: 25px
}

.m-r-25,
.social_widget2 .data,
.sprice2 .data {
	margin-right: 25px
}

.m-b-25 {
	margin-bottom: 25px
}

.m-l-30,
.ls-closed .navbar-brand,
.social_widget2 .name,
.sprice2 .name {
	margin-left: -6px
}

.m-t-30,
.social_widget2 .name,
.sprice2 .name {
	margin-top: 30px
}

.m-r-30 {
	margin-right: 30px
}

.m-b-30 {
	margin-bottom: 30px
}

.m-l-35 {
	margin-left: 35px
}

.m-t-35 {
	margin-top: 35px
}

.m-r-35 {
	margin-right: 35px
}

.m-b-35 {
	margin-bottom: 35px
}

.m-l-40 {
	margin-left: 40px
}

.m-t-40 {
	margin-top: 40px
}

.m-r-40 {
	margin-right: 40px
}

.m-b-40 {
	margin-bottom: 40px
}

.m-l-45 {
	margin-left: 45px
}

.m-t-45 {
	margin-top: 45px
}

.m-r-45 {
	margin-right: 45px
}

.m-b-45 {
	margin-bottom: 45px
}

.m-l-50 {
	margin-left: 50px
}

.m-t-50 {
	margin-top: 50px
}

.m-r-50 {
	margin-right: 50px
}

.m-b-50 {
	margin-bottom: 50px
}

.m-l-55 {
	margin-left: 55px
}

.m-t-55 {
	margin-top: 55px
}

.m-r-55 {
	margin-right: 55px
}

.m-b-55 {
	margin-bottom: 55px
}

.m-l-60 {
	margin-left: 60px
}

.m-t-60 {
	margin-top: 60px
}

.m-r-60 {
	margin-right: 60px
}

.m-b-60 {
	margin-bottom: 60px
}

.m-l-65 {
	margin-left: 65px
}

.m-t-65 {
	margin-top: 65px
}

.m-r-65 {
	margin-right: 65px
}

.m-b-65 {
	margin-bottom: 65px
}

.m-l-70 {
	margin-left: 70px
}

.m-t-70 {
	margin-top: 70px
}

.m-r-70 {
	margin-right: 70px
}

.m-b-70 {
	margin-bottom: 70px
}

.m-l-75 {
	margin-left: 75px
}

.m-t-75 {
	margin-top: 75px
}

.m-r-75 {
	margin-right: 75px
}

.m-b-75 {
	margin-bottom: 75px
}

.m-l-80 {
	margin-left: 80px
}

.m-t-80 {
	margin-top: 80px
}

.m-r-80 {
	margin-right: 80px
}

.m-b-80 {
	margin-bottom: 80px
}

.m-l-85 {
	margin-left: 85px
}

.m-t-85 {
	margin-top: 85px
}

.m-r-85 {
	margin-right: 85px
}

.m-b-85 {
	margin-bottom: 85px
}

.m-l-90 {
	margin-left: 90px
}

.m-t-90 {
	margin-top: 90px
}

.m-r-90 {
	margin-right: 90px
}

.m-b-90 {
	margin-bottom: 90px
}

.m-l-95 {
	margin-left: 95px
}

.m-t-95 {
	margin-top: 95px
}

.m-r-95 {
	margin-right: 95px
}

.m-b-95 {
	margin-bottom: 95px
}

.m-l-100 {
	margin-left: 100px
}

.m-t-100 {
	margin-top: 100px
}

.m-r-100 {
	margin-right: 100px
}

.m-b-100 {
	margin-bottom: 100px
}

.m-l-105 {
	margin-left: 105px
}

.m-t-105 {
	margin-top: 105px
}

.m-r-105 {
	margin-right: 105px
}

.m-b-105 {
	margin-bottom: 105px
}

.m-l-110 {
	margin-left: 110px
}

.m-t-110 {
	margin-top: 110px
}

.m-r-110 {
	margin-right: 110px
}

.m-b-110 {
	margin-bottom: 110px
}

.m-l-115 {
	margin-left: 115px
}

.m-t-115 {
	margin-top: 115px
}

.m-r-115 {
	margin-right: 115px
}

.m-b-115 {
	margin-bottom: 115px
}

.m-l-120 {
	margin-left: 120px
}

.m-t-120 {
	margin-top: 120px
}

.m-r-120 {
	margin-right: 120px
}

.m-b-120 {
	margin-bottom: 120px
}

.m-l-125 {
	margin-left: 125px
}

.m-t-125 {
	margin-top: 125px
}

.m-r-125 {
	margin-right: 125px
}

.m-b-125 {
	margin-bottom: 125px
}

.margin-0,
.card .header .header-dropdown,
.card .header h2,
.media .media-body .media-heading,
.wizard .content,
.wizard .content .body label.error,
.dropdown-menu ul.menu .menu-info h4,
.dropdown-menu ul.menu .menu-info p,
.fc-overlay.right,
.fc .fc-toolbar,
.fc .fc-toolbar h2,
.search_result_page .title,
.chat-wrapper .card,
.project_widget .pw_content .pw_header h6,
.weather2 .city-selected .temp h2,
.weather2 .days-list,
.agent .agent-content .agent-name h4,
.agent .agent-content .agent-contact-details,
.agent .agent-content .social-icons,
.inbox-widget,
.inbox-widget .inbox-inner .hover_action .btn,
.social_widget2 .name h5,
.profile_state,
.contact .action_bar .btn,
.contact .action_bar .search,
.contact .c_list tr td .btn,
.profile-page .profile-sub-header ul.box-list,
.profile-page .profile-sub-header ul.box-list li p {
	margin: 0 !important
}

.p-l-0,
.sidebar .menu .list,
.sidebar .menu .list .ml-menu,
.invoice .nav-tabs {
	padding-left: 0px
}

.p-t-0 {
	padding-top: 0px
}

.p-r-0,
.invoice .nav-tabs {
	padding-right: 0px
}

.p-b-0,
.inbox-widget .inbox-inner:last-child {
	padding-bottom: 0px
}

.p-l-5 {
	padding-left: 5px
}

.p-t-5 {
	padding-top: 5px
}

.p-r-5,
.right-sidebar .choose-skin li,
.wizard.vertical>.steps {
	padding-right: 5px
}

.p-b-5,
.right-sidebar .card h6 {
	padding-bottom: 5px
}

.p-l-10,
section.content,
.activity_2 h5 {
	padding-left: 10px
}

.p-t-10,
.activity_2 h5,
.sprice li,
.sprice2 .data li {
	padding-top: 10px
}

.p-r-10,
section.content {
	padding-right: 10px
}

.p-b-10,
.activity_2 h5,
.social_widget2 .name,
.sprice li,
.sprice2 .data li,
.sprice2 .name {
	padding-bottom: 10px
}

.p-l-15,
.navbar-header {
	padding-left: 15px
}

.p-t-15,
.right-sidebar .slim_scroll {
	padding-top: 15px
}

.p-r-15 {
	padding-right: 15px
}

.p-b-15 {
	padding-bottom: 15px
}

.p-l-20 {
	padding-left: 20px
}

.p-t-20 {
	padding-top: 20px
}

.p-r-20 {
	padding-right: 20px
}

.p-b-20,
.authentication .card-plain .header {
	padding-bottom: 20px
}

.p-l-25 {
	padding-left: 25px
}

.p-t-25 {
	padding-top: 25px
}

.p-r-25 {
	padding-right: 25px
}

.p-b-25 {
	padding-bottom: 25px
}

.p-l-30,
.social_widget2 .name,
.sprice2 .name {
	padding-left: 30px
}

.p-t-30 {
	padding-top: 30px
}

.p-r-30,
.social_widget2 .name,
.sprice2 .name {
	padding-right: 30px
}

.p-b-30 {
	padding-bottom: 30px
}

.p-l-35 {
	padding-left: 35px
}

.p-t-35 {
	padding-top: 35px
}

.p-r-35 {
	padding-right: 35px
}

.p-b-35 {
	padding-bottom: 35px
}

.p-l-40 {
	padding-left: 40px
}

.p-t-40,
.w_calender .days,
.w_calender span {
	padding-top: 40px
}

.p-r-40 {
	padding-right: 40px
}

.p-b-40 {
	padding-bottom: 40px
}

.p-l-45 {
	padding-left: 45px
}

.p-t-45 {
	padding-top: 45px
}

.p-r-45 {
	padding-right: 45px
}

.p-b-45 {
	padding-bottom: 45px
}

.p-l-50 {
	padding-left: 50px
}

.p-t-50,
.social_widget2 .name {
	padding-top: 50px
}

.p-r-50 {
	padding-right: 50px
}

.p-b-50 {
	padding-bottom: 50px
}

.p-l-55 {
	padding-left: 55px
}

.p-t-55 {
	padding-top: 55px
}

.p-r-55 {
	padding-right: 55px
}

.p-b-55 {
	padding-bottom: 55px
}

.p-l-60 {
	padding-left: 60px
}

.p-t-60 {
	padding-top: 60px
}

.p-r-60 {
	padding-right: 60px
}

.p-b-60 {
	padding-bottom: 60px
}

.p-l-65 {
	padding-left: 65px
}

.p-t-65 {
	padding-top: 65px
}

.p-r-65 {
	padding-right: 65px
}

.p-b-65 {
	padding-bottom: 65px
}

.p-l-70 {
	padding-left: 70px
}

.p-t-70 {
	padding-top: 70px
}

.p-r-70 {
	padding-right: 70px
}

.p-b-70 {
	padding-bottom: 70px
}

.p-l-75 {
	padding-left: 75px
}

.p-t-75 {
	padding-top: 75px
}

.p-r-75 {
	padding-right: 75px
}

.p-b-75 {
	padding-bottom: 75px
}

.p-l-80 {
	padding-left: 80px
}

.p-t-80 {
	padding-top: 80px
}

.p-r-80 {
	padding-right: 80px
}

.p-b-80 {
	padding-bottom: 80px
}

.p-l-85 {
	padding-left: 85px
}

.p-t-85 {
	padding-top: 85px
}

.p-r-85 {
	padding-right: 85px
}

.p-b-85 {
	padding-bottom: 85px
}

.p-l-90 {
	padding-left: 90px
}

.p-t-90 {
	padding-top: 90px
}

.p-r-90 {
	padding-right: 90px
}

.p-b-90 {
	padding-bottom: 90px
}

.p-l-95 {
	padding-left: 95px
}

.p-t-95 {
	padding-top: 95px
}

.p-r-95 {
	padding-right: 95px
}

.p-b-95 {
	padding-bottom: 95px
}

.p-l-100 {
	padding-left: 100px
}

.p-t-100 {
	padding-top: 100px
}

.p-r-100 {
	padding-right: 100px
}

.p-b-100 {
	padding-bottom: 100px
}

.p-l-105 {
	padding-left: 105px
}

.p-t-105 {
	padding-top: 105px
}

.p-r-105 {
	padding-right: 105px
}

.p-b-105 {
	padding-bottom: 105px
}

.p-l-110 {
	padding-left: 110px
}

.p-t-110 {
	padding-top: 110px
}

.p-r-110 {
	padding-right: 110px
}

.p-b-110 {
	padding-bottom: 110px
}

.p-l-115 {
	padding-left: 115px
}

.p-t-115 {
	padding-top: 115px
}

.p-r-115 {
	padding-right: 115px
}

.p-b-115 {
	padding-bottom: 115px
}

.p-l-120 {
	padding-left: 120px
}

.p-t-120 {
	padding-top: 120px
}

.p-r-120 {
	padding-right: 120px
}

.p-b-120 {
	padding-bottom: 120px
}

.p-l-125 {
	padding-left: 125px
}

.p-t-125 {
	padding-top: 125px
}

.p-r-125 {
	padding-right: 125px
}

.p-b-125 {
	padding-bottom: 125px
}

.padding-0,
.navbar-header .navbar-brand,
.navbar-nav.navbar-left .dropdown-menu,
.right-sidebar .nav-tabs+.tab-content,
.right_chat .media .status,
.card .header .header-dropdown,
.fc .fc-view-container .fc-view.fc-basic-view>table>thead tr th.fc-agenda-gutter,
.fc .fc-view-container .fc-view.fc-basic-view>table>thead tr td.fc-agenda-gutter,
.fc .fc-view-container .fc-view.fc-agenda>table>thead tr th.fc-agenda-gutter,
.fc .fc-view-container .fc-view.fc-agenda>table>thead tr td.fc-agenda-gutter,
.agent .agent-content .agent-contact-details,
.profile_state,
.profile_state li,
.w_calender ul,
.profile-page .profile-sub-header ul.box-list,
.file_manager .file {
	padding: 0 !important
}

.font-6 {
	font-size: 6px
}

.font-7 {
	font-size: 7px
}

.font-8 {
	font-size: 8px
}

.font-9 {
	font-size: 9px
}

.font-10 {
	font-size: 10px
}

.font-11,
.sidebar .menu .list .header,
.card .card-inside-title small,
.weather .days li h5 {
	font-size: 11px
}

.font-12,
.sidebar .menu .list .ml-menu li a:before,
.right-sidebar .card h6,
.wizard .content .body label.error,
.dropdown-menu ul.menu .menu-info p,
.chat-widget .chat-scroll-list li .datetime,
.project_widget .pw_content .pw_header small,
.inbox-widget .inbox-inner .inbox-item-info .inbox-date {
	font-size: 12px
}

.font-13,
.right_chat .media .message,
.card .header h2 small,
.dropdown-menu .header,
.dropdown-menu ul.menu .menu-info p i,
.weather2 .city-selected .city span {
	font-size: 13px
}

.font-14,
.card .body,
.dropdown-menu ul.menu .menu-info h4,
.dropdown-menu>li>a,
.chat-launcher:before,
.chat-launcher:after,
.inbox-widget .inbox-inner .inbox-item-info .author,
.profile-page #timeline .timeline {
	font-size: 14px
}

.font-15,
.sidebar .menu .list .menu-toggle:after,
.sidebar .menu .list .menu-toggle:before,
.right_chat .media .name,
body,
.card .card-inside-title,
.card .header h2,
.wizard .steps .number,
.activity li a .info h4,
.user_activity .b-accent .sl-item .sl-content h5,
.weather2 .city-selected .night,
.contact .c_list address i {
	font-size: 15px
}

.font-16,
.sidebar .menu .list a i,
.search-bar input[type="text"],
.search_result_page .title,
.agent .agent-content .agent-contact-details li span,
.w_calender span+span,
.w_calender ul li {
	font-size: 16px
}

.font-17,
.agent .agent-content .agent-name h4 {
	font-size: 17px
}

.font-18,
.dropdown-menu ul.menu .icon-circle i,
.agent .agent-content .agent-contact-details li i,
.social_widget2 .name h5,
.sprice2 .name h5 {
	font-size: 18px
}

.font-19 {
	font-size: 19px
}

.font-20,
.sidebar .user-info .detail h4,
.card .header .header-dropdown i,
.dropdown-menu>li>a i.material-icons {
	font-size: 20px
}

.font-21 {
	font-size: 21px
}

.font-22 {
	font-size: 22px
}

.font-23 {
	font-size: 23px
}

.font-24,
.weather2 .city-selected .city {
	font-size: 24px
}

.font-25,
.profile_state li i,
.w_calender span {
	font-size: 25px
}

.font-26,
.twitter-feed p i,
.facebook-feed p i {
	font-size: 26px
}

.font-27,
.social_widget2 .data i,
.sprice2 .data i {
	font-size: 27px
}

.font-28,
.search-bar .search-icon .material-icons,
.sprice .sprice-circle span {
	font-size: 28px
}

.font-29 {
	font-size: 29px
}

.font-30,
.search-bar .close-search,
.file_manager .file .icon i {
	font-size: 30px
}

.font-31 {
	font-size: 31px
}

.font-32 {
	font-size: 32px
}

.font-33 {
	font-size: 33px
}

.font-34 {
	font-size: 34px
}

.font-35 {
	font-size: 35px
}

.font-36 {
	font-size: 36px
}

.font-37 {
	font-size: 37px
}

.font-38 {
	font-size: 38px
}

.font-39 {
	font-size: 39px
}

.font-40 {
	font-size: 40px
}

.font-41 {
	font-size: 41px
}

.font-42 {
	font-size: 42px
}

.font-43 {
	font-size: 43px
}

.font-44 {
	font-size: 44px
}

.font-45 {
	font-size: 45px
}

.font-46 {
	font-size: 46px
}

.font-47 {
	font-size: 47px
}

.font-48 {
	font-size: 48px
}

.font-49 {
	font-size: 49px
}

.font-50 {
	font-size: 50px
}

.align-left {
	text-align: left
}

.align-center,
.sidebar .user-info,
.sidebar .menu .list a i,
.dropdown-menu ul.menu .icon-circle,
.activity li a i,
.weather .days li,
.weather2 .days-list .day,
.product-report .icon,
.member-card,
.profile_state li,
.social_icon,
.new_friend_list li,
.w_calender .date,
.w_calender .days,
.w_calender ul li {
	text-align: center
}

.align-right,
.wizard .actions {
	text-align: right
}

.align-justify {
	text-align: justify
}

.no-resize {
	resize: none
}

.font-bold {
	font-weight: bold
}

.font-italic {
	font-style: italic
}

.font-underline {
	text-decoration: underline
}

.font-line-through {
	text-decoration: line-through
}

.font-overline {
	text-decoration: overline
}

.block-header {
	padding: 15px
}

.block-header h2 {
	margin: 0 !important;
	font-size: 20px
}

.block-header h2 small {
	display: block;
	margin-top: 8px;
	color: #616161;
	font-size: 13px
}

.block-header h2 small a {
	color: #e0e0e0
}

.block-header .breadcrumb {
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	-ms-border-radius: 50px;
	border-radius: 50px;
	background: rgba(255, 255, 255, 0.3)
}

.block-header .breadcrumb a {
	color: #fff
}

.bg-red {
	background-color: #ec3b57 !important;
	color: #fff
}

.bg-red .content .text,
.bg-red .content .number {
	color: #fff !important
}

.bg-pink {
	background-color: #E91E63 !important;
	color: #fff
}

.bg-pink .content .text,
.bg-pink .content .number {
	color: #fff !important
}

.bg-purple {
	background-color: #6572b8 !important;
	color: #fff
}

.bg-purple .content .text,
.bg-purple .content .number {
	color: #fff !important
}

.bg-deep-purple {
	background-color: #673AB7 !important;
	color: #fff
}

.bg-deep-purple .content .text,
.bg-deep-purple .content .number {
	color: #fff !important
}

.bg-blue {
	background-color: #60bafd !important;
	color: #fff
}

.bg-blue .content .text,
.bg-blue .content .number {
	color: #fff !important
}

.bg-cyan {
	background-color: #00cfd1 !important;
	color: #fff
}

.bg-cyan .content .text,
.bg-cyan .content .number {
	color: #fff !important
}

.bg-green {
	background-color: #50d38a !important;
	color: #fff
}

.bg-green .content .text,
.bg-green .content .number {
	color: #fff !important
}

.bg-light-green {
	background-color: #8BC34A !important;
	color: #fff
}

.bg-light-green .content .text,
.bg-light-green .content .number {
	color: #fff !important
}

.bg-yellow {
	background-color: #ffe821 !important;
	color: #fff
}

.bg-yellow .content .text,
.bg-yellow .content .number {
	color: #fff !important
}

.bg-orange {
	background-color: #ffc323 !important;
	color: #fff
}

.bg-orange .content .text,
.bg-orange .content .number {
	color: #fff !important
}

.bg-deep-orange {
	background-color: #f83600 !important;
	color: #fff
}

.bg-deep-orange .content .text,
.bg-deep-orange .content .number {
	color: #fff !important
}

.bg-grey {
	background-color: #9E9E9E !important;
	color: #fff
}

.bg-grey .content .text,
.bg-grey .content .number {
	color: #fff !important
}

.bg-blue-grey {
	background-color: #607D8B !important;
	color: #fff
}

.bg-blue-grey .content .text,
.bg-blue-grey .content .number {
	color: #fff !important
}

.bg-indigo {
	background-color: #3F51B5 !important;
	color: #fff
}

.bg-indigo .content .text,
.bg-indigo .content .number {
	color: #fff !important
}

.bg-light-blue {
	background-color: #03A9F4 !important;
	color: #fff
}

.bg-light-blue .content .text,
.bg-light-blue .content .number {
	color: #fff !important
}

.bg-teal {
	background-color: #009688 !important;
	color: #fff
}

.bg-teal .content .text,
.bg-teal .content .number {
	color: #fff !important
}

.bg-lime {
	background-color: #CDDC39 !important;
	color: #fff
}

.bg-lime .content .text,
.bg-lime .content .number {
	color: #fff !important
}

.bg-amber {
	background-color: #FFC107 !important;
	color: #fff
}

.bg-amber .content .text,
.bg-amber .content .number {
	color: #fff !important
}

.bg-brown {
	background-color: #795548 !important;
	color: #fff
}

.bg-brown .content .text,
.bg-brown .content .number {
	color: #fff !important
}

.bg-blush {
	background-color: #ff758e !important;
	color: #fff
}

.bg-blush .content .text,
.bg-blush .content .number {
	color: #fff !important
}

.bg-black {
	background-color: #000 !important;
	color: #fff
}

.bg-black .content .text,
.bg-black .content .number {
	color: #fff !important
}

.bg-white {
	background-color: #fff !important;
	color: #fff
}

.bg-white .content .text,
.bg-white .content .number {
	color: #fff !important
}

.col-red {
	color: #ec3b57 !important
}

.col-pink {
	color: #E91E63 !important
}

.col-purple {
	color: #6572b8 !important
}

.col-deep-purple {
	color: #673AB7 !important
}

.col-blue {
	color: #60bafd !important
}

.col-cyan {
	color: #00cfd1 !important
}

.col-green {
	color: #50d38a !important
}

.col-light-green {
	color: #8BC34A !important
}

.col-yellow {
	color: #ffe821 !important
}

.col-orange {
	color: #ffc323 !important
}

.col-deep-orange {
	color: #f83600 !important
}

.col-grey {
	color: #9E9E9E !important
}

.col-blue-grey {
	color: #607D8B !important
}

.col-indigo {
	color: #3F51B5 !important
}

.col-light-blue {
	color: #03A9F4 !important
}

.col-teal {
	color: #009688 !important
}

.col-lime {
	color: #CDDC39 !important
}

.col-amber {
	color: #FFC107 !important
}

.col-brown {
	color: #795548 !important
}

.col-blush {
	color: #ff758e !important
}

.col-black {
	color: #000 !important
}

.col-white {
	color: #fff !important
}

@media screen and (max-width: 767px) {
	.navbar>.container .navbar-brand,
	.navbar>.container-fluid .navbar-brand {
		margin-left: 15px;
		width: 73%
	}
	.navbar .nav>li {
		display: inline-block
	}
	.navbar .navbar-nav .open .dropdown-menu {
		background-color: #fff;
		position: absolute
	}
	.navbar .dropdown-menu {
		right: -85px
	}
	.navbar .dropdown-menu::before {
		display: none
	}
	.dt-buttons {
		float: none !important;
		text-align: center;
		margin-bottom: 15px
	}
	.panel-switch-btn {
		top: 12px;
		right: 0 !important
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.navbar>.container .navbar-brand,
	.navbar>.container-fluid .navbar-brand {
		margin-left: 20px
	}
}

@media (min-width: 992px) and (max-width: 1169px) {
	.navbar>.container .navbar-brand,
	.navbar>.container-fluid .navbar-brand {
		margin-left: 20px
	}
}

body {
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
	background-color: #f4f6f9;
	font-family: 'Montserrat', Arial, Tahoma, sans-serif;
	font-weight: 400
}

button,
input,
select,
a {
	outline: none !important
}

a:hover {
	text-decoration: none
}

section.content {
	-moz-transition: .5s;
	-o-transition: .5s;
	-webkit-transition: .5s;
	transition: .5s;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	margin: 60px 0px 15px 250px;
	z-index: 9;
	position: relative
}

section.content:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 150px
}

section small {
	font-size: 90%
}

.g-bg-cyan {
	background: linear-gradient(60deg, #136a8a, #00cfd1);
	color: #fff !important
}

.g-bg-blue {
	background: linear-gradient(60deg, #00adef, #0094da);
	color: #fff !important
}

.g-bg-gy {
	background: linear-gradient(60deg, #94d23b, #fcb711);
	color: #fff !important
}

.g-bg-cgreen {
	background: linear-gradient(60deg, #16c99f, #12a682);
	color: #fff !important
}

.g-bg-blush2 {
	background: linear-gradient(325deg, #dd5e89, #f7bb97);
	color: #fff !important
}

.g-bg-soundcloud {
	background: linear-gradient(60deg, #f83600, #fe8c00);
	color: #fff !important
}

.g-bg-soundcloud2 {
	background: linear-gradient(135deg, rgba(243, 71, 75, 0.9) 0%, rgba(255, 152, 0, 0.9) 100%);
	color: #fff !important
}

.l-green {
	background: linear-gradient(45deg, #9ce89d, #cdfa7e) !important
}

.l-pink {
	background: linear-gradient(45deg, pink, #fbc1cb) !important
}

.l-turquoise {
	background: linear-gradient(45deg, #00ced1, #08e5e8) !important;
	color: #fff !important
}

.l-cyan {
	background: linear-gradient(45deg, #49cdd0, #00bcd4) !important;
	color: #fff !important
}

.l-khaki {
	background: linear-gradient(45deg, khaki, #fdf181) !important
}

.l-coral {
	background: linear-gradient(45deg, #f08080, #f58787) !important;
	color: #fff !important
}

.l-salmon {
	background: linear-gradient(45deg, #ec74a1, #fbc7c0) !important;
	color: #fff !important
}

.l-blue {
	background: linear-gradient(45deg, #72c2ff, #86f0ff) !important;
	color: #fff !important
}

.l-seagreen {
	background: linear-gradient(45deg, #8ed8ec, #85f7b5) !important;
	color: #fff !important
}

.l-amber,
.w_calender span+span,
.w_calender em {
	background: linear-gradient(45deg, #fda582, #f7cf68) !important;
	color: #fff !important
}

.l-blush {
	background: linear-gradient(45deg, #dd5e89, #f7bb97) !important;
	color: #fff !important
}

.l-parpl {
	background: linear-gradient(45deg, #a890d3, #edbae7) !important;
	color: #fff !important
}

.l-slategray {
	background: linear-gradient(45deg, #708090, #7c8ea0) !important;
	color: #fff !important
}

.xl-pink {
	background: #f9edee !important
}

.xl-turquoise {
	background: #bdf3f5 !important
}

.xl-khaki {
	background: #f9f1d8 !important
}

.xl-parpl {
	background: #efebf4 !important
}

.xl-salmon {
	background: #ffd4c3 !important
}

.xl-blue {
	background: #e0eff5 !important
}

.xl-seagreen {
	background: #ebf2e8 !important
}

.xl-slategray {
	background: #eceeef !important
}

.bg-black {
	background: #191f28
}

.btn-white {
	background-color: #fff;
	color: #000
}

.inlineblock,
.navbar-header,
.navbar-nav.navbar-left,
.navbar-nav.navbar-left>li,
.navbar-nav>li>a,
.sidebar,
.right-sidebar .choose-skin li,
.right-sidebar .choose-skin li div,
.right-sidebar .theme-light-dark li,
.right_chat .media .message,
.right_chat .media .status,
.wizard .actions ul,
.dropdown-menu ul.menu .icon-circle,
.dropdown-menu ul.menu .menu-info,
.team-info li,
.chat-widget .chat-scroll-list li.left .chat-info,
.chat-widget .chat-scroll-list li.right .chat-info,
.weather .days li,
.agent .agent-content .agent-contact-details li span,
.agent .agent-content .social-icons li,
.agent .agent-content .social-icons li a,
.member-card .social-links li,
.social_icon,
.w_calender span+span,
.w_calender ul li,
.contact .c_list .checkbox,
.contact .c_list .c_name {
	display: inline-block
}

.displayblock,
.navbar-nav.navbar-left .dropdown-menu ul.menu .media .media-body .name,
.navbar-nav.navbar-left .dropdown-menu ul.menu .media .media-body .message,
.sidebar .menu .list a,
.right_chat .media .name,
.card .header .header-dropdown li a,
.card .header h2 small,
.wizard .actions,
.wizard .actions a,
.wizard .actions a:hover,
.wizard .actions a:active,
.wizard .steps,
.wizard .steps a,
.wizard .steps a:hover,
.wizard .steps a:active,
.dropdown-menu ul.menu li a,
.dropdown-menu .footer a,
.dropdown-menu>li>a,
.page-calendar .event-name h4 span,
.page-calendar .event-name p,
.activity li a,
.user_activity .b-accent .sl-item .sl-content small,
.chat-widget .chat-scroll-list li .message,
.project_widget .pw_img:before,
.project_widget .pw_content .pw_meta small,
.project_widget .pw_content .pw_meta span,
.twitter-feed p,
.weather .days li .degrees,
.weather2 .city-selected .temp h2,
.agent .agent-avatar a,
.agent .agent-content .agent-contact-details,
.inbox-widget .inbox-inner:hover .hover_action,
.inbox-widget .inbox-inner .inbox-item-info .inbox-message,
.member-card .text-pink,
.social_media_table .list-name,
.new_friend_list li .join_date,
.w_calender span,
.profile-page .profile-header .job_post,
.sprice li,
.sprice2 .data li,
.file_manager .file .file-name small {
	display: block
}

.z-index {
	z-index: 9999
}

.table tr th,
.table tr td {
	white-space: nowrap
}

.fullscreen.is-fullscreen .zmdi-fullscreen::before {
	content: '\f16c'
}

@media screen and (max-width: 767px) {
	section.content {
		margin-left: 0 !important;
		margin-right: 0px;
		padding: 0
	}
}

.dashboard-donut-chart {
	height: 265px
}

.bootstrap-tagsinput {
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	-ms-box-shadow: none !important;
	box-shadow: none !important;
	border: none !important
}

.noUi-target {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-ms-touch-action: none;
	touch-action: none;
	-ms-user-select: none;
	-moz-user-select: none;
	user-select: none;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	position: relative;
	direction: ltr
}

.noUi-target * {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-ms-touch-action: none;
	touch-action: none;
	-ms-user-select: none;
	-moz-user-select: none;
	user-select: none;
	-moz-box-sizing: border-box;
	box-sizing: border-box
}

.noUi-base {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1
}

.noUi-origin {
	position: absolute;
	right: 0;
	top: 6px;
	left: 0;
	bottom: 0
}

.noUi-handle {
	position: relative;
	z-index: 1
}

.noUi-stacking .noUi-handle {
	z-index: 10
}

.noUi-state-tap .noUi-origin {
	-webkit-transition: left 0.25s, top 0.25s;
	transition: left 0.25s, top 0.25s
}

.noUi-state-drag * {
	cursor: inherit !important
}

.noUi-base {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0)
}

.noUi-horizontal {
	height: 18px
}

.noUi-horizontal .noUi-handle {
	width: 34px;
	height: 28px;
	left: -17px;
	top: 1px
}

.noUi-vertical {
	width: 18px
}

.noUi-vertical .noUi-handle {
	width: 28px;
	height: 34px;
	left: -6px;
	top: -17px
}

.noUi-background {
	background: #FAFAFA;
	box-shadow: inset 0 1px 1px #f0f0f0
}

.noUi-connect {
	background: #662d91;
	box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45);
	-webkit-transition: background 450ms;
	transition: background 450ms
}

.noUi-origin {
	border-radius: 2px
}

.noUi-target {
	border-radius: 4px;
	border: 1px solid #D3D3D3;
	box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB
}

.noUi-target.noUi-connect {
	box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45), 0 3px 6px -5px #BBB
}

.noUi-dragable {
	cursor: w-resize
}

.noUi-vertical .noUi-dragable {
	cursor: n-resize
}

.noUi-handle {
	border: 1px solid #D9D9D9;
	border-radius: 3px;
	background: #fff;
	cursor: default;
	box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB
}

.noUi-active {
	box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB
}

.noUi-handle:before {
	content: "";
	display: block;
	position: absolute;
	height: 14px;
	width: 1px;
	background: #E8E7E6;
	left: 14px;
	top: 6px
}

.noUi-handle:after {
	content: "";
	display: block;
	position: absolute;
	height: 14px;
	width: 1px;
	background: #E8E7E6;
	left: 14px;
	top: 6px;
	left: 17px
}

.noUi-vertical .noUi-handle:before {
	width: 14px;
	height: 1px;
	left: 6px;
	top: 14px
}

.noUi-vertical .noUi-handle:after {
	width: 14px;
	height: 1px;
	left: 6px;
	top: 14px;
	top: 17px
}

[disabled].noUi-connect,
[disabled] .noUi-connect {
	background: #B8B8B8
}

[disabled].noUi-origin,
[disabled] .noUi-handle {
	cursor: not-allowed
}

.noUi-target {
	box-shadow: none;
	border: none
}

.noUi-base {
	height: 15px;
	top: -6px
}

.noUi-background {
	height: 3px;
	top: 6px;
	background-color: #bfbfbf;
	box-shadow: none
}

.noUi-horizontal {
	height: 3px
}

.noUi-connect {
	height: 3px;
	top: 6px;
	background-color: #662d91;
	box-shadow: none
}

.noUi-horizontal .noUi-handle {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	box-shadow: none;
	background-color: #662d91;
	border: none;
	left: -5px;
	top: 1px;
	transition: width 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), height 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), left 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), top 0.2s cubic-bezier(0.215, 0.61, 0.355, 1)
}

.noUi-handle:before,
.noUi-handle:after {
	content: none
}

.noUi-target .noUi-active.noUi-handle {
	-webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
	-ms-box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.5)
}

.noUi-target .range-label {
	position: absolute;
	height: 30px;
	width: 30px;
	top: -17px;
	left: -2px;
	background-color: #26A69A;
	border-radius: 50%;
	transition: border-radius 0.25s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
	transform: scale(0.5) rotate(-45deg);
	transform-origin: 50% 100%
}

.noUi-target .noUi-active .range-label {
	border-radius: 15px 15px 15px 0;
	transform: rotate(-45deg) translate(23px, -25px)
}

.range-label span {
	width: 100%;
	text-align: center;
	color: #fff;
	font-size: 12px;
	transform: rotate(45deg);
	opacity: 0;
	position: absolute;
	top: 7px;
	left: -1px;
	transition: opacity 0.25s cubic-bezier(0.215, 0.61, 0.355, 1)
}

.noUi-active .range-label span {
	opacity: 1
}

.ms-container {
	width: auto !important
}

.ms-container .ms-list {
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	-ms-box-shadow: none !important;
	box-shadow: none !important;
	-webkit-border-radius: 0 !important;
	-moz-border-radius: 0 !important;
	-ms-border-radius: 0 !important;
	border-radius: 0 !important
}

.ms-container .ms-list.ms-focus {
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	-ms-box-shadow: none !important;
	box-shadow: none !important
}

.ms-container .ms-selectable,
.ms-container .ms-selection {
	min-width: 250px !important
}

.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover {
	color: #000000 !important;
	background-color: #e6e6e6 !important
}

.ms-container .ms-selectable li.ms-elem-selectable,
.ms-container .ms-selectable li.ms-elem-selection,
.ms-container .ms-selection li.ms-elem-selectable,
.ms-container .ms-selection li.ms-elem-selection {
	padding: 9px 15px 6px 15px !important
}

.ms-container .ms-optgroup-label {
	padding: 5px 0 0 8px !important
}

.card {
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
	background: #fff;
	border-radius: 12px;
	margin-bottom: 30px;
	border: 0;
	display: inline-block;
	position: relative;
	width: 100%;
	box-shadow: none
}

.card .card-inside-title {
	color: #000;
	display: block
}

.card .card-inside-title small {
	color: #9e9e9e;
	display: block
}

.card .card-inside-title small a {
	color: #757575;
	font-weight: 700
}

.card .bg-red,
.card .bg-pink,
.card .bg-purple,
.card .bg-deep-purple,
.card .bg-indigo,
.card .bg-blue,
.card .bg-light-blue,
.card .bg-cyan,
.card .bg-teal,
.card .bg-green,
.card .bg-light-green,
.card .bg-lime,
.card .bg-yellow,
.card .bg-amber,
.card .bg-orange,
.card .bg-deep-orange,
.card .bg-brown,
.card .bg-grey,
.card .bg-blue-grey,
.card .bg-blush,
.card .bg-black {
	border-bottom: none !important;
	color: #fff !important
}

.card .bg-red h2,
.card .bg-red small,
.card .bg-red .material-icons,
.card .bg-pink h2,
.card .bg-pink small,
.card .bg-pink .material-icons,
.card .bg-purple h2,
.card .bg-purple small,
.card .bg-purple .material-icons,
.card .bg-deep-purple h2,
.card .bg-deep-purple small,
.card .bg-deep-purple .material-icons,
.card .bg-indigo h2,
.card .bg-indigo small,
.card .bg-indigo .material-icons,
.card .bg-blue h2,
.card .bg-blue small,
.card .bg-blue .material-icons,
.card .bg-light-blue h2,
.card .bg-light-blue small,
.card .bg-light-blue .material-icons,
.card .bg-cyan h2,
.card .bg-cyan small,
.card .bg-cyan .material-icons,
.card .bg-teal h2,
.card .bg-teal small,
.card .bg-teal .material-icons,
.card .bg-green h2,
.card .bg-green small,
.card .bg-green .material-icons,
.card .bg-light-green h2,
.card .bg-light-green small,
.card .bg-light-green .material-icons,
.card .bg-lime h2,
.card .bg-lime small,
.card .bg-lime .material-icons,
.card .bg-yellow h2,
.card .bg-yellow small,
.card .bg-yellow .material-icons,
.card .bg-amber h2,
.card .bg-amber small,
.card .bg-amber .material-icons,
.card .bg-orange h2,
.card .bg-orange small,
.card .bg-orange .material-icons,
.card .bg-deep-orange h2,
.card .bg-deep-orange small,
.card .bg-deep-orange .material-icons,
.card .bg-brown h2,
.card .bg-brown small,
.card .bg-brown .material-icons,
.card .bg-grey h2,
.card .bg-grey small,
.card .bg-grey .material-icons,
.card .bg-blue-grey h2,
.card .bg-blue-grey small,
.card .bg-blue-grey .material-icons,
.card .bg-blush h2,
.card .bg-blush small,
.card .bg-blush .material-icons,
.card .bg-black h2,
.card .bg-black small,
.card .bg-black .material-icons {
	color: #fff !important
}

.card .bg-red .badge,
.card .bg-pink .badge,
.card .bg-purple .badge,
.card .bg-deep-purple .badge,
.card .bg-indigo .badge,
.card .bg-blue .badge,
.card .bg-light-blue .badge,
.card .bg-cyan .badge,
.card .bg-teal .badge,
.card .bg-green .badge,
.card .bg-light-green .badge,
.card .bg-lime .badge,
.card .bg-yellow .badge,
.card .bg-amber .badge,
.card .bg-orange .badge,
.card .bg-deep-orange .badge,
.card .bg-brown .badge,
.card .bg-grey .badge,
.card .bg-blue-grey .badge,
.card .bg-blush .badge,
.card .bg-black .badge {
	background-color: #fff;
	color: #424242
}

.card .header {
	color: #424242;
	padding: 20px;
	position: relative;
	box-shadow: none
}

.card .header .header-dropdown {
	position: absolute;
	top: 14px;
	right: 20px;
	list-style: none
}

.card .header .header-dropdown li {
	display: inline-block
}

.card .header .header-dropdown li a {
	padding: 5px 10px
}

.card .header .header-dropdown li .dropdown-menu {
	padding: 5px;
	overflow: hidden;
	right: 0 !important;
	left: auto !important;
	transform: none !important;
	top: 30px !important
}

.card .header .header-dropdown li .dropdown-menu li {
	display: block !important
}

.card .header .header-dropdown li .dropdown-menu li a {
	padding: 5px 10px
}

.card .header .header-dropdown i {
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
	color: #9e9e9e
}

.card .header .header-dropdown i:hover {
	color: #000
}

.card .header h2 {
	color: #757575;
	position: relative
}

.card .header h2::before {
	position: absolute;
	width: 2px;
	height: 18px;
	left: -20px;
	top: 0;
	content: ''
}

.card .header h2 small {
	color: #9e9e9e;
	line-height: 15px
}

.card .header h2 small a {
	font-weight: 700;
	color: #757575
}

.card .p-15 {
	padding: 15px !important
}

.card .body {
	color: #424242;
	font-weight: 400;
	padding: 20px
}

.info-box {
	height: 80px;
	display: flex;
	cursor: default;
	background-color: #fff;
	position: relative;
	overflow: hidden
}

.info-box .icon {
	display: inline-block;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.12);
	width: 80px
}

.info-box .icon i {
	color: #fff;
	font-size: 30px;
	line-height: 80px
}

.info-box .icon .chart.chart-bar {
	height: 100%;
	line-height: 100px
}

.info-box .icon .chart.chart-bar canvas {
	vertical-align: baseline !important
}

.info-box .icon .chart.chart-pie {
	height: 100%;
	line-height: 123px
}

.info-box .icon .chart.chart-pie canvas {
	vertical-align: baseline !important
}

.info-box .icon .chart.chart-line {
	height: 100%;
	line-height: 115px
}

.info-box .icon .chart.chart-line canvas {
	vertical-align: baseline !important
}

.info-box .content {
	display: inline-block;
	padding: 7px 10px
}

.info-box .content .text {
	font-size: 13px;
	margin-top: 11px;
	color: #555
}

.info-box .content .number {
	font-weight: normal;
	font-size: 26px;
	color: #555
}

.info-box.hover-zoom-effect .icon {
	overflow: hidden
}

.info-box.hover-zoom-effect .icon i {
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease
}

.info-box.hover-zoom-effect:hover .icon i {
	opacity: 0.4;
	-moz-transform: rotate(-32deg) scale(1.4);
	-ms-transform: rotate(-32deg) scale(1.4);
	-o-transform: rotate(-32deg) scale(1.4);
	-webkit-transform: rotate(-32deg) scale(1.4);
	transform: rotate(-32deg) scale(1.4)
}

.info-box.hover-expand-effect:after {
	background-color: rgba(0, 0, 0, 0.05);
	content: ".";
	position: absolute;
	left: 80px;
	top: 0;
	width: 0;
	height: 100%;
	color: transparent;
	-moz-transition: all 0.95s;
	-o-transition: all 0.95s;
	-webkit-transition: all 0.95s;
	transition: all 0.95s
}

.info-box.hover-expand-effect:hover:after {
	width: 100%
}

.info-box-2 {
	display: flex;
	cursor: default;
	background-color: #fafbfd;
	position: relative;
	overflow: hidden;
	text-align: center;
	min-height: 150px
}

.info-box-2 .icon {
	display: inline-block
}

.info-box-2 .icon i {
	color: #fff;
	font-size: 30px;
	line-height: 70px
}

.info-box-2 .chart.chart-bar {
	height: 100%
}

.info-box-2 .chart.chart-bar canvas {
	vertical-align: baseline !important
}

.info-box-2 .chart.chart-pie {
	height: 100%
}

.info-box-2 .chart.chart-pie canvas {
	vertical-align: baseline !important
}

.info-box-2 .chart.chart-line {
	height: 100%
}

.info-box-2 .chart.chart-line canvas {
	vertical-align: baseline !important
}

.info-box-2 .content {
	display: inline-block;
	padding: 7px 10px
}

.info-box-2 .content .text {
	font-size: 14px;
	margin-top: 11px;
	color: #555
}

.info-box-2 .content .number {
	font-weight: normal;
	font-size: 26px;
	color: #555
}

.info-box-2.hover-zoom-effect .icon {
	overflow: hidden
}

.info-box-2.hover-zoom-effect .icon i {
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease
}

.info-box-2.hover-zoom-effect:hover .icon i {
	opacity: 0.4;
	-moz-transform: rotate(-32deg) scale(1.4);
	-ms-transform: rotate(-32deg) scale(1.4);
	-o-transform: rotate(-32deg) scale(1.4);
	-webkit-transform: rotate(-32deg) scale(1.4);
	transform: rotate(-32deg) scale(1.4)
}

.info-box-2.hover-expand-effect:after {
	background-color: rgba(0, 0, 0, 0.05);
	content: ".";
	position: absolute;
	left: 0;
	top: 0;
	width: 0;
	height: 100%;
	color: transparent;
	-moz-transition: all 0.95s;
	-o-transition: all 0.95s;
	-webkit-transition: all 0.95s;
	transition: all 0.95s
}

.info-box-2.hover-expand-effect:hover:after {
	width: 100%
}

.notify {
	position: absolute;
	top: 30px;
	right: 11px
}

.notify .heartbit {
	-webkit-border-radius: 70px;
	-moz-border-radius: 70px;
	-ms-border-radius: 70px;
	border-radius: 70px;
	position: absolute;
	top: -20px;
	right: -16px;
	height: 25px;
	width: 25px;
	z-index: 10;
	border: 4px solid;
	border-color: #fff;
	animation: heartbit 1s ease-out;
	-moz-animation: heartbit 1s ease-out;
	-moz-animation-iteration-count: infinite;
	-o-animation: heartbit 1s ease-out;
	-o-animation-iteration-count: infinite;
	-webkit-animation: heartbit 1s ease-out;
	-ms-animation: heartbit 1s ease-out;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite
}

.notify .point {
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	-ms-border-radius: 30px;
	border-radius: 30px;
	width: 6px;
	height: 6px;
	background-color: #d31c1f;
	position: absolute;
	right: -6px;
	top: -10px
}

.notify .More {	
	position: absolute;
	right: -15px;
	top: -7px
}

@-moz-keyframes heartbit {
	0% {
		-moz-transform: scale(0);
		opacity: 0
	}
	25% {
		-moz-transform: scale(0.1);
		opacity: .1
	}
	50% {
		-moz-transform: scale(0.5);
		opacity: .3
	}
	75% {
		-moz-transform: scale(0.8);
		opacity: .5
	}
	100% {
		-moz-transform: scale(1);
		opacity: 0
	}
}

@-webkit-keyframes heartbit {
	0% {
		-webkit-transform: scale(0);
		opacity: 0
	}
	25% {
		-webkit-transform: scale(0.1);
		opacity: .1
	}
	50% {
		-webkit-transform: scale(0.5);
		opacity: .3
	}
	75% {
		-webkit-transform: scale(0.8);
		opacity: .5
	}
	100% {
		-webkit-transform: scale(1);
		opacity: 0
	}
}

@-ms-keyframes heartbit {
	0% {
		-ms-transform: scale(0);
		opacity: 0
	}
	25% {
		-ms-transform: scale(0.1);
		opacity: .1
	}
	50% {
		-ms-transform: scale(0.5);
		opacity: .3
	}
	75% {
		-ms-transform: scale(0.8);
		opacity: .5
	}
	100% {
		-ms-transform: scale(1);
		opacity: 0
	}
}

.sweet-alert {
	-webkit-border-radius: 0 !important;
	-moz-border-radius: 0 !important;
	-ms-border-radius: 0 !important;
	border-radius: 0 !important
}

.sweet-alert p {
	font-size: 14px !important
}

.sweet-alert .sa-input-error {
	top: 23px !important;
	right: 13px !important
}

.sweet-alert h2 {
	font-size: 18px !important;
	margin: 0 0 5px 0 !important
}

.sweet-alert button {
	font-size: 15px !important;
	-webkit-border-radius: 0 !important;
	-moz-border-radius: 0 !important;
	-ms-border-radius: 0 !important;
	border-radius: 0 !important;
	padding: 5px 20px !important
}

.dtp div.dtp-date,
.dtp div.dtp-time {
	background: #424242
}

.dtp>.dtp-content {
	max-height: 530px !important
}

.dtp>.dtp-content>.dtp-date-view>header.dtp-header {
	background: #424242
}

.dtp .dtp-buttons {
	text-align: center !important
}

.dtp .dtp-buttons .dtp-btn-ok {
	margin-left: 10px
}

.dtp .dtp-buttons .dtp-btn-clear {
	margin-right: 10px !important
}

.dtp .p10>a {
	color: #fff
}

.dtp div.dtp-actual-year {
	font-size: 1.5em;
	color: #fff
}

.dtp table.dtp-picker-days tr td a.selected {
	background: #424242;
	color: #fff
}

.bootstrap-select {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	border-radius: 0;
	box-shadow: none !important
}

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select .dropdown-toggle:active {
	outline: none !important
}

.bootstrap-select .btn.btn-round.btn-simple {
	border-color: #E3E3E3;
	padding-left: 10px;
	padding-right: 10px
}

.bootstrap-select .btn.btn-round.btn-simple .filter-option {
	font-size: 14px;
	width: calc(100% - 20px)
}

.bootstrap-select .bs-searchbox,
.bootstrap-select .bs-actionsbox,
.bootstrap-select .bs-donebutton {
	padding: 0 0 5px 0;
	border-bottom: 1px solid #eee
}

.bootstrap-select .bs-searchbox {
	position: relative;
	padding: 10px
}

.bootstrap-select .bs-searchbox:after {
	content: '\f1c3';
	font-family: 'Material-Design-Iconic-Font';
	position: absolute;
	top: 14px;
	right: 20px;
	font-size: 20px
}

.bootstrap-select ul.dropdown-menu {
	margin-top: 0 !important
}

.bootstrap-select .dropdown-menu li.selected a {
	background-color: #eee !important;
	color: #555 !important
}

.bootstrap-select .dropdown-menu .active a {
	background-color: transparent;
	color: #333 !important
}

.bootstrap-select .dropdown-menu .notify {
	background-color: #F44336 !important;
	color: #fff !important;
	border: none !important
}

.bootstrap-select.form-control .dropdown-menu .hidden {
	display: none
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
	margin-top: 9px
}

.bootstrap-select.btn-group.show-tick .dropdown-menu.inner {
	display: block
}

.bootstrap-select.btn-group.show .dropdown-menu {
	z-index: 999999
}

.bootstrap-select.show .dropdown-menu {
	display: block
}

.collapse .well,
.collapse.in .well,
.collapsing .well {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	border-radius: 0;
	margin-bottom: 0
}

@media only screen and (min-width: 993px) {
	.collapse.collapse-sm,
	.collapse.collapse-xs {
		display: block
	}
}

.panel-group .panel-col-red {
	border: 1px solid #ec3b57
}

.panel-group .panel-col-red .panel-title {
	background-color: #ec3b57 !important;
	color: #fff;
	margin: 0
}

.panel-group .panel-col-red .panel-body {
	border-top-color: transparent !important
}

.panel-group .panel-col-pink {
	border: 1px solid #E91E63
}

.panel-group .panel-col-pink .panel-title {
	background-color: #E91E63 !important;
	color: #fff;
	margin: 0
}

.panel-group .panel-col-pink .panel-body {
	border-top-color: transparent !important
}

.panel-group .panel-col-purple {
	border: 1px solid #6572b8
}

.panel-group .panel-col-purple .panel-title {
	background-color: #6572b8 !important;
	color: #fff;
	margin: 0
}

.panel-group .panel-col-purple .panel-body {
	border-top-color: transparent !important
}

.panel-group .panel-col-deep-purple {
	border: 1px solid #673AB7
}

.panel-group .panel-col-deep-purple .panel-title {
	background-color: #673AB7 !important;
	color: #fff;
	margin: 0
}

.panel-group .panel-col-deep-purple .panel-body {
	border-top-color: transparent !important
}

.panel-group .panel-col-blue {
	border: 1px solid #60bafd
}

.panel-group .panel-col-blue .panel-title {
	background-color: #60bafd !important;
	color: #fff;
	margin: 0
}

.panel-group .panel-col-blue .panel-body {
	border-top-color: transparent !important
}

.panel-group .panel-col-cyan {
	border: 1px solid #00cfd1
}

.panel-group .panel-col-cyan .panel-title {
	background-color: #00cfd1 !important;
	color: #fff;
	margin: 0
}

.panel-group .panel-col-cyan .panel-body {
	border-top-color: transparent !important
}

.panel-group .panel-col-green {
	border: 1px solid #50d38a
}

.panel-group .panel-col-green .panel-title {
	background-color: #50d38a !important;
	color: #fff;
	margin: 0
}

.panel-group .panel-col-green .panel-body {
	border-top-color: transparent !important
}

.panel-group .panel-col-light-green {
	border: 1px solid #8BC34A
}

.panel-group .panel-col-light-green .panel-title {
	background-color: #8BC34A !important;
	color: #fff;
	margin: 0
}

.panel-group .panel-col-light-green .panel-body {
	border-top-color: transparent !important
}

.panel-group .panel-col-yellow {
	border: 1px solid #ffe821
}

.panel-group .panel-col-yellow .panel-title {
	background-color: #ffe821 !important;
	color: #fff;
	margin: 0
}

.panel-group .panel-col-yellow .panel-body {
	border-top-color: transparent !important
}

.panel-group .panel-col-orange {
	border: 1px solid #ffc323
}

.panel-group .panel-col-orange .panel-title {
	background-color: #ffc323 !important;
	color: #fff;
	margin: 0
}

.panel-group .panel-col-orange .panel-body {
	border-top-color: transparent !important
}

.panel-group .panel-col-deep-orange {
	border: 1px solid #f83600
}

.panel-group .panel-col-deep-orange .panel-title {
	background-color: #f83600 !important;
	color: #fff;
	margin: 0
}

.panel-group .panel-col-deep-orange .panel-body {
	border-top-color: transparent !important
}

.panel-group .panel-col-grey {
	border: 1px solid #9E9E9E
}

.panel-group .panel-col-grey .panel-title {
	background-color: #9E9E9E !important;
	color: #fff;
	margin: 0
}

.panel-group .panel-col-grey .panel-body {
	border-top-color: transparent !important
}

.panel-group .panel-col-blue-grey {
	border: 1px solid #607D8B
}

.panel-group .panel-col-blue-grey .panel-title {
	background-color: #607D8B !important;
	color: #fff;
	margin: 0
}

.panel-group .panel-col-blue-grey .panel-body {
	border-top-color: transparent !important
}

.panel-group .panel-col-indigo {
	border: 1px solid #3F51B5
}

.panel-group .panel-col-indigo .panel-title {
	background-color: #3F51B5 !important;
	color: #fff;
	margin: 0
}

.panel-group .panel-col-indigo .panel-body {
	border-top-color: transparent !important
}

.panel-group .panel-col-light-blue {
	border: 1px solid #03A9F4
}

.panel-group .panel-col-light-blue .panel-title {
	background-color: #03A9F4 !important;
	color: #fff;
	margin: 0
}

.panel-group .panel-col-light-blue .panel-body {
	border-top-color: transparent !important
}

.panel-group .panel-col-teal {
	border: 1px solid #009688
}

.panel-group .panel-col-teal .panel-title {
	background-color: #009688 !important;
	color: #fff;
	margin: 0
}

.panel-group .panel-col-teal .panel-body {
	border-top-color: transparent !important
}

.panel-group .panel-col-lime {
	border: 1px solid #CDDC39
}

.panel-group .panel-col-lime .panel-title {
	background-color: #CDDC39 !important;
	color: #fff;
	margin: 0
}

.panel-group .panel-col-lime .panel-body {
	border-top-color: transparent !important
}

.panel-group .panel-col-amber {
	border: 1px solid #FFC107
}

.panel-group .panel-col-amber .panel-title {
	background-color: #FFC107 !important;
	color: #fff;
	margin: 0
}

.panel-group .panel-col-amber .panel-body {
	border-top-color: transparent !important
}

.panel-group .panel-col-brown {
	border: 1px solid #795548
}

.panel-group .panel-col-brown .panel-title {
	background-color: #795548 !important;
	color: #fff;
	margin: 0
}

.panel-group .panel-col-brown .panel-body {
	border-top-color: transparent !important
}

.panel-group .panel-col-blush {
	border: 1px solid #ff758e
}

.panel-group .panel-col-blush .panel-title {
	background-color: #ff758e !important;
	color: #fff;
	margin: 0
}

.panel-group .panel-col-blush .panel-body {
	border-top-color: transparent !important
}

.panel-group .panel-col-black {
	border: 1px solid #000
}

.panel-group .panel-col-black .panel-title {
	background-color: #000 !important;
	color: #fff;
	margin: 0
}

.panel-group .panel-col-black .panel-body {
	border-top-color: transparent !important
}

.panel-group .panel-col-white {
	border: 1px solid #fff
}

.panel-group .panel-col-white .panel-title {
	background-color: #fff !important;
	color: #fff;
	margin: 0
}

.panel-group .panel-col-white .panel-body {
	border-top-color: transparent !important
}

.panel-group .panel {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	border-radius: 0;
	margin-bottom: 1px
}

.panel-group .panel .panel-title {
	font-weight: normal;
	font-size: 16px;
	margin: 0
}

.panel-group .panel .panel-title a {
	color: inherit
}

.panel-group .panel .panel-title .material-icons {
	float: left;
	line-height: 16px;
	margin-right: 8px
}

.panel-group .panel .panel-heading {
	padding: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	border-radius: 0
}

.panel-group .panel .panel-heading a {
	display: block;
	padding: 10px 15px
}

.panel-group .panel .panel-heading a:hover,
.panel-group .panel .panel-heading a:focus,
.panel-group .panel .panel-heading a:active {
	text-decoration: none
}

.panel-group .panel .panel-body {
	padding: 20px
}

.panel-group .panel-primary .panel-title {
	background-color: #f5f5f5;
	border-bottom: 1px solid #eee;
	color: #757575;
	font-weight: 400;
	font-size: 16px;
	margin: 0
}

.panel-group .panel-success .panel-title {
	background-color: #2b982b;
	color: #fff
}

.panel-group .panel-warning .panel-title {
	background-color: #ff9600;
	color: #fff
}

.panel-group .panel-danger .panel-title {
	background-color: #fb483a;
	color: #fff
}

.full-body .panel-col-red .panel-body {
	border-top-color: #fff !important;
	background-color: #ec3b57;
	color: #fff
}

.full-body .panel-col-pink .panel-body {
	border-top-color: #fff !important;
	background-color: #E91E63;
	color: #fff
}

.full-body .panel-col-purple .panel-body {
	border-top-color: #fff !important;
	background-color: #6572b8;
	color: #fff
}

.full-body .panel-col-deep-purple .panel-body {
	border-top-color: #fff !important;
	background-color: #673AB7;
	color: #fff
}

.full-body .panel-col-blue .panel-body {
	border-top-color: #fff !important;
	background-color: #60bafd;
	color: #fff
}

.full-body .panel-col-cyan .panel-body {
	border-top-color: #fff !important;
	background-color: #00cfd1;
	color: #fff
}

.full-body .panel-col-green .panel-body {
	border-top-color: #fff !important;
	background-color: #50d38a;
	color: #fff
}

.full-body .panel-col-light-green .panel-body {
	border-top-color: #fff !important;
	background-color: #8BC34A;
	color: #fff
}

.full-body .panel-col-yellow .panel-body {
	border-top-color: #fff !important;
	background-color: #ffe821;
	color: #fff
}

.full-body .panel-col-orange .panel-body {
	border-top-color: #fff !important;
	background-color: #ffc323;
	color: #fff
}

.full-body .panel-col-deep-orange .panel-body {
	border-top-color: #fff !important;
	background-color: #f83600;
	color: #fff
}

.full-body .panel-col-grey .panel-body {
	border-top-color: #fff !important;
	background-color: #9E9E9E;
	color: #fff
}

.full-body .panel-col-blue-grey .panel-body {
	border-top-color: #fff !important;
	background-color: #607D8B;
	color: #fff
}

.full-body .panel-col-indigo .panel-body {
	border-top-color: #fff !important;
	background-color: #3F51B5;
	color: #fff
}

.full-body .panel-col-light-blue .panel-body {
	border-top-color: #fff !important;
	background-color: #03A9F4;
	color: #fff
}

.full-body .panel-col-teal .panel-body {
	border-top-color: #fff !important;
	background-color: #009688;
	color: #fff
}

.full-body .panel-col-lime .panel-body {
	border-top-color: #fff !important;
	background-color: #CDDC39;
	color: #fff
}

.full-body .panel-col-amber .panel-body {
	border-top-color: #fff !important;
	background-color: #FFC107;
	color: #fff
}

.full-body .panel-col-brown .panel-body {
	border-top-color: #fff !important;
	background-color: #795548;
	color: #fff
}

.full-body .panel-col-blush .panel-body {
	border-top-color: #fff !important;
	background-color: #ff758e;
	color: #fff
}

.full-body .panel-col-black .panel-body {
	border-top-color: #fff !important;
	background-color: #000;
	color: #fff
}

.full-body .panel-col-white .panel-body {
	border-top-color: #fff !important;
	background-color: #fff;
	color: #fff
}

.full-body .panel-primary .panel-body {
	border-top-color: #fff !important;
	background-color: #1f91f3;
	color: #fff
}

.full-body .panel-success .panel-body {
	border-top-color: #fff !important;
	background-color: #2b982b;
	color: #fff
}

.full-body .panel-warning .panel-body {
	border-top-color: #fff !important;
	background-color: #ff9600;
	color: #fff
}

.full-body .panel-danger .panel-body {
	border-top-color: #fff !important;
	background-color: #fb483a;
	color: #fff
}

.progress {
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	border-radius: 8px;
	height: 8px
}

.progress .progress-bar {
	line-height: 8px;
	background-color: #1f91f3
}

.progress .progress-bar-success {
	background-color: #2b982b
}

.progress .progress-bar-info {
	background-color: #00b0e4
}

.progress .progress-bar-warning {
	background-color: #ff9600
}

.progress .progress-bar-danger {
	background-color: #fb483a
}

.irs .irs-min,
.irs .irs-max,
.irs .irs-from,
.irs .irs-to,
.irs .irs-single {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	border-radius: 0
}

.colorpicker {
	z-index: 99
}

.colorpicker:before,
.colorpicker:after {
	display: none !important
}

.colorpicker.colorpicker-with-alpha {
	max-width: max-content
}

.dropzone {
	border: 2px solid transparent !important;
	background-color: #eee !important
}

.dropzone .dz-message .drag-icon-cph .material-icons {
	font-size: 80px;
	color: #777
}

.dz-drag-hover {
	border: 2px dashed #888 !important
}

.badge {
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
	border-radius: 2px
}

.list-group-item {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	border-radius: 0;
	-moz-transition: .5s;
	-o-transition: .5s;
	-webkit-transition: .5s;
	transition: .5s
}

.list-group .active {
	background-color: #2196F3;
	border-color: #2196F3
}

.list-group .active:hover,
.list-group .active:focus,
.list-group .active:active {
	background-color: #2196F3;
	border-color: #2196F3
}

.list-group .active .list-group-item-text {
	color: #dfe9f1;
	font-size: 13px
}

.list-group .active .list-group-item-text:hover,
.list-group .active .list-group-item-text:active,
.list-group .active .list-group-item-text:focus {
	color: #dfe9f1
}

.list-group .list-group-item.active:hover .list-group-item-text,
.list-group .list-group-item.active:focus .list-group-item-text,
.list-group .list-group-item.active:active .list-group-item-text {
	color: #dfe9f1
}

.list-group .list-group-item:first-child,
.list-group .list-group-item:last-child {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	border-radius: 0
}

.list-group .list-group-item .list-group-item-heading {
	font-weight: bold;
	font-size: 17px
}

.list-group .list-group-item-success {
	background-color: #2b982b;
	border: none;
	color: #fff
}

.list-group .list-group-item-success:hover,
.list-group .list-group-item-success:focus {
	background-color: #2b982b;
	color: #fff;
	opacity: 0.8
}

.list-group .list-group-item-info {
	background-color: #00b0e4;
	border: none;
	color: #fff
}

.list-group .list-group-item-info:hover,
.list-group .list-group-item-info:focus {
	background-color: #00b0e4;
	color: #fff;
	opacity: 0.8
}

.list-group .list-group-item-warning {
	background-color: #ff9600;
	border: none;
	color: #fff
}

.list-group .list-group-item-warning:hover,
.list-group .list-group-item-warning:focus {
	background-color: #ff9600;
	color: #fff;
	opacity: 0.8
}

.list-group .list-group-item-danger {
	background-color: #fb483a;
	border: none;
	color: #fff
}

.list-group .list-group-item-danger:hover,
.list-group .list-group-item-danger:focus {
	background-color: #fb483a;
	color: #fff;
	opacity: 0.8
}

.list-group .pl-red {
	stroke: #ec3b57
}

.list-group .list-group-bg-red {
	background-color: #ec3b57;
	border: none;
	color: #fff
}

.list-group .list-group-bg-red:hover,
.list-group .list-group-bg-red:focus {
	background-color: #ec3b57;
	color: #fff;
	opacity: 0.8
}

.list-group .pl-pink {
	stroke: #E91E63
}

.list-group .list-group-bg-pink {
	background-color: #E91E63;
	border: none;
	color: #fff
}

.list-group .list-group-bg-pink:hover,
.list-group .list-group-bg-pink:focus {
	background-color: #E91E63;
	color: #fff;
	opacity: 0.8
}

.list-group .pl-purple {
	stroke: #6572b8
}

.list-group .list-group-bg-purple {
	background-color: #6572b8;
	border: none;
	color: #fff
}

.list-group .list-group-bg-purple:hover,
.list-group .list-group-bg-purple:focus {
	background-color: #6572b8;
	color: #fff;
	opacity: 0.8
}

.list-group .pl-deep-purple {
	stroke: #673AB7
}

.list-group .list-group-bg-deep-purple {
	background-color: #673AB7;
	border: none;
	color: #fff
}

.list-group .list-group-bg-deep-purple:hover,
.list-group .list-group-bg-deep-purple:focus {
	background-color: #673AB7;
	color: #fff;
	opacity: 0.8
}

.list-group .pl-blue {
	stroke: #60bafd
}

.list-group .list-group-bg-blue {
	background-color: #60bafd;
	border: none;
	color: #fff
}

.list-group .list-group-bg-blue:hover,
.list-group .list-group-bg-blue:focus {
	background-color: #60bafd;
	color: #fff;
	opacity: 0.8
}

.list-group .pl-cyan {
	stroke: #00cfd1
}

.list-group .list-group-bg-cyan {
	background-color: #00cfd1;
	border: none;
	color: #fff
}

.list-group .list-group-bg-cyan:hover,
.list-group .list-group-bg-cyan:focus {
	background-color: #00cfd1;
	color: #fff;
	opacity: 0.8
}

.list-group .pl-green {
	stroke: #50d38a
}

.list-group .list-group-bg-green {
	background-color: #50d38a;
	border: none;
	color: #fff
}

.list-group .list-group-bg-green:hover,
.list-group .list-group-bg-green:focus {
	background-color: #50d38a;
	color: #fff;
	opacity: 0.8
}

.list-group .pl-light-green {
	stroke: #8BC34A
}

.list-group .list-group-bg-light-green {
	background-color: #8BC34A;
	border: none;
	color: #fff
}

.list-group .list-group-bg-light-green:hover,
.list-group .list-group-bg-light-green:focus {
	background-color: #8BC34A;
	color: #fff;
	opacity: 0.8
}

.list-group .pl-yellow {
	stroke: #ffe821
}

.list-group .list-group-bg-yellow {
	background-color: #ffe821;
	border: none;
	color: #fff
}

.list-group .list-group-bg-yellow:hover,
.list-group .list-group-bg-yellow:focus {
	background-color: #ffe821;
	color: #fff;
	opacity: 0.8
}

.list-group .pl-orange {
	stroke: #ffc323
}

.list-group .list-group-bg-orange {
	background-color: #ffc323;
	border: none;
	color: #fff
}

.list-group .list-group-bg-orange:hover,
.list-group .list-group-bg-orange:focus {
	background-color: #ffc323;
	color: #fff;
	opacity: 0.8
}

.list-group .pl-deep-orange {
	stroke: #f83600
}

.list-group .list-group-bg-deep-orange {
	background-color: #f83600;
	border: none;
	color: #fff
}

.list-group .list-group-bg-deep-orange:hover,
.list-group .list-group-bg-deep-orange:focus {
	background-color: #f83600;
	color: #fff;
	opacity: 0.8
}

.list-group .pl-grey {
	stroke: #9E9E9E
}

.list-group .list-group-bg-grey {
	background-color: #9E9E9E;
	border: none;
	color: #fff
}

.list-group .list-group-bg-grey:hover,
.list-group .list-group-bg-grey:focus {
	background-color: #9E9E9E;
	color: #fff;
	opacity: 0.8
}

.list-group .pl-blue-grey {
	stroke: #607D8B
}

.list-group .list-group-bg-blue-grey {
	background-color: #607D8B;
	border: none;
	color: #fff
}

.list-group .list-group-bg-blue-grey:hover,
.list-group .list-group-bg-blue-grey:focus {
	background-color: #607D8B;
	color: #fff;
	opacity: 0.8
}

.list-group .pl-indigo {
	stroke: #3F51B5
}

.list-group .list-group-bg-indigo {
	background-color: #3F51B5;
	border: none;
	color: #fff
}

.list-group .list-group-bg-indigo:hover,
.list-group .list-group-bg-indigo:focus {
	background-color: #3F51B5;
	color: #fff;
	opacity: 0.8
}

.list-group .pl-light-blue {
	stroke: #03A9F4
}

.list-group .list-group-bg-light-blue {
	background-color: #03A9F4;
	border: none;
	color: #fff
}

.list-group .list-group-bg-light-blue:hover,
.list-group .list-group-bg-light-blue:focus {
	background-color: #03A9F4;
	color: #fff;
	opacity: 0.8
}

.list-group .pl-teal {
	stroke: #009688
}

.list-group .list-group-bg-teal {
	background-color: #009688;
	border: none;
	color: #fff
}

.list-group .list-group-bg-teal:hover,
.list-group .list-group-bg-teal:focus {
	background-color: #009688;
	color: #fff;
	opacity: 0.8
}

.list-group .pl-lime {
	stroke: #CDDC39
}

.list-group .list-group-bg-lime {
	background-color: #CDDC39;
	border: none;
	color: #fff
}

.list-group .list-group-bg-lime:hover,
.list-group .list-group-bg-lime:focus {
	background-color: #CDDC39;
	color: #fff;
	opacity: 0.8
}

.list-group .pl-amber {
	stroke: #FFC107
}

.list-group .list-group-bg-amber {
	background-color: #FFC107;
	border: none;
	color: #fff
}

.list-group .list-group-bg-amber:hover,
.list-group .list-group-bg-amber:focus {
	background-color: #FFC107;
	color: #fff;
	opacity: 0.8
}

.list-group .pl-brown {
	stroke: #795548
}

.list-group .list-group-bg-brown {
	background-color: #795548;
	border: none;
	color: #fff
}

.list-group .list-group-bg-brown:hover,
.list-group .list-group-bg-brown:focus {
	background-color: #795548;
	color: #fff;
	opacity: 0.8
}

.list-group .pl-blush {
	stroke: #ff758e
}

.list-group .list-group-bg-blush {
	background-color: #ff758e;
	border: none;
	color: #fff
}

.list-group .list-group-bg-blush:hover,
.list-group .list-group-bg-blush:focus {
	background-color: #ff758e;
	color: #fff;
	opacity: 0.8
}

.list-group .pl-black {
	stroke: #000
}

.list-group .list-group-bg-black {
	background-color: #000;
	border: none;
	color: #fff
}

.list-group .list-group-bg-black:hover,
.list-group .list-group-bg-black:focus {
	background-color: #000;
	color: #fff;
	opacity: 0.8
}

.list-group .pl-white {
	stroke: #fff
}

.list-group .list-group-bg-white {
	background-color: #fff;
	border: none;
	color: #fff
}

.list-group .list-group-bg-white:hover,
.list-group .list-group-bg-white:focus {
	background-color: #fff;
	color: #fff;
	opacity: 0.8
}

.media.mright .media-body {
	text-align: right
}

.media .media-body {
	color: #616161;
	font-size: 14px
}

.media .media-body .media-heading {
	font-size: 16px;
	font-weight: bold;
	color: #212121
}

.wizard .content {
	min-height: 245px;
	overflow-y: auto;
	border-radius: 0
}

.wizard .content .body {
	padding: 15px
}

.wizard .content .body label.error {
	color: #c62828
}

.wizard .content .body input[type="checkbox"] {
	display: none
}

.wizard .actions {
	position: relative;
	width: 100%;
	margin-top: 10px !important
}

.wizard .actions ul>li {
	float: left;
	margin: 0 !important
}

.wizard .actions a {
	color: #fff;
	text-decoration: none
}

.wizard .actions a:hover,
.wizard .actions a:active {
	color: #fff;
	text-decoration: none
}

.wizard .actions .disabled a {
	background: #eee;
	color: #aaa
}

.wizard .actions .disabled a:hover,
.wizard .actions .disabled a:active {
	background: #eee;
	color: #aaa
}

.wizard .steps {
	position: relative;
	width: 100%;
	margin-bottom: 2px
}

.wizard .steps a {
	width: auto;
	margin: 0 2px 0 0;
	padding: 10px;
	text-decoration: none;
	border-radius: 0
}

.wizard .steps a:hover,
.wizard .steps a:active {
	width: auto;
	margin: 0 2px 0 0;
	padding: 10px;
	text-decoration: none;
	border-radius: 0
}

.wizard .steps .disabled a {
	background: #eee;
	color: #bdbdbd;
	cursor: default
}

.wizard .steps .disabled a:hover,
.wizard .steps .disabled a:active {
	background: #eee;
	color: #bdbdbd;
	cursor: default
}

.wizard .steps .current a {
	color: #fff;
	cursor: default
}

.wizard .steps .current a:hover,
.wizard .steps .current a:active {
	color: #fff;
	cursor: default
}

.wizard .steps .done a {
	color: #fff;
	opacity: 0.7
}

.wizard .steps .done a:hover,
.wizard .steps .done a:active {
	color: #fff;
	opacity: 0.7
}

.wizard .steps>ul>li {
	width: 25%;
	float: left
}

.wizard .steps>ul>li:last-child a {
	margin: 0
}

.wizard.vertical>.steps {
	float: left;
	width: 30%
}

.wizard.vertical>.steps>ul>li {
	float: none;
	width: 100%
}

.wizard.vertical>.steps>ul>li a {
	margin: 0 0 2px 0
}

.wizard.vertical>.content {
	float: left;
	width: 70%;
	margin: 0
}

.wizard.vertical>.actions {
	float: right;
	width: 100%;
	margin: 0
}

.wizard_validation .wizard .steps>ul>li {
	width: auto !important
}

@media screen and (max-width: 767px) {
	.wizard>.steps>ul>li {
		width: 100%;
		margin-bottom: 2px
	}
	.wizard>.steps a {
		margin: 0
	}
	.wizard.wizard.vertical>.content,
	.wizard.wizard.vertical>.steps {
		width: 100%;
		padding: 0
	}
	.wizard_validation .wizard .steps>ul>li {
		width: 100% !important
	}
}

.waves-effect.waves-red .waves-ripple {
	background: rgba(236, 59, 87, 0.5)
}

.waves-effect.waves-pink .waves-ripple {
	background: rgba(233, 30, 99, 0.5)
}

.waves-effect.waves-purple .waves-ripple {
	background: rgba(101, 114, 184, 0.5)
}

.waves-effect.waves-deep-purple .waves-ripple {
	background: rgba(103, 58, 183, 0.5)
}

.waves-effect.waves-blue .waves-ripple {
	background: rgba(96, 186, 253, 0.5)
}

.waves-effect.waves-cyan .waves-ripple {
	background: rgba(0, 207, 209, 0.5)
}

.waves-effect.waves-green .waves-ripple {
	background: rgba(80, 211, 138, 0.5)
}

.waves-effect.waves-light-green .waves-ripple {
	background: rgba(139, 195, 74, 0.5)
}

.waves-effect.waves-yellow .waves-ripple {
	background: rgba(255, 232, 33, 0.5)
}

.waves-effect.waves-orange .waves-ripple {
	background: rgba(255, 195, 35, 0.5)
}

.waves-effect.waves-deep-orange .waves-ripple {
	background: rgba(248, 54, 0, 0.5)
}

.waves-effect.waves-grey .waves-ripple {
	background: rgba(158, 158, 158, 0.5)
}

.waves-effect.waves-blue-grey .waves-ripple {
	background: rgba(96, 125, 139, 0.5)
}

.waves-effect.waves-indigo .waves-ripple {
	background: rgba(63, 81, 181, 0.5)
}

.waves-effect.waves-light-blue .waves-ripple {
	background: rgba(3, 169, 244, 0.5)
}

.waves-effect.waves-teal .waves-ripple {
	background: rgba(0, 150, 136, 0.5)
}

.waves-effect.waves-lime .waves-ripple {
	background: rgba(205, 220, 57, 0.5)
}

.waves-effect.waves-amber .waves-ripple {
	background: rgba(255, 193, 7, 0.5)
}

.waves-effect.waves-brown .waves-ripple {
	background: rgba(121, 85, 72, 0.5)
}

.waves-effect.waves-blush .waves-ripple {
	background: rgba(255, 117, 142, 0.5)
}

.waves-effect.waves-black .waves-ripple {
	background: rgba(0, 0, 0, 0.5)
}

.waves-effect.waves-white .waves-ripple {
	background: rgba(255, 255, 255, 0.5)
}

.dropdown-menu {
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	border: none;
	right: 0;
	left: auto;
	padding: 0;
	transform: none !important
}

.dropdown-menu .divider {
	margin: 1px 0;
	border-bottom: 1px solid #eaeaea
}

.dropdown-menu .header {
	font-weight: 600;
	min-width: 270px;
	border-bottom: 1px solid #eee;
	text-align: left;
	padding: 10px 15px
}

.dropdown-menu ul.menu {
	padding-left: 0
}

.dropdown-menu ul.menu .icon-circle {
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	border-radius: 50%;
	width: 36px;
	height: 36px;
	color: #fff;
	vertical-align: top
}

.dropdown-menu ul.menu .icon-circle i {
	line-height: 36px
}

.dropdown-menu ul.menu li a {
	-moz-transition: .5s;
	-o-transition: .5s;
	-webkit-transition: .5s;
	transition: .5s;
	padding: 15px;
	text-decoration: none
}

.dropdown-menu ul.menu li a:hover {
	background-color: #e9e9e9
}

.dropdown-menu ul.menu .menu-info {
	position: relative;
	top: 0px;
	left: 5px
}

.dropdown-menu ul.menu .menu-info h4 {
	color: #424242;
	font-weight: 400
}

.dropdown-menu ul.menu .menu-info p {
	color: #78909c
}

.dropdown-menu ul.menu .menu-info p i {
	color: #78909c;
	position: relative;
	top: 3px;
	vertical-align: top
}

.dropdown-menu .footer {
	padding: 0
}

.dropdown-menu .footer a {
	color: inherit !important;
	border-top: 1px solid #eee
}

.dropdown-menu>li>a {
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
	color: #616161;
	padding: 7px 18px;
	line-height: 25px
}

.dropdown-menu>li>a:hover {
	background-color: rgba(0, 0, 0, 0.1);
	text-decoration: none;
	color: #24241e
}

.dropdown-menu>li>a i.material-icons {
	float: left;
	margin-right: 7px;
	margin-top: 2px
}

.dropdown .dropdown-toggle:after {
	display: none
}

.bootstrap-notify-container {
	max-width: 320px;
	text-align: center;
	border-radius: .1875rem
}

.dd-handle {
	background-color: #f9f9f9 !important
}

.dd-handle:hover {
	color: #2196F3
}

.nestable-dark-theme .dd-handle {
	background: #ccc !important;
	border: 1px solid #999 !important
}

.dd3-handle {
	background: #999 !important
}

.dd3-content:hover {
	color: #2196F3
}

.page-calendar .event-name {
	padding: 10px 0;
	margin: 2px 0;
	border-left: 3px solid
}

.page-calendar .event-name:hover {
	background: #eee
}

.page-calendar .event-name h6 {
	text-transform: capitalize
}

.page-calendar .event-name h4 {
	font-size: 25px;
	line-height: 24px
}

.page-calendar .event-name h4 span {
	font-size: 11px;
	line-height: 16px
}

.page-calendar .event-name p {
	overflow: hidden;
	white-space: nowrap;
	width: 100%;
	text-overflow: ellipsis
}

.page-calendar .event-name address {
	font-size: 11px
}

.page-calendar .event-name address i {
	font-size: 12px
}

.page-calendar .b-primary {
	border-color: #2196f3 !important
}

.page-calendar .b-success {
	border-color: #4caf50 !important
}

.page-calendar .b-lightred {
	border-color: #f44336 !important
}

.page-calendar .b-greensea {
	border-color: #4caf50 !important
}

.page-calendar #calendar {
	max-width: 900px
}

.event-control {
	background-color: #eee;
	font-size: 13px;
	cursor: pointer
}

.event-control:hover {
	background-color: #fff
}

.event-control a {
	color: #000;
	cursor: pointer;
	opacity: .5
}

.event-control a:hover {
	opacity: 1
}

.event-control[class*='bg-']:not(.bg-default):not(.bg-white) a {
	color: #000 !important;
	opacity: 1
}

.event-control[class*='bg-']:not(.bg-default):not(.bg-white) a:hover {
	color: #fff !important
}

.fc-overlay {
	display: none;
	position: absolute;
	min-width: 260px;
	padding: 15px 0;
	top: auto;
	left: 50%;
	margin-left: -150px;
	z-index: 1000;
	color: #000 !important
}

.fc-overlay.left {
	left: 100%;
	top: -15px;
	padding: 0 10px;
	margin: 0
}

.fc-overlay.right {
	left: auto;
	right: 100%;
	top: -15px;
	padding: 0 10px
}

.fc-overlay .panel {
	padding: 10px
}

.fc .fc-toolbar {
	height: 50px;
	color: #fff;
	background: #37474f;
	border-radius: 0
}

.fc .fc-toolbar .fc-button {
	color: #fff;
	background: transparent;
	border: none;
	box-shadow: none;
	text-shadow: none;
	margin: 0;
	height: 50px;
	line-height: 28px;
	padding: 10px
}

.fc .fc-toolbar .fc-button:hover {
	color: #fff
}

.fc .fc-toolbar h2 {
	font-size: 24px;
	line-height: 50px;
	text-transform: uppercase;
	font-weight: 100
}

.fc .fc-view-container .fc-event {
	padding: 3px 5px;
	font-size: 12px;
	border-radius: 0;
	background-color: #fff;
	border: 0;
	color: #424242;
	border-left: 3px solid
}

.fc .fc-view-container .fc-event.fc-event-end:not(.fc-event-start) {
	border: 0 !important
}

.fc .fc-view-container .fc-event:focus,
.fc .fc-view-container .fc-event:hover,
.fc .fc-view-container .fc-event:active {
	z-index: 1000
}

.fc .fc-view-container .fc-event:focus .fc-overlay,
.fc .fc-view-container .fc-event:hover .fc-overlay,
.fc .fc-view-container .fc-event:active .fc-overlay {
	display: block
}

.fc .fc-view-container .fc-view.fc-basic-view>table>thead tr th.fc-widget-header,
.fc .fc-view-container .fc-view.fc-basic-view>table>thead tr td.fc-widget-header,
.fc .fc-view-container .fc-view.fc-agenda>table>thead tr th.fc-widget-header,
.fc .fc-view-container .fc-view.fc-agenda>table>thead tr td.fc-widget-header {
	border: 0;
	background-color: #d2d2d2;
	padding: 10px;
	color: #000;
	font-size: 12px;
	font-weight: normal
}

.fc .fc-view-container .fc-view.fc-basic-view>table>thead tr td.fc-widget-header,
.fc .fc-view-container .fc-view.fc-agenda>table>thead tr td.fc-widget-header {
	padding: 2px
}

.fc .fc-view-container .fc-view.fc-basic-view>table tbody tr td.fc-widget-content,
.fc .fc-view-container .fc-view.fc-agenda>table tbody tr td.fc-widget-content {
	border: 0;
	border-bottom: 1px solid #eee;
	border-right: 1px solid #eee
}

.fc .fc-view-container .fc-view.fc-basic-view>table tbody tr td.fc-widget-content.fc-state-highlight,
.fc .fc-view-container .fc-view.fc-agenda>table tbody tr td.fc-widget-content.fc-state-highlight {
	background: #fff
}

.fc .fc-view-container .fc-view.fc-basic-view>table tbody tr td.fc-day-number,
.fc .fc-view-container .fc-view.fc-agenda>table tbody tr td.fc-day-number {
	padding: 5px 10px;
	margin: 5px;
	color: #000;
	font-size: 15px
}

.fc .fc-view-container .fc-view.fc-basic-view>table tbody tr td.fc-day-number.fc-state-highlight,
.fc .fc-view-container .fc-view.fc-agenda>table tbody tr td.fc-day-number.fc-state-highlight {
	background-color: red;
	color: #fff;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	border-radius: 50%;
	padding: 4px 6px;
	float: right
}

.fc .fc-view-container .fc-view.fc-basic-view>table tbody tr td:first-child.fc-widget-content,
.fc .fc-view-container .fc-view.fc-agenda>table tbody tr td:first-child.fc-widget-content {
	border-left: 1px solid #eee
}

.fc .fc-view-container .fc-view.fc-basic-view .fc-body .fc-row .fc-bg,
.fc .fc-view-container .fc-view.fc-agenda .fc-body .fc-row .fc-bg {
	border-bottom: 1px solid #eee
}

.fc .fc-view-container .fc-view.fc-basic-view .fc-body .fc-row:last-of-type .fc-bg,
.fc .fc-view-container .fc-view.fc-agenda .fc-body .fc-row:last-of-type .fc-bg {
	border-bottom: 0
}

.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-allday .fc-agenda-axis,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-allday .fc-agenda-gutter {
	border-left: 1px solid #e0e0e0;
	border-right: 1px solid #e0e0e0;
	background-color: #eee;
	color: #000;
	font-size: 12px;
	font-weight: 100;
	padding: 10px 5px
}

.fc .fc-view-container .fc-view.fc-agenda>table>tbody>tr>td.fc-state-highlight {
	background: #eee
}

.fc .fc-view-container .fc-view.fc-agenda>table>tbody>tr>td.fc-sat {
	border-right: 0
}

.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-slots tr {
	line-height: 35px
}

.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-slots tr th {
	border: 0;
	border-left: 1px solid #e0e0e0;
	border-right: 1px solid #e0e0e0;
	background-color: #eee;
	color: #000;
	font-size: 12px;
	font-weight: 100;
	padding-top: 10px
}

.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-slots tr td.fc-widget-content {
	border-bottom: 1px solid #e0e0e0;
	line-height: 50px
}

.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-slots tr td.fc-widget-content:last-child {
	border-right: 0
}

.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-slots tr.fc-minor td.fc-widget-content {
	border-bottom: 1px solid #e0e0e0
}

.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-divider {
	border: 1px solid #e0e0e0
}

.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-divider .fc-agenda-divider-inner {
	background-color: #eee;
	height: 3px
}

.btn.fc-state-active {
	background-color: #888;
	color: #fff
}

.btn.fc-state-active:focus {
	background-color: #888;
	color: #fff
}

.gmap {
	width: 100%;
	height: 400px
}

.jvector-map {
	width: 100%;
	height: 400px
}

.morris-hover.morris-default-style {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	border-radius: 0
}

.flot-chart {
	width: 100%;
	height: 320px
}

.panel-switch-btn {
	position: relative;
	right: 20px;
	z-index: 9
}

.panel-switch-btn label {
	font-weight: bold !important
}

.legendLabel {
	width: 85px !important;
	position: relative;
	left: 3px
}

#multiple_axis_chart .legendLabel {
	width: 160px !important
}

.sparkline {
	text-align: center
}

.search-bar {
	-moz-transition: .25s;
	-o-transition: .25s;
	-webkit-transition: .25s;
	transition: .25s;
	position: fixed;
	top: -100px;
	left: 0;
	z-index: 9999999;
	width: 100%;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5)
}

.search-bar.open {
	top: 0
}

.search-bar .search-icon {
	position: absolute;
	top: 14px;
	left: 14px
}

.search-bar .search-icon .material-icons {
	color: #999
}

.search-bar .close-search {
	position: absolute;
	cursor: pointer;
	top: 6px;
	right: 18px
}

.search-bar .close-search .material-icons {
	-moz-transition: .5s;
	-o-transition: .5s;
	-webkit-transition: .5s;
	transition: .5s;
	color: #999;
	opacity: 1
}

.search-bar .close-search .material-icons:hover {
	opacity: .5
}

.search-bar input[type="text"] {
	width: 100%;
	padding: 15px 60px 15px 56px;
	border: none
}

.search_result_page .title {
	font-weight: 500
}

.search_result_page .not_found {
	padding: 50px 0
}

.search_result_page .not_found h4 {
	text-transform: uppercase
}

.dataTables_wrapper {
	position: relative
}

.dataTables_wrapper select {
	border: none;
	border-bottom: 1px solid #ddd;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	border-radius: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	-ms-box-shadow: none;
	box-shadow: none
}

.dataTables_wrapper select:active,
.dataTables_wrapper select:focus {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	-ms-box-shadow: none;
	box-shadow: none
}

.dataTables_wrapper input[type="search"] {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	border-radius: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	-ms-box-shadow: none;
	box-shadow: none;
	border: none;
	font-size: 12px;
	border-bottom: 1px solid #ddd
}

.dataTables_wrapper input[type="search"]:focus,
.dataTables_wrapper input[type="search"]:active {
	border-bottom: 2px solid #1f91f3
}

.dataTables_wrapper .dt-buttons {
	float: left
}

.dataTables_wrapper .dt-buttons a.dt-button {
	background-color: #607D8B;
	color: #fff;
	padding: 7px 12px;
	margin-right: 5px;
	text-decoration: none;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	-ms-border-radius: 2px;
	border-radius: 2px;
	border: none;
	font-size: 13px;
	outline: none
}

.dataTables_wrapper .dt-buttons a.dt-button:active {
	opacity: 0.8
}

.dt-button-info {
	position: fixed;
	top: 50%;
	left: 50%;
	min-width: 400px;
	text-align: center;
	background-color: #fff;
	border: 2px solid #999;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	margin-top: -100px;
	margin-left: -200px;
	z-index: 21
}

.dt-button-info h2 {
	color: #777
}

.dt-button-info div {
	color: #777;
	margin-bottom: 20px
}

.lg-outer .lg-thumb-item,
.lg-outer .lg-toogle-thumb {
	-webkit-border-radius: 0 !important;
	-moz-border-radius: 0 !important;
	-ms-border-radius: 0 !important;
	border-radius: 0 !important
}

.select2-container .select2-choice {
	border: none;
	background-image: none;
	box-shadow: none;
	padding: 0;
	margin: 0
}

.select2-container .select2-choice:focus,
.select2-container .select2-choice:hover,
.select2-container .select2-choice:active {
	box-shadow: none !important;
	border: 0 !important
}

.select2-container .select2-choice .select2-arrow {
	border-left: none;
	background-image: none;
	background: transparent
}

.select2-container.select2-dropdown-open .select2-choice {
	background: transparent;
	box-shadow: none !important
}

.select2-drop {
	border-radius: 0;
	border: 0 !important
}

.select2-container-multi .select2-choices {
	border: 0 !important;
	padding: 0;
	margin: 0;
	background-image: none;
	box-shadow: none !important
}

.select2-container-multi .select2-choices:focus,
.select2-container-multi .select2-choices:hover,
.select2-container-multi .select2-choices:active {
	box-shadow: none !important;
	border: none;
	border: 0 !important
}

.activity li a:hover,
.activity li a:focus {
	text-decoration: none
}

.activity li a i {
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	float: left;
	width: 40px;
	height: 40px;
	line-height: 40px
}

.activity li a .info {
	margin-left: 50px
}

.activity li a .info h4 {
	color: #424242;
	line-height: 18px
}

.activity li a .info small {
	color: #78909c
}

.activity_2 h5 {
	color: #fff
}

.activity_2 .profile_state li {
	border: 1px solid #eee
}

.user_activity .streamline {
	position: relative;
	border-color: #e0e0e0
}

.user_activity .streamline .b-info {
	border-color: #2196f3
}

.user_activity .streamline .b-primary {
	border-color: #673ab7
}

.user_activity .streamline .b-warning {
	border-color: #ff9800
}

.user_activity .b-accent {
	border-color: #e0e0e0 !important;
	border-left: 1px solid
}

.user_activity .b-accent .sl-item {
	position: relative
}

.user_activity .b-accent .sl-item .user {
	position: absolute;
	width: 35px;
	left: -18px;
	border: 2px solid #e0e0e0;
	top: 3px
}

.team-info li+li {
	margin-left: -10px
}

.team-info li img {
	width: 32px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	border-radius: 50%;
	border: 2px solid #fff;
	box-shadow: 0px 2px 10px 0px rgba(33, 33, 33, 0.2)
}

.chat-widget .chat-scroll-list {
	padding: 0
}

.chat-widget .chat-scroll-list li {
	list-style: none;
	width: 100%
}

.chat-widget .chat-scroll-list li.left img {
	float: left
}

.chat-widget .chat-scroll-list li.left .message {
	padding: 10px 20px;
	border-radius: 0 15px 15px 15px;
	background: #616161;
	color: #fff
}

.chat-widget .chat-scroll-list li.right {
	text-align: right
}

.chat-widget .chat-scroll-list li.right .chat-info {
	background: #616161;
	padding: 10px 20px;
	border-radius: 15px 0 15px 15px;
	color: #fff
}

.chat-widget .chat-scroll-list li .datetime {
	color: #bdbdbd
}

.chat-widget .chat-scroll-list li img {
	height: 40px;
	width: 40px
}

.chat-launcher {
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	border-radius: 50%;
	box-shadow: 0 0 6px rgba(33, 33, 33, 0.16), 0 6px 12px rgba(33, 33, 33, 0.32);
	position: fixed;
	bottom: 20px;
	right: 20px;
	width: 40px;
	height: 40px;
	z-index: 12;
	background: #212121;
	color: #fff;
	cursor: pointer
}

.chat-launcher:before,
.chat-launcher:after {
	font-family: "Material-Design-Iconic-Font";
	position: absolute;
	right: 15px;
	top: 9px;
	-webkit-transition: transform 180ms linear, opacity 130ms linear;
	transition: transform 180ms linear, opacity 130ms linear
}

.chat-launcher:before {
	content: "\f266";
	opacity: 1;
	-webkit-transform: rotate(0deg) scale(1);
	transform: rotate(0deg) scale(1)
}

.chat-launcher:after {
	content: "\f136";
	opacity: 0;
	-webkit-transform: rotate(-30deg);
	transform: rotate(-30deg)
}

.chat-launcher:hover {
	background: #424242
}

.chat-launcher.active:before {
	opacity: 0;
	-webkit-transform: rotate(70deg) scale(0);
	transform: rotate(70deg) scale(0)
}

.chat-launcher.active:after {
	opacity: 1;
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.chat-wrapper {
	width: 385px;
	position: fixed;
	right: 40px;
	bottom: 40px;
	z-index: 11;
	-webkit-transition: transform 400ms ease;
	transition: transform 400ms ease;
	-webkit-transform: translateY(130%);
	transform: translateY(130%)
}

.chat-wrapper.is-open {
	-webkit-transform: translateY(0);
	transform: translateY(0)
}

.chat-wrapper .card {
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	border-radius: 10px;
	box-shadow: 1px 1px 100px 2px rgba(33, 33, 33, 0.22)
}

.chat-wrapper .card .header {
	border-radius: 10px 10px 0 0
}

.project_list .table .avatar {
	width: 40px
}

.project_list .table tbody tr td,
.project_list .table tbody th td {
	vertical-align: middle
}

.widget_2 li {
	border-right: 1px solid #eee
}

.widget_2 li:last-child {
	border: none
}

.widget_2 .body {
	padding: 20px
}

.widget_2 h2 {
	font-weight: 300;
	color: #bdbdbd;
	margin-top: -10px
}

.project_widget .pw_img {
	position: relative;
	overflow: hidden
}

.project_widget .pw_img:before {
	position: absolute;
	top: 0;
	left: -75%;
	z-index: 2;
	content: '';
	width: 50%;
	height: 100%;
	background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
	background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
	-webkit-transform: skewX(-25deg);
	transform: skewX(-25deg)
}

.project_widget .pw_img:hover::before {
	-webkit-animation: shine .75s;
	animation: shine .75s
}

@-webkit-keyframes shine {
	100% {
		left: 125%
	}
}

@keyframes shine {
	100% {
		left: 125%
	}
}

.project_widget .pw_img img {
	border-radius: 3px 3px 0 0
}

.project_widget .pw_content .pw_header {
	padding: 20px;
	border-bottom: 1px solid #eee
}

.project_widget .pw_content .pw_meta {
	padding: 20px
}

.project_widget .pw_content .pw_meta span {
	font-weight: 500
}

.twitter-feed {
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	background: #32cdfd;
	color: #fff
}

.twitter-feed .carousel-inner {
	box-shadow: none
}

.twitter-feed .carousel-inner .carousel-item {
	padding: 15px
}

.twitter-feed .zmdi-twitter {
	font-size: 50px
}

.twitter-feed p i {
	padding: 0 3px
}

.facebook-feed {
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	background: #3b5998;
	color: #fff
}

.facebook-feed .carousel-inner {
	box-shadow: none
}

.facebook-feed .carousel-inner .carousel-item {
	padding: 15px
}

.facebook-feed .zmdi-facebook {
	font-size: 50px
}

.facebook-feed p i {
	padding: 0 3px
}

.weather .city {
	position: relative
}

.weather .city img {
	width: 50px;
	position: absolute;
	right: 0;
	top: 0
}

.weather .days li {
	width: 13.20%
}

.weather .days li img {
	width: 25px
}

.weather2 .city-selected {
	position: relative;
	overflow: hidden;
	border-radius: 3px 3px 0 0
}

.weather2 .city-selected .city span {
	font-weight: 700;
	text-transform: lowercase
}

.weather2 .city-selected .night {
	text-transform: uppercase
}

.weather2 .city-selected .temp h2 {
	font-size: 73px;
	position: relative;
	font-weight: 700
}

.weather2 .city-selected img {
	width: 70px
}

.weather2 .table tbody tr td {
	padding: 14px 20px
}

.weather2 .days-list .day {
	padding: 20px 10px
}

.weather2 .days-list .day img {
	width: 40px
}

.weather2 .carousel .carousel-inner {
	box-shadow: none
}

.agent .agent-avatar {
	width: 100%
}

.agent .agent-avatar a {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden
}

.agent .agent-content .agent-name {
	padding: 20px
}

.agent .agent-content .agent-contact-details {
	color: #9e9e9e;
	list-style: none;
	padding: 20px !important;
	background-color: #f5f5f5
}

.agent .agent-content .agent-contact-details li {
	position: relative;
	margin-left: 28px;
	line-height: 24px;
	padding: 3px 0;
	word-break: break-all
}

.agent .agent-content .agent-contact-details li i {
	top: 5px;
	left: -28px;
	position: absolute
}

.agent .agent-content .social-icons {
	padding: 10px 17px
}

.agent .agent-content .social-icons li {
	list-style: none
}

.agent .agent-content .social-icons li a {
	padding: 8px 15px
}

.product-report .counter {
	font-weight: 400
}

.product-report .icon {
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	line-height: 50px;
	float: left;
	width: 50px
}

.inbox-widget .inbox-inner {
	float: left;
	width: 100%;
	border-bottom: 1px solid #eee;
	padding: 14px 0px;
	position: relative
}

.inbox-widget .inbox-inner:last-child {
	border: 0
}

.inbox-widget .inbox-inner .hover_action {
	display: none;
	position: absolute;
	right: 0px;
	top: 8px
}

.inbox-widget .inbox-inner .hover_action .btn {
	padding: 15px
}

.inbox-widget .inbox-inner .inbox-img {
	float: left
}

.inbox-widget .inbox-inner .inbox-img img {
	width: 60px;
	border: 2px solid #fff
}

.inbox-widget .inbox-inner .inbox-item-info {
	margin-left: 75px;
	position: relative
}

.inbox-widget .inbox-inner .inbox-item-info .author {
	color: #37474f;
	font-weight: 600
}

.inbox-widget .inbox-inner .inbox-item-info .inbox-message {
	color: #616161;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap
}

.members_profiles .table tbody tr td,
.members_profiles .table tbody th td {
	padding: 10px;
	vertical-align: middle
}

.member-card .header {
	min-height: 150px
}

.member-card .member-img {
	position: relative;
	margin-top: -70px
}

.member-card .member-img img {
	width: 150px;
	border: 3px solid #fff;
	box-shadow: 0px 10px 25px 0px rgba(33, 33, 33, 0.3)
}

.member-card .social-links li a {
	padding: 5px 10px
}

.social-widget .text {
	margin-top: 0 !important
}

.social-widget .facebook-widget .icon i {
	color: #3B5998
}

.social-widget .google-widget .icon i {
	color: #DB4437
}

.social-widget .twitter-widget .icon i {
	color: #1DA1F2
}

.social-widget .instagram-widget .icon i {
	color: #C32AA3
}

.social-widget .linkedin-widget .icon i {
	color: #0077B5
}

.social-widget .behance-widget .icon i {
	color: #053eff
}

.social_widget2 {
	background: transparent
}

.social_widget2:hover .data {
	z-index: 1
}

.social_widget2:hover .name {
	z-index: 2
}

.social_widget2 .data {
	border-radius: .1875rem;
	background: #fff;
	position: absolute;
	top: 0;
	left: 0;
	padding: 30px;
	width: calc(100% - 25px);
	box-shadow: 0 22px 43px rgba(33, 33, 33, 0.15);
	z-index: 2
}

.social_widget2 .name {
	position: relative;
	z-index: 1;
	border-radius: .1875rem
}

.social_widget2 .name.facebook {
	background: #3B5998
}

.social_widget2 .name.dribbble {
	background: #EA4C89
}

.social_widget2 .name.twitter {
	background: #1DA1F2
}

.social_widget2 .name.instagram {
	background: #C32AA3
}

.social_widget2 .name.google {
	background: #DB4437
}

.social_widget2 .name.youtube {
	background: red
}

.social_widget2 .name .progress-container .progress-badge {
	color: #fff
}

.social_widget2 .name .progress-container .progress {
	background: rgba(255, 255, 255, 0.3)
}

.social_widget2 .name .progress-container .progress .progress-bar {
	background-color: #fff
}

.social_widget2 .name .progress-container .progress .progress-value {
	color: #fff
}

.social_widget2 .name h5 {
	color: #fff
}

.profile_state li {
	border-right: 1px solid #eee
}

.profile_state li:last-child {
	border: none
}

.top-report .progress {
	height: 3px
}

.social_media_table .table tbody tr td {
	vertical-align: middle
}

.social_media_table .list-name {
	text-transform: uppercase
}

.social_icon {
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	-ms-border-radius: 50px;
	border-radius: 50px;
	color: #fff;
	line-height: 40px;
	width: 40px;
	height: 40px
}

.social_icon.linkedin {
	background: #0077B5
}

.social_icon.twitter-table {
	background: #1DA1F2
}

.social_icon.facebook {
	background: #3B5998
}

.social_icon.google {
	background: #DB4437
}

.social_icon.youtube {
	background: red
}

.new_friend_list li a:hover,
.new_friend_list li a:focus {
	text-decoration: none
}

.new_friend_list li .users_name {
	color: #424242;
	text-transform: capitalize
}

.new_friend_list li .join_date {
	color: #757575
}

.card-group .card+.card {
	border-left: 1px solid #bdbdbd
}

.w_calender .date {
	height: 150px;
	border-radius: 3px 3px 0 0
}

.w_calender .days {
	clear: both
}

.w_calender span {
	color: #fff;
	font-weight: 400
}

.w_calender span+span {
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	-ms-border-radius: 20px;
	border-radius: 20px;
	padding: 5px 25px
}

.w_calender ul {
	list-style-type: none
}

.w_calender ul li {
	width: 13.0%
}

.w_calender ul:nth-of-type(2) {
	line-height: 20px
}

.w_calender ul:nth-of-type(3) {
	line-height: 20px
}

.w_calender ul:nth-of-type(4) {
	line-height: 20px
}

.w_calender ul:nth-of-type(5) {
	line-height: 20px
}

.w_calender ul:nth-of-type(6) {
	line-height: 20px
}

.w_calender ul:nth-of-type(6) li:nth-child(1n+4) {
	color: #bdc3c7
}

.w_calender em {
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	-ms-border-radius: 50px;
	border-radius: 50px;
	padding: 8px;
	color: #fff;
	box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.2)
}

@media screen and (max-width: 992px) {
	.chat-widget .chat-scroll-list li {
		width: 100%;
		max-width: initial
	}
	.chat-widget .chat-scroll-list li .message {
		display: block
	}
	.widget_2 li {
		border: 1px solid #eee !important
	}
}

@media screen and (max-width: 767px) {
	.member-card .s-profile {
		text-align: center
	}
	.profile_state li {
		border-bottom: 1px solid #eee
	}
	.profile_state li:last-child {
		border: none
	}
	.card-group .card+.card {
		border-left: none
	}
	.chat-wrapper {
		right: 30px;
		width: 310px
	}
}

@keyframes fadeIn {
	to {
		opacity: 1
	}
}

.contact .action_bar .delete_all {
	margin-top: 8px
}

.contact .c_list tr td {
	vertical-align: middle
}

.contact .c_list .c_name {
	font-weight: 600
}

.contact .c_list .avatar {
	width: 40px;
	box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
	border: 1px solid #fff
}

.contact .c_list address i {
	width: 25px;
	text-align: center
}

.profile-page .profile-header {
	position: relative
}

.profile-page .profile-header .profile-image img {
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	border-radius: 50%;
	width: 180px;
	border: 3px solid #fff;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.profile-page .profile-header .social-icon a {
	margin: 0 5px
}

.profile-page .profile-sub-header {
	min-height: 60px;
	width: 100%
}

.profile-page .profile-sub-header ul.box-list {
	background: #eee;
	display: inline-table;
	table-layout: fixed;
	width: 100%
}

.profile-page .profile-sub-header ul.box-list li {
	border-right: 1px solid #e0e0e0;
	display: table-cell;
	list-style: none
}

.profile-page .profile-sub-header ul.box-list li:last-child {
	border-right: none
}

.profile-page .profile-sub-header ul.box-list li a {
	display: block;
	padding: 15px 0;
	color: #424242
}

.profile-page #timeline .timeline {
	border-left: 1px solid #bdbdbd;
	position: relative
}

.profile-page #timeline .timeline .border-info {
	border-color: #2CA8FF
}

.profile-page #timeline .timeline .border-warning {
	border-color: #FFB236
}

.profile-page #timeline .timeline .border-danger {
	border-color: #FF3636
}

.profile-page #timeline .timeline .timeline-item {
	position: relative
}

.profile-page #timeline .timeline .timeline-item:after {
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	border-radius: 50%;
	background-color: #fff;
	border-color: inherit;
	border-style: solid;
	border-width: 2px;
	content: "";
	height: 11px;
	left: 0;
	margin-left: -6px;
	position: absolute;
	width: 11px;
	bottom: auto;
	clear: both;
	top: 4px
}

.profile-page #timeline .timeline .timeline-item .item-content {
	margin-left: 24px
}

.profile-page #timeline .timeline .timeline-item .item-content p {
	font-weight: 400;
	color: #444
}

@media screen and (max-width: 992px) {
	.profile-page .nav-tabs {
		padding-left: 0;
		padding-right: 0
	}
	.profile-page .nav-tabs .nav-item {
		display: inline-block
	}
}

@media screen and (max-width: 767px) {
	.profile-page .profile-header {
		text-align: center
	}
	.profile-page .profile-header .profile-image {
		margin-bottom: 15px
	}
}

.sprice .sprice-circle {
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
	-webkit-border-radius: 80px;
	-moz-border-radius: 80px;
	-ms-border-radius: 80px;
	border-radius: 80px;
	font-size: 50px;
	height: 140px;
	width: 140px;
	border: 1px solid;
	display: inline-block;
	padding: 35px 30px 5px 30px
}

.sprice .sprice-circle span {
	vertical-align: top;
	position: relative;
	top: 10px
}

.sprice li {
	border-bottom: 1px solid #eee
}

.sprice2 {
	background: transparent
}

.sprice2 .data {
	background: #fff;
	position: absolute;
	top: 0;
	left: 0;
	padding: 30px 10px;
	width: calc(100% - 25px);
	box-shadow: 0 22px 43px rgba(0, 0, 0, 0.15);
	z-index: 2;
	border-radius: .1875rem
}

.sprice2 .data li {
	border-bottom: 1px solid #eee
}

.sprice2 .name {
	padding-top: 385px;
	position: relative;
	z-index: 1;
	border-radius: .1875rem
}

.sprice2 .name h5 {
	margin: 0;
	color: #fff
}

.invoice .table tr td {
	vertical-align: middle
}

.file_manager .file {
	position: relative
}

.file_manager .file:hover {
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.2)
}

.file_manager .file .image,
.file_manager .file .icon {
	height: 150px;
	overflow: hidden;
	background-size: cover;
	background-position: top
}

.file_manager .file .hover {
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	position: absolute;
	right: 10px;
	top: 10px;
	display: none
}

.file_manager .file a:hover .hover {
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	display: block
}

.file_manager .file .icon {
	padding: 15px 10px;
	display: table;
	width: 100%;
	text-align: center
}

.file_manager .file .icon i {
	color: #757575;
	display: table-cell;
	vertical-align: middle
}

.file_manager .file .file-name {
	padding: 10px;
	border-top: 1px solid #eceff1
}

.file_manager .file .file-name small .date {
	float: right
}

@media screen and (max-width: 992px) {
	.file_manager .nav-tabs {
		padding-left: 0;
		padding-right: 0
	}
	.file_manager .nav-tabs .nav-item {
		display: inline-block
	}
}